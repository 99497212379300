import React from "react";

import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import { formatPrice } from "modules/utils";

type PriceProps = {
  instalments: Quote["instalments"];
  normallyPayByDirectDebit: Risk["additionalQuestions"]["normallyPayByDirectDebit"];
  totalAnnualPrice: number;
};

const Price: React.FunctionComponent<PriceProps> = ({ instalments, normallyPayByDirectDebit, totalAnnualPrice }) => {
  return (
    <h1>
      <span>Your quote</span>
      {instalments ? (
        normallyPayByDirectDebit === false ? (
          <React.Fragment>
            <span>
              <strong>{formatPrice(totalAnnualPrice)}</strong> <span>annually</span>
            </span>
            <span>or {formatPrice(instalments.amount)} per month***</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>
              <strong>{formatPrice(instalments.amount)}</strong> <span>per month***</span>
            </span>
            <span>or {formatPrice(totalAnnualPrice)} annually</span>
          </React.Fragment>
        )
      ) : (
        <span>
          <strong>{formatPrice(totalAnnualPrice)}</strong> <span>per year</span>
        </span>
      )}
    </h1>
  );
};

export default React.memo(Price);
