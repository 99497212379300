import React from "react";

import { ReactComponent as DeleteSVG } from "modules/theme/icons/buttons/delete.svg";
import { ReactComponent as PhoneSVG } from "modules/theme/icons/header/phone.svg";
import { ReactComponent as EditSVG } from "modules/theme/icons/buttons/edit.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";
import { ReactComponent as PlusSVG } from "modules/theme/icons/buttons/plus.svg";
import { ReactComponent as ChatSVG } from "modules/theme/icons/header/chat.svg";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import PageWrapper, { Control } from "components/PageWrapper";
import QuestionsDebug from "modules/debug/questions";
import { FormProps, TemplateProps } from "./types";
import * as Form from "components/Form";

const Template: React.FunctionComponent<TemplateProps> = ({ debug, handleSubmit, schema }) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={debug}>
        {({ isSubmitting, ...form }) => (
          <React.Fragment>
            <QuestionsDebug isSubmitting={isSubmitting} {...form} />

            <QuestionsDebug disabled={true} isSubmitting={isSubmitting} {...form} />

            <Form.Fieldset title="Buttons">
              <div>
                <Button ariaLabel="Unstyled button" label="Unstyled button" />
              </div>

              <Button ariaLabel="Primary button" label="Primary button" style={ButtonStyle.PRIMARY} />

              <Button ariaLabel="Secondary button" label="Secondary button" style={ButtonStyle.SECONDARY} />

              <Button ariaLabel="Success button" label="Success button" style={ButtonStyle.SUCCESS} />

              <Button
                ariaLabel="Success inverted button"
                label="Success inverted button"
                style={ButtonStyle.SUCCESS_INVERTED}
              />

              <Button ariaLabel="Invalid button" label="Invalid button" style={ButtonStyle.INVALID} />

              <Button ariaLabel="Add a claim" label="Add a claim" style={ButtonStyle.PRIMARY}>
                <PlusSVG />
              </Button>

              <Button ariaLabel="Edit" label="Edit" style={ButtonStyle.SECONDARY}>
                <EditSVG />
              </Button>

              <Button ariaLabel="Delete" label="Delete" style={ButtonStyle.SECONDARY}>
                <DeleteSVG />
              </Button>

              <Button ariaLabel="Main rider" label="Main rider" style={ButtonStyle.SUCCESS}>
                <TickSVG />
              </Button>

              <Button ariaLabel="Main rider" label="Main rider" style={ButtonStyle.SUCCESS_INVERTED}>
                <TickSVG />
              </Button>

              <Button ariaLabel="Call" style={ButtonStyle.PRIMARY}>
                <PhoneSVG />
              </Button>

              <Button ariaLabel="Edit" style={ButtonStyle.SECONDARY}>
                <EditSVG />
              </Button>

              <Button ariaLabel="Chat" style={ButtonStyle.SUCCESS}>
                <ChatSVG />
              </Button>

              <Button ariaLabel="Chat" style={ButtonStyle.SUCCESS_INVERTED}>
                <ChatSVG />
              </Button>
              <div>
                <Button
                  ariaLabel="Primary clear button"
                  label="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}
                />

                <Button
                  ariaLabel="Secondary clear button"
                  label="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}
                />

                <Button
                  ariaLabel="Success clear button"
                  label="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}
                />

                <Button ariaLabel="Primary clear button" label="Primary clear button" style={ButtonStyle.PRIMARY_CLEAR}>
                  <PhoneSVG />
                </Button>

                <Button
                  ariaLabel="Secondary clear button"
                  label="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}>
                  <EditSVG />
                </Button>

                <Button ariaLabel="Success clear button" label="Success clear button" style={ButtonStyle.SUCCESS_CLEAR}>
                  <ChatSVG />
                </Button>

                <Button ariaLabel="Call" style={ButtonStyle.PRIMARY_CLEAR}>
                  <PhoneSVG />
                </Button>

                <Button ariaLabel="Edit" style={ButtonStyle.SECONDARY_CLEAR}>
                  <EditSVG />
                </Button>

                <Button ariaLabel="Chat" style={ButtonStyle.SUCCESS_CLEAR}>
                  <ChatSVG />
                </Button>
              </div>
            </Form.Fieldset>

            <Form.Fieldset title="Links">
              <div>
                <Button ariaLabel="Unstyled link" label="Unstyled link" type={ButtonType.LINK} />
              </div>

              <Button
                ariaLabel="Primary button"
                style={ButtonStyle.PRIMARY}
                label="Primary button"
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Secondary button"
                style={ButtonStyle.SECONDARY}
                label="Secondary button"
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Success button"
                style={ButtonStyle.SUCCESS}
                label="Success button"
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Success inverted button"
                style={ButtonStyle.SUCCESS_INVERTED}
                label="Success inverted button"
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Invalid button"
                style={ButtonStyle.INVALID}
                label="Invalid button"
                type={ButtonType.LINK}
              />

              <Button ariaLabel="Add a claim" style={ButtonStyle.PRIMARY} label="Add a claim" type={ButtonType.LINK}>
                <PlusSVG />
              </Button>

              <Button ariaLabel="Edit" style={ButtonStyle.SECONDARY} label="Edit" type={ButtonType.LINK}>
                <EditSVG />
              </Button>

              <Button ariaLabel="Delete" style={ButtonStyle.SECONDARY} label="Delete" type={ButtonType.LINK}>
                <DeleteSVG />
              </Button>

              <Button ariaLabel="Main rider" style={ButtonStyle.SUCCESS} label="Main rider" type={ButtonType.LINK}>
                <TickSVG />
              </Button>

              <Button
                ariaLabel="Main rider"
                style={ButtonStyle.SUCCESS_INVERTED}
                label="Main rider"
                type={ButtonType.LINK}>
                <TickSVG />
              </Button>

              <Button ariaLabel="Call" style={ButtonStyle.PRIMARY} type={ButtonType.LINK}>
                <PhoneSVG />
              </Button>

              <Button ariaLabel="Edit" style={ButtonStyle.SECONDARY} type={ButtonType.LINK}>
                <EditSVG />
              </Button>

              <Button ariaLabel="Chat" style={ButtonStyle.SUCCESS} type={ButtonType.LINK}>
                <ChatSVG />
              </Button>

              <Button ariaLabel="Chat" style={ButtonStyle.SUCCESS_INVERTED} type={ButtonType.LINK}>
                <ChatSVG />
              </Button>
              <div>
                <Button
                  ariaLabel="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}
                  label="Primary clear button"
                  type={ButtonType.LINK}
                />

                <Button
                  ariaLabel="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}
                  label="Secondary clear button"
                  type={ButtonType.LINK}
                />

                <Button
                  ariaLabel="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}
                  label="Success clear button"
                  type={ButtonType.LINK}
                />

                <Button
                  ariaLabel="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}
                  label="Primary clear button"
                  type={ButtonType.LINK}>
                  <PhoneSVG />
                </Button>

                <Button
                  ariaLabel="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}
                  label="Secondary clear button"
                  type={ButtonType.LINK}>
                  <EditSVG />
                </Button>

                <Button
                  ariaLabel="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}
                  label="Success clear button"
                  type={ButtonType.LINK}>
                  <ChatSVG />
                </Button>

                <Button ariaLabel="Call" style={ButtonStyle.PRIMARY_CLEAR} type={ButtonType.LINK}>
                  <PhoneSVG />
                </Button>

                <Button ariaLabel="Edit" style={ButtonStyle.SECONDARY_CLEAR} type={ButtonType.LINK}>
                  <EditSVG />
                </Button>

                <Button ariaLabel="Chat" style={ButtonStyle.SUCCESS_CLEAR} type={ButtonType.LINK}>
                  <ChatSVG />
                </Button>
              </div>
            </Form.Fieldset>

            <Form.Fieldset title="Buttons - Disabled">
              <div>
                <Button ariaLabel="Unstyled button" label="Unstyled button" />
              </div>

              <Button ariaLabel="Primary button" disabled={true} label="Primary button" style={ButtonStyle.PRIMARY} />

              <Button
                ariaLabel="Secondary button"
                disabled={true}
                label="Secondary button"
                style={ButtonStyle.SECONDARY}
              />

              <Button ariaLabel="Success button" disabled={true} label="Success button" style={ButtonStyle.SUCCESS} />

              <Button
                ariaLabel="Success inverted button"
                disabled={true}
                label="Success inverted button"
                style={ButtonStyle.SUCCESS_INVERTED}
              />

              <Button ariaLabel="Invalid button" disabled={true} label="Invalid button" style={ButtonStyle.INVALID} />

              <Button ariaLabel="Add a claim" disabled={true} label="Add a claim" style={ButtonStyle.PRIMARY}>
                <PlusSVG />
              </Button>

              <Button ariaLabel="Edit" disabled={true} label="Edit" style={ButtonStyle.SECONDARY}>
                <EditSVG />
              </Button>

              <Button ariaLabel="Delete" disabled={true} label="Delete" style={ButtonStyle.SECONDARY}>
                <DeleteSVG />
              </Button>

              <Button ariaLabel="Main rider" disabled={true} label="Main rider" style={ButtonStyle.SUCCESS}>
                <TickSVG />
              </Button>

              <Button ariaLabel="Main rider" disabled={true} label="Main rider" style={ButtonStyle.SUCCESS_INVERTED}>
                <TickSVG />
              </Button>

              <Button ariaLabel="Call" disabled={true} style={ButtonStyle.PRIMARY}>
                <PhoneSVG />
              </Button>

              <Button ariaLabel="Edit" disabled={true} style={ButtonStyle.SECONDARY}>
                <EditSVG />
              </Button>

              <Button ariaLabel="Chat" disabled={true} readOnly={isSubmitting} style={ButtonStyle.SUCCESS}>
                <ChatSVG />
              </Button>

              <Button ariaLabel="Chat" disabled={true} readOnly={isSubmitting} style={ButtonStyle.SUCCESS_INVERTED}>
                <ChatSVG />
              </Button>
              <div>
                <Button
                  ariaLabel="Primary clear button"
                  disabled={true}
                  label="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}
                />

                <Button
                  ariaLabel="Secondary clear button"
                  disabled={true}
                  label="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}
                />

                <Button
                  ariaLabel="Success clear button"
                  disabled={true}
                  label="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}
                />

                <Button
                  ariaLabel="Primary clear button"
                  disabled={true}
                  label="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}>
                  <PhoneSVG />
                </Button>

                <Button
                  ariaLabel="Secondary clear button"
                  disabled={true}
                  label="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}>
                  <EditSVG />
                </Button>

                <Button
                  ariaLabel="Success clear button"
                  disabled={true}
                  label="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}>
                  <ChatSVG />
                </Button>

                <Button ariaLabel="Call" disabled={true} style={ButtonStyle.PRIMARY_CLEAR}>
                  <PhoneSVG />
                </Button>

                <Button ariaLabel="Edit" disabled={true} style={ButtonStyle.SECONDARY_CLEAR}>
                  <EditSVG />
                </Button>

                <Button ariaLabel="Chat" disabled={true} style={ButtonStyle.SUCCESS_CLEAR}>
                  <ChatSVG />
                </Button>
              </div>
            </Form.Fieldset>

            <Form.Fieldset title="Links - Disabled">
              <div>
                <Button ariaLabel="Unstyled link" label="Unstyled link" type={ButtonType.LINK} />
              </div>

              <Button
                ariaLabel="Primary button"
                disabled={true}
                label="Primary button"
                style={ButtonStyle.PRIMARY}
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Secondary button"
                disabled={true}
                label="Secondary button"
                style={ButtonStyle.SECONDARY}
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Success button"
                disabled={true}
                label="Success button"
                style={ButtonStyle.SUCCESS}
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Success inverted button"
                disabled={true}
                label="Success inverted button"
                style={ButtonStyle.SUCCESS_INVERTED}
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Invalid button"
                disabled={true}
                label="Invalid button"
                style={ButtonStyle.INVALID}
                type={ButtonType.LINK}
              />

              <Button
                ariaLabel="Add a claim"
                disabled={true}
                label="Add a claim"
                style={ButtonStyle.PRIMARY}
                type={ButtonType.LINK}>
                <PlusSVG />
              </Button>

              <Button
                ariaLabel="Edit"
                disabled={true}
                label="Edit"
                style={ButtonStyle.SECONDARY}
                type={ButtonType.LINK}>
                <EditSVG />
              </Button>

              <Button
                ariaLabel="Delete"
                disabled={true}
                label="Delete"
                style={ButtonStyle.SECONDARY}
                type={ButtonType.LINK}>
                <DeleteSVG />
              </Button>

              <Button
                ariaLabel="Main rider"
                disabled={true}
                label="Main rider"
                style={ButtonStyle.SUCCESS}
                type={ButtonType.LINK}>
                <TickSVG />
              </Button>

              <Button
                ariaLabel="Main rider"
                disabled={true}
                label="Main rider"
                style={ButtonStyle.SUCCESS_INVERTED}
                type={ButtonType.LINK}>
                <TickSVG />
              </Button>

              <Button ariaLabel="Call" disabled={true} style={ButtonStyle.PRIMARY} type={ButtonType.LINK}>
                <PhoneSVG />
              </Button>

              <Button ariaLabel="Edit" disabled={true} style={ButtonStyle.SECONDARY} type={ButtonType.LINK}>
                <EditSVG />
              </Button>

              <Button
                ariaLabel="Chat"
                disabled={true}
                readOnly={isSubmitting}
                style={ButtonStyle.SUCCESS}
                type={ButtonType.LINK}>
                <ChatSVG />
              </Button>

              <Button
                ariaLabel="Chat"
                disabled={true}
                readOnly={isSubmitting}
                style={ButtonStyle.SUCCESS_INVERTED}
                type={ButtonType.LINK}>
                <ChatSVG />
              </Button>
              <div>
                <Button
                  ariaLabel="Primary clear button"
                  disabled={true}
                  label="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}
                  type={ButtonType.LINK}
                />

                <Button
                  ariaLabel="Secondary clear button"
                  disabled={true}
                  label="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}
                  type={ButtonType.LINK}
                />

                <Button
                  ariaLabel="Success clear button"
                  disabled={true}
                  label="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}
                  type={ButtonType.LINK}
                />

                <Button
                  ariaLabel="Primary clear button"
                  disabled={true}
                  label="Primary clear button"
                  style={ButtonStyle.PRIMARY_CLEAR}
                  type={ButtonType.LINK}>
                  <PhoneSVG />
                </Button>

                <Button
                  ariaLabel="Secondary clear button"
                  disabled={true}
                  label="Secondary clear button"
                  style={ButtonStyle.SECONDARY_CLEAR}
                  type={ButtonType.LINK}>
                  <EditSVG />
                </Button>

                <Button
                  ariaLabel="Success clear button"
                  disabled={true}
                  label="Success clear button"
                  style={ButtonStyle.SUCCESS_CLEAR}
                  type={ButtonType.LINK}>
                  <ChatSVG />
                </Button>

                <Button ariaLabel="Call" disabled={true} style={ButtonStyle.PRIMARY_CLEAR} type={ButtonType.LINK}>
                  <PhoneSVG />
                </Button>

                <Button ariaLabel="Edit" disabled={true} style={ButtonStyle.SECONDARY_CLEAR} type={ButtonType.LINK}>
                  <EditSVG />
                </Button>

                <Button ariaLabel="Chat" disabled={true} style={ButtonStyle.SUCCESS_CLEAR} type={ButtonType.LINK}>
                  <ChatSVG />
                </Button>
              </div>
            </Form.Fieldset>

            <Control isSubmitting={isSubmitting} submit={true} />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
