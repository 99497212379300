import { matchPath } from "react-router-dom";
import Cookies from "js-cookie";

import { ROUTE } from "modules/router/constants";
import { ProceedLocationChange } from "./types";

const REGEX = /\.pms\?token=(.*$)/i;
const COOKIE = "devitt-proceed";
const TIMEOUT = 5;

const proceedDeleteCookie = () => Cookies.remove(COOKIE);

const proceedLoadCookie = () => Cookies.get(COOKIE);

const proceedRedirect = (url: string) => (window.location.href = url);

export const proceedSaveCookie = (url: string) =>
  Cookies.set(COOKIE, url.replace(REGEX, ".page"), { expires: (1 / 24 / 60) * TIMEOUT });

export const proceedLocationChange: ProceedLocationChange = path => {
  const cookie = proceedLoadCookie();
  if (!cookie) return;

  switch (true) {
    case !!matchPath(ROUTE.MOTORCYCLES, path):
    case !!matchPath(`${ROUTE.MOTORCYCLES}/:id`, path):
    case !!matchPath(ROUTE.RIDER, path):
    case !!matchPath(ROUTE.RIDERS, path):
    case !!matchPath(`${ROUTE.RIDERS}/:id`, path):
    case !!matchPath(ROUTE.QUOTE, path):
    case !!matchPath(ROUTE.PRODUCT, path):
    case !!matchPath(ROUTE.POLICY, path):
    case !!matchPath(ROUTE.PAYMENT, path):
    case !!matchPath(ROUTE.MESSAGE_QUOTE, path):
      proceedRedirect(cookie);
      break;

    case !!matchPath(ROUTE.MOTORCYCLE, path):
    case !!matchPath(ROUTE.CONFIRMATION, path):
    case !!matchPath(ROUTE.AGGREGATOR, path):
    case !!matchPath(ROUTE.RECALL, path):
    case !!matchPath(ROUTE.DEBUG, path):
    case !!matchPath(ROUTE.MESSAGE, path):
      proceedDeleteCookie();
      break;
  }
};
