import { memo } from "react";

import { InputDateFormat, InputFormat, InputNumberFormat, InputStringFormat } from "../constants";
import { ReactComponent as CurrencySVG } from "modules/theme/icons/form/inputs/currency.svg";
import { ReactComponent as EmailSVG } from "modules/theme/icons/form/inputs/email.svg";
import { ReactComponent as PhoneSVG } from "modules/theme/icons/form/inputs/phone.svg";
import { ReactComponent as DateSVG } from "modules/theme/icons/form/inputs/date.svg";

type InputIconProps = {
  format: InputFormat;
};

function InputIcon({ format }: InputIconProps) {
  switch (format) {
    case InputNumberFormat.CURRENCY:
      return <CurrencySVG />;
    case InputStringFormat.EMAIL:
      return <EmailSVG />;
    case InputStringFormat.TEL:
      return <PhoneSVG />;
    case InputDateFormat.DATE:
      return <DateSVG />;
    case InputDateFormat.MONTH:
      return <DateSVG />;
    default:
      return null;
  }
}

export default memo(InputIcon);
