import { AdditionalRider, Risk } from "modules/risk/types";
import yup, { Messages } from "modules/validation";
import { getContext } from "./utils";

const validateAdditionalRiders = (additionalRiders: AdditionalRider[] | undefined) =>
  additionalRiders &&
  additionalRiders.filter(item => item.relationshipToProposer === "S" || item.relationshipToProposer === "V").length >
    0;

const validateSpouse = (maritalStatus: string, proposerMaritalStatus: string, relationshipToProposer: string) =>
  relationshipToProposer === "S" && (maritalStatus !== "M" || proposerMaritalStatus !== "M");

const validateCivilPartner = (maritalStatus: string, proposerMaritalStatus: string, relationshipToProposer: string) =>
  relationshipToProposer === "V" && (maritalStatus !== "V" || proposerMaritalStatus !== "V");

function validator(this: yup.StringSchema) {
  return this.test("relationshipRules", Messages.RELATIONSHIP_RULES_MULTIPLE, function (value) {
    const additionalRiders = getContext<Risk>(this)?.additionalRiders.filter(item => item.id !== this.parent.id);
    const proposerMaritalStatus = getContext<Risk>(this)?.proposer.maritalStatus || "";
    const maritalStatus = this.parent.maritalStatus || "";
    const relationshipToProposer = value || "";
    if (relationshipToProposer === "S" || relationshipToProposer === "V") {
      if (validateAdditionalRiders(additionalRiders)) {
        return false;
      }
      if (validateSpouse(maritalStatus, proposerMaritalStatus, relationshipToProposer)) {
        return this.createError({ message: Messages.RELATIONSHIP_RULES_SPOUSE });
      }
      if (validateCivilPartner(maritalStatus, proposerMaritalStatus, relationshipToProposer)) {
        return this.createError({ message: Messages.RELATIONSHIP_RULES_CIVIL_PARTNER });
      }
    }
    return true;
  });
}

export default validator;
