import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";

const MessageMaintenance: React.FunctionComponent = () => {
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Under Maintenance</h2>
            <h3>Our site is currently down for planned maintenance.</h3>
          </header>

          <p>We apologise for the inconvenience but it will be back up and running again soon.</p>

          <p>Thank you for your patience.</p>

          <Button
            ariaLabel="Home"
            href="https://www.devittinsurance.com/"
            label="Home"
            style={ButtonStyle.SUCCESS}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />

          <Button
            ariaLabel="Request a callback"
            href="https://www.devittinsurance.com/contact-us/request-callback/"
            label="Request a callback"
            style={ButtonStyle.PRIMARY}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(MessageMaintenance);
