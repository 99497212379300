import { AnalyticsItemFinance } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemFinance: AnalyticsItemFinance = price => ({
  item_id: "FINANCE",
  item_name: "Finance",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemFinance;
