import { setSelectionRange } from "components/Inputs/Input/helpers";
import { AllowedKeys } from "components/Inputs/Input/constants";
import { Mask, MaskProps } from "./types";
import { MASK } from "./masks";

export function getMask(props: MaskProps): Mask {
  return (MASK[props.format] && MASK[props.format](props)) || {};
}

export const handleInput =
  (sanitiser: (event: React.FormEvent<HTMLTextAreaElement>) => string) =>
  (onInput?: (event: React.FormEvent<HTMLTextAreaElement>) => void) =>
  (event: React.FormEvent<HTMLTextAreaElement>) => {
    onInput && onInput(event);
    const sanitised = sanitiser(event);
    if (event.currentTarget.value !== sanitised) {
      typeof event.currentTarget.selectionEnd === "number"
        ? setSelectionRange(event, sanitised)
        : (event.currentTarget.value = sanitised);
    }
  };

export const handleKeyDown =
  (regex: RegExp) =>
  (onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void) =>
  (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyDown && onKeyDown(event);
    if (AllowedKeys.indexOf(event.key) > -1) {
      return;
    }
    if (!(event.metaKey || event.ctrlKey) && event.key.length === 1 && !regex.test(event.key)) {
      event.preventDefault();
    }
  };
