import React, { useContext } from "react";
import { shallowEqual, useSelector } from "modules/redux/store";

import { ReactComponent as ErrorCallUsSVG } from "modules/theme/icons/questions/call-us.svg";
import PlatformContext from "modules/platform/context";
import Button from "components/Button";

import styles from "./styles.module.scss";

const ErrorCallUs: React.FunctionComponent = () => {
  const { sales } = useSelector(state => state.app.contact.telephone, shallowEqual);
  const { isMobileOnly } = useContext(PlatformContext);
  return (
    <div className={styles["error-call-us"]} aria-live="polite">
      {!isMobileOnly ? (
        <Button ariaLabel="Call" onClick={() => window.open(`tel:${sales.replace(/\s+/g, "")}`, `_self`)}>
          <ErrorCallUsSVG />
          {sales}
        </Button>
      ) : (
        <span>
          <ErrorCallUsSVG />
          {sales}
        </span>
      )}
    </div>
  );
};

export default React.memo(ErrorCallUs);
