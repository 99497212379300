import { useCallback } from "react";

import { scrollTop } from "modules/scroll/helpers";
import { UseHandleEdit } from "./types";

const useHandleEdit: UseHandleEdit = navigate =>
  useCallback(
    async (route: string) => {
      await scrollTop();
      navigate(route);
    },
    [navigate],
  );

export default useHandleEdit;
