import { useSelector } from "modules/redux/store";

import { MetadataType } from "modules/metadata/constants";
import { Metadata } from "modules/metadata/types";
import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import { UseProps } from "./types";

const useProps: UseProps = () => {
  const metadata: Metadata = useSelector(state => state.metadata);
  const quote: Quote = useSelector(state => state.quote);
  const risk: Risk = useSelector(state => state.risk);
  return {
    cover: risk.vehicle.cover,
    enhanced: quote.optionalExtras.enhanced,
    enhancedPlus: quote.optionalExtras.enhancedPlus,
    evolution: quote.optionalExtras.evolution,
    evolutionPlus: quote.optionalExtras.evolutionPlus,
    hasAdditionalRiders: risk.hasAdditionalRiders,
    normallyPayByDirectDebit: risk.additionalQuestions.normallyPayByDirectDebit,
    optionalExtras: risk.cart.optionalExtras,
    optionsCover: metadata[MetadataType.COVER_TYPE],
    quote,
    quoteToken: risk.cart.quoteToken,
  };
};

export default useProps;
