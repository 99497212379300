import React from "react";
import clsx from "clsx";

import { PaymentMethod } from "modules/risk/constants";
import { useHeight, useSticky } from "./hooks";
import { Quote } from "modules/quote/types";
import PolicyHeader from "../PolicyHeader";
import PolicyFooter from "../PolicyFooter";

import styles from "./styles.module.scss";

type PolicyWrapperLeftProps = {
  children: React.ReactNode;
};

export const PolicyWrapperLeft: React.FunctionComponent<PolicyWrapperLeftProps> = ({ children }) => {
  const { isSticky, ref } = useSticky();
  const className = clsx(isSticky && styles["sticky"]);
  return (
    <section className={className} ref={ref}>
      {children}
    </section>
  );
};

type PolicyWrapperRightProps = {
  children: React.ReactNode;
};

export const PolicyWrapperRight: React.FunctionComponent<PolicyWrapperRightProps> = ({ children }) => {
  return <section>{children}</section>;
};

type PolicyProps = {
  children: React.ReactNode;
  onBack: () => void;
  paymentMethod: PaymentMethod;
  quote: Quote;
  readOnly?: boolean;
};

export const Policy: React.FunctionComponent<PolicyProps> = ({ children, onBack, paymentMethod, quote, readOnly }) => {
  const { height, ref } = useHeight();
  return (
    <div className={styles["policy-wrapper"]}>
      <PolicyHeader onBack={onBack} webReference={quote.webReference} />

      <div style={{ marginBottom: `${height}px` }}>{children}</div>

      <PolicyFooter forwardedRef={ref} paymentMethod={paymentMethod} quote={quote} readOnly={readOnly} />
    </div>
  );
};

export default Policy;
