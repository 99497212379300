import React from "react";
import clsx from "clsx";

import { ReactComponent as LoadingSVG } from "modules/theme/icons/questions/loading.svg";
import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import Error from "components/Questions/QuestionTemplate/Error";
import { useScrollRef } from "modules/scroll/hooks";
import Messages from "modules/validation/messages";
import { errorMessage } from "modules/api/errors";
import { SearchFooterProps } from "./types";
import { ApiError } from "modules/api";

import styles from "./styles.module.scss";

const SearchFooter: React.FunctionComponent<SearchFooterProps> = ({
  apiError,
  disabled,
  error,
  handleSubmit,
  loading,
  readOnly,
  touched,
}) => {
  const scrollRef = useScrollRef("SearchFooter");
  const handleShowModalInfo = useModalInfo();
  const invalid =
    (apiError && [ApiError.BIKE_INVALID, ApiError.BIKE_NOT_FOUND].includes(apiError as keyof ApiError)) ||
    (!!error && touched);
  const className = clsx(
    styles["vehicle-search-button"],
    loading && styles["loading"],
    !disabled && invalid && styles["invalid"],
  );
  return (
    <footer className={className}>
      {loading && (
        <span>
          <LoadingSVG />
        </span>
      )}
      <div ref={!disabled && invalid ? scrollRef : undefined}>
        <Button
          ariaLabel="Find my motorcycle"
          disabled={disabled}
          label="Find my motorcycle"
          onClick={handleSubmit}
          readOnly={readOnly}
          style={invalid ? ButtonStyle.INVALID : ButtonStyle.SECONDARY}
        />

        {!disabled &&
        apiError &&
        [ApiError.BIKE_INVALID, ApiError.BIKE_NOT_FOUND].includes(apiError as keyof ApiError) ? (
          <Error error={errorMessage(apiError)} />
        ) : !disabled && error && touched ? (
          <Error error={Messages.VRM_SEARCH} />
        ) : (
          !disabled && !!apiError && <p>{errorMessage(apiError)}</p>
        )}

        <Button
          allowFocus={true}
          ariaLabel="Can't find your bike?"
          onClick={event =>
            handleShowModalInfo({
              event,
              template: ModalInfoTemplates.VEHICLE_SEARCH,
            })
          }
          label="Can't find your bike?"
          style={ButtonStyle.SECONDARY_CLEAR}
          type={ButtonType.BUTTON}
        />
      </div>
    </footer>
  );
};

export default React.memo(SearchFooter);
