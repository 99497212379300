import yup, { CreateError, Messages } from "modules/validation";
import { AdditionalRider, Rider } from "modules/risk/types";
import { getContext } from "./utils";
import moment from "moment";

const validateBikeLicenceYears16 = (
  bikeLicenceYears: string,
  dateOfBirth: moment.Moment,
): boolean | yup.ValidationError => {
  return dateOfBirth.isSameOrBefore(moment().subtract(16 + Number(bikeLicenceYears), "years"), "day");
};

const validateBikeLicenceYears17 = (
  bikeLicenceYears: string,
  dateOfBirth: moment.Moment,
): boolean | yup.ValidationError => {
  return dateOfBirth.isSameOrBefore(moment().subtract(17 + Number(bikeLicenceYears), "years"), "day");
};

const validateBikeLicenceYears = (
  createError: CreateError,
  bikeLicenceType: string,
  bikeLicenceYears: string,
  dateOfBirth: moment.Moment,
  proposer: boolean,
): boolean | yup.ValidationError => {
  return ["M", "P"].includes(bikeLicenceType)
    ? validateBikeLicenceYears16(bikeLicenceYears, dateOfBirth)
      ? true
      : createError({
          message: Messages.LICENCE_YEARS,
          params: proposer ? { you1: "You", age: "16", you2: "you" } : { you1: "They", age: "16", you2: "they" },
        })
    : validateBikeLicenceYears17(bikeLicenceYears, dateOfBirth)
    ? true
    : createError({
        message: Messages.LICENCE_YEARS,
        params: proposer ? { you1: "You", age: "17", you2: "you" } : { you1: "They", age: "17", you2: "they" },
      });
};

function validator(this: yup.StringSchema) {
  return this.test("bikeLicenceYears", Messages.DATE, function (value) {
    const bikeLicenceType = getContext<Rider | AdditionalRider>(this)?.bikeLicence.type;
    const bikeLicenceYears = value;
    const dateOfBirth = getContext<Rider | AdditionalRider>(this)?.dateOfBirth
      ? moment(getContext<Rider | AdditionalRider>(this)?.dateOfBirth, "YYYY-MM-DD", true)
      : false;
    const proposer = this.path.startsWith("proposer");
    return bikeLicenceYears && bikeLicenceType && dateOfBirth && dateOfBirth.isValid()
      ? validateBikeLicenceYears(this.createError, bikeLicenceType, bikeLicenceYears, dateOfBirth, proposer)
      : true;
  });
}

export default validator;
