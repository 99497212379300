import React, { useEffect } from "react";
import clsx from "clsx";

import { ReactComponent as HouseSVG } from "modules/theme/icons/form/lookup/house.svg";
import Error from "components/Questions/QuestionTemplate/Error";
import Button, { ButtonStyle } from "components/Button";
import Messages from "modules/validation/messages";
import { DescriptionProps } from "./types";

import styles from "./styles.module.scss";

const Description: React.FunctionComponent<DescriptionProps> = ({
  address,
  errors,
  readOnly,
  setShowQuestions,
  touched,
}) => {
  useEffect(() => {
    errors && touched && setShowQuestions(true);
  }, [errors, setShowQuestions, touched]);
  const className = clsx(styles["address-search-description"], errors && touched && styles["invalid"]);
  return (
    <div className={className}>
      <p>Your address</p>
      <div>
        <div>
          <HouseSVG />
          <p>
            {!!address.addressLine1 && (
              <React.Fragment>
                {address.addressLine1}
                <br />
              </React.Fragment>
            )}
            {!!address.addressLine2 && (
              <React.Fragment>
                {address.addressLine2}
                <br />
              </React.Fragment>
            )}
            {!!address.addressLine3 && (
              <React.Fragment>
                {address.addressLine3}
                <br />
              </React.Fragment>
            )}
            {!!address.addressLine4 && (
              <React.Fragment>
                {address.addressLine4}
                <br />
              </React.Fragment>
            )}
            {!!address.postCode && `${address.postCode}`}
          </p>
        </div>
        <Button
          ariaLabel="Change my address"
          label="Change my address"
          onClick={() => setShowQuestions(true)}
          readOnly={readOnly}
          style={errors && touched ? ButtonStyle.INVALID : ButtonStyle.SECONDARY}
        />
      </div>
      {errors && touched && <Error error={Messages.SECTION_REQUIRED} />}
    </div>
  );
};

export default React.memo(Description);
