import React, { useContext } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";

import { LoadingType } from "components/Loading/constants";
import Loading from "components/Loading";
import logoAegas from "modules/theme/icons/modal/ageas.png";
import logoAviva from "modules/theme/icons/modal/aviva.svg";
import logoKGM from "modules/theme/icons/modal/kgm.svg";
import logoAllianz from "modules/theme/icons/modal/allianz.svg";
import ModalOverlay from "../ModalOverlay";
import PlatformContext from "modules/platform/context";

import styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export const ModalLoadingQuoteTemplate = React.memo(() => {
  return (
    <section className={styles.modelLoadingQuote} role="dialog">
      <div>
        <Loading className={styles.dots} type={LoadingType.DOTS} />
        <p>We're finding our most competitive quote for you from our panel of leading UK insurers including</p>
        <ul>
          <li>
            <div aria-label="Allianz logo" role="img" style={{ backgroundImage: `url(${logoAllianz})` }}></div>
          </li>
          <li>
            <div aria-label="Aegas logo" role="img" style={{ backgroundImage: `url(${logoAegas})` }}></div>
          </li>
          <li>
            <div aria-label="Aviva logo" role="img" style={{ backgroundImage: `url(${logoAviva})` }}></div>
          </li>
          <li>
            <div aria-label="KGM logo" role="img" style={{ backgroundImage: `url(${logoKGM})` }}></div>
          </li>
        </ul>
      </div>
    </section>
  );
});

export type ModalLoadingQuoteProps = {
  destroyModal: () => void;
  hideModal: () => void;
  id: string;
  open: boolean;
};

const ModalLoadingQuote: React.FunctionComponent<ModalLoadingQuoteProps> = ({ destroyModal, open }) => {
  const { isMobile } = useContext(PlatformContext);
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay onExited={destroyModal} open={open} scroll="paper" TransitionComponent={Transition}>
        <ModalLoadingQuoteTemplate />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default ModalLoadingQuote;
