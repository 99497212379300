import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { quoteActionRequest } from "modules/quote/actions";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import vrmValidateApi from "modules/api/vrmValidate";
import { ModalType } from "modules/modals/constants";
import { RootState } from "modules/redux/store";
import { AggregatorAction } from "./constants";
import { isAxiosError } from "modules/api";
import { isError } from "modules/utils";
import {
  AggregatorActionRequestStarted,
  AggregatorActionRequestSuccess,
  AggregatorActionRequestFailure,
  AggregatorApiResponseResult,
  AggregatorApiResponse,
  AggregatorApiRequest,
} from "./types";

export const aggregatorActionRequest = (request: AggregatorApiRequest) => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const id = dispatch(modalActionShow({ type: ModalType.LOADING_QUOTE }));
    dispatch(aggregatorActionRequestStarted());
    try {
      const response: AggregatorApiResponse = await api(API.AGGREGATOR_REQUEST(request));
      dispatch(aggregatorActionRequestSuccessCache(response.data.risk));
      dispatch(aggregatorActionRequestSuccess(response.data.risk));
      await dispatch(quoteActionRequest({ modal: id }));
    } catch (error) {
      dispatch(aggregatorActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
      dispatch(modalActionHide(id));
    }
  };
};

export const aggregatorActionRequestSuccessCache = (risk: AggregatorApiResponseResult) => {
  return async (): Promise<void> => {
    !!risk.vehicle.regNumber && vrmValidateApi(risk.vehicle.regNumber);
    process.env.REACT_APP_MULTIBIKE === "true" &&
      risk.additionalVehicles.forEach(item => !!item.regNumber && vrmValidateApi(item.regNumber));
  };
};

const aggregatorActionRequestStarted = (): AggregatorActionRequestStarted => ({
  type: AggregatorAction.REQUEST_STARTED,
});

const aggregatorActionRequestSuccess = (risk: AggregatorApiResponseResult): AggregatorActionRequestSuccess => ({
  type: AggregatorAction.REQUEST_SUCCESS,
  risk: risk,
});

const aggregatorActionRequestFailure = (error: string): AggregatorActionRequestFailure => ({
  type: AggregatorAction.REQUEST_FAILURE,
  error: error,
});
