import React from "react";

import { QuestionVrm } from "components/Questions";
import { ApiError } from "modules/api";
import { VrmProps } from "./types";
import useVrm from "./hooks";

const Vrm: React.FunctionComponent<VrmProps> = ({
  errorAbiCode,
  errorRegNumber,
  name,
  onBlur,
  onChange,
  open,
  readOnly,
  setFieldTouched,
  setFieldValue,
  setOpen,
  touchedAbiCode,
  touchedRegNumber,
  value,
}) => {
  const [apiError, error, handleChange, handleClick, handleKeyDown, handleSubmit, isMainVehicle, loading, touched] =
    useVrm({
      errorAbiCode,
      errorRegNumber,
      name,
      onChange,
      open,
      setFieldTouched,
      setFieldValue,
      setOpen,
      touchedAbiCode,
      touchedRegNumber,
      value,
    });
  return (
    <QuestionVrm
      error={error}
      help={
        isMainVehicle
          ? "<p>Please enter your registration number and click <strong>Find my motorcycle</strong>.</p><p>If you do not know your registration number, leave this question blank and click <strong>I don't know the registration</strong>.</p>"
          : "<p>Please enter your registration number and click <strong>Find my motorcycle</strong>.</p>"
      }
      info={
        isMainVehicle && apiError === ApiError.VRM_NOT_FOUND
          ? "Sorry, we could not find a motorcycle matching this registration number. Please enter your vehicle details below."
          : undefined
      }
      isMainVehicle={isMainVehicle}
      label="Registration number<br/><small>(leave blank if unknown)</small>"
      loading={loading}
      name={`${name}.regNumber`}
      onChange={handleChange}
      onClick={handleClick}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      open={open}
      readOnly={readOnly || loading}
      touched={touched}
      value={value}
    />
  );
};

export default React.memo(Vrm);
