import { cache, cacheFailure, cacheSearch, cacheSuccess } from "./cache";
import { CacheApiRequest, Request } from "./types";
import { errorMessage } from "modules/api/errors";
import { getApiError } from "modules/api";
import debounce from "debounce-promise";

const remote: Request = (endpoint, errors, name) => {
  !cache[name] && (cache[name] = { active: {}, results: [] });

  const apiRequest: CacheApiRequest = (createError, path, value) => {
    return endpoint(value).then(
      response => cacheSuccess(createError, name, path, response, value),
      error => cacheFailure(createError, getApiError(error), errors, name, path, value),
    );
  };

  const debounceApiRequest = debounce(apiRequest, 1000, { leading: true });

  return [
    (createError, path, value) => {
      const cached = cacheSearch(name, value);
      return cached
        ? typeof cached.valid === "string"
          ? createError({ message: errorMessage(cached.valid) })
          : cached.valid
        : debounceApiRequest(createError, path, value);
    },
    (path, value) => (cache[name].active[path] = value),
  ];
};

export default remote;
