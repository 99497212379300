import React from "react";
import clsx from "clsx";

import { ReactComponent as HelmetSVG } from "modules/theme/icons/product/addons/helmet.svg";
import { ReactComponent as DeleteSVG } from "modules/theme/icons/buttons/delete.svg";
import { ReactComponent as EditSVG } from "modules/theme/icons/buttons/edit.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";
import { ReactComponent as ErrorIcon } from "modules/theme/icons/questions/error.svg";
import { Rider, AdditionalRider } from "modules/risk/types";
import Button, { ButtonStyle } from "components/Button";
import { useScrollRef } from "modules/scroll/hooks";
import { Messages } from "modules/validation";

import styles from "./styles.module.scss";

type ItemProps = {
  id: string;
  invalid?: boolean;
  isProposer: boolean;
  onDelete?: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    id: string,
  ) => void;
  onEdit: (id: string) => void;
  onMain: (id: string) => void;
  readOnly?: boolean;
  rider: Rider | AdditionalRider;
};

const Item: React.FunctionComponent<ItemProps> = ({
  id,
  invalid,
  isProposer,
  onDelete,
  onEdit,
  onMain,
  readOnly,
  rider,
}) => {
  const scrollRef = useScrollRef(id);
  const className = clsx(styles["rider"], invalid && styles["invalid"], rider.isMainRider && styles["main"]);
  return (
    <li className={className} ref={invalid ? scrollRef : undefined}>
      <HelmetSVG />
      <div>
        <div>
          <h2>
            {rider.name.title === "Other" ? rider.name.titleOther : rider.name.title} {rider.name.forenames}{" "}
            {rider.name.surname} {isProposer && "(Proposer)"}
          </h2>
          {!!invalid && (
            <div aria-live="polite">
              <ErrorIcon />
              <p>{Messages.SECTION_REQUIRED}</p>
            </div>
          )}
        </div>
        <div>
          <div>
            <Button
              allowFocus={true}
              ariaLabel={rider.isMainRider ? "Main rider" : "Set as main rider"}
              label={rider.isMainRider ? "Main rider" : "Set as main rider"}
              onClick={() => !rider.isMainRider && onMain(rider.id)}
              readOnly={rider.isMainRider ? true : readOnly}
              style={rider.isMainRider ? ButtonStyle.SUCCESS_CLEAR : ButtonStyle.PRIMARY_CLEAR}>
              {!!rider.isMainRider && <TickSVG />}
            </Button>

            <Button
              ariaLabel="Edit"
              label="Edit"
              onClick={() => onEdit(rider.id)}
              readOnly={readOnly}
              style={ButtonStyle.PRIMARY_CLEAR}>
              <EditSVG />
            </Button>

            {!isProposer && !!onDelete && (
              <Button
                allowFocus={true}
                ariaLabel="Delete"
                label="Delete"
                onClick={event => onDelete(event, rider.id)}
                readOnly={readOnly}
                style={ButtonStyle.PRIMARY_CLEAR}>
                <DeleteSVG />
              </Button>
            )}
          </div>
          <div>
            <Button
              ariaLabel="Edit"
              label="Edit"
              onClick={() => onEdit(rider.id)}
              readOnly={readOnly}
              style={ButtonStyle.SECONDARY}>
              <EditSVG />
            </Button>

            {!isProposer && !!onDelete && (
              <Button
                allowFocus={true}
                ariaLabel="Delete"
                label="Delete"
                onClick={event => onDelete(event, rider.id)}
                readOnly={readOnly}
                style={ButtonStyle.SECONDARY}>
                <DeleteSVG />
              </Button>
            )}

            <Button
              allowFocus={true}
              ariaLabel={rider.isMainRider ? "Main rider" : "Set as main rider"}
              onClick={() => !rider.isMainRider && onMain(rider.id)}
              label={rider.isMainRider ? "Main rider" : "Set as main rider"}
              readOnly={rider.isMainRider ? true : readOnly}
              style={rider.isMainRider ? ButtonStyle.SUCCESS : ButtonStyle.SECONDARY}>
              {!!rider.isMainRider && <TickSVG />}
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default React.memo(Item);
