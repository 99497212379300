import yup from "modules/validation";

import schemaRider from "./schemaRider";

const schema = yup
  .object({
    relationshipToProposer: yup.string().required().relationshipRules(),
  })
  .concat(schemaRider as yup.AnyObjectSchema);

export const schemaCurrentAdditionalRider = yup.object({
  currentAdditionalRider: schema,
});

export default schema;
