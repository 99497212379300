import { useEffect, useRef } from "react";

const useOnMount = (onMount: () => void, strictMode = false) => {
  const isActive = useRef(process.env.NODE_ENV === "production" ? true : !strictMode);
  useEffect(
    () => {
      isActive.current && onMount();
      isActive.current = true;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useOnMount;
