import { AnalyticsItemTierEnhancedPlus } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemTierEnhancedPlus: AnalyticsItemTierEnhancedPlus = price => ({
  item_id: "DEVITT_ENHANCED_PLUS",
  item_name: "Devitt Enhanced Plus Cover",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemTierEnhancedPlus;
