import React, { useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import Button from "components/Button";
import Item from "./Item";

import styles from "./styles.module.scss";

type PolicyEndorsementsProps = {
  endorsements: string[];
  isOpen?: boolean;
};

const PolicyEndorsements: React.FunctionComponent<PolicyEndorsementsProps> = ({ endorsements, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);
  const className = clsx(styles["policy-endorsements"], open && styles["open"]);
  return (
    <div className={className}>
      <header>
        <Button ariaLabel="Your Endorsements" onClick={() => setOpen(!open)}>
          <h2>Your Endorsements</h2>
          <ChevronDownSVG />
        </Button>
      </header>
      {open && (
        <ul>
          {endorsements.map((endorsement: string, index: number) => (
            <Item endorsement={endorsement} key={index} isOpen={index === 0} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default React.memo(PolicyEndorsements);
