import React, { useMemo } from "react";

import { OptionEnhanced, OptionEvolution } from "modules/router/hooks";
import Button, { ButtonStyle } from "components/Button";
import { Quote } from "modules/quote/types";
import { formatPrice } from "modules/utils";
import { Risk } from "modules/risk/types";
import WebReference from "./WebReference";
import Defaqto from "./Defaqto";
import Price from "./Price";

import styles from "./styles.module.scss";

type QuoteCartProps = {
  normallyPayByDirectDebit: Risk["additionalQuestions"]["normallyPayByDirectDebit"];
  onContinue: () => void;
  optionEnhanced: OptionEnhanced;
  optionEvolution: OptionEvolution;
  quote: Quote;
};

const QuoteCart: React.FunctionComponent<QuoteCartProps> = ({
  normallyPayByDirectDebit,
  onContinue,
  optionEnhanced,
  optionEvolution,
  quote: { instalments, totalAnnualPrice, webReference },
}) => {
  const buttonText = useMemo(
    () =>
      optionEnhanced?.selected
        ? "Continue with Enhanced"
        : optionEvolution?.selected
        ? "Continue with Evolution"
        : "Continue",
    [optionEnhanced, optionEvolution],
  );

  return (
    <div className={styles["quote-cart"]}>
      <Price
        instalments={instalments}
        normallyPayByDirectDebit={normallyPayByDirectDebit}
        totalAnnualPrice={totalAnnualPrice}
      />
      <Button ariaLabel={buttonText} onClick={onContinue} style={ButtonStyle.SUCCESS}>
        <span>
          {optionEnhanced?.selected ? (
            <React.Fragment>
              Continue with <span>Enhanced</span>
            </React.Fragment>
          ) : optionEvolution?.selected ? (
            <React.Fragment>
              Continue with <span>Evolution</span>
            </React.Fragment>
          ) : (
            <React.Fragment>Continue</React.Fragment>
          )}
        </span>
      </Button>
      <WebReference webReference={webReference} />
      <small>
        {!!instalments && (
          <p>
            ***Monthly payment: {instalments.numberOfPayments} instalments of {formatPrice(instalments.amount)} with
            initial payment of {formatPrice(instalments.deposit)}. An interest rate of {instalments.interestPercentage}%
            is applicable and the total amount to pay is {formatPrice(instalments.totalPayable)}, which includes APR{" "}
            {instalments.apr}% fixed.
          </p>
        )}
        <p>**Compared to buying all products separately.</p>
        <p>
          *Monthly price excluding interest. You can choose to select monthly instalments or yearly payment on the
          following page.
        </p>
      </small>
      <Defaqto />
    </div>
  );
};

export default QuoteCart;
