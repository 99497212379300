import { InputNumberFormat } from "../../constants";
import { Mask, MaskProps } from "../../types";

import currency from "./currency";
import decimal from "./decimal";
import integer from "./integer";

export const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  [InputNumberFormat.CURRENCY]: currency,
  [InputNumberFormat.DECIMAL]: decimal,
  [InputNumberFormat.INTEGER]: integer,
};

export default MASK;
