import adminReducer from "modules/admin/reducer";
import appReducer from "modules/app/reducer";

import confirmationReducer from "modules/confirmation/reducer";
import trustpilotReducer from "modules/trustpilot/reducer";
import articlesReducer from "modules/articles/reducer";
import metadataReducer from "modules/metadata/reducer";
import proceedReducer from "modules/proceed/reducer";
import modalsReducer from "modules/modals/reducer";
import quoteReducer from "modules/quote/reducer";
import riskReducer from "modules/risk/reducer";

const reducers = {
  app: appReducer,
  trustpilot: trustpilotReducer,
  metadata: metadataReducer,
  modals: modalsReducer,
  admin: adminReducer,
  proceed: proceedReducer,
  quote: quoteReducer,
  risk: riskReducer,
  confirmation: confirmationReducer,
  articles: articlesReducer,
};

export default reducers;
