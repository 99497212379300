import React from "react";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import { getMetadataLabel } from "modules/metadata/helpers";
import { OptionalExtra, Quote } from "modules/quote/types";
import { PaymentMethod } from "modules/risk/constants";
import { MetadataItem } from "modules/metadata/types";
import BreakdownPlus from "../BreakdownPlus";
import { UseCart } from "modules/cart";
import Included from "../Included";

type ProductOverviewEvolutionProps = {
  cart: ReturnType<UseCart>;
  cover: string;
  onAdd: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
  optionsCover: MetadataItem[];
  paymentMethod: PaymentMethod;
};

const ProductOverviewEvolution: React.FunctionComponent<ProductOverviewEvolutionProps> = ({
  cart: { selectedEvolutionPlus },
  cover,
  onAdd,
  options,
  optionsCover,
  paymentMethod,
}) => {
  const handleShowModalInfo = useModalInfo();
  return (
    <ul>
      <Included
        label={optionsCover.length ? `${getMetadataLabel(optionsCover, cover)} Cover` : ""}
        onShowModal={event =>
          handleShowModalInfo({
            event,
            template:
              cover === "TPFT"
                ? ModalInfoTemplates.THIRD_PARTY_FIRE_THEFT
                : cover === "TPO"
                ? ModalInfoTemplates.THIRD_PARTY_ONLY
                : ModalInfoTemplates.COMPREHENSIVE_COVER,
          })
        }
      />

      <Included
        label="90 days full policy cover in EU"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.EU_COVER })}
      />

      <Included
        label="10% discount at SportsBikeShop"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.SPORTS_BIKE_SHOP })}
      />

      <Included
        label="24/7 Claim line"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.CLAIM_LINE })}
      />

      <Included
        label="Legal Expenses & Loss Recovery"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.LEGAL_EXPENSES })}
        optional={true}
      />

      <Included
        label="Helmet & Leathers"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.HELMET_LEATHERS_COVER })}
        optional={true}
      />

      <Included
        label="Personal Accident"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.PERSONAL_ACCIDENT_COVER })}
        optional={true}
      />

      <Included
        disabled={selectedEvolutionPlus}
        label="RAC UK Roadside Breakdown Cover"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.BREAKDOWN_ROADSIDE })}
        optional={true}
      />

      {!!options.evolution && !!options.evolutionPlus && (
        <BreakdownPlus
          label="Upgrade to RAC Roadside, Recovery, At Home, Onward Travel and EU Breakdown"
          onAdd={onAdd}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.BREAKDOWN_ONWARD_TRAVEL })}
          option={options.evolution}
          optionPlus={options.evolutionPlus}
          paymentMethod={paymentMethod}
          selected={selectedEvolutionPlus}
        />
      )}
    </ul>
  );
};

export default React.memo(ProductOverviewEvolution);
