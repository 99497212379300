import React from "react";

import { htmlSafe } from "modules/utils";

import styles from "../styles.module.scss";

type HelpProps = {
  help: string | JSX.Element;
};

const Help: React.FunctionComponent<HelpProps> = ({ help }) => {
  return (
    <div className={styles["help"]}>
      <div className={styles["rich-text"]}>{typeof help === "string" ? htmlSafe(help) : help}</div>
    </div>
  );
};

export default React.memo(Help);
