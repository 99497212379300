import { produce } from "immer";

import { Risk, RiskActionSetAnnual } from "../../types";
import { PaymentMethod } from "modules/risk/constants";

const setAnnual = (state: Risk, action: RiskActionSetAnnual): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.cart.paymentMethod = PaymentMethod.ANNUAL;
  });
};

export default setAnnual;
