import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import { ROUTE } from "modules/router/constants";

import styles from "./styles.module.scss";

const RecallBanner: React.FunctionComponent = () => {
  return (
    <section className={styles["recall-banner"]}>
      <h2>Start A New Insurance Quote</h2>
      <Button
        ariaLabel="Get started"
        href={ROUTE.MOTORCYCLE}
        label="Get started"
        style={ButtonStyle.SECONDARY}
        target={ButtonTarget.SELF}
        type={ButtonType.LINK}
      />
    </section>
  );
};

export default React.memo(RecallBanner);
