import React from "react";

import ProductBreakdownIncludedPlus from "./ProductBreakdownIncludedPlus";
import ProductBreakdownIncluded from "./ProductBreakdownIncluded";
import ProductBreakdownBasic from "./ProductBreakdownBasic";
import { OptionalExtra, Quote } from "modules/quote/types";
import { PaymentMethod } from "modules/risk/constants";
import { UseCart } from "modules/cart";

type ProductBreakdownProps = {
  cart: ReturnType<UseCart>;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
  paymentMethod: PaymentMethod;
};

const ProductBreakdown: React.FunctionComponent<ProductBreakdownProps> = ({ cart, ...props }) => {
  return cart.selectedEnhancedPlus || cart.selectedEvolutionPlus ? (
    <ProductBreakdownIncludedPlus />
  ) : cart.selectedEnhanced || cart.selectedEvolution ? (
    <ProductBreakdownIncluded />
  ) : (
    <ProductBreakdownBasic cart={cart} {...props} />
  );
};

export default React.memo(ProductBreakdown);
