import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";
import OpeningTimes from "../components/OpeningTimes";
import { ROUTE } from "modules/router/constants";
import CallUs from "../components/CallUs";

type MessageQuoteRetrieveProps = {
  webReference?: string;
};

const MessageQuoteRetrieve: React.FunctionComponent<MessageQuoteRetrieveProps> = ({ webReference }) => {
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Quote Retrieve Failed</h2>
            <h3>We're sorry but we could not recall your quote from the details provided.</h3>
          </header>

          <p>Please check your details and try again.</p>

          <CallUs telephone={telephone.customerService} text="Need help? Our advisors are here for you." />

          <OpeningTimes openingTimes={openingTimes} />

          <Button
            ariaLabel="Try Again"
            href={webReference ? `${ROUTE.RECALL}?webReference=${webReference}` : ROUTE.RECALL}
            label="Try Again"
            style={ButtonStyle.SUCCESS}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />

          <Button
            ariaLabel="Request a callback"
            href="https://www.devittinsurance.com/contact-us/request-callback/"
            label="Request a callback"
            style={ButtonStyle.PRIMARY}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(MessageQuoteRetrieve);
