import { useMemo } from "react";

import { UseCart } from "./types";

const useCart: UseCart = (options, optionalExtras) => {
  const selectedEnhanced = useMemo(
    () =>
      optionalExtras
        ? !!options.enhanced && optionalExtras.includes(options.enhanced.type)
        : !!options.enhanced?.selected,
    [optionalExtras, options.enhanced],
  );

  const selectedEnhancedPlus = useMemo(
    () =>
      optionalExtras
        ? !!options.enhancedPlus && optionalExtras.includes(options.enhancedPlus.type)
        : !!options.enhancedPlus?.selected,
    [optionalExtras, options.enhancedPlus],
  );

  const selectedEvolution = useMemo(
    () =>
      optionalExtras
        ? !!options.evolution && optionalExtras.includes(options.evolution.type)
        : !!options.evolution?.selected,
    [optionalExtras, options.evolution],
  );

  const selectedEvolutionPlus = useMemo(
    () =>
      optionalExtras
        ? !!options.evolutionPlus && optionalExtras.includes(options.evolutionPlus.type)
        : !!options.evolutionPlus?.selected,
    [optionalExtras, options.evolutionPlus],
  );

  const selectedBreakdown1 = useMemo(
    () =>
      optionalExtras
        ? !!options.breakdown1 && optionalExtras.includes(options.breakdown1.type)
        : !!options.breakdown1?.selected,
    [optionalExtras, options.breakdown1],
  );

  const selectedBreakdown2 = useMemo(
    () =>
      optionalExtras
        ? !!options.breakdown2 && optionalExtras.includes(options.breakdown2.type)
        : !!options.breakdown2?.selected,
    [optionalExtras, options.breakdown2],
  );

  const selectedBreakdown3 = useMemo(
    () =>
      optionalExtras
        ? !!options.breakdown3 && optionalExtras.includes(options.breakdown3.type)
        : !!options.breakdown3?.selected,
    [optionalExtras, options.breakdown3],
  );

  const selectedBreakdown4 = useMemo(
    () =>
      optionalExtras
        ? !!options.breakdown4 && optionalExtras.includes(options.breakdown4.type)
        : !!options.breakdown4?.selected,
    [optionalExtras, options.breakdown4],
  );

  const selectedBreakdown = useMemo(
    () => selectedBreakdown1 || selectedBreakdown2 || selectedBreakdown3 || selectedBreakdown4,
    [selectedBreakdown1, selectedBreakdown2, selectedBreakdown3, selectedBreakdown4],
  );

  const selectedBreakdownOrIncluded = useMemo(
    () => selectedBreakdown || selectedEnhanced || selectedEnhancedPlus || selectedEvolution || selectedEvolutionPlus,
    [selectedBreakdown, selectedEnhanced, selectedEnhancedPlus, selectedEvolution, selectedEvolutionPlus],
  );

  const selectedBreakdown1OrIncluded = useMemo(
    () => selectedBreakdown1 || selectedEnhanced || selectedEvolution,
    [selectedBreakdown1, selectedEnhanced, selectedEvolution],
  );

  const selectedBreakdown4OrIncluded = useMemo(
    () => selectedBreakdown4 || selectedEnhancedPlus || selectedEvolutionPlus,
    [selectedBreakdown4, selectedEnhancedPlus, selectedEvolutionPlus],
  );

  const selectedHelmetLeathers = useMemo(
    () =>
      optionalExtras
        ? !!options.helmetLeathers && optionalExtras.includes(options.helmetLeathers.type)
        : !!options.helmetLeathers?.selected,
    [optionalExtras, options.helmetLeathers],
  );

  const selectedHelmetLeathersOrIncluded = useMemo(
    () => selectedHelmetLeathers || selectedEvolution || selectedEvolutionPlus,
    [selectedHelmetLeathers, selectedEvolution, selectedEvolutionPlus],
  );

  const selectedLegal = useMemo(
    () => (optionalExtras ? !!options.legal && optionalExtras.includes(options.legal.type) : !!options.legal?.selected),
    [optionalExtras, options.legal],
  );

  const selectedLegalOrIncluded = useMemo(
    () => selectedLegal || selectedEnhanced || selectedEnhancedPlus || selectedEvolution || selectedEvolutionPlus,
    [selectedLegal, selectedEnhanced, selectedEnhancedPlus, selectedEvolution, selectedEvolutionPlus],
  );

  const selectedPersonalAccident = useMemo(
    () =>
      optionalExtras
        ? !!options.personalAccident && optionalExtras.includes(options.personalAccident.type)
        : !!options.personalAccident?.selected,
    [optionalExtras, options.personalAccident],
  );

  const selectedPersonalAccidentOrIncluded = useMemo(
    () => selectedPersonalAccident || selectedEvolution || selectedEvolutionPlus,
    [selectedPersonalAccident, selectedEvolution, selectedEvolutionPlus],
  );

  const selectedAccidentHelmetLeathers = useMemo(
    () =>
      optionalExtras
        ? !!options.accidentHelmetLeathers && optionalExtras.includes(options.accidentHelmetLeathers.type)
        : !!options.accidentHelmetLeathers?.selected,
    [optionalExtras, options.accidentHelmetLeathers],
  );

  return {
    selectedAccidentHelmetLeathers,
    selectedBreakdown,
    selectedBreakdown1,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4,
    selectedBreakdownOrIncluded,
    selectedBreakdown1OrIncluded,
    selectedBreakdown4OrIncluded,
    selectedEnhanced,
    selectedEnhancedPlus,
    selectedEvolution,
    selectedEvolutionPlus,
    selectedHelmetLeathers,
    selectedHelmetLeathersOrIncluded,
    selectedLegal,
    selectedLegalOrIncluded,
    selectedPersonalAccident,
    selectedPersonalAccidentOrIncluded,
  };
};

export default useCart;
