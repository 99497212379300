import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "modules/redux/store";
import { vehicleActionRequest } from "./actions";
import { VehicleApiRequest, VehicleApiResponseResult } from "./types";

export const useVehicleAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    vehicleRequest: useCallback(
      (search: VehicleApiRequest): Promise<VehicleApiResponseResult> => {
        return dispatch(vehicleActionRequest(search));
      },
      [dispatch],
    ),
  };
};
