import { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "modules/redux/store";

import { LiveChatVisibility } from "../types";

type UseHandleClickProps = (visibility: LiveChatVisibility) => () => void;

const setCustomerDetails = (email: string, fullname: string) => {
  fullname && window.LiveChatWidget && window.LiveChatWidget.call("set_customer_name", fullname);
  email && window.LiveChatWidget && window.LiveChatWidget.call("set_customer_email", email);
};

const useHandleClick: UseHandleClickProps = visibility => {
  const { email, name } = useSelector(state => state.risk.proposer, shallowEqual);
  const fullname = name.forenames !== "" && name.surname !== "" ? `${name.forenames} ${name.surname}` : "";

  const handleClickRef = useRef<ReturnType<UseHandleClickProps>>(() => void 0);
  const handleClick = useCallback(() => handleClickRef.current(), []);

  useEffect(() => {
    handleClickRef.current = () => {
      if (visibility !== "maximized") {
        window.LiveChatWidget && window.LiveChatWidget.call("maximize");
        setCustomerDetails(email, fullname);
      } else {
        window.LiveChatWidget && window.LiveChatWidget.call("minimize");
      }
    };
  }, [email, fullname, visibility]);

  return handleClick;
};

export default useHandleClick;
