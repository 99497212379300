import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";
import moment from "moment";

type UseLicenceProps = (props: {
  date: string;
  name: string;
  setFieldValue: FormikProps<string>["setFieldValue"];
  years: string;
}) => void;

const useLicence: UseLicenceProps = ({ date, name, setFieldValue, years }) => {
  const handleChangeRef = useRef<(years: string) => void>(() => void 0);
  const handleChange = useCallback((years: string) => handleChangeRef.current(years), []);

  useEffect(() => {
    handleChangeRef.current = years => {
      if (["0", "1", "2", "3"].includes(years)) {
        const value = date ? moment(date, years === "0" ? "YYYY-MM" : "YYYY-MM-DD", true) : undefined;
        value &&
          value.isValid() &&
          setFieldValue(`${name}.bikeLicence.date`, value.format(years === "0" ? "YYYY-MM-DD" : "YYYY-MM"));
      }
    };
  }, [date, name, setFieldValue]);

  useEffect(() => {
    handleChange(years);
  }, [handleChange, years]);
};

export default useLicence;
