import { VrmApiRequest, VrmValidateApiResponseResult } from "./types";

const cache: {
  vrm: VrmApiRequest;
  result: VrmValidateApiResponseResult;
}[] = [];

export const cacheSearch = (vrm: VrmApiRequest) => {
  return cache.find(item => item.vrm === vrm);
};

export const cacheResult = (vrm: VrmApiRequest, result: VrmValidateApiResponseResult) => {
  !cacheSearch(vrm) &&
    cache.push({
      vrm: vrm,
      result: result,
    });
};
