import { produce } from "immer";

import { Risk, RiskActionSetMainRider } from "../../types";

const setMainRider = (state: Risk, action: RiskActionSetMainRider): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.proposer.isMainRider = draftState.proposer.id === action.id;
    draftState.additionalRiders.forEach(item => (item.isMainRider = item.id === action.id));
  });
};

export default setMainRider;
