import { produce } from "immer";

import { Proceed, ProceedActionType } from "./types";
import { ProceedAction } from "./constants";
import { newProceed } from "./helpers";

const initialState: Proceed = newProceed();

function proceedReducer(state = initialState, action: ProceedActionType) {
  switch (action.type) {
    case ProceedAction.REQUEST_CONFIGURE:
      return produce(state, (draftState: Proceed) => {
        draftState.quoteToken = action.risk.cart.quoteToken;
        draftState.update.daytimeTelephone = action.risk.proposer.daytimeTelephone;
        draftState.update.detailsOfProductsEmail = action.risk.additionalQuestions.detailsOfProductsEmail;
        draftState.update.detailsOfProductsPhone = action.risk.additionalQuestions.detailsOfProductsPhone;
        draftState.update.detailsOfProductsPost = action.risk.additionalQuestions.detailsOfProductsPost;
        draftState.update.detailsOfProductsText = action.risk.additionalQuestions.detailsOfProductsText;
        draftState.update.documentDelivery = action.risk.additionalQuestions.documentDelivery;
        draftState.update.email = action.risk.proposer.email;
        draftState.update.paymentMethod = action.risk.cart.paymentMethod;
        draftState.update.termsAgreedPolicy = action.risk.additionalQuestions.termsAgreedPolicy;
        draftState.update.vehicleRegistration = action.risk.vehicle.regNumber.replace(/\s+/g, "");
        draftState.webReference = action.webReference;
      });

    case ProceedAction.UPDATE:
      return produce(state, (draftState: Proceed) => {
        draftState.bankDetails.accountName = action.proceed.bankDetails.accountName;
        draftState.bankDetails.accountNumber = action.proceed.bankDetails.accountNumber;
        draftState.bankDetails.bankName = action.proceed.bankDetails.bankName;
        draftState.bankDetails.preferredPaymentDay = action.proceed.bankDetails.preferredPaymentDay;
        draftState.bankDetails.sortCode = action.proceed.bankDetails.sortCode;
        draftState.bankDetails.termsAgreedPayment = action.proceed.bankDetails.termsAgreedPayment;
      });

    default:
      return state;
  }
}

export default proceedReducer;
