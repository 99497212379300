import { FormikProps } from "formik";

import { AbiApiResponseResult } from "modules/api/abi/types";

export const setFields = (
  name: string,
  result: AbiApiResponseResult,
  setFieldTouched: FormikProps<number | string>["setFieldTouched"],
  setFieldValue: FormikProps<number | string>["setFieldValue"],
  yearOfManufacture: "" | number,
  reset: boolean | undefined = true,
): void => {
  setFieldValue(`${name}.yearOfManufacture`, yearOfManufacture);
  !!reset && setTimeout(() => setFieldTouched(`${name}.yearOfManufacture`, false));

  setFieldValue(`${name}.model`, result.model);
  !!reset && setTimeout(() => setFieldTouched(`${name}.model`, false));

  setFieldValue(`${name}.make`, result.manufacturer);
  !!reset && setTimeout(() => setFieldTouched(`${name}.make`, false));

  setFieldValue(`${name}.cc`, result.engineCC);
  !!reset && setTimeout(() => setFieldTouched(`${name}.cc`, false));
};
