import { Dispatch } from "modules/redux/store";

import { AdminActionHideNavigation, AdminActionShowNavigation } from "./types";
import { RootState } from "modules/redux/store";
import { AdminAction } from "./constants";

export const adminActionToggleNavigation = (hide?: boolean) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const showNavigation = getState().admin.showNavigation;
    const html = document.getElementsByTagName("html")[0];
    if (showNavigation || hide) {
      html.classList.remove("platform-admin");
      dispatch(adminActionHideNavigation());
    } else {
      html.classList.add("platform-admin");
      dispatch(adminActionShowNavigation());
    }
  };
};

const adminActionHideNavigation = (): AdminActionHideNavigation => ({
  type: AdminAction.HIDE_NAVIGATION,
});

const adminActionShowNavigation = (): AdminActionShowNavigation => ({
  type: AdminAction.SHOW_NAVIGATION,
});
