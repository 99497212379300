import { produce } from "immer";

import { TrustpilotApiResponseResult, TrustpilotActionType } from "./types";
import { TrustpilotAction } from "./constants";

const initialState: TrustpilotApiResponseResult = {
  adjective: "",
  reviews: 0,
  stars: 0,
};

function trustpilotReducer(state = initialState, action: TrustpilotActionType) {
  switch (action.type) {
    case TrustpilotAction.REQUEST_SUCCESS:
      return produce(state, (draftState: TrustpilotApiResponseResult) => {
        draftState.adjective = action.trustpilot.adjective || "";
        draftState.reviews = action.trustpilot.reviews || 0;
        draftState.stars = action.trustpilot.stars || 0;
      });
    default:
      return state;
  }
}

export default trustpilotReducer;
