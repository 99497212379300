import { Sanitisers } from "../constants";

export function maskPostcode(value: string) {
  value = value
    .toUpperCase()
    .replace(Sanitisers.LEADING_WHITESPACE, "")
    .replace(Sanitisers.ALPHANUMERIC_ALLOW_SPACE, "");
  if (value.match(/[a-zA-Z]{1,2}[0-9]\d?[a-zA-Z]?\s*\d{1}[abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}/)) {
    value = value.replace(/\s+/g, "");
    value = value.substring(0, value.length - 3) + " " + value.substring(value.length - 3);
  }
  return value;
}

export enum MaskVrmInputType {
  INPUT = "input",
  TEXTAREA = "textarea",
}

export function maskVrm(value: string, type: MaskVrmInputType) {
  value = value
    .toUpperCase()
    .replace(Sanitisers.LEADING_WHITESPACE, "")
    .replace(Sanitisers.ALPHANUMERIC_ALLOW_SPACE, "")
    .replace(/\r?\n|\r/g, "");
  if (
    type === MaskVrmInputType.TEXTAREA &&
    value.replace(/\s+/g, "").length === 7 &&
    value
      .replace(/\s+/g, "")
      .match(
        /^[A-Z]{2}([1-9]|0[2-9]|5[1-9]|0[2-9]|6[0-9]|1[0-9]|1[0-9]|2[0-9]|7[0-9]|3[0-9]|8[0-9]|4[0-9]|9[0-9])[A-Z]{3}$/,
      )
  ) {
    value = value.replace(/\s+/g, "");
    value = value.substring(0, 4) + "\n" + value.substring(4);
  }
  return value;
}

export function maskName(value: string) {
  return value
    .replace(Sanitisers.LEADING_WHITESPACE, "")
    .replace(Sanitisers.NAME, "")
    .replace(/(^|\s)([a-z])/gi, function (all, p1, p2) {
      return p1 + p2.toUpperCase();
    })
    .replace(/-([a-z])/gi, function (all, letter) {
      return "-" + letter.toUpperCase();
    })
    .replace(/'([a-z])/gi, function (all, letter) {
      return "'" + letter.toUpperCase();
    })
    .replace(" -", "-")
    .replace("- ", "-")
    .replace(/(-)\1+/, "-")
    .replace(/^-+/g, "")
    .replace(/(')\1+/, "'")
    .replace(/(\s)\1+/, " ");
}
