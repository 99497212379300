import { produce } from "immer";

import { Admin, AdminActionType } from "./types";
import { AdminAction } from "./constants";

const initialState: Admin = {
  showNavigation: false,
};

function adminReducer(state = initialState, action: AdminActionType) {
  switch (action.type) {
    case AdminAction.HIDE_NAVIGATION:
      return produce(state, (draftState: Admin) => {
        draftState.showNavigation = false;
      });
    case AdminAction.SHOW_NAVIGATION:
      return produce(state, (draftState: Admin) => {
        draftState.showNavigation = true;
      });
    default:
      return state;
  }
}

export default adminReducer;
