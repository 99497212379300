import React from "react";

import { ReactComponent as ErrorIcon } from "modules/theme/icons/questions/error.svg";

import styles from "./styles.module.scss";

type ErrorProps = {
  error: string | string[];
};

const Error: React.FunctionComponent<ErrorProps> = ({ error }) => {
  return typeof error === "string" ? (
    <div className={styles["error"]} aria-live="polite">
      <ErrorIcon />
      <p>{error}</p>
    </div>
  ) : null;
};

export default React.memo(Error);
