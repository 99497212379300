import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ModalLoadingRequoteProps } from "components/Modals/ModalLoadingRequote";
import { ModalLoadingQuoteProps } from "components/Modals/ModalLoadingQuote";
import { ModalLoadingProps } from "components/Modals/ModalLoading";
import { ModalConfirmProps } from "components/Modals/ModalConfirm";
import { modalActionShow, modalActionHide } from "../actions";
import { ModalInfoProps } from "components/Modals/ModalInfo";
import { ModalFormProps } from "components/Modals/ModalForm";
import { Dispatch } from "modules/redux/store";
import { ModalType } from "../constants";

type Modal = {
  type: ModalType;
} & (
  | ({ type: ModalType.CONFIRM } & Omit<ModalConfirmProps, "destroyModal" | "hideModal" | "id" | "open">)
  | ({ type: ModalType.FORM } & Omit<ModalFormProps, "destroyModal" | "hideModal" | "id" | "open">)
  | ({ type: ModalType.INFO } & Omit<ModalInfoProps, "destroyModal" | "hideModal" | "id" | "open">)
  | ({ type: ModalType.LOADING } & Omit<ModalLoadingProps, "destroyModal" | "hideModal" | "id" | "open">)
  | ({ type: ModalType.LOADING_QUOTE } & Omit<ModalLoadingQuoteProps, "destroyModal" | "hideModal" | "id" | "open">)
  | ({ type: ModalType.LOADING_REQUOTE } & Omit<ModalLoadingRequoteProps, "destroyModal" | "hideModal" | "id" | "open">)
);

const useModalAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    modalHide: useCallback(
      (id: string) => {
        dispatch(modalActionHide(id));
      },
      [dispatch],
    ),
    modalShow: useCallback(
      (modal: Modal) => {
        return dispatch(modalActionShow(modal));
      },
      [dispatch],
    ),
  };
};

export default useModalAction;
