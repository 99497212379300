import { EventsType } from "react-idle-timer";

export const events: EventsType[] = [
  "keydown",
  "mousedown",
  "mousemove",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange",
];

export const eventsWithScroll: EventsType[] = [...events, "DOMMouseScroll", "mousewheel", "wheel"];

export enum SessionAction {
  RESET = "SESSION_RESET",
  DESTROY = "SESSION_DESTROY",
}
