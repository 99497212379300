import { applyMiddleware, combineReducers, compose, createStore, StoreEnhancer } from "redux";
import { TypedUseSelectorHook, useSelector as useDefaultSelector } from "react-redux";
import { createReduxHistoryContext } from "redux-first-history";
import { composeWithDevTools } from "@redux-devtools/extension";
import { createBrowserHistory } from "history";
import { isChrome } from "react-device-detect";
import thunk from "redux-thunk";

import { isAdmin } from "modules/admin";
import middleware from "./middleware";
import reducers from "./reducers";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  router: routerReducer,
  ...reducers,
});

const composeEnhancers = isAdmin && isChrome ? composeWithDevTools({}) : compose;
const storeEnhancers: StoreEnhancer[] = [];

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, routerMiddleware, ...middleware()), ...storeEnhancers),
);

export { shallowEqual } from "react-redux";

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<RootState> = useDefaultSelector;

export type { Dispatch } from "redux";

export default store;
