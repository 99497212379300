import { ArticlesApiResponseResult, ArticlesActionType } from "./types";
import { ArticlesAction } from "./constants";
import { produce } from "immer";

const initialState: ArticlesApiResponseResult = {
  blogs: [],
  fixed: [],
};

function articlesReducer(state = initialState, action: ArticlesActionType): ArticlesApiResponseResult {
  switch (action.type) {
    case ArticlesAction.REQUEST_SUCCESS:
      return produce(state, (draftState: ArticlesApiResponseResult) => {
        draftState.blogs = action.articles.blogs;
        draftState.competition = action.articles.competition;
        draftState.fixed = action.articles.fixed;
      });
    default:
      return state;
  }
}

export default articlesReducer;
