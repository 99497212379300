import { useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import sleep from "sleep-promise";

import { VehicleApiResponseResult } from "modules/api/vehicle/types";
import { MetadataItem } from "modules/metadata/types";
import useHandleRequest from "./handleRequest";
import useHandleChange from "./handleChange";
import useHandleSubmit from "./handleSubmit";
import { formatResults } from "../helpers";
import { isError } from "modules/utils";

type UseSearchProps = {
  errorCC?: string;
  errorMake?: string;
  errorModel?: string;
  errorYearOfManufacture?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFieldTouched: FormikProps<number | string>["setFieldTouched"];
  setFieldValue: FormikProps<number | string>["setFieldValue"];
  valueAbiCode: string;
  valueCC: "" | number;
  valueMake: string;
  valueModel: string;
  valueYearOfManufacture: "" | number;
};

type UseSearchReturnProps = [
  string | undefined,
  (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
  () => void,
  boolean,
  MetadataItem[],
];

const useSearch = ({
  errorCC,
  errorMake,
  errorModel,
  errorYearOfManufacture,
  name,
  onChange,
  setFieldTouched,
  setFieldValue,
  valueAbiCode,
  valueCC,
  valueMake,
  valueModel,
  valueYearOfManufacture,
}: UseSearchProps): UseSearchReturnProps => {
  const [results, setResults] = useState<VehicleApiResponseResult>();
  const vehicles = useMemo(() => formatResults(results), [results]);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>();

  const handleChange = useHandleChange({
    name,
    onChange,
    setApiError,
    setFieldTouched,
    setFieldValue,
    setResults,
    valueAbiCode,
  });

  const handleRequest = useHandleRequest(valueCC, valueMake, valueModel, valueYearOfManufacture);

  const handleSubmit = useHandleSubmit({
    errorCC,
    errorMake,
    errorModel,
    errorYearOfManufacture,
    name,
    setApiError,
    setFieldTouched,
    setFieldValue,
    setLoading,
    setResults,
  });

  useEffect(() => {
    if (loading) {
      let active = true;
      (async function () {
        try {
          await sleep(500);
          const response = await handleRequest();
          active && setResults(response);
        } catch (error) {
          active && isError(error) && setApiError(error.message);
        } finally {
          active && setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [handleRequest, loading]);

  return [apiError, handleChange, handleSubmit, loading, vehicles];
};

export default useSearch;
