import { useMemo } from "react";

import { newVehicle } from "modules/risk/helpers";
import { UseState } from "./types";

const useState: UseState = ({ id, index, risk }) =>
  useMemo(
    () => ({
      currentAdditionalVehicle: id === "new" ? newVehicle() : risk.additionalVehicles[index],
      hasAdditionalRiders: risk.hasAdditionalRiders,
      hasAdditionalVehicles: risk.hasAdditionalVehicles,
      policy: risk.policy,
    }),
    [id, index, risk.additionalVehicles, risk.hasAdditionalRiders, risk.hasAdditionalVehicles, risk.policy],
  );

export default useState;
