import React from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "No thanks",
  confirmLabel: "Add to cart",
  title: "Are you sure you don't want to add Helmet & Leathers?",
  icon: ModalIconType.HELMET_LEATHERS,
  text: (
    <React.Fragment>
      <h3>
        Give yourself peace of mind by ensuring your gear is covered and avoid that expensive repurchase in the case of
        an accident
      </h3>
      <p>Cover for your helmet and protective clothing</p>
      <ul>
        <li>Up to £1,500 cover</li>
        <li>Protect your helmet and leathers in the event of an accident</li>
      </ul>
    </React.Fragment>
  ),
};

export default template;
