import React from "react";

import { PaymentMethod } from "modules/risk/constants";
import { formatPrice } from "modules/utils";
import { useMemo } from "react";

type UsePrice = (
  grossPremium: number,
  paymentMethod: PaymentMethod,
  disabled?: boolean,
) => {
  price: string | JSX.Element;
  priceLabel: string;
};

export const usePrice: UsePrice = (grossPremium, paymentMethod, disabled) => {
  const priceMonthly = useMemo(() => formatPrice(Math.round(grossPremium / 12), true), [grossPremium]);

  const priceAnnually = useMemo(() => formatPrice(grossPremium, true), [grossPremium]);

  const priceLabel = useMemo(() => {
    switch (true) {
      case disabled:
        return "Included";
      case paymentMethod === PaymentMethod.ANNUAL:
        return `${priceAnnually} or ${priceMonthly} p/m`;
      default:
        return `${priceMonthly} p/m or ${priceAnnually} annually`;
    }
  }, [disabled, paymentMethod, priceAnnually, priceMonthly]);

  const price = useMemo(() => {
    switch (true) {
      case disabled:
        return "Included";
      case paymentMethod === PaymentMethod.ANNUAL:
        return (
          <React.Fragment>
            <span>{priceAnnually}</span>
            <small>{priceMonthly} p/month</small>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <span>{priceMonthly} p/m</span>
            <small>{priceAnnually} p/year</small>
          </React.Fragment>
        );
    }
  }, [disabled, paymentMethod, priceAnnually, priceMonthly]);

  return { price, priceLabel };
};
