import { LOCATION_CHANGE } from "redux-first-history";
import { Middleware } from "redux";

import { proceedLocationChange, proceedSaveCookie } from "./utils";
import { RouterActionType } from "modules/router/types";
import { ProceedActionType } from "./types";
import { ProceedAction } from "./constants";

const createProceedMiddleware: () => Middleware =
  () => () => next => async (action: ProceedActionType | RouterActionType) => {
    let nextAction;
    switch (action.type) {
      case LOCATION_CHANGE:
        proceedLocationChange(action.payload.location.pathname);
        break;

      case ProceedAction.REQUEST_SUCCESS:
        proceedSaveCookie(action.url);
        break;
    }
    return nextAction ? nextAction : next(action);
  };

export default createProceedMiddleware;
