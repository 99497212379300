import * as yup from "yup";

import * as validators from "./validators";
import Messages from "./messages";

yup.setLocale({
  mixed: {
    notType: Messages.NOT_TYPE,
    oneOf: Messages.ONE_OF,
    required: Messages.REQUIRED,
  },
  string: {
    email: Messages.EMAIL,
  },
});

yup.addMethod<yup.DateSchema>(yup.date, "bikeLicenceDate", validators.bikeLicenceDate);
yup.addMethod<yup.DateSchema>(yup.date, "dateOfBirth", validators.dateOfBirth);
yup.addMethod<yup.DateSchema>(yup.date, "inceptionDate", validators.inceptionDate);
yup.addMethod<yup.DateSchema>(yup.date, "purchasedDateInceptionDate", validators.purchasedDateInceptionDate);
yup.addMethod<yup.DateSchema>(yup.date, "purchasedDateYearOfManufacture", validators.purchasedDateYearOfManufacture);
yup.addMethod<yup.DateSchema>(yup.date, "residentSince", validators.residentSince);

yup.addMethod<yup.NumberSchema>(yup.number, "maxLength", validators.maxLengthNumber);
yup.addMethod<yup.NumberSchema>(yup.number, "yearOfManufacture", validators.yearOfManufactureNumber);

yup.addMethod<yup.StringSchema>(yup.string, "account", validators.account);
yup.addMethod<yup.StringSchema>(yup.string, "bikeLicenceYears", validators.bikeLicenceYears);
yup.addMethod<yup.StringSchema>(yup.string, "emailCDL", validators.emailCDL);
yup.addMethod<yup.StringSchema>(yup.string, "gender", validators.gender);
yup.addMethod<yup.StringSchema>(yup.string, "maxLength", validators.maxLengthString);
yup.addMethod<yup.StringSchema>(yup.string, "postcode", validators.postcode);
yup.addMethod<yup.StringSchema>(yup.string, "relationshipRules", validators.relationshipRules);
yup.addMethod<yup.StringSchema>(yup.string, "sortcode", validators.sortcode);
yup.addMethod<yup.StringSchema>(yup.string, "telephone", validators.telephone);
yup.addMethod<yup.StringSchema>(yup.string, "vrm", validators.vrm);

export { default as Messages } from "./messages";
export type { CreateError } from "./types";

export default yup;
