import React from "react";

import { ReactComponent as ArrowSVG } from "modules/theme/icons/product/arrow.svg";
import Button from "components/Button";

import styles from "./styles.module.scss";

type PolicyHeaderProps = {
  onBack: () => void;
  webReference: string;
};

const PolicyHeader: React.FunctionComponent<PolicyHeaderProps> = ({ onBack, webReference }) => {
  return (
    <header className={styles["policy-header"]}>
      <Button ariaLabel="Go back" label="Go back" onClick={onBack}>
        <ArrowSVG />
      </Button>
      <p>
        Your web reference: <span>{webReference}</span>
      </p>
    </header>
  );
};

export default React.memo(PolicyHeader);
