import { InputType, Sanitisers } from "../constants";

export const getIsNegative = (min?: number | string, max?: number | string) => {
  return (min && min < 0) || (max && max < 0) ? true : false;
};

export const getMaxLength = (digits: number, min?: number | string, max?: number | string): number | undefined => {
  let maxLength = 0;
  if (min !== undefined && max !== undefined) {
    maxLength = Math.max(
      (min && String(min).replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "").length) || 0,
      (max && String(max).replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "").length) || 0,
    );
  } else if (max !== undefined) {
    maxLength = String(max).replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "").length || 0;
  }
  if (maxLength > 0 && digits > 0) {
    maxLength += digits + 1;
  }
  return maxLength > 0 ? maxLength : undefined;
};

export const handleBlur =
  (onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void) => (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    event.currentTarget.type = InputType.NUMBER;
  };

export function handleChange(
  event: React.ChangeEvent<HTMLInputElement>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
) {
  const value = event.currentTarget.value;
  const descriptor = Object.getOwnPropertyDescriptor(event.currentTarget, "value");
  if (descriptor) {
    const descriptorNew = { ...descriptor };
    descriptorNew.get = function () {
      return value === "" || isNaN(Number(value)) ? "" : Number(value);
    };
    Object.defineProperty(event.currentTarget, "value", descriptorNew);
    onChange(event);
    Object.defineProperty(event.currentTarget, "value", descriptor);
  }
}

export const handleFocus =
  (onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void) => (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
    event.currentTarget.type = InputType.TEXT;
  };
