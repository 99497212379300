import React, { useCallback, useMemo, useRef, useState } from "react";

import { QuestionSelect, QuestionSwitch } from "components/Questions";
import { QuestionQualificationsProps } from "./types";
import Messages from "modules/validation/messages";

const QuestionQualifications: React.FunctionComponent<QuestionQualificationsProps> = ({
  autoFocus,
  error,
  onBlur,
  onChange,
  options,
  setFieldTouched,
  setFieldValue,
  touched,
  value,
  ...props
}) => {
  const [valueSwitch, setValueSwitch] = useState<boolean | "">(value ? (value === "NONE" ? false : true) : "");

  const open = useRef<boolean>(!!valueSwitch);

  const handleSetFieldTouched = useCallback(
    (field: string, isTouched?: boolean | undefined) => {
      !open.current && setFieldTouched(field, isTouched);
    },
    [setFieldTouched],
  );

  const handleSetFieldValue = useCallback(
    (field: string, value: boolean | "") => {
      setFieldValue(field, value === false ? "NONE" : "");
      setFieldTouched(field, !value);
      setValueSwitch(value);
      open.current = !!value;
    },
    [setFieldTouched, setFieldValue],
  );

  const filtered = useMemo(() => options?.filter(option => option.value !== "NONE"), [options]);

  return (
    <React.Fragment>
      <QuestionSwitch
        autoFocus={autoFocus}
        error={!open.current && error ? Messages.OPTION : undefined}
        label="Any riding qualifications?"
        setFieldTouched={handleSetFieldTouched}
        setFieldValue={handleSetFieldValue}
        touched={!open.current ? touched : undefined}
        value={valueSwitch}
        {...props}
      />
      {!!open.current && (
        <QuestionSelect
          error={error}
          label="Please select your qualification"
          onBlur={onBlur}
          onChange={onChange}
          options={filtered}
          secondary={true}
          touched={touched}
          value={value}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(QuestionQualifications);
