import { LOCATION_CHANGE } from "redux-first-history";
import { matchPath } from "react-router-dom";
import { Middleware } from "redux";

import { ConfirmationActionType } from "modules/confirmation/types";
import { ConfirmationAction } from "modules/confirmation/constants";
import { Dispatch, RootState } from "modules/redux/store";
import { PrecisActionType } from "modules/precis/types";
import { PrecisAction } from "modules/precis/constants";
import { RouterActionType } from "modules/router/types";
import { ROUTE } from "modules/router/constants";
import { getRoute } from "modules/router/utils";
import { pushEvent } from "./utils";
import {
  analyticsEventAddToCart,
  analyticsEventCheckout,
  analyticsEventPageView,
  analyticsEventPurchase,
} from "./events";

const cache = { conversion: false };

const createAnalyticsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: ConfirmationActionType | PrecisActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        const route = getRoute(action.payload.location.pathname);
        !!route && route.path !== ROUTE.CONFIRMATION && analyticsEventPageView(route);

        if (matchPath(ROUTE.PRODUCT, action.payload.location.pathname)) {
          analyticsEventAddToCart(getState);
        }

        if (
          matchPath(ROUTE.QUOTE, action.payload.location.pathname) &&
          !!process.env.REACT_APP_GTM_ADS &&
          !cache.conversion
        ) {
          pushEvent("conversion", { send_to: `AW-1010755900/PEDTCMm1rtwYELzS--ED` });
          cache.conversion = true;
        }

        break;
      }

      case PrecisAction.REQUEST_STARTED:
        analyticsEventCheckout(getState);
        break;

      case ConfirmationAction.REQUEST_SUCCESS: {
        nextAction = next(action);

        const route = getRoute(ROUTE.CONFIRMATION);
        !!route && analyticsEventPageView(route);

        analyticsEventPurchase(getState);

        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default createAnalyticsMiddleware;
