import yup, { Messages } from "modules/validation";
import moment from "moment";

const validatePurchasedDate = (
  purchasedDate: moment.Moment,
  yearOfManufacture: moment.Moment,
): boolean | yup.ValidationError => {
  return yearOfManufacture.isSameOrBefore(purchasedDate, "day");
};

function validator(this: yup.DateSchema) {
  return this.test("purchasedDateYearOfManufacture", Messages.YEAR_OF_MANUFACTURE_PURCHASED, function (value) {
    const purchasedDate = value ? moment(value) : false;
    const yearOfManufacture = this.parent.yearOfManufacture
      ? moment(this.parent.yearOfManufacture, "YYYY", true)
      : false;
    return yearOfManufacture && yearOfManufacture.isValid() && purchasedDate && purchasedDate.isValid()
      ? validatePurchasedDate(purchasedDate, yearOfManufacture)
      : true;
  });
}

export default validator;
