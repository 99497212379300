import { useDispatch } from "react-redux";

import schema from "modules/schema/routes/recall";
import { Dispatch } from "modules/redux/store";
import { UseRecallRoute } from "./types";

import useHandleSubmit from "./useHandleSubmit";
import useRedirect from "./useRedirect";
import useRecall from "./useRecall";

const useRecallRoute: UseRecallRoute = () => {
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = useHandleSubmit(dispatch);

  const redirect = useRedirect();

  const recall = useRecall();

  return {
    handleSubmit,
    recall,
    redirect,
    schema,
  };
};

export default useRecallRoute;
