import React from "react";

import { ReactComponent as HelpIcon } from "modules/theme/icons/questions/help.svg";

import styles from "../styles.module.scss";

type TooltipProps = {
  onMouseDown: (event: React.MouseEvent<SVGElement>) => void;
};

const Tooltip: React.FunctionComponent<TooltipProps> = ({ onMouseDown }) => {
  return <HelpIcon className={styles["tooltip"]} onMouseDown={onMouseDown} />;
};

export default React.memo(Tooltip);
