import React, { useCallback, useState } from "react";
import clsx from "clsx";

import { QuestionTemplateProps } from "./types";
import Tooltip from "./Tooltip";
import Error from "./Error";
import Label from "./Label";
import Help from "./Help";
import Info from "./Info";

import styles from "./styles.module.scss";

const QuestionTemplate: React.FunctionComponent<QuestionTemplateProps> = ({
  children,
  disabled,
  error,
  grouped,
  help,
  id,
  info,
  label,
  secondary,
  size,
  touched,
}) => {
  const [showHelp, setHelp] = useState(false);
  const handleMouseDown = useCallback((event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    setHelp(state => !state);
  }, []);
  const className = clsx(
    styles["question"],
    disabled && styles["disabled"],
    !disabled && error && touched && styles["invalid"],
    grouped && styles["grouped"],
    secondary && styles["secondary"],
    showHelp && styles["show-help"],
    size && styles[`${size}`],
  );
  return (
    <div className={className}>
      <div>{!!label && <Label disabled={disabled} id={id} label={label} />}</div>
      <div>
        <div>
          {children}
          {!!help && <Tooltip onMouseDown={handleMouseDown} />}
        </div>
        {!disabled && !!error && !!touched && <Error error={error} />}
        {!disabled && !!help && showHelp && <Help help={help} />}
        {!disabled && !!info && <Info info={info} />}
      </div>
    </div>
  );
};

export default React.memo(QuestionTemplate);
