import { useEffect, useState } from "react";

import { useDocumentAction } from "modules/api/document/hooks";
import useHandleClick from "./handleClick";
import { checkLink } from "../helpers";

type UseDocumentReturnProps = [(event: React.MouseEvent<HTMLAnchorElement>) => void, string | undefined];

const useDocument = (link: string, webReference?: string): UseDocumentReturnProps => {
  const [href, setHref] = useState<string | undefined>(checkLink(link) ? link : undefined);
  const { documentRequest } = useDocumentAction();
  const handleClick = useHandleClick({ documentRequest, href, link, setHref, webReference });

  useEffect(() => {
    if (!checkLink(link)) {
      let active = true;
      (async function () {
        try {
          const response = await documentRequest(false, link);
          active && setHref(response ? response : undefined);
        } catch (error) {
          active && setHref(undefined);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [documentRequest, link]);

  return [handleClick, href];
};

export default useDocument;
