import React from "react";
import { useId } from "react-id-generator";
import { FormikProps } from "formik";
import clsx from "clsx";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import Error from "components/Questions/QuestionTemplate/Error";
import Checkbox from "components/Inputs/Checkbox";

import styles from "./styles.module.scss";

type PolicyConfirmProps = {
  error?: string;
  name: string;
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  touched?: boolean;
  value: "" | boolean;
};

const PolicyConfirm: React.FunctionComponent<PolicyConfirmProps> = ({
  error,
  name,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touched,
  value,
}) => {
  const handleShowModalInfo = useModalInfo();
  const [id] = useId(1, "question-");
  const className = clsx(styles["policy-confirm"], error && touched && styles["invalid"]);
  return (
    <div className={className}>
      <Checkbox
        id={id}
        invalid={!!error && touched}
        name={name}
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={value}
      />
      <div>
        <p>By ticking this box I confirm that:</p>
        <div>
          I have had the opportunity to read the{" "}
          <span>
            <button
              aria-label="Assumptions"
              onClick={event => {
                event.preventDefault();
                handleShowModalInfo({ event, template: ModalInfoTemplates.ASSUMPTIONS });
              }}
              onMouseDown={event => event.preventDefault()}
              type="button">
              Assumptions
            </button>
            <span>,</span>
          </span>
          <a
            href="https://www.devittinsurance.com/motorbike-insurance/terms-of-business/"
            rel="noopener noreferrer"
            target="_blank">
            Terms of Business and Important Information
          </a>{" "}
          and{" "}
          <a href="https://www.devittinsurance.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
            Security and Privacy Policy Statement
          </a>{" "}
          together with the insurance product information and policy wording for each product I am purchasing.
        </div>
      </div>
      {!!error && !!touched && <Error error={error} />}
    </div>
  );
};

export default React.memo(PolicyConfirm);
