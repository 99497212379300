import yup, { CreateError, Messages } from "modules/validation";
import { Risk } from "modules/risk/types";
import { getContext } from "./utils";
import moment from "moment";

const validatePurchasedDate = (
  createError: CreateError,
  inceptionDate: moment.Moment,
  purchasedDate: moment.Moment,
): boolean | yup.ValidationError => {
  return inceptionDate.isBefore(purchasedDate, "day")
    ? createError({
        message: Messages.PURCHASED_DATE,
      })
    : true;
};

function validator(this: yup.DateSchema) {
  return this.test("purchasedDateInceptionDate", Messages.DATE, function (value) {
    let inceptionDate: false | moment.Moment, purchasedDate: false | moment.Moment;
    if (this.path === "policy.inceptionDate") {
      inceptionDate = value ? moment(value, "YYYY-MM-DD", true) : false;
      purchasedDate = getContext<Risk>(this)?.vehicle.purchased
        ? moment(getContext<Risk>(this)?.vehicle.purchased, "YYYY-MM-DD", true)
        : false;
    } else {
      inceptionDate = getContext<Risk>(this)?.policy.inceptionDate
        ? moment(getContext<Risk>(this)?.policy.inceptionDate, "YYYY-MM-DD", true)
        : false;
      purchasedDate = value ? moment(value) : false;
    }
    return inceptionDate && inceptionDate.isValid() && purchasedDate && purchasedDate.isValid()
      ? validatePurchasedDate(this.createError, inceptionDate, purchasedDate)
      : true;
  });
}

export default validator;
