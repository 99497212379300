import React from "react";

import { ReactComponent as CompareTheMarket } from "modules/theme/icons/product/agg-logos/compare-the-market.svg";
import { ReactComponent as Quotezone } from "modules/theme/icons/product/agg-logos/quotezone.svg";

type AggLogoProps = {
  whereHear: string;
};

const AggLogo: React.FunctionComponent<AggLogoProps> = ({ whereHear }) => {
  if (["COMKAT", "COMKB1", "COMKL1"].includes(whereHear)) {
    return <CompareTheMarket />;
  }
  if (whereHear === "QTZONE") {
    return <Quotezone />;
  }
  return null;
};

export default React.memo(AggLogo);
