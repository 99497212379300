import React, { useContext } from "react";
import { shallowEqual, useSelector } from "modules/redux/store";
import { matchPath, useLocation } from "react-router-dom";
import clsx from "clsx";

import { ReactComponent as GlobalSignSVG } from "modules/theme/icons/footer/globalsign.svg";
import { ReactComponent as BikeSafeSVG } from "modules/theme/icons/footer/bikesafe.svg";
import { ReactComponent as MciaSVG } from "modules/theme/icons/footer/mcia.svg";
import { ReactComponent as BibaSVG } from "modules/theme/icons/footer/biba.svg";
import { ReactComponent as MagSVG } from "modules/theme/icons/footer/mag.svg";
import { ReactComponent as BmfSVG } from "modules/theme/icons/footer/bmf.svg";

import { isAdmin } from "modules/admin";
import { matchPaths } from "modules/router/utils";
import { ROUTE } from "modules/router/constants";
import Button from "components/Button";
import Defaqto from "./Defaqto";
import Navigation from "components/Footer/Navigation";
import PlatformContext from "modules/platform/context";

import styles from "./styles.module.scss";

const getEnvironment = (): string => {
  const environment =
    process.env.NODE_ENV === process.env.REACT_APP_ENV
      ? process.env.NODE_ENV
      : `${process.env.NODE_ENV} - ${process.env.REACT_APP_ENV}`;
  return environment.toUpperCase();
};

const getVersion = (): string => {
  return process.env.REACT_APP_VERSION || "0.0.0";
};

const Footer: React.FunctionComponent = () => {
  const { isMobileOnly } = useContext(PlatformContext);
  const error = useSelector(state => state.app.error.route, shallowEqual);
  const location = useLocation();
  const isConfirmationPage = !error && !!matchPath(ROUTE.CONFIRMATION, location.pathname);
  const showFooter = error ? true : !matchPaths([ROUTE.PRODUCT, ROUTE.POLICY], location.pathname);
  const className = clsx(
    styles["footer"],
    isAdmin && styles["admin"],
    isAdmin && isConfirmationPage && styles["confirmation"],
  );
  return showFooter ? (
    <footer className={className}>
      <div>
        <div className={styles["text-container"]}>
          <p>
            <strong>Need a little extra support? We're here to help</strong>
          </p>
          <p>
            Being aware of any challenges our customers are facing can help us to consider our approach and offer
            appropriate support where it's needed.
          </p>
          <p>
            Should you need support or assistance please contact us on{" "}
            {isMobileOnly ? (
              <Button ariaLabel="Call" onClick={() => window.open("tel:03453004870", "_self")}>
                0345 300 4870
              </Button>
            ) : (
              <React.Fragment>0345 300 4870</React.Fragment>
            )}
            .
          </p>
        </div>
        <Navigation />
        <div className={styles["logo-container"]}>
          <div>
            <div>
              <BibaSVG />
              <BmfSVG />
              <MciaSVG />
            </div>
            <div>
              <MagSVG />
              <BikeSafeSVG />
            </div>
          </div>
          <div>
            <Defaqto />
            <GlobalSignSVG />
          </div>
        </div>
        <p>
          © {new Date().getFullYear()} Devitt Insurance Services Limited, which is authorised and regulated by the{" "}
          <a href="https://www.fca.org.uk/firms/financial-services-register" rel="noopener noreferrer" target="_blank">
            Financial Conduct Authority
          </a>
          . Registered Office: The Walbrook Building, 25 Walbrook, London, EC4N 8AW. Registered in England and Wales.
          Company Number: 02438974.
        </p>
        <small>
          <span>Environment:</span> {getEnvironment()}
          <span>Version:</span> {getVersion()}
        </small>
      </div>
    </footer>
  ) : null;
};

export default React.memo(Footer);
