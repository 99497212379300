import { produce } from "immer";

import { Risk, RiskActionRemoveOptionalExtra } from "../../types";

const removeOptionalExtra = (state: Risk, action: RiskActionRemoveOptionalExtra): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.cart.optionalExtras = draftState.cart.optionalExtras.filter(option => option !== action.option.type);
  });
};

export default removeOptionalExtra;
