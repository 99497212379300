import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Metadata } from "modules/metadata/types";
import schema from "modules/schema/routes/rider";
import { Dispatch } from "modules/redux/store";
import { Risk } from "modules/risk/types";
import { UseRiderRoute } from "./types";
import useQuery from "../useQuery";

import useHandleSubmit from "./useHandleSubmit";
import useHandleCancel from "./useHandleCancel";
import useHandleBack from "./useHandleBack";
import useValidate from "../useValidate";
import useRedirect from "./useRedirect";

const useRiderRoute: UseRiderRoute = () => {
  const metadata: Metadata = useSelector(state => state.metadata);
  const risk: Risk = useSelector(state => state.risk);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const handleBack = useHandleBack({ dispatch, hasAdditionalVehicles: risk.hasAdditionalVehicles, navigate, query });

  const handleCancel = useHandleCancel({ navigate, query });

  const handleSubmit = useHandleSubmit({ dispatch, navigate });

  const redirect = useRedirect();

  const validate = useValidate(query);

  return {
    handleBack,
    handleCancel,
    handleSubmit,
    metadata,
    redirect,
    risk,
    schema,
    validate,
  };
};

export default useRiderRoute;
