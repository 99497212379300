import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Comprehensive Cover",
  text: (
    <React.Fragment>
      <p>Covers you for accidental damage, theft, damage as a result of a fire, theft or attempted theft.</p>
      <p>
        You are also covered for claims made against you by other people for bodily injury or damage to their property.
      </p>
    </React.Fragment>
  ),
};

export default template;
