import React from "react";

import PageWrapper from "components/PageWrapper";
import * as Components from "./Confirmation";
import { TemplateProps } from "./types";

const Template: React.FunctionComponent<TemplateProps> = ({ articles, confirmation }) => {
  return (
    <PageWrapper>
      <Components.ConfirmationMessage confirmation={confirmation} />
      <Components.ConfirmationBanner />
      {!!articles.competition && <Components.ConfirmationCompetition competition={articles.competition} />}
      {articles.fixed.length > 0 && <Components.ConfirmationPages pages={articles.fixed} />}
      {articles.blogs.length > 0 && <Components.ConfirmationBlog blogs={articles.blogs} />}
      <Components.ConfirmationSocial />
    </PageWrapper>
  );
};

export default Template;
