import React from "react";
import { FormikErrors, FormikProps, FormikTouched } from "formik";

import { MetadataItem } from "modules/metadata/types";
import * as Question from "components/Questions";
import { Risk } from "modules/risk/types";
import Confirm from "./Confirm";

import styles from "./styles.module.scss";

type PolicyReviewProps = {
  errors: FormikErrors<Risk>;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLElement>) => void;
  options: MetadataItem[];
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  touched: FormikTouched<Risk>;
  values: Risk;
};

const PolicyReview: React.FunctionComponent<PolicyReviewProps> = ({
  errors,
  onBlur,
  onChange,
  options,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  return (
    <div className={styles["policy-review"]}>
      <header>
        <h2>Review Your Details</h2>
      </header>
      <section>
        <Question.QuestionText
          autoFocus={true}
          error={errors.vehicle?.regNumber}
          format={Question.QuestionTextFormat.VRM}
          label="Please enter the registration number of your motorcycle"
          maxLength={8}
          name={"vehicle.regNumber"}
          onBlur={onBlur}
          onChange={onChange}
          placeholder="ENTER REG"
          readOnly={readOnly}
          touched={touched.vehicle?.regNumber}
          value={values.vehicle.regNumber}
        />

        <Question.QuestionText
          error={errors.proposer?.email}
          format={Question.QuestionTextFormat.EMAIL}
          help="<p>We may use this email address to contact you about this quotation.</p><p>We will not otherwise use this means to contact you unless you give us permission to do so.</p>"
          label="Your email address"
          maxLength={50}
          name="proposer.email"
          onBlur={onBlur}
          onChange={onChange}
          readOnly={readOnly}
          touched={touched.proposer?.email}
          value={values.proposer.email}
        />

        {values.proposer.daytimeTelephoneUnknown && (
          <Question.QuestionText
            error={errors.proposer?.daytimeTelephone}
            format={Question.QuestionTextFormat.TEL}
            help="We may need to speak with you about your quote or policy. Your number is only used to provide the service you have requested and we won't call you unless we need to."
            label="Preferred telephone number"
            name="proposer.daytimeTelephone"
            onBlur={onBlur}
            onChange={onChange}
            readOnly={readOnly}
            touched={touched.proposer?.daytimeTelephone}
            value={values.proposer.daytimeTelephone}
          />
        )}

        <Question.QuestionSelect
          error={errors.additionalQuestions?.documentDelivery}
          label="How would you like to receive your policy?"
          help="You'll get your documents quicker by e-mail and it will help the environment by reducing the amount we print."
          name="additionalQuestions.documentDelivery"
          onBlur={onBlur}
          onChange={onChange}
          options={options}
          readOnly={readOnly}
          touched={touched.additionalQuestions?.documentDelivery}
          value={values.additionalQuestions.documentDelivery}
        />
      </section>
      <footer>
        <Confirm
          error={errors.additionalQuestions?.termsAgreedPolicy}
          name="additionalQuestions.termsAgreedPolicy"
          readOnly={readOnly}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.additionalQuestions?.termsAgreedPolicy}
          value={values.additionalQuestions.termsAgreedPolicy}
        />
      </footer>
    </div>
  );
};

export default PolicyReview;
