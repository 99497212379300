import React from "react";

import { MetadataItem } from "modules/metadata/types";
import Select from "components/Inputs/Select";
import { formatPrice } from "modules/utils";

import styles from "./styles.module.scss";

type ProductExcessProps = {
  compulsoryExcess: number;
  handleSetVoluntaryExcess: (event: React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLSelectElement>) => void;
  options: MetadataItem[];
  volXS: string;
};

const ProductExcess: React.FunctionComponent<ProductExcessProps> = ({
  compulsoryExcess,
  handleSetVoluntaryExcess,
  options,
  volXS,
}) => {
  return (
    <div className={styles["product-excess"]}>
      <header>
        <h2>Your Excess</h2>
      </header>
      <section>
        <h3 id="product-excess-label">Your Voluntary Accidental Damage excess</h3>
        <p>This will be in addition to any excess applied by the insurer, but may lead to a reduced premium.</p>
        <div>
          <Select
            ariaLabelledBy="product-excess-label"
            id="product-excess"
            name="product-excess"
            onChange={handleSetVoluntaryExcess}
            options={options}
            placeholder={false}
            value={volXS}
          />
        </div>
      </section>
      <footer>
        <p>Your Compulsory Accidental Damage excess is {formatPrice(compulsoryExcess)}</p>
      </footer>
    </div>
  );
};

export default ProductExcess;
