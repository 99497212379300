import React, { useCallback, useContext } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { RemoveScroll } from "react-remove-scroll";
import Slide from "@mui/material/Slide";
import ScrollContext from "modules/scroll";
import yup from "modules/validation";
import { Formik } from "formik";
import clsx from "clsx";

import Button, { ButtonStyle, ButtonType } from "components/Button";
import ModalIcon, { ModalIconType } from "components/Modals/Icons";
import PlatformContext from "modules/platform/context";
import { Claim, Conviction } from "modules/risk/types";
import { FormProps } from "components/Form/Wrapper";
import { useScroll } from "modules/scroll/hooks";
import ModalOverlay from "../ModalOverlay";

import styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ModalFormProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  icon?: ModalIconType;
  id: string;
  onCancel: () => void;
  onSubmit: (values: Claim | Conviction) => void;
  open: boolean;
  renderContent: (props: FormProps<Claim | Conviction>) => JSX.Element;
  schema?: yup.AnyObjectSchema | (() => yup.AnyObjectSchema);
  state: Claim | Conviction;
  title: string;
};

const ModalForm: React.FunctionComponent<Omit<ModalFormProps, "destroyModal" | "disableRestoreFocus" | "open">> = ({
  icon,
  onCancel,
  onSubmit,
  renderContent,
  schema,
  state,
  title,
}) => {
  const setShouldScroll = useScroll();
  return (
    <Formik
      enableReinitialize={false}
      initialValues={state}
      onSubmit={async values => {
        setShouldScroll(false);
        onSubmit(values);
      }}
      validateOnMount={false}
      validationSchema={schema}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          className={clsx(styles["modal-form"], isSubmitting && styles["submitting"])}
          onSubmit={event => {
            setShouldScroll(true);
            handleSubmit(event);
          }}
          noValidate>
          <fieldset role="dialog">
            <header>
              <legend>
                <h2>
                  <ModalIcon type={icon} />
                  <span>{title}</span>
                </h2>
              </legend>
            </header>
            <div>
              {renderContent({
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                touched,
                values,
              })}
            </div>
            <footer>
              <Button
                ariaLabel="Cancel"
                label="Cancel"
                onClick={onCancel}
                readOnly={isSubmitting}
                style={ButtonStyle.SECONDARY}
              />
              <Button
                ariaLabel="Continue"
                label="Continue"
                readOnly={isSubmitting}
                style={ButtonStyle.PRIMARY}
                type={ButtonType.SUBMIT}
              />
            </footer>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};

const ModalFormWrapper: React.FunctionComponent<ModalFormProps> = ({
  destroyModal,
  disableRestoreFocus,
  hideModal,
  onCancel,
  open,
  ...props
}) => {
  const { isMobile, isMobileOnly } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    onCancel ? open && onCancel() : open && hideModal();
  }, [hideModal, onCancel, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose],
  );

  return (
    <ScrollContext>
      <RemoveScroll enabled={isMobile}>
        <ModalOverlay
          disableRestoreFocus={disableRestoreFocus}
          fullScreen={isMobileOnly}
          fullWidth={true}
          onExited={destroyModal}
          onKeyDown={handleKeyDown}
          open={open}
          scroll="paper"
          TransitionComponent={Transition}>
          <ModalForm hideModal={hideModal} onCancel={onCancel} {...props} />
        </ModalOverlay>
      </RemoveScroll>
    </ScrollContext>
  );
};

export default ModalFormWrapper;
