import React, { useEffect, useRef } from "react";
import Inputmask from "inputmask";
import clsx from "clsx";

import { ReactComponent as RevealSVG } from "modules/theme/icons/form/inputs/reveal.svg";
import { InputStringFormat, InputType } from "../constants";
import { useScrollRef } from "modules/scroll/hooks";
import { InputPrimitiveProps } from "../types";
import { useOnMount } from "modules/utils";
import { handlePaste } from "../helpers";
import InputIcon from "./icons";

import styles from "./styles.module.scss";

const InputPrimitive: React.FunctionComponent<InputPrimitiveProps> = ({
  disabled,
  format = InputStringFormat.TEXT,
  id,
  inputMask,
  invalid,
  onMount,
  type = InputType.TEXT,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const scrollRef = useScrollRef(id);

  useOnMount(() => {
    !!onMount && onMount(inputRef);
  });

  useEffect(() => {
    if (inputMask && inputRef && inputRef.current && !inputRef.current.inputmask) {
      Inputmask(inputMask).mask(inputRef.current);
    }
  }, [inputRef, inputMask]);

  const className = clsx(
    styles["input"],
    format && styles[`input-${format}`],
    disabled && styles["disabled"],
    !disabled && invalid && styles["invalid"],
  );
  return (
    <div className={className} ref={!disabled && invalid ? scrollRef : undefined}>
      <InputIcon format={format} />
      <input
        disabled={disabled}
        id={id}
        ref={inputRef}
        onPaste={inputMask ? handlePaste : undefined}
        type={type}
        {...props}
      />
      {format === InputStringFormat.PASSWORD && (
        <RevealSVG
          onClick={() => {
            inputRef.current &&
              (inputRef.current.type === InputType.PASSWORD
                ? (inputRef.current.type = InputType.TEXT)
                : (inputRef.current.type = InputType.PASSWORD));
          }}
        />
      )}
    </div>
  );
};

export default React.memo(InputPrimitive);
