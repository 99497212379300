import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Personal Accident + Helmet & Leathers",
  text: (
    <React.Fragment>
      <p>Payment if you are hospitalised as a result of an accident, including protective clothing and helmet cover.</p>
      <ul>
        <li>Up to £20,000 cover</li>
        <li>£50 per night</li>
        <li>Cover for damage to protective clothing and helmets</li>
      </ul>
    </React.Fragment>
  ),
};

export default template;
