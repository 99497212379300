import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { Claim, Conviction } from "modules/risk/types";

type UseHandleResetProps = (props: {
  name: string;
  setFieldTouched: FormikProps<boolean | string | number | Claim[] | Conviction[]>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string | number | Claim[] | Conviction[]>["setFieldValue"];
  value: boolean | string | number | Claim[] | Conviction[];
}) => (open: boolean) => void;

const useHandleReset: UseHandleResetProps = ({ name, setFieldTouched, setFieldValue, value }) => {
  const handleResetRef = useRef<ReturnType<UseHandleResetProps>>(() => void 0);
  const handleReset = useCallback((open: boolean) => handleResetRef.current(open), []);

  useEffect(() => {
    handleResetRef.current = open => {
      if (!open && value !== "" && ["boolean", "string", "number"].includes(typeof value)) {
        setFieldValue(name, "");
      }
      if (!open && Array.isArray(value) && value.length) {
        setFieldValue(name, []);
      }
      setFieldTouched(name, false);
    };
  }, [name, setFieldTouched, setFieldValue, value]);

  return handleReset;
};

export default useHandleReset;
