import React from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { shallowEqual, useSelector } from "modules/redux/store";
import { Route, Routes } from "react-router-dom";
import { history } from "modules/redux/store";
import { isAdmin } from "modules/admin";
import { ROUTE } from "./constants";

import RouteAggregator from "routes/aggregator";
import RouteApp from "routes/app";
import RouteConfirmation from "routes/confirmation";
import RouteDebug from "routes/debug";
import RouteMotorcycle from "routes/motorcycle";
import RouteMotorcycles from "routes/motorcycles";
import RouteMotorcyclesAdditional from "routes/motorcycles/additional";
import RouteMessage from "routes/message";
import RoutePayment from "routes/payment";
import RoutePolicy from "routes/policy";
import RouteProduct from "routes/product";
import RouteQuote from "routes/quote";
import RouteRecall from "routes/recall";
import RouteRider from "routes/rider";
import RouteRiders from "routes/riders";
import RouteRidersAdditional from "routes/riders/additional";

const Component: React.FunctionComponent = () => {
  const error = useSelector(state => state.app.error.route, shallowEqual);
  const loading = useSelector(state => state.app.loading, shallowEqual);
  return (
    <Router history={history}>
      <RouteApp>
        {loading ? null : error ? (
          <Routes>
            <Route path="*" element={<RouteMessage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={ROUTE.MOTORCYCLE} element={<RouteMotorcycle />} />

            <Route path={`${ROUTE.MOTORCYCLES}/:id`} element={<RouteMotorcyclesAdditional />} />

            <Route path={ROUTE.MOTORCYCLES} element={<RouteMotorcycles />} />

            <Route path={ROUTE.RIDER} element={<RouteRider />} />

            <Route path={`${ROUTE.RIDERS}/:id`} element={<RouteRidersAdditional />} />

            <Route path={ROUTE.RIDERS} element={<RouteRiders />} />

            <Route path={ROUTE.QUOTE} element={<RouteQuote />} />

            <Route path={ROUTE.PRODUCT} element={<RouteProduct />} />

            <Route path={ROUTE.POLICY} element={<RoutePolicy />} />

            <Route path={ROUTE.PAYMENT} element={<RoutePayment />} />

            <Route path={ROUTE.CONFIRMATION} element={<RouteConfirmation />} />

            <Route path={ROUTE.RECALL} element={<RouteRecall />} />

            <Route path={ROUTE.AGGREGATOR} element={<RouteAggregator />} />

            {isAdmin && <Route path={ROUTE.DEBUG} element={<RouteDebug />} />}

            {isAdmin && <Route path={ROUTE.DEBUG_MODALS} element={<RouteDebug />} />}

            <Route path={`${ROUTE.MESSAGE}/:message`} element={<RouteMessage />} />

            <Route path={ROUTE.MESSAGE} element={<RouteMessage />} />

            <Route path="*" element={<RouteMessage />} />
          </Routes>
        )}
      </RouteApp>
    </Router>
  );
};

export default Component;
