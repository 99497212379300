import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { RiskActionRequestStarted, RiskActionRequestSuccess, RiskActionRequestFailure } from "./types";
import { RiskApiRequest, RiskApiResponse, RiskApiResponseResult } from "./types";
import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { quoteActionRequest } from "modules/quote/actions";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import vrmValidateApi from "modules/api/vrmValidate";
import { ModalType } from "modules/modals/constants";
import { OptionalExtra } from "modules/quote/types";
import { RootState } from "modules/redux/store";
import { isAxiosError } from "modules/api";
import { RiskAction } from "./constants";
import { isError } from "modules/utils";
import {
  AdditionalRider,
  Bike,
  Risk,
  RiskActionDeleteVehicle,
  RiskActionUpdateVehicle,
  RiskActionNewAdditionalVehicle,
  RiskActionUpdateAdditionalVehicle,
  RiskActionResetAdditionalVehicles,
  RiskActionSetMainRider,
  RiskActionUpdateRider,
  RiskActionDeleteAdditionalRider,
  RiskActionNewAdditionalRider,
  RiskActionUpdateAdditionalRider,
  RiskActionResetAdditionalRiders,
  RiskActionUpdateCart,
  RiskActionAddOptionalExtra,
  RiskActionRemoveOptionalExtra,
  RiskActionSetVoluntaryExcess,
  RiskActionSetAnnual,
  RiskActionSetMonthly,
  RiskActionSetParams,
  RiskActionUpdatePolicy,
  RiskActionDestroy,
} from "./types";

export const riskActionRequest = (request: RiskApiRequest) => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const id = dispatch(modalActionShow({ type: ModalType.LOADING_QUOTE }));
    dispatch(riskActionRequestStarted());
    try {
      const response: RiskApiResponse = await api(API.RISK_REQUEST(request));
      dispatch(riskActionRequestSuccessCache(response.data.risk));
      dispatch(riskActionRequestSuccess(response.data.risk));
      await dispatch(quoteActionRequest({ modal: id }));
    } catch (error) {
      dispatch(riskActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
      dispatch(modalActionHide(id));
    }
  };
};

export const riskActionRequestSuccessCache = (risk: RiskApiResponseResult) => {
  return async (): Promise<void> => {
    !!risk.vehicle.regNumber && vrmValidateApi(risk.vehicle.regNumber);
    process.env.REACT_APP_MULTIBIKE === "true" &&
      risk.additionalVehicles.forEach(item => !!item.regNumber && vrmValidateApi(item.regNumber));
  };
};

const riskActionRequestStarted = (): RiskActionRequestStarted => ({
  type: RiskAction.REQUEST_STARTED,
});

const riskActionRequestSuccess = (risk: RiskApiResponseResult): RiskActionRequestSuccess => ({
  type: RiskAction.REQUEST_SUCCESS,
  risk: risk,
});

const riskActionRequestFailure = (error: string): RiskActionRequestFailure => ({
  type: RiskAction.REQUEST_FAILURE,
  error: error,
});

export const riskActionDeleteVehicle = (id: string): RiskActionDeleteVehicle => ({
  type: RiskAction.DELETE_VEHICLE,
  id: id,
});

export const riskActionUpdateVehicle = (risk: Risk): RiskActionUpdateVehicle => ({
  type: RiskAction.UPDATE_VEHICLE,
  risk: risk,
});

export const riskActionNewAdditionalVehicle = (bike: Bike): RiskActionNewAdditionalVehicle => ({
  type: RiskAction.NEW_ADDITIONAL_VEHICLE,
  bike: bike,
});

export const riskActionUpdateAdditionalVehicle = (bike: Bike): RiskActionUpdateAdditionalVehicle => ({
  type: RiskAction.UPDATE_ADDITIONAL_VEHICLE,
  bike: bike,
});

export const riskActionResetAdditionalVehicles = (): RiskActionResetAdditionalVehicles => ({
  type: RiskAction.RESET_ADDITIONAL_VEHICLES,
});

export const riskActionSetMainRider = (id: string): RiskActionSetMainRider => ({
  type: RiskAction.SET_MAIN_RIDER,
  id: id,
});

export const riskActionUpdateRider = (risk: Risk): RiskActionUpdateRider => ({
  type: RiskAction.UPDATE_RIDER,
  risk: risk,
});

export const riskActionDeleteAdditionalRider = (id: string): RiskActionDeleteAdditionalRider => ({
  type: RiskAction.DELETE_ADDITIONAL_RIDER,
  id: id,
});

export const riskActionNewAdditionalRider = (additionalRider: AdditionalRider): RiskActionNewAdditionalRider => ({
  type: RiskAction.NEW_ADDITIONAL_RIDER,
  additionalRider: additionalRider,
});

export const riskActionUpdateAdditionalRider = (additionalRider: AdditionalRider): RiskActionUpdateAdditionalRider => ({
  type: RiskAction.UPDATE_ADDITIONAL_RIDER,
  additionalRider: additionalRider,
});

export const riskActionResetAdditionalRiders = (): RiskActionResetAdditionalRiders => ({
  type: RiskAction.RESET_ADDITIONAL_RIDERS,
});

export const riskActionUpdateCart = (cart: Risk["cart"]): RiskActionUpdateCart => ({
  type: RiskAction.UPDATE_CART,
  cart: cart,
});

export const riskActionAddOptionalExtra = (option: OptionalExtra): RiskActionAddOptionalExtra => ({
  type: RiskAction.ADD_OPTIONAL_EXTRA,
  option: option,
});

export const riskActionRemoveOptionalExtra = (option: OptionalExtra): RiskActionRemoveOptionalExtra => ({
  type: RiskAction.REMOVE_OPTIONAL_EXTRA,
  option: option,
});

export const riskActionSetVoluntaryExcess = (excess: string): RiskActionSetVoluntaryExcess => ({
  type: RiskAction.SET_VOLUNTARY_EXCESS,
  excess: excess,
});

export const riskActionSetAnnual = (): RiskActionSetAnnual => ({
  type: RiskAction.SET_ANNUAL,
});

export const riskActionSetMonthly = (): RiskActionSetMonthly => ({
  type: RiskAction.SET_MONTHLY,
});

export const riskActionSetParams = (
  affinity: string | null,
  policyuser1: string | null,
  policyuser16: string | null,
  subagent: string | null,
): RiskActionSetParams => ({
  type: RiskAction.SET_PARAMS,
  affinity: affinity,
  policyuser1: policyuser1,
  policyuser16: policyuser16,
  subagent: subagent,
});

export const riskActionUpdatePolicy = (risk: Risk): RiskActionUpdatePolicy => ({
  type: RiskAction.UPDATE_POLICY,
  risk: risk,
});

export const riskActionDestroy = (): RiskActionDestroy => ({
  type: RiskAction.DESTROY,
});
