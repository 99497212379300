import React from "react";

import { ReactComponent as ChevronRightSVG } from "modules/theme/icons/buttons/chevron-right.svg";
import { ReactComponent as ChevronLeftSVG } from "modules/theme/icons/buttons/chevron-left.svg";
import Button, { ButtonStyle, ButtonType } from "components/Button";

import styles from "./styles.module.scss";

type ControlProps = {
  backLabel?: string;
  continueLabel?: string;
  isSubmitting?: boolean;
  onBack?: () => void;
  onContinue?: () => void;
  submit?: boolean;
  submitLabel?: string;
};

const Control: React.FunctionComponent<ControlProps> = ({
  backLabel,
  continueLabel,
  isSubmitting,
  onBack,
  onContinue,
  submit,
  submitLabel,
}) => {
  return (
    <div className={styles["control"]}>
      {!!onContinue && (
        <Button
          allowFocus={true}
          ariaLabel={continueLabel ? continueLabel : "Continue"}
          label={continueLabel ? continueLabel : "Continue"}
          onClick={onContinue}
          readOnly={isSubmitting}
          style={ButtonStyle.SUCCESS}>
          <ChevronRightSVG />
        </Button>
      )}
      {!!submit && (
        <Button
          allowFocus={true}
          ariaLabel={submitLabel ? submitLabel : "Continue"}
          label={submitLabel ? submitLabel : "Continue"}
          readOnly={isSubmitting}
          style={ButtonStyle.SUCCESS}
          type={ButtonType.SUBMIT}>
          <ChevronRightSVG />
        </Button>
      )}
      {!!onBack && (!!onContinue || !!submit) && (
        <Button
          allowFocus={true}
          ariaLabel={backLabel ? backLabel : "Back"}
          label={backLabel ? backLabel : "Back"}
          onClick={onBack}
          readOnly={isSubmitting}
          style={ButtonStyle.SECONDARY}>
          <ChevronLeftSVG />
        </Button>
      )}
    </div>
  );
};

export default React.memo(Control);
