import React from "react";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import { getMetadataLabel } from "modules/metadata/helpers";
import { OptionalExtra, Quote } from "modules/quote/types";
import { PaymentMethod } from "modules/risk/constants";
import { MetadataItem } from "modules/metadata/types";
import { UseCart } from "modules/cart";
import Breakdown from "../Breakdown";
import Included from "../Included";
import Option from "../Option";

type ProductOverviewBasicProps = {
  cart: ReturnType<UseCart>;
  cover: string;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
  optionsCover: MetadataItem[];
  paymentMethod: PaymentMethod;
};

const ProductOverviewBasic: React.FunctionComponent<ProductOverviewBasicProps> = ({
  cart: {
    selectedAccidentHelmetLeathers,
    selectedBreakdown,
    selectedBreakdown1,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4,
    selectedHelmetLeathers,
    selectedLegal,
    selectedPersonalAccident,
  },
  cover,
  onAdd,
  onRemove,
  options,
  optionsCover,
  paymentMethod,
}) => {
  const handleShowModalInfo = useModalInfo();
  return (
    <ul>
      <Included
        label={optionsCover.length ? `${getMetadataLabel(optionsCover, cover)} Cover` : ""}
        onShowModal={event =>
          handleShowModalInfo({
            event,
            template:
              cover === "TPFT"
                ? ModalInfoTemplates.THIRD_PARTY_FIRE_THEFT
                : cover === "TPO"
                ? ModalInfoTemplates.THIRD_PARTY_ONLY
                : ModalInfoTemplates.COMPREHENSIVE_COVER,
          })
        }
      />

      <Included
        label="90 days full policy cover in EU"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.EU_COVER })}
      />

      <Included
        label="10% discount at SportsBikeShop"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.SPORTS_BIKE_SHOP })}
      />

      <Included
        label="24/7 Claim line"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.CLAIM_LINE })}
      />

      {!!options.legal && (
        <Option
          label="Legal Expenses & Loss Recovery"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.LEGAL_EXPENSES })}
          option={options.legal}
          paymentMethod={paymentMethod}
          selected={selectedLegal}
        />
      )}

      {!!options.breakdown1 && (
        <Breakdown
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={handleShowModalInfo}
          option1={options.breakdown1}
          option2={options.breakdown2}
          option3={options.breakdown3}
          option4={options.breakdown4}
          paymentMethod={paymentMethod}
          selectedBreakdown={selectedBreakdown}
          selectedBreakdown1={selectedBreakdown1}
          selectedBreakdown2={selectedBreakdown2}
          selectedBreakdown3={selectedBreakdown3}
          selectedBreakdown4={selectedBreakdown4}
        />
      )}

      {!!options.helmetLeathers && (
        <Option
          disabled={selectedAccidentHelmetLeathers}
          label="Helmet & Leathers"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.HELMET_LEATHERS_COVER })}
          option={options.helmetLeathers}
          paymentMethod={paymentMethod}
          selected={selectedHelmetLeathers}
        />
      )}

      {!!options.personalAccident && (
        <Option
          disabled={selectedAccidentHelmetLeathers}
          label="Personal Accident"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.PERSONAL_ACCIDENT_COVER })}
          option={options.personalAccident}
          paymentMethod={paymentMethod}
          selected={selectedPersonalAccident}
        />
      )}

      {!!options.accidentHelmetLeathers && (
        <Option
          label="Personal Accident + Helmet & Leathers"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.ACCIDENT_HELMET_LEATHERS })}
          option={options.accidentHelmetLeathers}
          paymentMethod={paymentMethod}
          selected={selectedAccidentHelmetLeathers}
        />
      )}
    </ul>
  );
};

export default React.memo(ProductOverviewBasic);
