import { useCallback } from "react";

import { quoteActionAddOptionalExtra } from "modules/quote/actions";
import { OptionalExtra } from "modules/quote/types";
import { UseHandleAdd } from "./types";

const useHandleAdd: UseHandleAdd = dispatch =>
  useCallback((option: OptionalExtra) => dispatch(quoteActionAddOptionalExtra(option)), [dispatch]);

export default useHandleAdd;
