import React, { useCallback, useState } from "react";

import { QuestionVehicleProps } from "./types";
import Description from "./Description";
import { getProps } from "./helpers";
import Search from "./Search";
import Vrm from "./Vrm";

const QuestionVehicle: React.FunctionComponent<QuestionVehicleProps> = props => {
  const { descriptionProps, searchProps, vrmProps } = getProps(props);
  const [open, setOpen] = useState<boolean>(false);
  const handleSetOpen = useCallback((state: boolean): void => setOpen(state), []);
  return (
    <React.Fragment>
      <Vrm open={open} setOpen={handleSetOpen} {...vrmProps} />

      {open && <Search {...searchProps} />}

      {!!props.valueAbiCode && <Description open={open} setOpen={handleSetOpen} {...descriptionProps} />}
    </React.Fragment>
  );
};

export default React.memo(QuestionVehicle);
