import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "RAC Breakdown",
  text: (
    <React.Fragment>
      <h2>Onward Travel plus EU cover</h2>

      <p>
        This option is the most comprehensive cover and is definitely the best choice if you travel long distances on
        your bike regularly.
      </p>

      <p>
        Onward Travel plus EU provides you with breakdown assistance if you're at home or more than ¼ of a mile away
        from home, as well as a replacement bike for up to 2 days whilst your bike is being repaired.
      </p>

      <p>
        If they cannot repair the bike, they can also provide an alternative form of transport, or accommodation if
        you're stuck. If you break down and you're on your way to a lovely warm location somewhere abroad, or while
        you're actually abroad, attempts will be made to fix your bike at the roadside but if that's not possible, it'll
        be taken to a local garage and will be fixed there. Again, if this isn't possible, alternative transport or
        overnight accommodation can be arranged.
      </p>
    </React.Fragment>
  ),
};

export default template;
