import React, { useState } from "react";
import { Formik, FormikErrors, FormikProps, FormikTouched, FormikValues } from "formik";
import clsx from "clsx";

import { scrollTop } from "modules/scroll/helpers";
import { useScroll } from "modules/scroll/hooks";
import ScrollContext from "modules/scroll";
import { isAdmin } from "modules/admin";
import yup from "modules/validation";
import ErrorLog from "../ErrorLog";
import Validate from "../Validate";

import styles from "./styles.module.scss";

export type FormProps<T> = {
  errors: FormikErrors<T>;
  handleBlur: (event: React.FocusEvent<HTMLElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLElement>) => void;
  isSubmitting: boolean;
  setFieldTouched: FormikProps<T>["setFieldTouched"];
  setFieldValue: FormikProps<T>["setFieldValue"];
  touched: FormikTouched<T>;
  values: T;
};

type FormPrimitiveProps<T> = {
  children?: (props: FormProps<T>) => React.ReactNode;
  enableReinitialize?: boolean;
  onSubmit?: (values: T) => void;
  schema?: yup.AnyObjectSchema | (() => yup.AnyObjectSchema);
  scroll?: boolean;
  state: T;
  validate?: boolean;
  validateOnMount?: boolean;
};

function FormPrimitive<T extends FormikValues>({
  children,
  enableReinitialize,
  onSubmit,
  schema,
  scroll = true,
  state,
  validate,
  validateOnMount,
}: Omit<FormPrimitiveProps<T>, "enableReinitialize"> & { enableReinitialize: boolean }) {
  const [count, setCount] = useState<number>(0);
  const setShouldScroll = useScroll();
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={state}
      onSubmit={async values => {
        setShouldScroll(false);
        scroll && (await scrollTop());
        onSubmit && onSubmit(values);
      }}
      validateOnMount={validateOnMount}
      validationSchema={schema}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidating,
        setFieldTouched,
        setFieldValue,
        submitCount,
        submitForm,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          className={clsx(styles["form"], isSubmitting && styles["submitting"])}
          onSubmit={event => {
            setShouldScroll(true);
            handleSubmit(event);
          }}
          noValidate>
          {children &&
            children({
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            })}
          {isAdmin && !isValidating && count !== submitCount && !!Object.keys(errors).length && (
            <ErrorLog<T> errors={errors} setCount={setCount} />
          )}
          {!!validate && <Validate setShouldScroll={setShouldScroll} submitForm={submitForm} />}
        </form>
      )}
    </Formik>
  );
}

function Wrapper<T extends FormikValues>({
  children,
  enableReinitialize = false,
  onSubmit,
  schema,
  scroll,
  state,
  validate,
  validateOnMount,
}: FormPrimitiveProps<T>) {
  return (
    <ScrollContext>
      <FormPrimitive<T>
        enableReinitialize={enableReinitialize}
        onSubmit={onSubmit}
        schema={schema}
        scroll={scroll}
        state={state}
        validate={validate}
        validateOnMount={validateOnMount}>
        {children}
      </FormPrimitive>
    </ScrollContext>
  );
}

export default Wrapper;
