import React from "react";

import ProductBreakdownTemplate from "../ProductBreakdownTemplate";
import ProductBreakdownOption from "./ProductBreakdownOption";
import { OptionalExtra, Quote } from "modules/quote/types";
import { PaymentMethod } from "modules/risk/constants";
import useProductBreakdownBasic from "./hooks";
import { UseCart } from "modules/cart";

type ProductBreakdownBasicProps = {
  cart: ReturnType<UseCart>;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
  paymentMethod: PaymentMethod;
};

const ProductBreakdownBasic: React.FunctionComponent<ProductBreakdownBasicProps> = ({ cart, options, ...props }) => {
  const { breakdown1, breakdown2, breakdown3, breakdown4, onClick, price, selected } = useProductBreakdownBasic({
    cart,
    options,
    ...props,
  });
  return breakdown1 ? (
    <ProductBreakdownTemplate
      included={false}
      onClick={onClick}
      open={true}
      price={price}
      selected={selected}
      subtitle="Choose from a selection of four different RAC Breakdown options to add to your insurance, depending on the level of cover you need."
      title="RAC Breakdown Cover">
      {!!breakdown1 && (
        <ProductBreakdownOption
          description="Breakdown cover for more than ¼ of a mile away from home within the UK, taxi fare for up to 20 miles if your bike can't be fixed as well as a tow for up to 10 miles."
          title="Roadside"
          {...breakdown1}
          {...props}
        />
      )}
      {!!breakdown2 && (
        <ProductBreakdownOption
          description="Roadside cover plus Recovery for up to 8 people (including you) and your bike to any single spot in the UK."
          title="Recovery"
          {...breakdown2}
          {...props}
        />
      )}
      {!!breakdown3 && (
        <ProductBreakdownOption
          description="Includes Roadside and Recovery plus assistance if you're At Home or less than ¼ of a mile away from home."
          title="At Home"
          {...breakdown3}
          {...props}
        />
      )}
      {!!breakdown4 && (
        <ProductBreakdownOption
          description="UK Roadside, Recovery and At Home cover plus Onward Travel and EU Breakdown. Repair will be attempted at the roadside or local garage, where this isn't possible, alternative transport or accommodation can be arranged."
          title="Onward Travel plus EU"
          {...breakdown4}
          {...props}
        />
      )}
    </ProductBreakdownTemplate>
  ) : null;
};

export default React.memo(ProductBreakdownBasic);
