import { InputAutocomplete, InputMode, InputType, RegularExpressions, Sanitisers } from "../../constants";
import { getIsNegative, getMaxLength, handleBlur, handleFocus } from "../helpers";
import { getInputPattern, handleInput, handleKeyDown } from "../../helpers";
import { Mask, MaskProps } from "../../types";

const mask = (props: MaskProps): Mask => {
  const isNegative = getIsNegative(props.min, props.max);
  return props.platform.isMobile
    ? {
        autoComplete: InputAutocomplete.OFF,
        inputMode: !isNegative ? InputMode.NUMERIC : undefined,
        maxLength: props.maxLength ? props.maxLength : getMaxLength(0, props.min, props.max),
        onBlur: handleBlur,
        onFocus: handleFocus,
        onInput: handleInput(event =>
          event.currentTarget.value
            .replace(isNegative ? Sanitisers.INTEGER_ALLOW_NEGATIVE : Sanitisers.INTEGER, "")
            .replace(Sanitisers.LEADING_ZEROS, "$1"),
        ),
        onKeyDown: handleKeyDown(isNegative ? RegularExpressions.INTEGER_ALLOW_NEGATIVE : RegularExpressions.INTEGER),
        pattern: !isNegative ? getInputPattern(props.platform) : undefined,
        type: InputType.NUMBER,
      }
    : {
        autoComplete: InputAutocomplete.OFF,
        inputMask: {
          alias: "integer",
          allowMinus: isNegative,
          autoUnmask: true,
          clearIncomplete: true,
          max: props.max ? String(props.max) : undefined,
          min: props.min ? String(props.min) : undefined,
          placeholder: "",
          rightAlign: false,
          showMaskOnHover: false,
        },
        maxLength: !props.min && !props.max ? props.maxLength : undefined,
        type: InputType.TEXT,
      };
};

export default mask;
