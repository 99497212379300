import { MetadataItem } from "modules/metadata/types";

export const getMetadataLabel = (metadata: MetadataItem[], value: string): string => {
  return metadata.find(item => item.value === value)?.label || "";
};

export const removeMetadataDuplicates = (metadata: MetadataItem[]): MetadataItem[] =>
  metadata.filter(
    (item, index, self) => index === self.findIndex(t => t.label === item.label && t.value === item.value),
  );
