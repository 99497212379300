import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { resetAbiCode, resetRegNumber, resetSearch } from "../../helpers";

type UseHandleClickProps = (props: {
  name: string;
  open: boolean;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setOpen: (state: boolean) => void;
}) => () => void;

const useHandleClick: UseHandleClickProps = ({ name, open, setApiError, setFieldTouched, setFieldValue, setOpen }) => {
  const handleClickRef = useRef<ReturnType<UseHandleClickProps>>(() => void 0);
  const handleClick = useCallback(() => handleClickRef.current(), []);

  useEffect(() => {
    handleClickRef.current = () => {
      resetRegNumber(name, setFieldTouched, setFieldValue);
      resetAbiCode(name, setFieldTouched, setFieldValue);
      !open && resetSearch(name, setFieldTouched, setFieldValue);
      setApiError(undefined);
      setOpen(true);
    };
  }, [name, open, setApiError, setFieldTouched, setFieldValue, setOpen]);

  return handleClick;
};

export default useHandleClick;
