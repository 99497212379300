import React from "react";

import useAppRoute from "modules/router/hooks/useAppRoute";
import { RouteProps } from "./types";
import Template from "./Template";

const Route: React.FunctionComponent<RouteProps> = ({ children }) => {
  const props = useAppRoute();
  return <Template {...props}>{children}</Template>;
};

export default Route;
