import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import schema from "modules/schema/routes/motorcycles/additional";
import { UseAdditionalMotorcyclesRoute } from "./types";
import { Metadata } from "modules/metadata/types";
import { useSelector } from "modules/redux/store";
import { Dispatch } from "modules/redux/store";
import { Risk } from "modules/risk/types";
import useQuery from "../useQuery";

import useHandleCancel from "./useHandleCancel";
import useHandleSubmit from "./useHandleSubmit";
import useValidate from "../useValidate";
import useRedirect from "./useRedirect";
import useIndex from "./useIndex";
import useState from "./useState";

const useAdditionalMotorcyclesRoute: UseAdditionalMotorcyclesRoute = () => {
  const metadata: Metadata = useSelector(state => state.metadata);
  const risk: Risk = useSelector(state => state.risk);
  const { id } = useParams<{ id: string }>();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const index = useIndex({ additionalVehicles: risk.additionalVehicles, id });

  const handleCancel = useHandleCancel({ count: risk.additionalVehicles.length, navigate });

  const handleSubmit = useHandleSubmit({ dispatch, id, navigate });

  const redirect = useRedirect(index);

  const state = useState({ id, index, risk });

  const validate = useValidate(query);

  return {
    handleCancel,
    handleSubmit,
    index,
    metadata,
    redirect,
    schema,
    state,
    validate,
  };
};

export default useAdditionalMotorcyclesRoute;
