import React from "react";

import PageWrapper, { Control } from "components/PageWrapper";
import { MetadataType } from "modules/metadata/constants";
import { FormProps, TemplateProps } from "./types";
import * as Question from "components/Questions";
import Progress from "components/Progress";
import * as Form from "components/Form";

const Template: React.FunctionComponent<TemplateProps> = ({
  handleBack,
  handleCancel,
  handleSubmit,
  metadata,
  risk,
  schema,
  validate,
}) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={risk} validate={validate}>
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
          <React.Fragment>
            <Progress
              hasAdditionalRiders={risk.hasAdditionalRiders}
              hasAdditionalVehicles={risk.hasAdditionalVehicles}
              readOnly={isSubmitting}
            />

            <Form.Fieldset title="About you" autoFocus={true}>
              <Question.QuestionTitle
                errors={errors.proposer?.name}
                name="proposer"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsTitle={metadata[MetadataType.TITLE]}
                optionsTitleOther={metadata[MetadataType.TITLE_OTHER]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.name}
                values={values.proposer.name}
                valueGender={values.proposer.gender}
              />

              <Question.QuestionText
                error={errors.proposer?.name?.forenames}
                format={Question.QuestionTextFormat.NAME}
                label="First name(s)"
                maxLength={30}
                name="proposer.name.forenames"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.proposer?.name?.forenames}
                value={values.proposer.name.forenames}
              />

              <Question.QuestionText
                error={errors.proposer?.name?.surname}
                format={Question.QuestionTextFormat.NAME}
                label="Surname"
                maxLength={20}
                name="proposer.name.surname"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.proposer?.name?.surname}
                value={values.proposer.name.surname}
              />

              {values.proposer.name.title === "Other" && ["Dr", "Prof"].includes(values.proposer.name.titleOther) && (
                <Question.QuestionRadio
                  error={errors.proposer?.gender}
                  label="Gender"
                  name="proposer.gender"
                  options={metadata[MetadataType.GENDER]}
                  readOnly={isSubmitting}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.proposer?.gender}
                  value={values.proposer.gender}
                />
              )}

              <Question.QuestionDate
                error={errors.proposer?.dateOfBirth}
                format={Question.QuestionDateFormat.DATE}
                help="Please enter the date in the format DD/MM/YYYY, e.g. 21/01/1975"
                label="Date of birth"
                name="proposer.dateOfBirth"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.dateOfBirth}
                value={values.proposer.dateOfBirth}
              />

              <Question.QuestionSwitch
                error={errors.proposer?.residentSinceBirth}
                label="Have you been permanently resident in the UK since birth?"
                name="proposer.residentSinceBirth"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.residentSinceBirth}
                value={values.proposer.residentSinceBirth}
              />

              <Question.QuestionSecondary
                name="proposer.residentSince"
                open={values.proposer.residentSinceBirth === false}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.proposer.residentSince}>
                <Question.QuestionDate
                  error={errors.proposer?.residentSince}
                  format={Question.QuestionDateFormat.DATE}
                  label="Enter the date you became a permanent UK resident"
                  name="proposer.residentSince"
                  readOnly={isSubmitting}
                  secondary={true}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.proposer?.residentSince}
                  value={values.proposer.residentSince}
                />
              </Question.QuestionSecondary>

              <Question.QuestionRadio
                columns={true}
                error={errors.proposer?.maritalStatus}
                format={Question.QuestionRadioFormat.VERTICAL}
                label="Marital status"
                name="proposer.maritalStatus"
                options={metadata[MetadataType.MARITAL_STATUS]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.maritalStatus}
                value={values.proposer.maritalStatus}
              />

              <Question.QuestionSwitch
                error={errors.additionalQuestions?.homeowner}
                label="Are you a homeowner?"
                name="additionalQuestions.homeowner"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.additionalQuestions?.homeowner}
                value={values.additionalQuestions.homeowner}
              />

              <Question.QuestionNormallyPay
                error={errors.additionalQuestions?.normallyPayByDirectDebit}
                name="additionalQuestions.normallyPayByDirectDebit"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.additionalQuestions?.normallyPayByDirectDebit}
                value={values.additionalQuestions.normallyPayByDirectDebit}
              />

              <Question.QuestionLicence
                errors={errors.proposer?.bikeLicence}
                name="proposer"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsType={metadata[MetadataType.LICENCE_TYPES]}
                optionsYears={metadata[MetadataType.LICENCE_HELD_YEARS]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.bikeLicence}
                values={values.proposer.bikeLicence}
              />

              <Question.QuestionQualifications
                error={errors.proposer?.motoringOrg}
                name="proposer.motoringOrg"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.MOTOR_ORG]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.motoringOrg}
                value={values.proposer.motoringOrg}
              />

              {!values.isAggregator && (
                <Question.QuestionSelect
                  error={errors.additionalQuestions?.whereHear}
                  label="Where did you hear about us?"
                  name="additionalQuestions.whereHear"
                  options={metadata[MetadataType.WHERE_HEAR]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  touched={touched.additionalQuestions?.whereHear}
                  value={values.additionalQuestions.whereHear}
                />
              )}
            </Form.Fieldset>

            <Form.Fieldset title="Employment">
              <Question.QuestionsEmploymentFT
                errors={errors.proposer?.fullTimeOccupation}
                name="proposer.fullTimeOccupation"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsEmployerBusiness={metadata[MetadataType.EMPLOYER_BUSINESS]}
                optionsOccupation={metadata[MetadataType.OCCUPATION]}
                optionsOccupationStudent={metadata[MetadataType.OCCUPATION_STUDENT]}
                optionsOccupationTypes={metadata[MetadataType.OCCUPATION_TYPES]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.fullTimeOccupation}
                values={values.proposer.fullTimeOccupation}
              />

              <Question.QuestionSwitch
                error={errors.proposer?.hasPartTimeOccupation}
                label="Do you have any part-time employment?"
                name="proposer.hasPartTimeOccupation"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.hasPartTimeOccupation}
                value={values.proposer.hasPartTimeOccupation}
              />

              <Question.QuestionsEmploymentPT
                errors={errors.proposer?.partTimeOccupation}
                hasPartTimeOccupation={values.proposer.hasPartTimeOccupation}
                name="proposer.partTimeOccupation"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsEmployerBusiness={metadata[MetadataType.EMPLOYER_BUSINESS]}
                optionsOccupation={metadata[MetadataType.OCCUPATION]}
                optionsOccupationTypesPartTime={metadata[MetadataType.OCCUPATION_TYPES_PART_TIME]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.partTimeOccupation}
                values={values.proposer.partTimeOccupation}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Contact information">
              <Question.QuestionText
                error={errors.proposer?.email}
                format={Question.QuestionTextFormat.EMAIL}
                help="<p>We may use this email address to contact you about this quotation.</p><p>We will not otherwise use this means to contact you unless you give us permission to do so.</p>"
                label="Your email address"
                maxLength={50}
                name="proposer.email"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.proposer?.email}
                value={values.proposer.email}
              />

              <Question.QuestionText
                error={errors.proposer?.daytimeTelephone}
                format={Question.QuestionTextFormat.TEL}
                help="We may need to speak with you about your quote or policy. Your number is only used to provide the service you have requested and we won't call you unless we need to."
                label="Preferred telephone number"
                name="proposer.daytimeTelephone"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.proposer?.daytimeTelephone}
                value={values.proposer.daytimeTelephone}
              />

              <Question.QuestionAddress
                errors={errors.proposer?.address}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                readOnly={isSubmitting}
                touched={touched.proposer?.address}
                values={values.proposer.address}
              />

              <Question.QuestionContact
                errors={errors.additionalQuestions}
                label="<p>How should we contact you?</p><p>We wouldn't want you to miss out on offers, exclusive deals and the latest information on products and services that are available to you.</p><p>If you don't wish to hear from us then please leave all options deselected.</p>"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.additionalQuestions}
                values={values.additionalQuestions}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Motoring history">
              <Question.QuestionSwitch
                error={errors.proposer?.hasClaims}
                label="<span>Have you had any motor accidents, claims or losses in the past 5 years, no matter who was at fault or if a claim was made?</span>"
                name="proposer.hasClaims"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.hasClaims}
                value={values.proposer.hasClaims}
              />

              <Question.QuestionSecondary
                name="proposer.claims"
                open={values.proposer.hasClaims === true}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.proposer.claims}>
                <Question.QuestionClaims
                  errors={errors.proposer?.claims}
                  name="proposer.claims"
                  options={metadata[MetadataType.MOTOR_CLAIM]}
                  readOnly={isSubmitting}
                  touched={touched.proposer?.claims}
                  values={values.proposer.claims}
                />
              </Question.QuestionSecondary>

              <Question.QuestionSwitch
                error={errors.proposer?.hasConvictions}
                label="<span>Have you had any driving related convictions, endorsements, penalties, disqualifications or bans in the past 5 years?</span>"
                name="proposer.hasConvictions"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.proposer?.hasConvictions}
                value={values.proposer.hasConvictions}
              />

              <Question.QuestionSecondary
                name="proposer.convictions"
                open={values.proposer.hasConvictions === true}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.proposer.convictions}>
                <Question.QuestionConvictions
                  errors={errors.proposer?.convictions}
                  name="proposer.convictions"
                  options={metadata[MetadataType.CONVICTION]}
                  readOnly={isSubmitting}
                  touched={touched.proposer?.convictions}
                  values={values.proposer.convictions}
                />
              </Question.QuestionSecondary>
            </Form.Fieldset>

            {(process.env.REACT_APP_MULTIBIKE !== "true" || !risk.hasAdditionalVehicles) && (
              <Form.Fieldset title="Additional Riders">
                <Question.QuestionSwitch
                  error={errors.hasAdditionalRiders}
                  label="Do you wish to include any additional riders?"
                  name="hasAdditionalRiders"
                  readOnly={isSubmitting}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.hasAdditionalRiders}
                  value={values.hasAdditionalRiders}
                />
              </Form.Fieldset>
            )}

            <Control
              backLabel={handleCancel ? "Cancel" : undefined}
              isSubmitting={isSubmitting}
              onBack={handleCancel ? handleCancel : handleBack && (() => handleBack(values))}
              submit={true}
            />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
