import React from "react";

import { InputDateFormat } from "../constants";
import { InputDateProps } from "../types";
import InputPrimitive from "../Primitive";
import { useMask } from "../hooks";

const InputDate: React.FunctionComponent<InputDateProps> = ({
  autoFocus,
  disabled,
  format = InputDateFormat.DATE,
  id,
  invalid,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  tabIndex,
  value,
}) => {
  const { inputMask, ...inputProps } = useMask({
    format: format,
    max: max,
    min: min,
    placeholder: placeholder,
  });
  return (
    <InputPrimitive
      autoComplete={inputProps.autoComplete}
      autoFocus={!disabled && autoFocus}
      disabled={disabled}
      format={format}
      id={id}
      inputMask={inputMask}
      inputMode={inputProps.inputMode}
      invalid={invalid}
      maxLength={inputProps.maxLength}
      name={name}
      pattern={inputProps.pattern}
      placeholder={inputProps.placeholder}
      onBlur={inputProps.onBlur ? inputProps.onBlur(onBlur) : onBlur}
      onChange={onChange}
      onFocus={inputProps.onFocus ? inputProps.onFocus(onFocus) : onFocus}
      onInput={inputProps.onInput ? inputProps.onInput(onInput) : onInput}
      onKeyDown={inputProps.onKeyDown ? inputProps.onKeyDown(onKeyDown) : onKeyDown}
      onMount={inputProps.onMount}
      readOnly={readOnly}
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
      type={inputProps.type}
      value={value || ""}
    />
  );
};

export default React.memo(InputDate);
