import React from "react";
import clsx from "clsx";

import { ReactComponent as LoadingSVG } from "modules/theme/icons/questions/loading.svg";
import { InputStringFormat } from "components/Inputs/Input/constants";
import InputString from "components/Inputs/Input/String";
import Button, { ButtonStyle } from "components/Button";
import { QuestionVrmChildProps } from "../types";

import styles from "./styles.module.scss";

const QuestionVrmAdditionalVehicle: React.FunctionComponent<QuestionVrmChildProps> = ({
  disabled,
  invalid,
  loading,
  onClick,
  onSubmit,
  open,
  readOnly,
  value,
  ...props
}) => {
  const className = clsx(styles["question-vrm-additional-vehicle-buttons"], loading && styles["loading"]);
  return (
    <div className={styles["question-vrm-additional-vehicle"]}>
      <InputString
        autoFocus={true}
        disabled={disabled}
        format={InputStringFormat.VRM}
        invalid={invalid}
        readOnly={readOnly}
        value={value}
        {...props}
      />
      <div className={className}>
        {!!loading && (
          <span>
            <LoadingSVG />
          </span>
        )}
        <div>
          <Button
            ariaLabel="Find my motorcycle"
            disabled={disabled || loading}
            label="Find my motorcycle"
            onClick={onSubmit}
            readOnly={readOnly}
            style={invalid ? ButtonStyle.INVALID : open ? ButtonStyle.SECONDARY : ButtonStyle.PRIMARY}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuestionVrmAdditionalVehicle);
