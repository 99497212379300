import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { ArticlesAction } from "./constants";
import { isError } from "modules/utils";
import {
  ArticlesActionRequestStarted,
  ArticlesActionRequestSuccess,
  ArticlesActionRequestFailure,
  ArticlesApiResponseResult,
  ArticlesApiResponse,
} from "./types";

export const articlesActionRequest = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(articlesActionRequestStarted());
    try {
      const response: ArticlesApiResponse = await api(API.ARTICLES_REQUEST("2"));
      dispatch(articlesActionRequestSuccess(response.data.articles));
    } catch (error) {
      dispatch(articlesActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
    }
  };
};

const articlesActionRequestStarted = (): ArticlesActionRequestStarted => ({
  type: ArticlesAction.REQUEST_STARTED,
});

const articlesActionRequestSuccess = (articles: ArticlesApiResponseResult): ArticlesActionRequestSuccess => ({
  type: ArticlesAction.REQUEST_SUCCESS,
  articles: articles,
});

const articlesActionRequestFailure = (error: string): ArticlesActionRequestFailure => ({
  type: ArticlesAction.REQUEST_FAILURE,
  error: error,
});
