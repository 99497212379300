import { cacheResult, cacheSearch } from "./cache";
import { VrmValidateApi } from "./types";
import { api, API } from "../";

const vrmValidateApi: VrmValidateApi = vrm => {
  return new Promise((resolve, reject) => {
    !!vrm && (vrm = vrm.replace(/\s+/g, ""));
    const cached = cacheSearch(vrm);
    if (cached) {
      resolve(cached.result);
    } else {
      api(API.VRM_VALIDATE_REQUEST(vrm)).then(
        response => {
          cacheResult(vrm, response.data.valid);
          resolve(response.data.valid);
        },
        error => reject(error),
      );
    }
  });
};

export default vrmValidateApi;
