import React from "react";
import { Provider } from "react-redux";

import { PlatformType } from "modules/platform/context";
import { appActionInit } from "modules/app/actions";
import { LiveChatProvider } from "modules/livechat";
import { useOnMount } from "modules/utils";
import { setupPlatform } from "./helpers";
import store from "modules/redux/store";
import Session from "modules/session";
import Modals from "modules/modals";
import Router from "modules/router";

import PlatformContext, {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} from "modules/platform/context";

const platform: PlatformType = {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
};

export default function App() {
  useOnMount(() => {
    setupPlatform(platform);
    store.dispatch(appActionInit());
  }, true);
  return (
    <Provider store={store}>
      <PlatformContext.Provider value={platform}>
        <LiveChatProvider>
          <Session>
            <React.Fragment>
              <Router />
              <Modals />
            </React.Fragment>
          </Session>
        </LiveChatProvider>
      </PlatformContext.Provider>
    </Provider>
  );
}
