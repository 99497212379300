import { QuestionAddressProps } from "./types";

import { DescriptionProps } from "./Description/types";
import { QuestionsProps } from "./Questions/types";
import { SearchProps } from "./Search/types";

type GetProps = {
  descriptionProps: Omit<DescriptionProps, "setShowQuestions">;
  questionsProps: QuestionsProps;
  searchProps: Omit<SearchProps, "setShowDescription">;
};

export const getProps = (props: QuestionAddressProps): GetProps => {
  return {
    descriptionProps: {
      address: props.values,
      errors: !!props.errors,
      readOnly: props.readOnly,
      touched: !!props.touched?.addressLine2,
    },
    questionsProps: {
      errors: props.errors,
      onBlur: props.onBlur,
      onChange: props.onChange,
      readOnly: props.readOnly,
      touched: props.touched,
      values: props.values,
    },
    searchProps: {
      errors: props.errors,
      onBlur: props.onBlur,
      onChange: props.onChange,
      readOnly: props.readOnly,
      setFieldTouched: props.setFieldTouched,
      setFieldValue: props.setFieldValue,
      touched: props.touched,
      values: props.values,
    },
  };
};
