import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "RAC Breakdown",
  text: (
    <React.Fragment>
      <h2>At Home</h2>

      <p>
        Cover away from the home is all well and good, but if you turn the key first thing in the morning and your bike
        won't start, you're a bit stuck.
      </p>

      <p>
        If you rely on your bike to get to work, it's a good idea to choose At Home cover, which includes assistance if
        you're at home or less than ¼ of a mile away from home, as well as taxi fare for 20 miles and a tow for 10 miles
        (you do get cover if you're more than ¼ of a mile away from home, too).
      </p>
    </React.Fragment>
  ),
};

export default template;
