import yup, { CreateError, Messages } from "modules/validation";
import vrmValidateApi from "modules/api/vrmValidate";
import { ApiError } from "modules/api";
import remote from "../remote";

const Errors = [ApiError.VRM_INVALID_TYPE, ApiError.VRM_INVALID_VRM, ApiError.VRM_NOT_FOUND, ApiError.VRM_Q_PLATE];

const [request, setActive] = remote(vrmValidateApi, Errors, "vrm");

const regexQPlate = new RegExp("^Q", "i");

const regexVrm = new RegExp(
  [
    new RegExp(
      "^[A-Z]{2}([1-9]|0[2-9]|5[1-9]|0[2-9]|6[0-9]|1[0-9]|1[0-9]|2[0-9]|7[0-9]|3[0-9]|8[0-9]|4[0-9]|9[0-9])[A-Z]{3}$",
    ),
    new RegExp("^[A-HJ-NP-Y][0-9]{1,3}[A-Z]{3}$"),
    new RegExp("^[A-Z]{3}[0-9]{1,3}[A-HJ-NP-Y]$"),
    new RegExp("^(?:[A-Z]{1,2}[0-9]{1,4}|[A-Z]{3}[0-9]{1,3})$"),
    new RegExp("^(?:[0-9]{1,4}[A-Z]{1,2}|[0-9]{1,3}[A-Z]{3})$"),
    new RegExp("^([A-Z]{2,3}\\s?[0-9]{1,4})$"),
    new RegExp("^([0-9]{1,4}\\s?[A-Z]{2,3})$"),
  ]
    .map(rule => {
      return rule.source;
    })
    .join("|"),
);

const validateQPlate = (value: string): boolean => !regexQPlate.test(value);

const validateVrm = (value: string): boolean => regexVrm.test(value);

const validate = (
  createError: CreateError,
  path: string,
  remote: boolean,
  value: string,
): boolean | yup.ValidationError | Promise<boolean | yup.ValidationError> =>
  !value || !validateVrm(value)
    ? false
    : !validateQPlate(value)
    ? createError({ message: Messages.VRM_Q_PLATE })
    : remote
    ? request(createError, path, value)
    : true;

function validator(this: yup.StringSchema, remote?: boolean) {
  return this.test("vrm", Messages.VRM, function (value) {
    !!value && (value = value.replace(/\s+/g, ""));
    setActive(this.path, value || "");
    return value ? validate(this.createError, this.path, !!remote, value) : true;
  });
}

export default validator;
