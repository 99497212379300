import React, { useRef, useState } from "react";
import { useOnMount } from "modules/utils";

import styles from "./styles.module.scss";

type FieldsetProps = {
  autoFocus?: boolean;
  children: React.ReactNode;
  title?: string;
};

const Fieldset: React.FunctionComponent<FieldsetProps> = ({ autoFocus, children, title }) => {
  const [tabIndex, setTabIndex] = useState<number | undefined>(autoFocus ? 0 : undefined);
  const fieldsetRef = useRef<HTMLFieldSetElement | null>(null);
  useOnMount(() => {
    if (!!autoFocus && fieldsetRef.current) {
      fieldsetRef.current.focus({
        preventScroll: true,
      });
      setTabIndex(undefined);
    }
  });
  return (
    <fieldset className={styles.fieldset} ref={fieldsetRef} tabIndex={tabIndex}>
      {!!title && (
        <legend>
          <h2>{title}</h2>
        </legend>
      )}
      {children}
    </fieldset>
  );
};

export default Fieldset;
