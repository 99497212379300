import Messages from "modules/validation/messages";
import yup from "modules/validation";
import moment from "moment";

const schema = yup.object({
  id: yup.string().required(),

  date: yup
    .date()
    .required()
    .min(moment().subtract(5, "years").format("YYYY-MM-DD"), Messages.CONVICTION_DATE)
    .max(moment().format("YYYY-MM-DD"), Messages.DATE_FUTURE),

  code: yup.string().required(Messages.LIST),

  drinkReading: yup.string().when("code", {
    is: (value?: string) => !!value && ["DR10", "DR20", "DR40", "DR50"].includes(value),
    then: schema => schema.required(Messages.OPTION),
  }),

  drinkReadingLevel: yup.number().when("code", {
    is: (value?: string) => !!value && ["DR10", "DR20", "DR40", "DR50"].includes(value),
    then: schema => schema.required().min(0, Messages.READING_LEVEL).max(999, Messages.READING_LEVEL),
  }),

  points: yup.number().required().min(0, Messages.POINTS).max(12, Messages.POINTS),

  fine: yup.number().required().min(0, Messages.FINE).max(9999, Messages.FINE),

  disqualified: yup.boolean().required(Messages.OPTION),

  disqualification: yup.number().when("disqualified", {
    is: true,
    then: schema => schema.required().min(1, Messages.BANNED).max(99, Messages.BANNED),
  }),
});

export default schema;
