import yup, { CreateError, Messages } from "modules/validation";
import moment from "moment";

const validateDateOfBirth = (
  createError: CreateError,
  dateOfBirth: moment.Moment,
  proposer: boolean,
): boolean | yup.ValidationError => {
  return dateOfBirth.isAfter(moment().subtract(16, "years"), "day") ||
    dateOfBirth.isSameOrBefore(moment().subtract(85, "years"), "day")
    ? createError({
        message: Messages.DATE_OF_BIRTH,
        params: proposer ? { you: "You" } : { you: "They" },
      })
    : true;
};

function validator(this: yup.DateSchema) {
  return this.test("dateOfBirth", Messages.DATE, function (value) {
    const dateOfBirth = value ? moment(value) : false;
    const proposer = this.path.startsWith("proposer");
    return dateOfBirth && dateOfBirth.isValid() ? validateDateOfBirth(this.createError, dateOfBirth, proposer) : true;
  });
}

export default validator;
