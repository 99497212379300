import { useCallback, useEffect, useRef } from "react";
import { flushSync } from "react-dom";
import { FormikProps } from "formik";

import { VrmSearchApiResponseResult } from "modules/api/vrmSearch/types";
import { setFields } from "../helpers";

type UseHandleResponseProps = (props: {
  name: string;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<number | string>["setFieldValue"];
  setOpen: (state: boolean) => void;
}) => (response: VrmSearchApiResponseResult) => void;

const useHandleResponse: UseHandleResponseProps = ({ name, setFieldTouched, setFieldValue, setOpen }) => {
  const handleResponseRef = useRef<ReturnType<UseHandleResponseProps>>(() => void 0);
  const handleResponse = useCallback<(response: VrmSearchApiResponseResult) => void>(
    response => handleResponseRef.current(response),
    [],
  );

  useEffect(() => {
    handleResponseRef.current = response =>
      flushSync(() => !setFields(response, name, setFieldTouched, setFieldValue) && setOpen(true));
  }, [name, setFieldTouched, setFieldValue, setOpen]);

  return handleResponse;
};

export default useHandleResponse;
