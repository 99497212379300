import React from "react";

import { ButtonIconGroup } from "components/Inputs/Buttons/Button/Icons";
import PageWrapper, { Control } from "components/PageWrapper";
import { MetadataType } from "modules/metadata/constants";
import { FormProps, TemplateProps } from "./types";
import * as Question from "components/Questions";
import Progress from "components/Progress";
import * as Form from "components/Form";

const Template: React.FunctionComponent<TemplateProps> = ({
  handleCancel,
  handleSubmit,
  metadata,
  risk,
  schema,
  validate,
}) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={risk} validate={validate}>
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
          <React.Fragment>
            <Progress
              hasAdditionalRiders={risk.hasAdditionalRiders}
              hasAdditionalVehicles={risk.hasAdditionalVehicles}
              readOnly={isSubmitting}
            />

            <Form.Fieldset title="Your Motorcycle">
              <Question.QuestionVehicle
                errorAbiCode={errors?.vehicle?.abiCode}
                errorCC={errors?.vehicle?.cc}
                errorMake={errors?.vehicle?.make}
                errorModel={errors?.vehicle?.model}
                errorRegNumber={errors?.vehicle?.regNumber}
                errorYearOfManufacture={errors?.vehicle?.yearOfManufacture}
                readOnly={isSubmitting}
                name="vehicle"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.MAKE]}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touchedAbiCode={touched?.vehicle?.abiCode}
                touchedCC={touched?.vehicle?.cc}
                touchedMake={touched?.vehicle?.make}
                touchedModel={touched?.vehicle?.model}
                touchedRegNumber={touched?.vehicle?.regNumber}
                touchedYearOfManufacture={touched?.vehicle?.yearOfManufacture}
                valueAbiCode={values.vehicle.abiCode}
                valueCC={values.vehicle.cc}
                valueMake={values.vehicle.make}
                valueModel={values.vehicle.model}
                valueRegNumber={values.vehicle.regNumber}
                valueYearOfManufacture={values.vehicle.yearOfManufacture}
              />

              <Question.QuestionTracker
                error={errors.vehicle?.tracker}
                name="vehicle.tracker"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.TRACKER]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.tracker}
                value={values.vehicle.tracker}
              />

              <Question.QuestionRadio
                error={errors.vehicle?.alarm}
                format={Question.QuestionRadioFormat.HORIZONTAL}
                help="<p>Security tags allow the police to easily trace the owner if a motorcycle is recovered after being stolen.</p>
                      <h2>Alphadot</h2>
                      <p>The motorcycle is marked with microdots containing ownership information.</p>
                      <h2>Datatag</h2>
                      <p>Various components of the motorcycle are marked with microdots, radio transponders, and other mechanisms.</p>
                      <h2>SmartWater</h2>
                      <p>The motorcycle is marked with an invisible liquid with a unique chemical signature.</p>"
                icons={ButtonIconGroup.ALARM}
                label="Has the motorcycle been tagged?"
                name="vehicle.alarm"
                options={metadata[MetadataType.ALARM]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.alarm}
                value={values.vehicle.alarm}
              />

              <Question.QuestionSecurityDevices
                error={errors.vehicle?.immob}
                name="vehicle.immob"
                onBlur={handleBlur}
                options={metadata[MetadataType.IMMOBILISER]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.immob}
                value={values.vehicle.immob}
              />

              <Question.QuestionSwitch
                error={errors.vehicle?.imported}
                label="Is your motorcycle an import?<br/><small>(Not usually sold in the UK)</small>"
                name="vehicle.imported"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.imported}
                value={values.vehicle.imported}
              />

              <Question.QuestionSwitch
                error={errors.vehicle?.purchasedYet}
                label="Have you purchased the motorcycle?"
                name="vehicle.purchasedYet"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.purchasedYet}
                value={values.vehicle.purchasedYet}
              />

              <Question.QuestionSecondary
                name="vehicle.purchased"
                open={values.vehicle.purchasedYet === true}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.vehicle.purchased}>
                <Question.QuestionPurchased
                  error={errors.vehicle?.purchased}
                  label="Enter the date of purchase"
                  name="vehicle.purchased"
                  open={values.vehicle.purchasedYet === true}
                  readOnly={isSubmitting}
                  secondary={true}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.vehicle?.purchased}
                  value={values.vehicle.purchased}
                />
              </Question.QuestionSecondary>

              <Question.QuestionNumber
                error={errors.vehicle?.value}
                help="Please note that this should be the approximate market value of the motorcycle at present, to the nearest pound. This should not be the original price paid. Please enter only digits - no commas or full stops, e.g. 1000."
                format={Question.QuestionNumberFormat.CURRENCY}
                label="Estimated market value"
                maxLength={6}
                name="vehicle.value"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.vehicle?.value}
                value={values.vehicle.value}
              />

              <Question.QuestionNumber
                error={errors.vehicle?.accessoriesValue}
                help="Accessories are items fitted to motorcycles, which do not form part of the manufacturer's standard specification, e.g. panniers, luggage rack, windscreen, etc."
                format={Question.QuestionNumberFormat.CURRENCY}
                label="Value of any accessories fitted<br/><small>(enter '0' if none fitted)</small>"
                maxLength={6}
                name="vehicle.accessoriesValue"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.vehicle?.accessoriesValue}
                value={values.vehicle.accessoriesValue}
              />

              {!values.vehicle.hideModifications && (
                <Question.QuestionModifications
                  error={errors.vehicle?.modifications}
                  name="vehicle.modifications"
                  readOnly={isSubmitting}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.vehicle?.modifications}
                  value={values.vehicle.modifications}
                />
              )}
            </Form.Fieldset>

            <Form.Fieldset title="About the motorcycle use">
              <Question.QuestionRadio
                error={errors.vehicle?.whereKept}
                help="A garage refers to a lockable brick built / concrete sectional garage, which is at the home address. It does not include wooden structures e.g. sheds or any garage which is not at the home address."
                icons={ButtonIconGroup.WHERE_KEPT}
                label="Where is your motorcycle kept overnight?"
                name="vehicle.whereKept"
                options={metadata[MetadataType.WHERE_KEPT]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.whereKept}
                value={values.vehicle.whereKept}
              />

              <Question.QuestionSwitch
                error={errors.vehicle?.keptAtHome}
                label="Is the motorcycle normally kept at your home address?"
                name="vehicle.keptAtHome"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.keptAtHome}
                value={values.vehicle.keptAtHome}
              />

              <Question.QuestionSecondary
                name="vehicle.keptPostcode"
                open={values.vehicle.keptAtHome === false}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.vehicle.keptPostcode}>
                <Question.QuestionText
                  error={errors.vehicle?.keptPostcode}
                  format={Question.QuestionTextFormat.POSTCODE}
                  label="Enter the postcode where the motorcycle is normally kept overnight"
                  name="vehicle.keptPostcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  secondary={true}
                  touched={touched.vehicle?.keptPostcode}
                  value={values.vehicle.keptPostcode}
                />
              </Question.QuestionSecondary>

              <Question.QuestionRadio
                error={errors.vehicle?.classOfUse}
                format={Question.QuestionRadioFormat.VERTICAL}
                help="<p>Social Domestic and Pleasure Only covers personal use only. It does not cover you to ride to and from work.</p>
                      <p>Social Domestic and Pleasure, including Commuting covers personal use and riding to and from a single place of work.</p>
                      <p>Business Use by you, or you and your spouse, or you and any named riders covers personal use, riding to and from work, and use for your business and that of your employer (riding to other sites and meetings elsewhere) but not including travel to make sales or deliver goods.</p>
                      <p>Courier or Despatch Rider covers carrying / delivering packages for others in exchange for payment.</p>"
                label="What will the motorcycle be used for?"
                name="vehicle.classOfUse"
                options={metadata[MetadataType.CLASS_OF_USE]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.classOfUse}
                value={values.vehicle.classOfUse}
              />

              <Question.QuestionSwitch
                error={errors.vehicle?.includesPillion}
                help="Allows you to ride with a passenger on your motorcycle."
                label="Do you want to include pillion (passenger) use?"
                name="vehicle.includesPillion"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.includesPillion}
                value={values.vehicle.includesPillion}
              />

              <Question.QuestionNumber
                error={errors.vehicle?.mileage}
                help="Please estimate your annual mileage."
                format={Question.QuestionNumberFormat.INTEGER}
                info={
                  values.vehicle.mileage && values.vehicle.mileage > 100 ? (
                    <Question.AnnualMileage mileage={values.vehicle.mileage} />
                  ) : undefined
                }
                label="What is the estimated annual mileage on this motorcycle?"
                maxLength={6}
                name="vehicle.mileage"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.vehicle?.mileage}
                value={values.vehicle.mileage}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Your cover">
              <Question.QuestionRadio
                error={errors.vehicle?.cover}
                help="<h2>Comprehensive</h2>
                      <p>Covers you for accidental damage, theft, damage as a result of a fire, theft or attempted theft. You are also covered for claims made against you by other people for bodily injury or damage to their property.</p>
                      <h2>Third Party, Fire & Theft</h2>
                      <p>Covers you for theft and damage as a result of a fire, theft or attempted theft. You are also covered for claims made against you by other people for bodily injury or damage to their property.</p>
                      <h2>Third Party Only</h2>
                      <p>Cover for claims made against you by other people for bodily injury or damage to their property. It does not cover any damage to your own motorcycle.</p>"
                icons={ButtonIconGroup.COVER}
                label="Level of cover required"
                name="vehicle.cover"
                options={metadata[MetadataType.COVER_TYPE]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.vehicle?.cover}
                value={values.vehicle.cover}
              />

              <Question.QuestionSecondary
                name="vehicle.volXS"
                open={["C", "TPFT"].includes(values.vehicle.cover)}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.vehicle.volXS}>
                <Question.QuestionSelect
                  error={errors.vehicle?.volXS}
                  help="This will be in addition to any excess applied by the insurer and may lead to a reduced premium."
                  label="Voluntary Accidental Damage excess"
                  name="vehicle.volXS"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={metadata[MetadataType.VOL_XS]}
                  readOnly={isSubmitting}
                  secondary={true}
                  touched={touched.vehicle?.volXS}
                  value={values.vehicle.volXS}
                />
              </Question.QuestionSecondary>

              <Question.QuestionRadio
                error={errors.policy?.previousInsuranceNCBYears}
                format={Question.QuestionRadioFormat.HORIZONTAL}
                help="<p>You may be asked to provide proof of your NCD.</p>
                      <p>Your NCD must match the following criteria:</p>
                      <ul>
                      <li>Earned in the UK</li>
                      <li>Issued by a UK Insurer</li>
                      <li>Issued in your own name as the policyholder</li>
                      <li>Must not be over two years old. Please contact us if it is and we will try to get an insurer to accept this</li>
                      <li>Not being used elsewhere</li>
                      </ul>
                      <p>If your NCD does not meet these requirements, you should select <strong>No NCD</strong>.</p>"
                label="How many years Motorcycle No Claim Discount (NCD) do you have that is available to transfer to this insurance?"
                name="policy.previousInsuranceNCBYears"
                options={metadata[MetadataType.NCD_YEARS]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.policy?.previousInsuranceNCBYears}
                value={values.policy.previousInsuranceNCBYears}
              />

              <Question.QuestionSecondary
                name="policy.protectedBonus"
                open={["4", "5", "6", "7", "8", "9"].includes(values.policy.previousInsuranceNCBYears)}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.policy.protectedBonus}>
                <Question.QuestionSwitch
                  error={errors.policy?.protectedBonus}
                  help="Selected Insurers offer the ability to protect your No Claim Discount, normally once you have obtained a minimum of four years claim free riding. If allowable, full details of the agreement will be shown on the Insurer's policy schedule."
                  label="Do you want to protect your NCD?"
                  name="policy.protectedBonus"
                  readOnly={isSubmitting}
                  secondary={true}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.policy?.protectedBonus}
                  value={values.policy.protectedBonus}
                />
              </Question.QuestionSecondary>

              <Question.QuestionSelect
                error={errors.policy?.inceptionDate}
                help="Quotes are guaranteed for 30 days. If you require cover to commence immediately, the policy will start from the time you accept the quotation. If you enter a date in the future, cover would start from 00:01hrs on the specified date."
                label="Insurance start date<br/><small>(we can only provide a quote for cover required to commence within the next 30 days)</small>"
                name="policy.inceptionDate"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.INSURANCE_START_DATE]}
                readOnly={isSubmitting}
                touched={touched.policy?.inceptionDate}
                value={values.policy.inceptionDate}
              />
            </Form.Fieldset>

            {process.env.REACT_APP_MULTIBIKE === "true" && !risk.hasAdditionalRiders && (
              <Form.Fieldset title="Additional Motorcycles">
                <Question.QuestionSwitch
                  error={errors.hasAdditionalVehicles}
                  info="Please note: you cannot include any additional riders with a multi bike policy."
                  label="Do you wish to include any additional motorcycles?"
                  name="hasAdditionalVehicles"
                  readOnly={isSubmitting}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.hasAdditionalVehicles}
                  value={values.hasAdditionalVehicles}
                />
              </Form.Fieldset>
            )}

            <Control
              backLabel={handleCancel ? "Cancel" : undefined}
              isSubmitting={isSubmitting}
              onBack={handleCancel}
              submit={true}
            />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
