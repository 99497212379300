import React from "react";

import * as Question from "components/Questions";
import { QuestionsProps } from "./types";

import styles from "./styles.module.scss";

const Questions: React.FunctionComponent<QuestionsProps> = ({
  errors,
  onBlur,
  onChange,
  readOnly,
  touched,
  values,
}) => {
  return (
    <div className={styles["address-search-questions"]}>
      <Question.QuestionText
        autoFocus={true}
        error={errors?.addressLine1}
        grouped={true}
        help="If you live in a subdivided property, such as a block of flats, please enter the relevant details here, e.g. Flat 1, Acacia Towers."
        label="Address line 1"
        maxLength={32}
        name={"proposer.address.addressLine1"}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        touched={touched?.addressLine1}
        value={values.addressLine1}
      />

      <Question.QuestionText
        error={errors?.addressLine2}
        grouped={true}
        label="Address line 2"
        maxLength={32}
        name={"proposer.address.addressLine2"}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        touched={touched?.addressLine2}
        value={values.addressLine2}
      />

      <Question.QuestionText
        error={errors?.addressLine3}
        grouped={true}
        label="Address line 3"
        maxLength={32}
        name={"proposer.address.addressLine3"}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        touched={touched?.addressLine3}
        value={values.addressLine3}
      />

      <Question.QuestionText
        error={errors?.addressLine4}
        grouped={true}
        label="Address line 4"
        maxLength={32}
        name={"proposer.address.addressLine4"}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        touched={touched?.addressLine4}
        value={values.addressLine4}
      />

      <Question.QuestionText
        error={errors?.postCode}
        format={Question.QuestionTextFormat.POSTCODE}
        grouped={true}
        label="Postcode"
        name={"proposer.address.postCode"}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        touched={touched?.postCode}
        value={values.postCode}
      />
    </div>
  );
};

export default Questions;
