import { ThunkMiddleware } from "redux-thunk";

import { AppAction } from "modules/app/constants";
import { RootState } from "modules/redux/store";
import { appActionRequest } from "./actions";
import { AppActionType } from "./types";

const createAppMiddleware: () => ThunkMiddleware<RootState> =
  () =>
  ({ dispatch }) =>
  next =>
  async (action: AppActionType) => {
    switch (action.type) {
      case AppAction.INIT:
        dispatch(appActionRequest());
        break;
    }
    return next(action);
  };

export default createAppMiddleware;
