import React, { useContext } from "react";
import clsx from "clsx";

import { ReactComponent as PhoneSVG } from "modules/theme/icons/header/phone.svg";
import { ReactComponent as ChatSVG } from "modules/theme/icons/header/chat.svg";
import { default as ChatContext } from "modules/livechat";
import Button, { ButtonStyle } from "components/Button";
import PlatformContext from "modules/platform/context";

import styles from "./styles.module.scss";

type HeaderContactProps = {
  telephone: string;
};

const HeaderContact: React.FunctionComponent<HeaderContactProps> = ({ telephone }) => {
  const { active, handleClick, visibility } = useContext(ChatContext);
  const { isMobileOnly } = useContext(PlatformContext);
  const className = clsx(styles["header-contact"], visibility === "maximized" && styles["maximized"]);
  return (
    <div className={className}>
      <p>Can we help? Call us</p>
      {isMobileOnly ? (
        <Button ariaLabel="Call" onClick={() => window.open(`tel:${telephone}`, "_self")}>
          <span>{telephone}</span>
          <PhoneSVG />
        </Button>
      ) : (
        <div>
          <span>{telephone}</span>
        </div>
      )}
      {active && (
        <Button ariaLabel="Chat" onClick={handleClick} style={ButtonStyle.SUCCESS}>
          <ChatSVG />
        </Button>
      )}
    </div>
  );
};

export default React.memo(HeaderContact);
