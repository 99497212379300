import React, { useCallback, useContext } from "react";

import DateSelect, { DateSelectProps } from "./DateSelect";
import PlatformContext from "modules/platform/context";
import { Expand } from "modules/typescript/helpers";
import { InputDateProps } from "../Input/types";
import InputDate from "../Input/Date";

export { getDate, setDate } from "./DateSelect/helpers";

export type DateProps = Expand<
  Omit<InputDateProps, "onBlur" | "onChange"> & DateSelectProps & { showFallback?: boolean }
>;

const Date: React.FunctionComponent<DateProps> = ({
  max,
  min,
  name,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  setFieldTouched,
  setFieldValue,
  showFallback,
  ...props
}) => {
  const platform = useContext(PlatformContext);
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFieldTouched(name, true);
    },
    [name, setFieldTouched],
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, event.currentTarget.value);
    },
    [name, setFieldValue],
  );
  return platform.isAndroid || !!showFallback ? (
    <DateSelect name={name} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} {...props} />
  ) : (
    <InputDate
      max={max}
      min={min}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onInput={onInput}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default React.memo(Date);
