import Messages from "modules/validation/messages";
import yup from "modules/validation";

const schema = yup.object({
  id: yup.string().required(),

  regNumber: yup.string().when("id", {
    is: (id?: string) => id === "mainVehicle",
    then: schema => schema.vrm(false),
    otherwise: schema =>
      schema.when("abiCode", {
        is: (abiCode: string) => !!abiCode,
        then: schema => schema.required().vrm(true),
        otherwise: schema => schema.required().vrm(false),
      }),
  }),

  make: yup
    .string()
    .notRequired()
    .when(["yearOfManufacture", "abiCode"], {
      is: (yearOfManufacture?: string, abiCode?: string) => !yearOfManufacture || !abiCode,
      then: schema => schema.required(Messages.MAKE),
    }),

  model: yup
    .string()
    .notRequired()
    .when(["yearOfManufacture", "abiCode"], {
      is: (yearOfManufacture?: string, abiCode?: string) => !yearOfManufacture || !abiCode,
      then: schema => schema.required().maxLength(20),
    }),

  yearOfManufacture: yup.number().when("abiCode", {
    is: (abiCode: string) => !!abiCode,
    then: schema => schema.required().min(1900, Messages.YEAR_OF_MANUFACTURE).yearOfManufacture(),
    otherwise: schema => schema.required().min(1900, Messages.YEAR_OF_MANUFACTURE),
  }),

  cc: yup
    .number()
    .notRequired()
    .when(["yearOfManufacture", "abiCode"], {
      is: (yearOfManufacture?: string, abiCode?: string) => !yearOfManufacture || !abiCode,
      then: schema => schema.min(0, Messages.CC).max(9999, Messages.CC),
    }),

  abiCode: yup.string().required(Messages.ABI),

  immob: yup.string().required(Messages.LIST),

  tracker: yup.string().required(Messages.LIST),

  alarm: yup.string().required(Messages.OPTION),

  imported: yup.boolean().required(Messages.OPTION),

  purchasedYet: yup.boolean().required(Messages.OPTION),

  purchased: yup.date().when("purchasedYet", {
    is: true,
    then: schema => schema.required().purchasedDateInceptionDate().purchasedDateYearOfManufacture(),
  }),

  value: yup.number().required().min(50, Messages.VEHICLE_VALUE).max(999999, Messages.VEHICLE_VALUE),

  accessoriesValue: yup.number().required().min(0, Messages.ACCESSORIES_VALUE).max(999999, Messages.ACCESSORIES_VALUE),

  modifications: yup.boolean().when("hideModifications", {
    is: true,
    then: schema => schema.notRequired(),
    otherwise: schema => schema.required(Messages.OPTION).oneOf([false], Messages.MODIFICATIONS),
  }),

  whereKept: yup.string().required(Messages.OPTION),

  keptAtHome: yup.boolean().required(Messages.OPTION),

  keptPostcode: yup.string().when("keptAtHome", {
    is: false,
    then: schema => schema.required().postcode().maxLength(8, Messages.POSTCODE),
  }),

  classOfUse: yup.string().required(Messages.OPTION),

  includesPillion: yup.boolean().required(Messages.OPTION),

  mileage: yup.number().required().min(100, Messages.MILEAGE).max(999999, Messages.MILEAGE),

  cover: yup.string().required(Messages.OPTION),

  volXS: yup.string().when("cover", {
    is: (value?: string) => !!value && ["C", "TPFT"].includes(value),
    then: schema => schema.required(Messages.LIST),
  }),
});

export default schema;
