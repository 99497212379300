import { VehicleApiRequest, VehicleApiResponseResult } from "./types";

const cache: {
  vehicle: VehicleApiRequest;
  result: VehicleApiResponseResult;
}[] = [];

export const cacheSearch = (vehicle: VehicleApiRequest) => {
  return cache.find(
    item =>
      item.vehicle.cc === vehicle.cc &&
      item.vehicle.make === vehicle.make &&
      item.vehicle.model === vehicle.model &&
      item.vehicle.year === vehicle.year,
  );
};

export const cacheResult = (vehicle: VehicleApiRequest, result: VehicleApiResponseResult) => {
  !cacheSearch(vehicle) &&
    cache.push({
      vehicle: vehicle,
      result: result,
    });
};
