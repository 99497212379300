import { ApiError as ApiErrorTypes, ApiErrorResponse } from "@devittinsurance/devitt-types";
import Messages from "modules/validation/messages";
import { ROUTE } from "modules/router/constants";

export type ApiError = {
  [key in ApiErrorTypes]: ApiErrorTypes;
};
//eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiError: ApiError = {
  ABI_INVALID: "ABI_INVALID",
  ABI_NOT_FOUND: "ABI_NOT_FOUND",
  ACCOUNT_INVALID: "ACCOUNT_INVALID",
  ACCOUNT_NUMBER_INVALID: "ACCOUNT_NUMBER_INVALID",
  ACCOUNT_NUMBER_MISSING: "ACCOUNT_NUMBER_MISSING",
  ACCOUNT_NUMBER_SORTING_CODE_MISSING: "ACCOUNT_NUMBER_SORTING_CODE_MISSING",
  ADDRESS_INVALID: "ADDRESS_INVALID",
  ADDRESS_NOT_FOUND: "ADDRESS_NOT_FOUND",
  ARTICLES_INVALID: "ARTICLES_INVALID",
  BIKE_INVALID: "BIKE_INVALID",
  BIKE_NOT_FOUND: "BIKE_NOT_FOUND",
  CONFIGURATION: "CONFIGURATION",
  INVALID: "INVALID",
  METADATA_NOT_FOUND: "METADATA_NOT_FOUND",
  NOT_FOUND: "NOT_FOUND",
  POLICY_NOT_FOUND: "POLICY_NOT_FOUND",
  QUOTE_CREATE_INVALID_INPUT: "QUOTE_CREATE_INVALID_INPUT",
  QUOTE_CREATE_NO_QUOTE: "QUOTE_CREATE_NO_QUOTE",
  QUOTE_NOT_FOUND: "QUOTE_NOT_FOUND",
  QUOTE_PURCHASED: "QUOTE_PURCHASED",
  SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
  SORTING_CODE_INVALID: "SORTING_CODE_INVALID",
  SORTING_CODE_MISSING: "SORTING_CODE_MISSING",
  UNEXPECTED: "UNEXPECTED",
  VRM_INVALID_RESPONSE: "VRM_INVALID_RESPONSE",
  VRM_INVALID_TYPE: "VRM_INVALID_TYPE",
  VRM_INVALID_VRM: "VRM_INVALID_VRM",
  VRM_NOT_FOUND: "VRM_NOT_FOUND",
  VRM_Q_PLATE: "VRM_Q_PLATE",
};

export const errorMessage = (error: string): string => {
  switch (error) {
    case ApiError.ABI_INVALID:
      return "ABI_INVALID";

    case ApiError.ABI_NOT_FOUND:
      return "ABI_NOT_FOUND";

    case ApiError.ACCOUNT_INVALID:
      return Messages.ACCOUNT_INVALID;

    case ApiError.ACCOUNT_NUMBER_MISSING:
      return Messages.ACCOUNT;

    case ApiError.ACCOUNT_NUMBER_SORTING_CODE_MISSING:
      return Messages.ACCOUNT_REMOTE;

    case ApiError.ACCOUNT_NUMBER_INVALID:
      return Messages.ACCOUNT;

    case ApiError.ADDRESS_INVALID:
      return "Sorry, we could not find an address for this postcode.";

    case ApiError.ADDRESS_NOT_FOUND:
      return "Sorry, we could not find an address for this postcode.";

    case ApiError.ARTICLES_INVALID:
      return "ARTICLES_INVALID";

    case ApiError.BIKE_INVALID:
      return "Sorry, we could not find a motorcycle matching this description.";

    case ApiError.BIKE_NOT_FOUND:
      return "Sorry, we could not find a motorcycle matching this description.";

    case ApiError.CONFIGURATION:
      return "CONFIGURATION";

    case ApiError.INVALID:
      return "INVALID";

    case ApiError.METADATA_NOT_FOUND:
      return "METADATA_NOT_FOUND";

    case ApiError.NOT_FOUND:
      return "NOT_FOUND";

    case ApiError.SERVICE_UNAVAILABLE:
      return "SERVICE_UNAVAILABLE";

    case ApiError.SORTING_CODE_MISSING:
      return Messages.ACCOUNT_REMOTE;

    case ApiError.SORTING_CODE_INVALID:
      return Messages.ACCOUNT_REMOTE;

    case ApiError.VRM_INVALID_RESPONSE:
      return "An error has occured, please try again.";

    case ApiError.VRM_INVALID_TYPE:
      return Messages.VRM_NOT_FOUND;

    case ApiError.VRM_INVALID_VRM:
      return Messages.VRM;

    case ApiError.VRM_NOT_FOUND:
      return Messages.VRM_NOT_FOUND;

    case ApiError.VRM_Q_PLATE:
      return Messages.VRM_Q_PLATE;

    case ApiError.UNEXPECTED:
    default:
      return "An error has occured. Please check your internet connection or try again later.";
  }
};

export const errorRoute = (response?: ApiErrorResponse): ROUTE => {
  if (response && response.error) {
    switch (response.error) {
      case ApiError.POLICY_NOT_FOUND:
        return ROUTE.MESSAGE_POLICY_RECALL;

      case ApiError.QUOTE_CREATE_INVALID_INPUT:
      case ApiError.QUOTE_CREATE_NO_QUOTE:
        return ROUTE.MESSAGE_QUOTE;

      case ApiError.QUOTE_NOT_FOUND:
        return ROUTE.MESSAGE_QUOTE_RETRIEVE;

      case ApiError.QUOTE_PURCHASED:
        return ROUTE.MESSAGE_QUOTE_PURCHASED;

      case ApiError.SERVICE_UNAVAILABLE:
        return ROUTE.MESSAGE;

      case ApiError.UNEXPECTED:
        return ROUTE.MESSAGE;

      default:
        return ROUTE.MESSAGE;
    }
  }
  return ROUTE.MESSAGE;
};
