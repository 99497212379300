import { Expand } from "modules/typescript/helpers";
import yup from "modules/validation";

type Context = Expand<
  yup.TestContext<object> & {
    from?: [
      {
        schema: yup.AnyObjectSchema;
        value: unknown;
      },
      {
        schema: yup.AnyObjectSchema;
        value: unknown;
      },
    ];
  }
>;

const isContext = (context: unknown): context is Context =>
  !!context &&
  typeof context === "object" &&
  "from" in context &&
  Array.isArray(context.from) &&
  !!context.from[1]?.value;

export const getContext = <T>(context: unknown) => (isContext(context) ? (context.from?.[1].value as T) : undefined);
