export enum ButtonType {
  BUTTON = "button",
  LINK = "link",
  SUBMIT = "submit",
}

export enum ButtonStyle {
  INVALID = "invalid",
  PRIMARY = "primary",
  PRIMARY_CLEAR = "primary-clear",
  SECONDARY = "secondary",
  SECONDARY_CLEAR = "secondary-clear",
  SUCCESS = "success",
  SUCCESS_CLEAR = "success-clear",
  SUCCESS_INVERTED = "success-inverted",
}

export enum ButtonTarget {
  BLANK = "_blank",
  SELF = "_self",
  PARENT = "_parent",
  TOP = "_top",
}
