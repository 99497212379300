import { useCallback } from "react";

import { riskActionNewAdditionalRider, riskActionUpdateAdditionalRider } from "modules/risk/actions";
import { CurrentAdditionalRider } from "modules/risk/types";
import { ROUTE } from "modules/router/constants";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, id, navigate }) =>
  useCallback(
    (values: CurrentAdditionalRider) => {
      dispatch(
        id === "new"
          ? riskActionNewAdditionalRider(values.currentAdditionalRider)
          : riskActionUpdateAdditionalRider(values.currentAdditionalRider),
      );
      navigate(ROUTE.RIDERS);
    },
    [dispatch, id, navigate],
  );

export default useHandleSubmit;
