import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Metadata } from "modules/metadata/types";
import schema from "modules/schema/routes/policy";
import { Dispatch } from "modules/redux/store";
import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import { UsePolicyRoute } from "./types";
import { useCart } from "modules/cart";

import useHandleSubmit from "./useHandleSubmit";
import useHandleBack from "./useHandleBack";
import useHandleEdit from "./useHandleEdit";
import useRedirect from "./useRedirect";

const usePolicyRoute: UsePolicyRoute = () => {
  const metadata: Metadata = useSelector(state => state.metadata);
  const quote: Quote = useSelector(state => state.quote);
  const risk: Risk = useSelector(state => state.risk);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBack = useHandleBack(navigate);

  const handleEdit = useHandleEdit(navigate);

  const handleSubmit = useHandleSubmit({ dispatch, navigate });

  const redirect = useRedirect(risk.cart.quoteToken);

  const cart = useCart(quote.optionalExtras);

  return {
    cart,
    handleBack,
    handleEdit,
    handleSubmit,
    metadata,
    quote,
    risk,
    redirect,
    schema,
  };
};

export default usePolicyRoute;
