import { useDispatch } from "react-redux";

import { Dispatch } from "modules/redux/store";
import { UseAggregatorRoute } from "./types";
import useQuery from "../useQuery";

import useRedirect from "./useRedirect";
import useRequest from "./useRequest";

const useAggregatorRoute: UseAggregatorRoute = () => {
  const token = useQuery().get("token") || undefined;
  const dispatch: Dispatch = useDispatch();
  const redirect = useRedirect(token);
  useRequest({ dispatch, token });
  return {
    redirect,
  };
};

export default useAggregatorRoute;
