import { useCallback, useEffect, useRef } from "react";

import { LiveChatVisibility } from "../types";

type Data = { visibility: LiveChatVisibility };

type UseHandleVisibilityProps = (
  setVisibility: React.Dispatch<React.SetStateAction<Data["visibility"]>>,
) => (data: Data) => void;

const useHandleVisibility: UseHandleVisibilityProps = setVisibility => {
  const handleVisibilityRef = useRef<ReturnType<UseHandleVisibilityProps>>(() => void 0);
  const handleVisibility = useCallback((data: Data) => handleVisibilityRef.current(data), []);

  useEffect(() => {
    handleVisibilityRef.current = (data: Data) => {
      data.visibility === "minimized" && window.LiveChatWidget && window.LiveChatWidget.call("hide");
      data.visibility === "maximized"
        ? document.getElementsByTagName("html")[0].classList.add("platform-chat-maximized")
        : document.getElementsByTagName("html")[0].classList.remove("platform-chat-maximized");
      setVisibility(data.visibility);
    };
  }, [setVisibility]);

  return handleVisibility;
};

export default useHandleVisibility;
