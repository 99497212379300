import React from "react";
import clsx from "clsx";

import { PaymentMethod } from "modules/risk/constants";
import { useHeight, useSticky } from "./hooks";
import ProductHeader from "../ProductHeader";
import ProductFooter from "../ProductFooter";
import { Quote } from "modules/quote/types";

import styles from "./styles.module.scss";

type ProductWrapperLeftProps = {
  children: React.ReactNode;
};

export const ProductWrapperLeft: React.FunctionComponent<ProductWrapperLeftProps> = ({ children }) => {
  const { isSticky, ref } = useSticky();
  const className = clsx(isSticky && styles["sticky"]);
  return (
    <section className={className} ref={ref}>
      {children}
    </section>
  );
};

type ProductWrapperRightProps = {
  children: React.ReactNode;
};

export const ProductWrapperRight: React.FunctionComponent<ProductWrapperRightProps> = ({ children }) => {
  return <section>{children}</section>;
};

type ProductProps = {
  children: React.ReactNode;
  onBack: () => void;
  onConfirmAndPay: () => void;
  onSetAnnually: () => void;
  onSetMonthly: () => void;
  paymentMethod: PaymentMethod;
  quote: Quote;
};

export const Product: React.FunctionComponent<ProductProps> = ({
  children,
  onBack,
  onConfirmAndPay,
  onSetAnnually,
  onSetMonthly,
  paymentMethod,
  quote,
}) => {
  const { height, ref } = useHeight();
  return (
    <div className={styles["product-wrapper"]}>
      <ProductHeader onBack={onBack} webReference={quote.webReference} />

      <div style={{ marginBottom: `${height}px` }}>{children}</div>

      <ProductFooter
        forwardedRef={ref}
        onConfirmAndPay={onConfirmAndPay}
        onSetAnnually={onSetAnnually}
        onSetMonthly={onSetMonthly}
        paymentMethod={paymentMethod}
        quote={quote}
      />
    </div>
  );
};

export default Product;
