import React from "react";

import { ReactComponent as DirectDebitSVG } from "modules/theme/icons/payment/direct-debit.svg";
import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Direct Debit Guarantee",
  text: (
    <React.Fragment>
      <DirectDebitSVG />

      <p>
        This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.
      </p>

      <p>
        If there are any changes to the amount, date or frequency of your Direct Debit, Devitt Insurance Services
        Limited will notify you 14 working days (normally 10 working days) in advance of your account being debited or
        as otherwise agreed. If you request Devitt Insurance Services Limited to collect a payment, confirmation of the
        amount and date will be given to you at the time of the request.
      </p>

      <p>
        If an error is made in the payment of your Direct Debit, by Devitt Insurance Services Limited or your bank or
        building society, you are entitled to a full and immediate refund of the amount paid from your bank or building
        society.
      </p>

      <p>
        If you receive a refund you are not entitled to, you must pay it back when Devitt Insurance Services Limited
        asks you to.
      </p>

      <p>
        You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written
        confirmation may be required. Please also notify us.
      </p>
    </React.Fragment>
  ),
};

export default template;
