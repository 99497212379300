import React from "react";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const Text: React.FunctionComponent = () => {
  const handleShowModalInfo = useModalInfo();
  return (
    <React.Fragment>
      <p>
        Please ensure that the following information provided by you and the assumptions below are correct. If you
        purchase insurance and you haven't answered the questions accurately to the best of your knowledge, your policy
        may be cancelled, or your claim rejected or not paid in full.
      </p>
      <ul>
        <li>
          The motorcycle has not been modified in any way from the standard manufacturer's UK model apart from
          modifications that are in our{" "}
          <button
            aria-label="Accepted as standard"
            onClick={event => {
              event.preventDefault();
              handleShowModalInfo({ event, template: ModalInfoTemplates.MODIFICATIONS });
            }}
            onMouseDown={event => event.preventDefault()}
            type="button">
            Accepted as standard
          </button>{" "}
          list
        </li>
        <li>The motorcycle is not a registered Q plate</li>
        <li>You are the owner and the registered keeper of the motorcycle</li>
        <li>You are the main user of the motorcycle</li>
        <li>
          You and all the proposed riders have;
          <ol>
            <li>The correct driving licence, including CBT if applicable, to ride the motorcycle proposed</li>
            <li>
              Never been refused insurance or had special terms imposed on previous insurance policies or had any
              previous policies cancelled
            </li>
          </ol>
        </li>
        <li>
          You and all of the proposed riders do not have;
          <ol>
            <li>Any DVLA reportable medical conditions or disabilities</li>
            <li>Any notice of intended motoring prosecution pending</li>
            <li>
              Any non-motoring convictions e.g. theft, dishonesty or fraud, have any prosecutions pending or police
              enquiries outstanding
            </li>
          </ol>
        </li>
        <li>The motorcycle is not currently impounded</li>
      </ul>
    </React.Fragment>
  );
};

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Assumptions",
  text: <Text />,
};

export default template;
