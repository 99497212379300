import React from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "No thanks",
  confirmLabel: "Upgrade me",
  title: "Upgrade your plan for a better saving",
  icon: ModalIconType.DEVITT_EVOLUTION,
  text: (
    <React.Fragment>
      <h3>We suggest you upgrade to Devitt Evolution to get an even bigger saving on your insurance</h3>
      <p>
        <strong>You could save an extra £20</strong> and your new plan will include all the benefits of Devitt Enhanced
        plus:
      </p>
      <ul>
        <li>Helmet & Leathers cover</li>
        <li>Personal Accident</li>
      </ul>
    </React.Fragment>
  ),
};

export default template;
