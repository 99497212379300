import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { DocumentApiRequest, DocumentApiResponseResult } from "./types";
import { documentActionRequest } from "./actions";
import { Dispatch } from "modules/redux/store";

export const useDocumentAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    documentRequest: useCallback(
      (modal: boolean, url: DocumentApiRequest): Promise<DocumentApiResponseResult> => {
        return dispatch(documentActionRequest(modal, url));
      },
      [dispatch],
    ),
  };
};
