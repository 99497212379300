import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { ReactComponent as LoadingSVG } from "modules/theme/icons/form/inputs/loading.svg";
import { useScrollRef } from "modules/scroll/hooks";

import styles from "./styles.module.scss";

export type OptionType = {
  label: string;
  value: string;
};

type SelectPrimitiveOptionsProps = {
  options?: OptionType[];
  readOnly?: boolean;
};

const SelectPrimitiveOptions = React.memo<SelectPrimitiveOptionsProps>(({ options, readOnly }) => (
  <React.Fragment>
    {options?.map((option, key) => (
      <option disabled={readOnly || option.value === ""} key={key} value={option.value}>
        {option.label}
      </option>
    ))}
  </React.Fragment>
));

export type SelectProps = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  id: string;
  invalid?: boolean;
  name: string;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  options?: OptionType[];
  placeholder?: false | string;
  readOnly?: boolean;
  tabIndex?: number;
  value: string;
};

const Select: React.FunctionComponent<SelectProps> = ({
  ariaLabel,
  ariaLabelledBy,
  autoFocus,
  disabled,
  id,
  invalid,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  readOnly,
  tabIndex,
  value,
}) => {
  const [loading, setLoading] = useState(!options?.length);
  const scrollRef = useScrollRef(id);

  useEffect(() => {
    setLoading(!options?.length);
  }, [options]);

  const className = clsx(
    styles["select"],
    disabled && styles["disabled"],
    !disabled && invalid && styles["invalid"],
    loading && styles["loading"],
    readOnly && styles["read-only"],
    !value && styles["placeholder"],
  );
  return (
    <div className={className} ref={!disabled && invalid ? scrollRef : undefined}>
      <select
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
        value={value}>
        {placeholder !== false && (
          <option disabled={readOnly} value="">
            {loading ? "Loading..." : placeholder ? placeholder : "Please select"}
          </option>
        )}
        <SelectPrimitiveOptions options={options} readOnly={readOnly} />
      </select>
      {!loading && <ChevronDownSVG />}
      {loading && <LoadingSVG />}
    </div>
  );
};

export default React.memo(Select);
