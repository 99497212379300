import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "",
  text: (
    <React.Fragment>
      <p>Cover for claims made against you by other people for bodily injury or damage to their property.</p>
      <p>It does not cover any damage to your own motorcycle.</p>
    </React.Fragment>
  ),
};

export default template;
