import yup, { Messages } from "modules/validation";

export const validateMaxLength = (value: string | number, limit: number): boolean => {
  return typeof value === "number" ? value.toString().length <= limit : value.length <= limit;
};

export function string(this: yup.StringSchema, limit: number, message?: string) {
  return this.test("maxLength", Messages.MAX_LENGTH, function (value) {
    return value
      ? validateMaxLength(value, limit)
        ? true
        : this.createError(message ? { message: message } : { message: Messages.MAX_LENGTH, params: { limit: limit } })
      : true;
  });
}

export function number(this: yup.NumberSchema, limit: number, message?: string) {
  return this.test("maxLength", Messages.MAX_LENGTH, function (value) {
    return value || value === 0
      ? validateMaxLength(value, limit)
        ? true
        : this.createError(message ? { message: message } : { message: Messages.MAX_LENGTH, params: { limit: limit } })
      : true;
  });
}
