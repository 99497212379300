import { LiveChat, LoadLiveChat } from "./types";
import { API_URL } from "./constants";

export const loadLiveChat: LoadLiveChat = onLoad => {
  const i = (n: [string, never[]]) => (liveChat._h ? liveChat._h.apply(null, n as unknown as []) : liveChat._q.push(n));

  const slice = [].slice;

  const liveChat: LiveChat = {
    _q: [],
    _h: null,
    _v: "2.0",
    on: function (...args) {
      i(["on", slice.call(args)]);
    },
    once: function (...args) {
      i(["once", slice.call(args)]);
    },
    off: function (...args) {
      i(["off", slice.call(args)]);
    },
    get: function (...args) {
      if (!liveChat._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
      return i(["get", slice.call(args)]);
    },
    call: function (...args) {
      i(["call", slice.call(args)]);
    },
    init: function () {
      const node = document.createElement("script");
      node.async = !0;
      node.type = "text/javascript";
      node.src = API_URL;
      document.head.appendChild(node);
      node.addEventListener("load", onLoad);
    },
  };

  !window.__lc.asyncInit && liveChat.init();
  window.LiveChatWidget = window.LiveChatWidget || liveChat;
};
