import React from "react";

import { formatPrice } from "modules/utils";
import { Risk } from "modules/risk/types";

type PriceFooterProps = {
  monthly: Risk["additionalQuestions"]["normallyPayByDirectDebit"];
  price: number;
};

const PriceFooter: React.FunctionComponent<PriceFooterProps> = ({ monthly, price }) =>
  monthly === false ? (
    <div>
      <strong>
        <span>+{formatPrice(price)}</span> p/y
      </strong>
      <span>or {formatPrice(price / 12)} p/m*</span>
    </div>
  ) : (
    <div>
      <strong>
        <span>+{formatPrice(price / 12)}</span> p/m*
      </strong>
      <span>or {formatPrice(price)} annually</span>
    </div>
  );

export default React.memo(PriceFooter);
