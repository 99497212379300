import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import schema from "modules/schema/routes/payment";
import { Proceed } from "modules/proceed/types";
import { Dispatch } from "modules/redux/store";
import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import { UsePaymentRoute } from "./types";

import useHandleSubmit from "./useHandleSubmit";
import useHandleBack from "./useHandleBack";
import useRedirect from "./useRedirect";

const usePaymentRoute: UsePaymentRoute = () => {
  const proceed: Proceed = useSelector(state => state.proceed);
  const quote: Quote = useSelector(state => state.quote);
  const risk: Risk = useSelector(state => state.risk);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBack = useHandleBack(navigate);

  const handleSubmit = useHandleSubmit(dispatch);

  const redirect = useRedirect(risk.cart.quoteToken);

  return {
    handleBack,
    handleSubmit,
    proceed,
    quote,
    redirect,
    schema,
  };
};

export default usePaymentRoute;
