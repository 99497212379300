import { InputAutocomplete, InputType, RegularExpressions } from "../../constants";
import { handleInput, handleKeyDown, setNativeInput } from "../../helpers";
import { Mask, MaskProps } from "../../types";
import { maskName } from "../helpers";

const mask = (props: MaskProps): Mask => ({
  autoComplete: InputAutocomplete.OFF,
  maxLength: props.maxLength,
  onInput: handleInput(event => maskName(event.currentTarget.value)),
  onKeyDown: handleKeyDown(RegularExpressions.NAME),
  onMount: input => {
    const value = input.current?.value;
    const sanitised = value && maskName(value);
    sanitised && sanitised !== value && setNativeInput(input, sanitised);
  },
  placeholder: props.placeholder,
  type: InputType.TEXT,
});

export default mask;
