import { isEqual } from "lodash";

import { AnalyticsEventPurchase, AnalyticsPayloadEcommerce } from "./types";
import { PaymentMethod } from "modules/risk/constants";
import { getPrice } from "modules/utils";
import { pushEvent } from "../utils";
import * as Items from "../items";

let cache = {};

const analyticsEventPurchase: AnalyticsEventPurchase = getState => {
  const { confirmation } = getState();

  const items = [Items.analyticsItemTierBasic(confirmation.premiumBasic)];

  !!confirmation.premiumEnhanced && items.push(Items.analyticsItemTierEnhanced(confirmation.premiumEnhanced));

  !!confirmation.premiumEnhancedPlus &&
    items.push(Items.analyticsItemTierEnhancedPlus(confirmation.premiumEnhancedPlus));

  !!confirmation.premiumEvolution && items.push(Items.analyticsItemTierEvolution(confirmation.premiumEvolution));

  !!confirmation.premiumEvolutionPlus &&
    items.push(Items.analyticsItemTierEvolutionPlus(confirmation.premiumEvolutionPlus));

  !!confirmation.premiumLegal && items.push(Items.analyticsItemLegalExpenses(confirmation.premiumLegal));

  !!confirmation.premiumBreakdown &&
    !!confirmation.tierBreakdown &&
    ["breakdown1", "breakdown2", "breakdown3", "breakdown4"].includes(confirmation.tierBreakdown) &&
    items.push(
      Items.analyticsItemBreakdown(
        confirmation.premiumBreakdown,
        confirmation.tierBreakdown as "breakdown1" | "breakdown2" | "breakdown3" | "breakdown4",
      ),
    );

  !!confirmation.premiumHelmetLeathers &&
    items.push(Items.analyticsItemHelmetLeathers(confirmation.premiumHelmetLeathers));

  !!confirmation.premiumPersonalAccident &&
    items.push(Items.analyticsItemPersonalAccident(confirmation.premiumPersonalAccident));

  !!confirmation.premiumAccidentHelmetLeathers &&
    items.push(Items.analyticsItemPersonalAccidentHelmetLeathers(confirmation.premiumAccidentHelmetLeathers));

  confirmation.paymentMethod === PaymentMethod.MONTHLY &&
    !!confirmation.totalMonthlyInterest &&
    items.push(Items.analyticsItemFinance(confirmation.totalMonthlyInterest));

  const payload: AnalyticsPayloadEcommerce = {
    billing: confirmation.paymentMethod === PaymentMethod.MONTHLY ? "monthly" : "annually",
    currency: "GBP",
    value:
      confirmation.paymentMethod === PaymentMethod.MONTHLY && !!confirmation.totalMonthlyPrice
        ? getPrice(confirmation.totalMonthlyPrice)
        : getPrice(confirmation.totalAnnualPrice),
    items,
    transaction_id: confirmation.webReference,
  };

  if (!isEqual(cache, payload)) {
    pushEvent("purchase", payload);
    cache = payload;
  }
};

export default analyticsEventPurchase;
