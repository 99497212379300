import React from "react";

import { ROUTE } from "modules/router/constants";

import Message404 from "../messages/Message404";
import MessageCardPayment from "../messages/MessageCardPayment";
import MessageDirectDebit from "../messages/MessageDirectDebit";
import MessageDocument from "../messages/MessageDocument";
import MessageMaintenance from "../messages/MessageMaintenance";
import MessagePayment from "../messages/MessagePayment";
import MessagePolicyRecall from "../messages/MessagePolicyRecall";
import MessageQuote from "../messages/MessageQuote";
import MessageQuotePurchased from "../messages/MessageQuotePurchased";
import MessageQuoteRetrieve from "../messages/MessageQuoteRetrieve";
import MessageSessionTimeout from "../messages/MessageSessionTimeout";
import MessageTechnical from "../messages/MessageTechnical";

type MessageProps = {
  route: string;
  webReference?: string;
  token?: string;
};

const Message: React.FunctionComponent<MessageProps> = ({ route, token, webReference }) => {
  switch (route) {
    case ROUTE.MESSAGE:
      return <MessageTechnical />;
    case ROUTE.MESSAGE_CARD_PAYMENT:
      return <MessageCardPayment webReference={webReference} token={token} />;
    case ROUTE.MESSAGE_MAINTENANCE:
      return <MessageMaintenance />;
    case ROUTE.MESSAGE_DIRECT_DEBIT:
      return <MessageDirectDebit />;
    case ROUTE.MESSAGE_DOCUMENT:
      return <MessageDocument webReference={webReference} />;
    case ROUTE.MESSAGE_PAYMENT:
      return <MessagePayment webReference={webReference} />;
    case ROUTE.MESSAGE_POLICY_RECALL:
      return <MessagePolicyRecall />;
    case ROUTE.MESSAGE_QUOTE:
      return <MessageQuote webReference={webReference} />;
    case ROUTE.MESSAGE_QUOTE_PURCHASED:
      return <MessageQuotePurchased />;
    case ROUTE.MESSAGE_QUOTE_RETRIEVE:
      return <MessageQuoteRetrieve />;
    case ROUTE.MESSAGE_SESSION_TIMEOUT:
      return <MessageSessionTimeout />;
    default:
      return <Message404 />;
  }
};

export default React.memo(Message);
