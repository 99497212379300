import React, { useCallback } from "react";
import { useScrollRef } from "modules/scroll/hooks";
import { FormikProps } from "formik";
import clsx from "clsx";

import { ButtonIconType } from "components/Inputs/Buttons/Button/Icons";
import { ButtonsFormat } from "components/Inputs/Buttons/constants";
import Button from "components/Inputs/Buttons/Button";

import styles from "components/Inputs/Buttons/styles.module.scss";

export type OptionType = {
  error?: string;
  icon?: ButtonIconType;
  label: string;
  name: string;
  selected: boolean;
  touched?: boolean;
};

export type FieldGroupProps = {
  disabled?: boolean;
  format?: ButtonsFormat;
  id: string;
  invalid?: boolean;
  options: OptionType[];
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  styles?: string;
  tabIndex?: number;
};

const FieldGroup: React.FunctionComponent<FieldGroupProps> = ({
  disabled,
  format,
  id,
  invalid,
  options,
  readOnly,
  setFieldTouched,
  setFieldValue,
  tabIndex,
  ...props
}) => {
  const scrollRef = useScrollRef(id);
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLUListElement>) => {
      const currentTarget = event.currentTarget;
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement) && document.body.contains(currentTarget)) {
          options.forEach((option: OptionType) => setFieldTouched(option.name, true));
        }
      });
    },
    [options, setFieldTouched],
  );
  const handleClick = useCallback(
    (option: OptionType) => {
      setFieldValue(option.name, option.selected !== true ? true : "");
      setTimeout(() => setFieldTouched(option.name, true));
    },
    [setFieldValue, setFieldTouched],
  );
  const handleSetAll = useCallback(() => {
    options.forEach((option: OptionType) => setFieldValue(option.name, true));
  }, [options, setFieldValue]);
  const buttonProps = {
    disabled: disabled,
    invalid: invalid,
    readOnly: readOnly,
    tabIndex: tabIndex,
  };
  const className = clsx(
    styles["buttons"],
    styles["icons"],
    format === ButtonsFormat.VERTICAL ? styles["vertical"] : styles["horizontal"],
    props.styles,
  );
  return (
    <ul className={className} onBlur={handleBlur} ref={!disabled && invalid ? scrollRef : undefined}>
      {options.map((option, index) => (
        <li key={index}>
          <Button
            {...buttonProps}
            icon={option.icon}
            id={index === 0 ? id : undefined}
            label={option.label}
            onClick={() => handleClick(option)}
            selected={option.selected}
          />
        </li>
      ))}
      <li>
        <Button
          {...buttonProps}
          disabled={disabled || options.every(option => option.selected === true)}
          icon={options.find(option => option.icon) && ButtonIconType.SELECT_ALL}
          label="I don't mind"
          onClick={handleSetAll}
          selected={false}
        />
      </li>
    </ul>
  );
};

export default React.memo(FieldGroup);
