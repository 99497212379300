import React from "react";

import Button from "./Button";

type CustomEvent = { pointerType?: "mouse" | "pen" | "touch" };

export type ButtonRadioProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  icon?: string;
  id: string;
  index: number;
  invalid?: boolean;
  onClick: (value: string) => void;
  option: { label: string; value: string };
  readOnly?: boolean;
  tabIndex?: number;
  value: string;
};

const ButtonRadio: React.FunctionComponent<ButtonRadioProps> = ({
  autoFocus,
  id,
  index,
  onClick,
  option,
  tabIndex,
  value,
  ...props
}) => {
  return (
    <Button
      {...props}
      autoFocus={autoFocus && (value === option.value || (!value && index === 0))}
      id={value ? (value === option.value ? id : undefined) : index === 0 ? id : undefined}
      label={option.label}
      onClick={event =>
        event.type === "click" &&
        (event.nativeEvent.detail || (event.nativeEvent as CustomEvent).pointerType === "mouse")
          ? value !== option.value && onClick(option.value)
          : onClick(value !== option.value ? option.value : "")
      }
      selected={value === option.value}
      tabIndex={value ? (value === option.value ? tabIndex : -1) : tabIndex}
    />
  );
};

export default React.memo(ButtonRadio);
