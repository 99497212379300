import React from "react";

import { ReactComponent as EditSVG } from "modules/theme/icons/buttons/edit.svg";
import Button, { ButtonStyle } from "components/Button";
import Icon, { IconType } from "./Icons";

type SectionProps = {
  children?: React.ReactNode;
  icon: IconType;
  onClick: () => void;
  title: string;
};

const Section: React.FunctionComponent<SectionProps> = ({ children, icon, title, onClick }) => {
  return (
    <div>
      <header>
        <div>
          <Icon icon={icon} />
          <h3>{title}</h3>
        </div>
        <Button ariaLabel="Edit" label="Edit" onClick={onClick} style={ButtonStyle.PRIMARY_CLEAR}>
          <EditSVG />
        </Button>
      </header>
      <table>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default React.memo(Section);
