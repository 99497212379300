import React from "react";
import { useId } from "react-id-generator";
import { FormikProps } from "formik";
import clsx from "clsx";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import Error from "components/Questions/QuestionTemplate/Error";
import Checkbox from "components/Inputs/Checkbox";
import Button from "components/Button";

import styles from "./styles.module.scss";

type PaymentLegalProps = {
  error?: string;
  name: string;
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  touched?: boolean;
  value: "" | boolean;
};

const PaymentLegal: React.FunctionComponent<PaymentLegalProps> = ({
  error,
  name,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touched,
  value,
}) => {
  const handleShowModalInfo = useModalInfo();
  const [id] = useId(1, "question-");
  const className = clsx(styles["payment-legal"], error && touched && styles["invalid"]);
  return (
    <div className={className}>
      <p>
        Devitt Motorcycle Insurance is arranged by Devitt. Please pay Devitt Insurance Services Limited, (originator
        number 762454) Direct Debits from the account detailed in this instruction subject to the safeguards assured by
        the Direct Debit Guarantee. I understand that this instruction may remain with Devitt Insurance Service Limited
        and, if so, details will be passed electronically to my Bank or Building Society.
      </p>
      <p>
        I confirm I am over 18 years of age, I can afford the repayments and I have read the Direct Debit Guarantee and
        Terms and Conditions of my Credit Agreement, which includes a sample copy of the Pre-Contract Credit
        Information.
      </p>
      <div>
        <Checkbox
          id={id}
          invalid={!!error && touched}
          name={name}
          readOnly={readOnly}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          value={value}
        />
        <div>
          <div>
            I have read the{" "}
            <Button
              ariaLabel="Terms"
              label="Terms"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.DIRECT_DEBIT_TERMS })}
            />{" "}
            and{" "}
            <Button
              ariaLabel="Guarantee"
              label="Guarantee"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.DIRECT_DEBIT_GUARANTEE })}
            />{" "}
            and confirm
          </div>
        </div>
      </div>
      {!!error && !!touched && <Error error={error} />}
    </div>
  );
};

export default PaymentLegal;
