import Messages from "modules/validation/messages";
import yup from "modules/validation";
import moment from "moment";

const schema = yup.object({
  id: yup.string().required(),

  date: yup
    .date()
    .required()
    .min(moment().subtract(5, "years").format("YYYY-MM-DD"), Messages.CLAIM_DATE)
    .max(moment().format("YYYY-MM-DD"), Messages.DATE_FUTURE),

  code: yup.string().required(Messages.LIST),

  cost: yup.number().required().min(0, Messages.CLAIM).max(999999, Messages.CLAIM),

  thirdPartyCost: yup.number().required().min(0, Messages.CLAIM).max(999999, Messages.CLAIM),

  personalInjury: yup.boolean().required(Messages.OPTION),

  fault: yup.boolean().required(Messages.OPTION),
});

export default schema;
