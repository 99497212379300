import { produce } from "immer";

import { Risk, RiskActionUpdateRider } from "../../types";
import { PaymentMethod } from "modules/risk/constants";

const updateRider = (state: Risk, action: RiskActionUpdateRider): Risk => {
  return produce(state, (draftState: Risk) => {
    if (action.risk.proposer.isMainRider) {
      draftState.additionalRiders.forEach(item => (item.isMainRider = false));
    }

    draftState.proposer = action.risk.proposer;

    draftState.hasAdditionalRiders = action.risk.hasAdditionalRiders;
    !action.risk.hasAdditionalRiders && (draftState.additionalRiders = []);

    !!action.risk.hasAdditionalRiders && (draftState.hasAdditionalVehicles = false);
    !!action.risk.hasAdditionalRiders && (draftState.additionalVehicles = []);

    draftState.additionalQuestions.detailsOfProductsEmail = action.risk.additionalQuestions.detailsOfProductsEmail;
    draftState.additionalQuestions.detailsOfProductsPhone = action.risk.additionalQuestions.detailsOfProductsPhone;
    draftState.additionalQuestions.detailsOfProductsPost = action.risk.additionalQuestions.detailsOfProductsPost;
    draftState.additionalQuestions.detailsOfProductsText = action.risk.additionalQuestions.detailsOfProductsText;

    draftState.additionalQuestions.homeowner = action.risk.additionalQuestions.homeowner;
    draftState.additionalQuestions.normallyPayByDirectDebit = action.risk.additionalQuestions.normallyPayByDirectDebit;
    draftState.additionalQuestions.whereHear = action.risk.additionalQuestions.whereHear;

    draftState.cart.paymentMethod =
      action.risk.additionalQuestions.normallyPayByDirectDebit === false ? PaymentMethod.ANNUAL : PaymentMethod.MONTHLY;
  });
};

export default updateRider;
