import React from "react";
import { FormikErrors, FormikProps, FormikTouched } from "formik";

import { MetadataItem } from "modules/metadata/types";
import * as Question from "components/Questions";
import { Occupation } from "modules/risk/types";

type QuestionsEmploymentPTProps = {
  errors?: FormikErrors<Occupation>;
  hasPartTimeOccupation: "" | boolean;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLElement>) => void;
  optionsEmployerBusiness: MetadataItem[];
  optionsOccupation: MetadataItem[];
  optionsOccupationTypesPartTime: MetadataItem[];
  readOnly?: boolean;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  touched?: FormikTouched<Occupation>;
  values: Occupation;
};

const proposerHelp = {
  occCode:
    "<p>Please enter at least the first three letters of your job description and as you type, the list of occupations will be shown that match the details you enter. For example: If working in a call centre, you may be a Telesales Person or Customer Advisor.</p><p>If you have any queries concerning this please telephone us for assistance. Calls may be recorded. There are certain occupations where we will be unable to provide you with an online quotation and you will need to speak to one of our insurance representatives to discuss your individual circumstances.</p>",
  empCode:
    "<p>Please enter at least the first three letters of the description which best describes the industry you work in e.g. 'accountancy' or 'banking'.</p><p>If you cannot find the industry relevant to your circumstances please call us. Calls may be recorded.</p>",
};

const currentAdditionalRiderHelp = {
  occCode:
    "<p>Please enter at least the first three letters of thier job description and as you type, the list of occupations will be shown that match the details you enter. For example: If working in a call centre, they may be a Telesales Person or Customer Advisor.</p><p>If you have any queries concerning this please telephone us for assistance. Calls may be recorded. There are certain occupations where we will be unable to provide you with an online quotation and you will need to speak to one of our insurance representatives to discuss your individual circumstances.</p>",
  empCode:
    "<p>Please enter at least the first three letters of the description which best describes the industry they work in e.g. 'accountancy' or 'banking'.</p><p>If you cannot find the industry relevant to their circumstances please call us. Calls may be recorded.</p>",
};

const QuestionsEmploymentPT: React.FunctionComponent<QuestionsEmploymentPTProps> = ({
  errors,
  hasPartTimeOccupation,
  name,
  onBlur,
  onChange,
  optionsEmployerBusiness,
  optionsOccupation,
  optionsOccupationTypesPartTime,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  const help = name === "proposer.partTimeOccupation" ? proposerHelp : currentAdditionalRiderHelp;
  return (
    <React.Fragment>
      <Question.QuestionSecondary
        name={`${name}.type`}
        open={hasPartTimeOccupation === true}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.type}>
        <Question.QuestionSelect
          error={errors?.type}
          label="Part-time employment status"
          name={`${name}.type`}
          onBlur={onBlur}
          onChange={onChange}
          options={optionsOccupationTypesPartTime}
          readOnly={readOnly}
          touched={touched?.type}
          value={values.type}
        />
      </Question.QuestionSecondary>

      <Question.QuestionSecondary
        name={`${name}.occCode`}
        open={hasPartTimeOccupation === true}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.occCode}>
        <Question.QuestionAutocomplete
          error={errors?.occCode}
          help={help.occCode}
          label="Occupation<br/><small>e.g. Sales Manager, Computer Programmer, Plumber.</small>"
          name={`${name}.occCode`}
          onBlur={onBlur}
          options={optionsOccupation}
          readOnly={readOnly}
          secondary={true}
          setFieldValue={setFieldValue}
          touched={touched?.occCode}
          value={values.occCode}
        />
      </Question.QuestionSecondary>

      <Question.QuestionSecondary
        name={`${name}.empCode`}
        open={hasPartTimeOccupation === true}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.empCode}>
        <Question.QuestionAutocomplete
          error={errors?.empCode}
          help={help.empCode}
          label="Employer's business<br/><small>e.g. Retail, Insurance, Building.</small>"
          name={`${name}.empCode`}
          onBlur={onBlur}
          options={optionsEmployerBusiness}
          readOnly={readOnly}
          secondary={true}
          setFieldValue={setFieldValue}
          touched={touched?.empCode}
          value={values.empCode}
        />
      </Question.QuestionSecondary>
    </React.Fragment>
  );
};

export default QuestionsEmploymentPT;
