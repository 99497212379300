export enum PaymentMethod {
  ANNUAL = "Annual",
  MONTHLY = "Monthly",
}

export enum RiskMaxAdditional {
  RIDERS = 4,
  VEHICLES = 3,
}

export enum RiskAction {
  REQUEST = "RISK_REQUEST",
  REQUEST_STARTED = "RISK_REQUEST_STARTED",
  REQUEST_SUCCESS = "RISK_REQUEST_SUCCESS",
  REQUEST_FAILURE = "RISK_REQUEST_FAILURE",

  DELETE_VEHICLE = "RISK_DELETE_VEHICLE",
  UPDATE_VEHICLE = "RISK_UPDATE_VEHICLE",

  NEW_ADDITIONAL_VEHICLE = "RISK_NEW_ADDITIONAL_VEHICLE",
  UPDATE_ADDITIONAL_VEHICLE = "RISK_UPDATE_ADDITIONAL_VEHICLE",

  RESET_ADDITIONAL_VEHICLES = "RISK_RESET_ADDITIONAL_VEHICLES",

  SET_MAIN_RIDER = "RISK_SET_MAIN_RIDER",
  UPDATE_RIDER = "RISK_UPDATE_RIDER",

  DELETE_ADDITIONAL_RIDER = "RISK_DELETE_ADDITIONAL_RIDER",
  NEW_ADDITIONAL_RIDER = "RISK_NEW_ADDITIONAL_RIDER",
  UPDATE_ADDITIONAL_RIDER = "RISK_UPDATE_ADDITIONAL_RIDER",

  RESET_ADDITIONAL_RIDERS = "RISK_RESET_ADDITIONAL_RIDERS",

  UPDATE_CART = "RISK_UPDATE_CART",

  ADD_OPTIONAL_EXTRA = "RISK_ADD_OPTIONAL_EXTRA",
  REMOVE_OPTIONAL_EXTRA = "RISK_REMOVE_OPTIONAL_EXTRA",

  SET_VOLUNTARY_EXCESS = "RISK_SET_VOLUNTARY_EXCESS",

  SET_ANNUAL = "RISK_SET_ANNUAL",
  SET_MONTHLY = "RISK_SET_MONTHLY",

  SET_PARAMS = "RISK_SET_PARAMS",

  UPDATE_POLICY = "RISK_UPDATE_POLICY",

  DESTROY = "RISK_DESTROY",
}
