import { ThunkMiddleware } from "redux-thunk";
import { logger } from "redux-logger";
import { Middleware } from "redux";

import createTrustpilotMiddleware from "modules/trustpilot/middleware";
import createAnalyticsMiddleware from "modules/analytics/middleware";
import createMetadataMiddleware from "modules/metadata/middleware";
import createArticlesMiddleware from "modules/articles/middleware";
import createProceedMiddleware from "modules/proceed/middleware";
import createRouterMiddleware from "modules/router/middleware";
import createAppMiddleware from "modules/app/middleware";

import { isAdmin } from "modules/admin";
const isLogger = true;

const middleware = (): Array<ThunkMiddleware | Middleware> => {
  const middleware = [
    createAppMiddleware(),
    createRouterMiddleware(),
    createAnalyticsMiddleware(),
    createProceedMiddleware(),
    createMetadataMiddleware(),
    createArticlesMiddleware(),
    createTrustpilotMiddleware(),
  ];
  isAdmin && isLogger && middleware.push(logger);
  return middleware;
};

export default middleware;
