import React from "react";

import PageWrapper, { Control } from "components/PageWrapper";
import { FormProps, TemplateProps } from "./types";
import * as Question from "components/Questions";
import Progress from "components/Progress";
import * as Form from "components/Form";

const Template: React.FunctionComponent<TemplateProps> = ({
  handleBack,
  handleDelete,
  handleEdit,
  handleNew,
  handleSubmit,
  risk,
  schema,
  validate,
}) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps>
        enableReinitialize={true}
        onSubmit={handleSubmit}
        schema={schema}
        state={risk}
        validate={validate}
        validateOnMount={true}>
        {({ errors, isSubmitting }) => (
          <React.Fragment>
            <Progress
              hasAdditionalRiders={risk.hasAdditionalRiders}
              hasAdditionalVehicles={risk.hasAdditionalVehicles}
              readOnly={isSubmitting}
            />

            <Question.QuestionVehicles
              errors={errors}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onNew={handleNew}
              values={risk}
            />

            <Control onBack={handleBack} isSubmitting={isSubmitting} submit={true} />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
