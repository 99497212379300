import React, { useContext } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { RemoveScroll } from "react-remove-scroll";
import Zoom from "@mui/material/Zoom";

import { LoadingType } from "components/Loading/constants";
import Loading from "components/Loading";
import ModalOverlay from "../ModalOverlay";
import PlatformContext from "modules/platform/context";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalLoadingProps = {
  destroyModal: () => void;
  hideModal: () => void;
  id: string;
  open: boolean;
};

const ModalLoading: React.FunctionComponent<ModalLoadingProps> = ({ destroyModal, open }) => {
  const { isMobile } = useContext(PlatformContext);
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay onExited={destroyModal} open={open} paper={false} scroll="paper" TransitionComponent={Transition}>
        <Loading type={LoadingType.WHEEL} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default ModalLoading;
