import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "RAC Breakdown",
  text: (
    <React.Fragment>
      <h2>Roadside</h2>
      <p>
        Covers for any breakdowns that occur more than ¼ of a mile away from your home address, along with a taxi fee
        for 20 miles if your bike can't be fixed there and then or towing for up to 10 miles.
      </p>
    </React.Fragment>
  ),
};

export default template;
