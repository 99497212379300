import React, { useEffect, useRef } from "react";
import { FieldArray, FormikErrors, FormikTouched } from "formik";
import clsx from "clsx";

import { ReactComponent as ConvictionSVG } from "modules/theme/icons/form/claims-convictions/conviction.svg";
import modalDeleteConviction from "modules/modals/templates/delete/modalDeleteConviction";
import { ReactComponent as PlusSVG } from "modules/theme/icons/buttons/plus.svg";
import schema from "modules/schema/partials/schemaConviction";
import { ModalIconType } from "components/Modals/Icons";
import Button, { ButtonStyle } from "components/Button";
import { useModalAction } from "modules/modals/hooks";
import { MetadataItem } from "modules/metadata/types";
import { ModalType } from "modules/modals/constants";
import { newConviction } from "modules/risk/helpers";
import { useScrollRef } from "modules/scroll/hooks";
import { Conviction } from "modules/risk/types";
import Questions from "./Questions";
import Item from "./Item";

import styles from "./styles.module.scss";

type QuestionConvictionsProps = {
  errors?: string | string[] | FormikErrors<Conviction>[];
  name: string;
  options: MetadataItem[];
  readOnly?: boolean;
  touched?: FormikTouched<Conviction>[];
  values: Conviction[];
};

const QuestionConvictions: React.FunctionComponent<QuestionConvictionsProps> = ({
  errors,
  name,
  options,
  readOnly,
  touched,
  values,
}) => {
  const scrollRef = useScrollRef("question-convictions");
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      modal.current && modalHide(modal.current);
    };
  }, [modalHide]);

  const handleDeleteConviction = (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    index: number,
    deleteConviction: (index: number) => void,
  ) => {
    if (!modal.current) {
      modal.current = modalShow({
        ...modalDeleteConviction,
        disableRestoreFocus: event.currentTarget !== document.activeElement,
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onConfirm: () => {
          deleteConviction(index);
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        type: ModalType.CONFIRM,
      });
    }
  };

  const handleEditConviction = (
    conviction: Conviction,
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    index: number,
    updateConviction: (index: number, conviction: Conviction) => void,
  ) => {
    if (!modal.current) {
      modal.current = modalShow({
        disableRestoreFocus: event.currentTarget !== document.activeElement,
        icon: ModalIconType.LEGAL_EXPENSES,
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onSubmit: values => {
          updateConviction(index, values as Conviction);
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderContent: ({ values, ...props }) => <Questions {...props} values={values as Conviction} />,
        schema: schema,
        state: conviction,
        type: ModalType.FORM,
        title: `Conviction ${index + 1}`,
      });
    }
  };

  const handlNewConviction = (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    index: number,
    insertConviction: (conviction: Conviction) => void,
  ) => {
    if (!modal.current) {
      modal.current = modalShow({
        disableRestoreFocus: event.currentTarget !== document.activeElement,
        icon: ModalIconType.LEGAL_EXPENSES,
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onSubmit: values => {
          insertConviction(values as Conviction);
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderContent: ({ values, ...props }) => <Questions {...props} values={values as Conviction} />,
        schema: schema,
        state: newConviction(),
        type: ModalType.FORM,
        title: `Conviction ${index + 1}`,
      });
    }
  };

  const className = clsx(styles["question-convictions"], !!errors && !!touched && styles["invalid"]);

  return (
    <FieldArray name={name}>
      {({ push, remove, replace }) => {
        return (
          <div className={className}>
            {values.length ? (
              <ul>
                {values.map((conviction, index) => (
                  <Item
                    id={`question-convictions-conviction-${index}`}
                    invalid={!!errors?.[index] && !!touched}
                    key={conviction.id}
                    onEdit={event => handleEditConviction(conviction, event, index, replace)}
                    onDelete={event => handleDeleteConviction(event, index, remove)}
                    readOnly={readOnly}
                    title={options.find(option => option.value === conviction.code)?.label || "Loading..."}
                  />
                ))}
              </ul>
            ) : (
              <div ref={!!errors && !!touched ? scrollRef : undefined}>
                <ConvictionSVG />
                <h3>You haven't added any convictions</h3>
              </div>
            )}
            <Button
              allowFocus={true}
              ariaLabel="Add a conviction"
              disabled={values.length >= 5}
              label="Add a conviction"
              onClick={event => values.length < 5 && handlNewConviction(event, values.length, push)}
              readOnly={readOnly}
              style={ButtonStyle.PRIMARY}>
              <PlusSVG />
            </Button>
          </div>
        );
      }}
    </FieldArray>
  );
};

export default React.memo(QuestionConvictions);
