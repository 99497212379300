import React from "react";
import clsx from "clsx";

import { ReactComponent as LoadingSVG } from "modules/theme/icons/questions/loading.svg";
import { InputStringFormat } from "components/Inputs/Input/constants";
import { TextAreaFormat } from "components/Inputs/TextArea/constants";
import InputString from "components/Inputs/Input/String";
import Button, { ButtonStyle } from "components/Button";
import TextArea from "components/Inputs/TextArea";
import { QuestionVrmChildProps } from "../types";
import { useIsMobileLayout } from "./hooks";

import styles from "./styles.module.scss";

const QuestionVrmMainVehicle: React.FunctionComponent<QuestionVrmChildProps> = ({
  disabled,
  invalid,
  loading,
  onClick,
  onSubmit,
  open,
  readOnly,
  value,
  ...props
}) => {
  const className = clsx(styles["question-vrm-main-vehicle-buttons"], loading && styles["loading"]);
  const isMobileLayout = useIsMobileLayout();
  return (
    <div className={styles["question-vrm-main-vehicle"]}>
      {isMobileLayout ? (
        <InputString
          autoFocus={true}
          disabled={disabled}
          format={InputStringFormat.VRM}
          invalid={invalid}
          readOnly={readOnly}
          value={value}
          {...props}
        />
      ) : (
        <TextArea
          autoFocus={true}
          disabled={disabled}
          format={TextAreaFormat.VRM}
          invalid={invalid}
          readOnly={readOnly}
          value={value}
          {...props}
        />
      )}
      <div className={className}>
        {!!loading && (
          <span>
            <LoadingSVG />
          </span>
        )}
        <div>
          <Button
            ariaLabel="Find my motorcycle"
            disabled={disabled || loading}
            label="Find my motorcycle"
            onClick={onSubmit}
            readOnly={readOnly}
            style={invalid ? ButtonStyle.INVALID : open && !value ? ButtonStyle.SECONDARY : ButtonStyle.PRIMARY}
          />
          <Button
            ariaLabel="I don't know the registration"
            disabled={disabled || loading}
            label="I don't know the registration"
            onClick={onClick}
            readOnly={readOnly || open}
            style={invalid ? ButtonStyle.INVALID : open && !value ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuestionVrmMainVehicle);
