import { InputDateFormat } from "components/Inputs/Input/constants";
import { OptionType } from "components/Inputs/Select";

export const optionsDays: OptionType[] = [];
for (let index = 1; index <= 31; index++) {
  const value = String(index).length < 2 ? "0" + String(index) : String(index);
  optionsDays.push({
    label: value,
    value: value,
  });
}

const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const monthNamesLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const optionsMonthsLong: OptionType[] = [];
export const optionsMonthsShort: OptionType[] = [];
for (let index = 1; index <= 12; index++) {
  const value = String(index).length < 2 ? "0" + String(index) : String(index);
  optionsMonthsLong.push({
    label: monthNamesLong[index - 1],
    value: value,
  });
  optionsMonthsShort.push({
    label: monthNamesShort[index - 1],
    value: value,
  });
}

export const optionsYears: OptionType[] = [];
for (let index = new Date().getFullYear(); index >= new Date().getFullYear() - 110; index--) {
  optionsYears.push({
    label: String(index),
    value: String(index),
  });
}

const leadingZero = (value: string | undefined): string => {
  return typeof value === "string" ? (value.length === 1 ? "0" + value : value) : "";
};

export const getDate = (
  date: string | undefined,
  format: InputDateFormat,
): { day: string; month: string; year: string } => {
  const parts = typeof date === "string" ? date.split("-") : [];
  return format === InputDateFormat.DATE && parts.length === 3
    ? { day: leadingZero(parts[2]), month: leadingZero(parts[1]), year: parts[0] }
    : format === InputDateFormat.MONTH && parts.length === 2
    ? { day: "", month: leadingZero(parts[1]), year: parts[0] }
    : { day: "", month: "", year: "" };
};

export const setDate = (day: string, month: string, year: string, format: InputDateFormat): string => {
  let date = year === "" || year === "YYYY" ? "YYYY" : year;
  date = `${date}-${month === "" || month === "MM" ? "MM" : leadingZero(month)}`;
  if (format === InputDateFormat.DATE) {
    date = `${date}-${day === "" || day === "DD" ? "DD" : leadingZero(day)}`;
  }
  return ["-", "--", "YYYY-MM", "YYYY-MM-DD"].includes(date) ? "" : date;
};
