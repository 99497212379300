import React from "react";

import * as Question from "components/Questions";
import { SearchProps } from "./types";
import useSearch from "./hooks";
import Footer from "./Footer";

const Search: React.FunctionComponent<SearchProps> = ({
  errors,
  onBlur,
  onChange,
  readOnly,
  setFieldTouched,
  setFieldValue,
  setShowDescription,
  touched,
  values,
}) => {
  const [apiError, handleChange, handleKeyDown, handleSubmit, loading] = useSearch({
    errors,
    onChange,
    setFieldTouched,
    setFieldValue,
    setShowDescription,
    values,
  });
  return (
    <React.Fragment>
      <Question.QuestionText
        disabled={loading}
        error={errors?.addressLine1}
        help="If you live in a subdivided property, such as a block of flats, please enter the relevant details here, e.g. Flat 1, Acacia Towers."
        label="Property name or number"
        maxLength={24}
        name="proposer.address.addressLine1"
        onBlur={onBlur}
        onChange={handleChange}
        readOnly={readOnly}
        touched={touched?.addressLine1}
        value={values.addressLine1}
      />

      <Question.QuestionText
        disabled={loading}
        error={errors?.postCode}
        format={Question.QuestionTextFormat.POSTCODE}
        help="Please enter your postcode then click Find address."
        label="Postcode"
        name="proposer.address.postCode"
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={readOnly}
        touched={touched?.postCode}
        value={values.postCode}
      />

      <Footer
        apiError={apiError}
        disabled={loading || !!errors?.addressLine1 || !!errors?.postCode}
        error={errors?.addressLine2}
        handleSubmit={handleSubmit}
        loading={loading}
        readOnly={readOnly}
        touched={touched?.addressLine2}
      />
    </React.Fragment>
  );
};

export default React.memo(Search);
