import React from "react";

import ProductBreakdownTemplate from "../ProductBreakdownTemplate";

const ProductBreakdownIncluded: React.FunctionComponent = () => {
  return (
    <ProductBreakdownTemplate
      included={true}
      open={false}
      subtitle="Covers for any breakdowns that occur more than ¼ of a mile away from your home address, along with a taxi fee for 20 miles if your bike can't be fixed there and then or towing for up to 10 miles."
      title="RAC UK Roadside Breakdown Cover">
      <ul>
        <li>Help to repair the motorcycle at the roadside when you're more than a quarter mile from your home</li>
        <li>
          Help to transport the motorcycle, you and passengers to a destination of your choice, up to 10 miles, if the
          RAC cannot repair the motorcycle at the roadside
        </li>
        <li>
          If your vehicle goes to the garage, the RAC will reimburse you for the cost of a taxi ride up to 20 miles
        </li>
        <li>Lines open 24/7</li>
      </ul>
      <blockquote>
        Breaking down can be an inconvenience that you could do without. But when it happens, be assured you've got
        someone coming to help you.
      </blockquote>
    </ProductBreakdownTemplate>
  );
};

export default React.memo(ProductBreakdownIncluded);
