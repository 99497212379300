import { AnalyticsPayloadAds, AnalyticsPayloadEcommerce, AnalyticsPayloadPageView } from "./events/types";
import { isAdmin } from "modules/admin";
import logger from "modules/logger";

const cleanObject = (obj: Record<string, unknown>): Record<string, unknown> => {
  for (const key in obj) {
    if (!obj[key] || typeof obj[key] !== "object") {
      continue;
    }
    cleanObject(obj[key] as Record<string, unknown>);
    if (Object.keys(obj[key] as Record<string, unknown>).length === 0) {
      delete obj[key];
    }
  }
  return obj;
};

const cleanProps = (key: unknown, value: unknown) =>
  value === null || value === undefined || value === "" ? undefined : value;

const getPayload = (data: Record<string, unknown>): Record<string, unknown> =>
  cleanObject(JSON.parse(JSON.stringify(data, cleanProps)));

const isAnalyticsPayloadEcommerce = (payload: unknown): payload is AnalyticsPayloadEcommerce =>
  typeof payload === "object" && payload !== null && "currency" in payload && "items" in payload && "value" in payload;

const isAnalyticsPayloadPageView = (payload: unknown): payload is AnalyticsPayloadPageView =>
  typeof payload === "object" &&
  payload !== null &&
  ("VirtualPageViewLocation" in payload || "VirtualPageViewPath" in payload || "VirtualPageViewTitle" in payload);

const logPayload = (name: string, payload: Record<string, unknown>) => {
  logger.debug(
    `%cgtag %c@@event/${name}`,
    `color: #757575; font-size: 12px; font-weight: normal`,
    `color: #008000; font-size: 13px; font-weight: bold`,
  );
  logger.debug(JSON.stringify(payload, null, 4));
};

export const pushEvent = (
  name: string,
  data: AnalyticsPayloadAds | AnalyticsPayloadEcommerce | AnalyticsPayloadPageView,
) => {
  const payload = getPayload(data);
  if (isAdmin) {
    logPayload(name, payload);
    validatePayload(name, payload);
  }
  if (!!process.env.REACT_APP_GTM && typeof window.dataLayer === "object" && typeof window.gtag === "function")
    window.gtag("event", name, payload);
};

const validatePayload = (name: string, payload: Record<string, unknown>) => {
  switch (true) {
    case isAnalyticsPayloadEcommerce(payload):
      return validateEcommercePayload(name, payload as AnalyticsPayloadEcommerce);
    case isAnalyticsPayloadPageView(payload):
      return validatePageViewPayload(name, payload as AnalyticsPayloadPageView);
  }
  return false;
};

const validateEcommercePayload = (name: string, payload: AnalyticsPayloadEcommerce): boolean => {
  const total = Number(payload.items.reduce((result, item) => result + item.price, 0).toFixed(2));
  switch (true) {
    case payload.value !== total:
      if (isAdmin) {
        logger.debug(
          `%cgtag %c@@event/${name} - Payload Error`,
          `color: #757575; font-size: 12px; font-weight: normal`,
          `color: #FF0000; font-size: 13px; font-weight: bold`,
        );
        logger.debug("Provided Total: ", payload.value);
        logger.debug("Calculated Total: ", total);
      }
      return false;
    default:
      return true;
  }
};

const validatePageViewPayload = (name: string, payload: AnalyticsPayloadPageView): boolean => {
  switch (true) {
    case !payload.VirtualPageViewLocation || !payload.VirtualPageViewPath || !payload.VirtualPageViewTitle:
      if (isAdmin) {
        logger.debug(
          `%cgtag %c@@event/${name} - Payload Error`,
          `color: #757575; font-size: 12px; font-weight: normal`,
          `color: #FF0000; font-size: 13px; font-weight: bold`,
        );
      }
      return false;
    default:
      return true;
  }
};
