import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useModalConfirm } from "modules/modals/hooks";
import schema from "modules/schema/routes/motorcycles";
import { Dispatch } from "modules/redux/store";
import { UseMotorcyclesRoute } from "./types";
import { Risk } from "modules/risk/types";
import useQuery from "../useQuery";

import useHandleDelete from "./useHandleDelete";
import useHandleSubmit from "./useHandleSubmit";
import useHandleBack from "./useHandleBack";
import useHandleEdit from "./useHandleEdit";
import useHandleNew from "./useHandleNew";
import useValidate from "../useValidate";
import useRedirect from "./useRedirect";

const useMotorcyclesRoute: UseMotorcyclesRoute = () => {
  const risk: Risk = useSelector(state => state.risk);
  const handleShowModalConfirm = useModalConfirm();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const handleBack = useHandleBack(navigate);

  const handleDelete = useHandleDelete(dispatch);

  const handleEdit = useHandleEdit(navigate);

  const handleNew = useHandleNew(navigate);

  const handleSubmit = useHandleSubmit({ dispatch, handleShowModalConfirm, navigate, risk });

  const redirect = useRedirect();

  const validate = useValidate(query);

  return {
    handleBack,
    handleDelete,
    handleEdit,
    handleNew,
    handleSubmit,
    redirect,
    risk,
    schema,
    validate,
  };
};

export default useMotorcyclesRoute;
