import { useCallback, useEffect, useRef } from "react";
import { resetAbiCode, resetYearOfManufacture } from "../../helpers";
import { ApiError } from "modules/api";
import { FormikProps } from "formik";

type UseHandleErrorProps = (props: {
  name: string;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
}) => (error: Error) => void;

const useHandleError: UseHandleErrorProps = ({ name, setFieldTouched, setFieldValue }) => {
  const handleErrorRef = useRef<ReturnType<UseHandleErrorProps>>(() => void 0);
  const handleError = useCallback<(error: Error) => void>(error => handleErrorRef.current(error), []);

  useEffect(() => {
    handleErrorRef.current = (error: Error) => {
      if (error.message === ApiError.ABI_INVALID || error.message === ApiError.ABI_NOT_FOUND) {
        resetYearOfManufacture(name, setFieldTouched, setFieldValue);
        resetAbiCode(name, setFieldTouched, setFieldValue);
      }
    };
  }, [name, setFieldTouched, setFieldValue]);

  return handleError;
};

export default useHandleError;
