import { AnalyticsItemPersonalAccidentHelmetLeathers } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemPersonalAccidentHelmetLeathers: AnalyticsItemPersonalAccidentHelmetLeathers = price => ({
  item_id: "PERSONAL_ACCIDENT_HELMET_LEATHERS",
  item_name: "Personal Accident + Helmet and Leathers",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemPersonalAccidentHelmetLeathers;
