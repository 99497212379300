import React from "react";
import clsx from "clsx";

import { PageWrapperFormat } from "./constants";

import styles from "./styles.module.scss";

export { default as Control } from "./Control";
export { PageWrapperFormat };

type PageWrapperProps = {
  children: React.ReactNode;
  format?: PageWrapperFormat;
  verticallyAlign?: boolean;
};

const PageWrapper: React.FunctionComponent<PageWrapperProps> = ({
  children,
  format = PageWrapperFormat.NARROW,
  verticallyAlign = false,
}) => {
  const className = clsx(styles["page-wrapper"], format && styles[format], verticallyAlign && styles["vertical-align"]);
  return <div className={className}>{children}</div>;
};

export default PageWrapper;
