import { prepareDataForValidation } from "formik";
import yup from "modules/validation";

import { schemaProposerAggregator, schemaProposerDirect } from "modules/schema/partials/schemaProposer";
import { schemaCurrentAdditionalVehicle } from "modules/schema/partials/schemaAdditionalVehicle";
import { CurrentAdditionalRider, CurrentAdditionalVehicle, Risk } from "modules/risk/types";
import { schemaCurrentAdditionalRider } from "modules/schema/partials/schemaAdditionalRider";
import schemaMotorcycle from "modules/schema/partials/schemaMotorcycle";
import { ROUTE, ROUTE_ACTION } from "modules/router/constants";
import { isAdmin } from "modules/admin";
import logger from "modules/logger";

const validate = async (
  values: CurrentAdditionalRider | CurrentAdditionalVehicle | Risk,
  schema: yup.AnyObjectSchema,
  title: string,
) => {
  return isAdmin
    ? await schema
        .validate(values)
        .then(() => true)
        .catch(error => {
          logger.debug(`Validation: ${title} Invalid`);
          logger.debug(error);
          logger.debug(values);
          return false;
        })
    : await schema.isValid(values);
};

export const validateMotorcycle = async (risk: Risk): Promise<boolean> => {
  return await validate(risk, schemaMotorcycle, "Motorcycle");
};

export const validateProposerAggregator = async (risk: Risk): Promise<boolean> => {
  return await validate(risk, schemaProposerAggregator, "Proposer");
};

export const validateProposerDirect = async (risk: Risk): Promise<boolean> => {
  return await validate(risk, schemaProposerDirect, "Proposer");
};

export const validateAdditionalRiders = async (risk: Risk): Promise<string | undefined> => {
  for (const rider of risk.additionalRiders) {
    if (
      !(await validate(
        {
          additionalRiders: risk.additionalRiders.filter(item => item.id !== rider.id),
          currentAdditionalRider: rider,
          hasAdditionalRiders: risk.hasAdditionalRiders,
          hasAdditionalVehicles: risk.hasAdditionalVehicles,
          proposer: risk.proposer,
        },
        schemaCurrentAdditionalRider,
        "Additional Rider",
      ))
    ) {
      return rider.id;
    }
  }
  return undefined;
};

export const validateAdditionalVehicles = async (risk: Risk): Promise<string | undefined> => {
  for (const vehicle of risk.additionalVehicles) {
    if (
      !(await validate(
        {
          currentAdditionalVehicle: vehicle,
          hasAdditionalRiders: risk.hasAdditionalRiders,
          hasAdditionalVehicles: risk.hasAdditionalVehicles,
          policy: risk.policy,
        },
        schemaCurrentAdditionalVehicle,
        "Additional Vehicle",
      ))
    ) {
      return vehicle.id;
    }
  }
  return undefined;
};

export const validateRiskDirect = async (risk: Risk): Promise<string | false> => {
  const result = prepareDataForValidation(risk) as Risk;

  if (!(await validateMotorcycle(result))) {
    return `${ROUTE.MOTORCYCLE}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  const invalidAdditionalVehicle = await validateAdditionalVehicles(result);
  if (invalidAdditionalVehicle) {
    return `${ROUTE.MOTORCYCLES}/${invalidAdditionalVehicle}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  if (!(await validateProposerDirect(result))) {
    return `${ROUTE.RIDER}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  const invalidAdditionalRider = await validateAdditionalRiders(result);
  if (invalidAdditionalRider) {
    return `${ROUTE.RIDERS}/${invalidAdditionalRider}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  return false;
};

export const validateRiskAggregator = async (risk: Risk): Promise<string | false> => {
  const result = prepareDataForValidation(risk) as Risk;

  if (!(await validateMotorcycle(result))) {
    return `${ROUTE.MOTORCYCLE}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  const invalidAdditionalVehicle = await validateAdditionalVehicles(result);
  if (invalidAdditionalVehicle) {
    return `${ROUTE.MOTORCYCLES}/${invalidAdditionalVehicle}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  if (!(await validateProposerAggregator(result))) {
    return `${ROUTE.RIDER}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  const invalidAdditionalRider = await validateAdditionalRiders(result);
  if (invalidAdditionalRider) {
    return `${ROUTE.RIDERS}/${invalidAdditionalRider}?action=${ROUTE_ACTION.VALIDATE}`;
  }

  return false;
};
