import { shallowEqual, useSelector } from "modules/redux/store";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { errorRoutes } from "modules/router/constants";
import { ROUTE } from "modules/router/constants";
import { Dispatch } from "modules/redux/store";
import { UseMessageRoute } from "./types";
import useQuery from "../useQuery";

import useRedirect from "./useRedirect";
import useRequest from "./useRequest";

const useMessageRoute: UseMessageRoute = () => {
  const error = useSelector(state => state.app.error, shallowEqual);
  const dispatch: Dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();

  const route = error.route
    ? error.route
    : errorRoutes.find(route => route.path === location.pathname)?.path || ROUTE.MESSAGE_404;

  const webReference = error.webReference ? error.webReference : query.get("webReference") || undefined;

  const token = query.get("token") || undefined;

  const redirect = useRedirect({ location, route });

  useRequest({ dispatch, error, route, webReference });

  return {
    redirect,
    route,
    token,
    webReference,
  };
};

export default useMessageRoute;
