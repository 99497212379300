import { useCallback, useContext, useLayoutEffect, useState } from "react";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import Scrollparent from "scrollparent";

import { focusNode, scrollDisable, scrollEnable, sortNodes } from "./helpers";
import { ScrollContext } from "./types";
import Context from "./context";

export function useNodes() {
  const { nodes } = useContext<ScrollContext>(Context);
  return nodes;
}

export function useOrderedNodes() {
  const [matches, setMatches] = useState<HTMLElement[]>([]);
  const nodes = useNodes();
  useLayoutEffect(() => {
    const sorted = sortNodes(Object.keys(nodes).map(k => nodes[k]));
    setMatches(sorted);
  }, [nodes]);
  return matches;
}

export function useScroll() {
  const [shouldScroll, setShouldScroll] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const nodes = useOrderedNodes();
  useLayoutEffect(() => {
    if (shouldScroll && !scrolling && nodes.length) {
      scrollDisable();
      setScrolling(true);
      setShouldScroll(false);
      smoothScrollIntoView(nodes[0], {
        scrollMode: "always",
        block: "center",
        inline: "start",
        boundary: Scrollparent(nodes[0]) || undefined,
      }).then(() => {
        scrollEnable();
        setScrolling(false);
        nodes && nodes.length > 0 && focusNode(nodes[0]);
      });
    }
  }, [nodes, scrolling, shouldScroll]);
  return setShouldScroll;
}

export function useScrollRef(key: string) {
  const { register, unregister } = useContext<ScrollContext>(Context);
  const callback = useCallback<(node: HTMLElement | null) => void>(
    node => {
      if (node) {
        register(key, node);
      } else {
        unregister(key);
      }
    },
    [key, register, unregister],
  );
  return callback;
}
