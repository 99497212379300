import React, { useState } from "react";

import { InputNumberFormat } from "../constants";
import { InputNumberProps } from "../types";
import InputPrimitive from "../Primitive";
import { handleChange } from "./helpers";
import { useMask } from "../hooks";

const InputNumber: React.FunctionComponent<InputNumberProps> = ({
  autoFocus,
  disabled,
  format = InputNumberFormat.INTEGER,
  id,
  invalid,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  tabIndex,
  value,
}) => {
  const [state, setState] = useState<string | number>(typeof value === "number" ? value : "");
  const { inputMask, ...inputProps } = useMask({
    format: format,
    max: max,
    maxLength: maxLength,
    min: min,
  });
  return (
    <InputPrimitive
      autoComplete={inputProps.autoComplete}
      autoFocus={!disabled && autoFocus}
      disabled={disabled}
      format={format}
      id={id}
      inputMask={inputMask}
      inputMode={inputProps.inputMode}
      invalid={invalid}
      maxLength={inputProps.maxLength}
      name={name}
      pattern={inputProps.pattern}
      placeholder={placeholder}
      onBlur={inputProps.onBlur ? inputProps.onBlur(onBlur) : onBlur}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.currentTarget.value);
        handleChange(event, onChange);
      }}
      onFocus={inputProps.onFocus ? inputProps.onFocus(onFocus) : onFocus}
      onInput={inputProps.onInput ? inputProps.onInput(onInput) : onInput}
      onKeyDown={inputProps.onKeyDown ? inputProps.onKeyDown(onKeyDown) : onKeyDown}
      onMount={inputProps.onMount}
      readOnly={readOnly}
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
      type={inputProps.type}
      value={
        state === "-" || state === "."
          ? state
          : typeof value === "number"
          ? value === Number(state)
            ? state
            : value
          : ""
      }
    />
  );
};

export default React.memo(InputNumber);
