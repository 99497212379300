import React, { useContext } from "react";

import PlatformContext from "modules/platform/context";
import { App } from "modules/app/types";

type CallUsProps = {
  telephone: App["contact"]["telephone"]["customerService"] | App["contact"]["telephone"]["sales"];
  text?: string;
};

const CallUs: React.FunctionComponent<CallUsProps> = ({ telephone, text }) => {
  const { isMobileOnly } = useContext(PlatformContext);
  return (
    <p>
      <strong>
        {text} Call us{" "}
        {isMobileOnly ? (
          <a href={`tel:${telephone.replace(/\s+/g, "")}`} onMouseDown={event => event.preventDefault()}>
            {telephone}
          </a>
        ) : (
          <span>{telephone}</span>
        )}
      </strong>
    </p>
  );
};

export default React.memo(CallUs);
