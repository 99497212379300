import { useCallback, useEffect, useState } from "react";

export const useIsMobileLayout = () => {
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);

  const handleResize = useCallback(() => {
    setMatches(window.matchMedia("(max-width: 1024px)").matches);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return matches;
};
