import React from "react";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import Button from "components/Button";

import styles from "./styles.module.scss";

const FooterNavigation: React.FunctionComponent = () => {
  const handleShowModalInfo = useModalInfo();
  return (
    <nav className={styles["footer-navigation"]}>
      <ul>
        <li>
          <a
            href="https://www.devittinsurance.com/motorbike-insurance/terms-of-business/"
            rel="noopener noreferrer"
            target="_blank">
            Important information and security
          </a>
        </li>
        <li>
          <a href="https://www.devittinsurance.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
            Privacy policy
          </a>
        </li>
        <li>
          <a href="https://policy.cookiereports.com/c2eb023d-en-gb.html" rel="noopener noreferrer" target="_blank">
            Cookie policy
          </a>
        </li>
        <li>
          <Button
            ariaLabel="Assumptions"
            label="Assumptions"
            onClick={event =>
              handleShowModalInfo({
                event,
                template: ModalInfoTemplates.ASSUMPTIONS,
              })
            }
          />
        </li>
      </ul>
    </nav>
  );
};

export default FooterNavigation;
