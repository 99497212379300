import { useCallback } from "react";

import { riskActionUpdateRider } from "modules/risk/actions";
import { quoteActionRequest } from "modules/quote/actions";
import { ModalType } from "modules/modals/constants";
import { ROUTE } from "modules/router/constants";
import { Risk } from "modules/risk/types";

import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, navigate }) =>
  useCallback(
    (values: Risk) => {
      dispatch(riskActionUpdateRider(values));
      if (values.hasAdditionalRiders && !values.additionalRiders.length) {
        navigate(`${ROUTE.RIDERS}/new`);
      } else if (values.hasAdditionalRiders && values.additionalRiders.length) {
        navigate(ROUTE.RIDERS);
      } else {
        dispatch(quoteActionRequest({ type: ModalType.LOADING_QUOTE }));
      }
    },
    [dispatch, navigate],
  );

export default useHandleSubmit;
