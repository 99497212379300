import { useCallback, useEffect, useRef } from "react";
import { FormikErrors, FormikProps } from "formik";

import { PostalAddress } from "modules/risk/types";
import { ApiError } from "modules/api";
import { validate } from "../helpers";

type UseHandleSubmitProps = (props: {
  errors?: FormikErrors<PostalAddress>;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => () => void;

const useHandleSubmit: UseHandleSubmitProps = ({ errors, setApiError, setFieldTouched, setLoading }) => {
  const handleSubmitRef = useRef<ReturnType<UseHandleSubmitProps>>(() => void 0);
  const handleSubmit = useCallback(() => handleSubmitRef.current(), []);

  useEffect(() => {
    handleSubmitRef.current = () => {
      setApiError(undefined);
      validate(setFieldTouched, errors) ? setLoading(true) : setApiError(ApiError.ADDRESS_INVALID);
    };
  }, [errors, setApiError, setFieldTouched, setLoading]);

  return handleSubmit;
};

export default useHandleSubmit;
