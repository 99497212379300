import { useCallback } from "react";

import { proceedActionRequest } from "modules/proceed/actions";
import { riskActionUpdatePolicy } from "modules/risk/actions";
import { PaymentMethod } from "modules/risk/constants";
import { ROUTE } from "modules/router/constants";
import { Risk } from "modules/risk/types";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, navigate }) =>
  useCallback(
    (values: Risk) => {
      dispatch(riskActionUpdatePolicy(values));
      values.cart.paymentMethod === PaymentMethod.MONTHLY ? navigate(ROUTE.PAYMENT) : dispatch(proceedActionRequest());
    },
    [dispatch, navigate],
  );

export default useHandleSubmit;
