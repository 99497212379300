import React from "react";
import clsx from "clsx";

import { ReactComponent as LoadingSVG } from "modules/theme/icons/questions/loading.svg";
import Error from "components/Questions/QuestionTemplate/Error";
import Button, { ButtonStyle } from "components/Button";
import { useScrollRef } from "modules/scroll/hooks";
import Messages from "modules/validation/messages";
import { errorMessage } from "modules/api/errors";
import { SearchFooterProps } from "./types";
import { ApiError } from "modules/api";

import styles from "./styles.module.scss";

const SearchFooter: React.FunctionComponent<SearchFooterProps> = ({
  apiError,
  disabled,
  error,
  handleSubmit,
  loading,
  readOnly,
  touched,
}) => {
  const scrollRef = useScrollRef("SearchFooter");
  const invalid =
    (apiError && [ApiError.ADDRESS_INVALID, ApiError.ADDRESS_NOT_FOUND].includes(apiError as keyof ApiError)) ||
    (!!error && touched);
  const className = clsx(
    styles["address-search-button"],
    loading && styles["loading"],
    !disabled && invalid && styles["invalid"],
  );
  return (
    <footer className={className}>
      {loading && (
        <span>
          <LoadingSVG />
        </span>
      )}
      <div ref={!disabled && invalid ? scrollRef : undefined}>
        <Button
          ariaLabel="Find address"
          disabled={disabled}
          label="Find address"
          onClick={handleSubmit}
          readOnly={readOnly}
          style={invalid ? ButtonStyle.INVALID : ButtonStyle.SECONDARY}
        />
        {!disabled &&
        !!apiError &&
        [ApiError.ADDRESS_INVALID, ApiError.ADDRESS_NOT_FOUND].includes(apiError as keyof ApiError) ? (
          <Error error={errorMessage(apiError)} />
        ) : !disabled && !!error && !!touched ? (
          <Error error={Messages.ADDRESS_SEARCH} />
        ) : (
          !disabled && !!apiError && <p>{errorMessage(apiError)}</p>
        )}
      </div>
    </footer>
  );
};

export default React.memo(SearchFooter);
