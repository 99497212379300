import { useEffect } from "react";

import { RiskApiRequest } from "modules/risk/types";
import { UseRecall } from "./types";
import useQuery from "../useQuery";

const recall: RiskApiRequest =
  process.env.NODE_ENV === "development"
    ? {
        dateOfBirth: "1970-01-01",
        postcode: "LA9 4RA",
        webReference: "276-634-038",
      }
    : {
        dateOfBirth: "",
        postcode: "",
        webReference: "",
      };

const useRecall: UseRecall = () => {
  const webReference = useQuery().get("webReference");
  useEffect(() => {
    if (webReference) {
      recall.dateOfBirth = "";
      recall.postcode = "";
      recall.webReference = webReference;
    }
  }, [webReference]);
  return recall;
};

export default useRecall;
