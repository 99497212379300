import { InputStringFormat } from "../../constants";
import { Mask, MaskProps } from "../../types";

import account from "./account";
import email from "./email";
import name from "./name";
import password from "./password";
import postcode from "./postcode";
import sortcode from "./sortcode";
import tel from "./tel";
import text from "./text";
import vrm from "./vrm";

export const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  [InputStringFormat.ACCOUNT]: account,
  [InputStringFormat.EMAIL]: email,
  [InputStringFormat.NAME]: name,
  [InputStringFormat.PASSWORD]: password,
  [InputStringFormat.POSTCODE]: postcode,
  [InputStringFormat.SORTCODE]: sortcode,
  [InputStringFormat.TEL]: tel,
  [InputStringFormat.TEXT]: text,
  [InputStringFormat.VRM]: vrm,
};

export default MASK;
