import React from "react";
import { useId } from "react-id-generator";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import { InputNumberProps } from "components/Inputs/Input/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import InputNumber from "components/Inputs/Input/Number";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";
import { QuestionNumberFormat } from "./constants";

type QuestionNumberProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> &
    Omit<InputNumberProps, "format" | "id" | "invalid"> & {
      format?: QuestionNumberFormat;
    }
>;

const QuestionNumber: React.FunctionComponent<QuestionNumberProps> = ({
  disabled,
  error,
  format = QuestionNumberFormat.INTEGER,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      size={size}
      touched={touched}>
      <React.Fragment>
        <InputNumber disabled={disabled} format={format} id={id} invalid={!!error && touched} {...props} />
        {process.env.NODE_ENV === "development" && <Debug name={props.name} value={props.value} />}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default React.memo(QuestionNumber);
