import React from "react";

import ProductOverviewEvolution from "./ProductOverviewEvolution";
import ProductOverviewEnhanced from "./ProductOverviewEnhanced";
import ProductOverviewBasic from "./ProductOverviewBasic";

import { OptionalExtra, Quote } from "modules/quote/types";
import { PaymentMethod } from "modules/risk/constants";
import { MetadataItem } from "modules/metadata/types";
import { getDevittLogo } from "modules/utils";
import { UseCart } from "modules/cart";
import AggLogo from "./AggLogo";

import styles from "./styles.module.scss";

type ProductOverviewProps = {
  cart: ReturnType<UseCart>;
  cover: string;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
  optionsCover: MetadataItem[];
  paymentMethod: PaymentMethod;
  whereHear: string;
};

const ProductOverview: React.FunctionComponent<ProductOverviewProps> = ({ cart, whereHear, ...props }) => {
  return (
    <div className={styles["product-overview"]}>
      <header>
        {getDevittLogo(cart)}
        {!!whereHear && <AggLogo whereHear={whereHear} />}
      </header>
      <div>
        {cart.selectedEvolution || cart.selectedEvolutionPlus ? (
          <ProductOverviewEvolution cart={cart} {...props} />
        ) : cart.selectedEnhanced || cart.selectedEnhancedPlus ? (
          <ProductOverviewEnhanced cart={cart} {...props} />
        ) : (
          <ProductOverviewBasic cart={cart} {...props} />
        )}
      </div>
    </div>
  );
};

export default ProductOverview;
