import React from "react";

import { ReactComponent as SportsBikeShopSVG } from "modules/theme/icons/confirmation/sports-bike-shop.svg";

import styles from "./styles.module.scss";

const ConfirmationBanner: React.FunctionComponent = () => {
  return (
    <a
      className={styles["confirmation-banner"]}
      href="https://www.devittinsurance.com/motorbike-insurance/10-discount-code-sportsbikeshop/"
      onMouseDown={event => event.preventDefault()}>
      <p>We'll send your 10% unique discount code for SportsBikeShop by email two days after your policy starts.</p>
      <SportsBikeShopSVG />
    </a>
  );
};

export default ConfirmationBanner;
