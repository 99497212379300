import { useMemo } from "react";

type UseStyles = (paper: boolean) => object;

const backdropStyles = {
  backgroundColor: "rgba(0,0,0,0.5)",
  backdropFilter: "blur(1px)",
};

const containerStyles = {
  willChange: "transform",
};

const paperStyles = (paper: boolean) =>
  !paper
    ? {
        backgroundColor: "transparent",
        borderRadius: "0px",
        boxShadow: "none",
        border: "none",
      }
    : {
        backgroundColor: "transparent",
        boxShadow: "none",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 0 0 0",
        maxWidth: "none",
        width: "100%",
        maxHeight: "100%",
        overflow: "hidden",
      };

const useStyles: UseStyles = paper => ({
  "> .MuiBackdrop-root": { ...backdropStyles },
  "> .MuiDialog-container": { ...containerStyles },
  "> .MuiDialog-container > .MuiPaper-root": { ...useMemo(() => paperStyles(paper), [paper]) },
});

export default useStyles;
