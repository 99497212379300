import { useContext } from "react";
import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import PlatformContext from "modules/platform/context";
import { Metadata } from "modules/metadata/types";
import { Dispatch } from "modules/redux/store";
import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import { UseProductRoute } from "./types";

import useHandleSetVoluntaryExcess from "./useHandleSetVoluntaryExcess";
import useHandleConfirmAndPay from "./useHandleConfirmAndPay";
import useHandleSetAnnually from "./useHandleSetAnnually";
import useHandleSetMonthly from "./useHandleSetMonthly";
import useHandleRemove from "./useHandleRemove";
import useHandleBack from "./useHandleBack";
import useHandleAdd from "./useHandleAdd";
import useRedirect from "./useRedirect";
import { useCart } from "modules/cart";

const useProductRoute: UseProductRoute = () => {
  const metadata: Metadata = useSelector(state => state.metadata);
  const quote: Quote = useSelector(state => state.quote);
  const risk: Risk = useSelector(state => state.risk);

  const { isMobile } = useContext(PlatformContext);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAdd = useHandleAdd(dispatch);

  const handleBack = useHandleBack(navigate);

  const handleConfirmAndPay = useHandleConfirmAndPay(dispatch);

  const handleRemove = useHandleRemove(dispatch);

  const handleSetAnnually = useHandleSetAnnually(dispatch);

  const handleSetMonthly = useHandleSetMonthly(dispatch);

  const handleSetVoluntaryExcess = useHandleSetVoluntaryExcess({ dispatch, isMobile });

  const redirect = useRedirect(risk.cart.quoteToken);

  const cart = useCart(quote.optionalExtras, risk.cart.optionalExtras);

  return {
    cart,
    handleAdd,
    handleBack,
    handleConfirmAndPay,
    handleRemove,
    handleSetAnnually,
    handleSetMonthly,
    handleSetVoluntaryExcess,
    metadata,
    quote,
    redirect,
    risk,
  };
};

export default useProductRoute;
