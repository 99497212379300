import { validateRiskDirect, validateRiskAggregator } from "./helpers";
import { ROUTE } from "modules/router/constants";
import { Risk } from "modules/risk/types";

const validate = async (risk: Risk, route?: string): Promise<string | false> => {
  switch (route) {
    case ROUTE.AGGREGATOR:
      return await validateRiskAggregator(risk);

    case ROUTE.RECALL:
      return await validateRiskDirect(risk);

    case ROUTE.QUOTE:
      return risk.isAggregator ? await validateRiskAggregator(risk) : await validateRiskDirect(risk);

    case ROUTE.PRODUCT:
      return risk.isAggregator ? await validateRiskAggregator(risk) : await validateRiskDirect(risk);

    case ROUTE.RIDER:
    case ROUTE.RIDERS:
      return await validateRiskDirect(risk);

    default:
      return false;
  }
};

export default validate;
