import React from "react";
import { useSelector } from "modules/redux/store";

import { ReactComponent as InfoSVG } from "modules/theme/icons/questions/info.svg";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { App } from "modules/app/types";

import styles from "./styles.module.scss";

const RecallControl: React.FunctionComponent = () => {
  const app: App = useSelector(state => state.app);
  return (
    <div className={styles["recall-control"]}>
      <div>
        <Button
          ariaLabel="Resume your quote"
          label="Resume your quote"
          style={ButtonStyle.SUCCESS}
          type={ButtonType.SUBMIT}
        />
      </div>
      <div aria-live="polite">
        <InfoSVG />
        <p>
          For security purposes, if you have taken out a policy you will not be able to recall your details. However, if
          you need to make any changes to your policy or have any queries,{" "}
          <strong>please call {app.contact.telephone.customerService}.</strong>
        </p>
        <p>*Calls may be recorded.</p>
      </div>
    </div>
  );
};

export default React.memo(RecallControl);
