import { useEffect } from "react";

import { appActionUpdate } from "modules/app/actions";
import { UseRequest } from "./types";

const useRequest: UseRequest = ({ dispatch, error, route, webReference }) =>
  useEffect(() => {
    (!error.route || (!error.webReference && webReference)) &&
      dispatch(appActionUpdate({ route: route, webReference: webReference }));
  }, [dispatch, error.route, error.webReference, route, webReference]);

export default useRequest;
