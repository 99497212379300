import React from "react";

import { ReactComponent as InfoSVG } from "modules/theme/icons/questions/info.svg";

import styles from "./styles.module.scss";

type AnnualMileageProps = {
  mileage: "" | number;
};

const AnnualMileage: React.FunctionComponent<AnnualMileageProps> = ({ mileage }) => {
  return typeof mileage === "number" ? (
    <div className={styles["annual-mileage"]} aria-live="polite">
      <InfoSVG />
      <span>On average this is approximately {Math.floor(mileage / 52)} miles per week.</span>
    </div>
  ) : null;
};

export default React.memo(AnnualMileage);
