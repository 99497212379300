import { produce } from "immer";

import { Risk, RiskActionDeleteVehicle } from "../../types";

const deleteVehicle = (state: Risk, action: RiskActionDeleteVehicle): Risk => {
  return produce(state, (draftState: Risk) => {
    const bikes = [draftState.vehicle, ...draftState.additionalVehicles].filter(item => item.id !== action.id);
    const [first, ...rest] = bikes;
    if (bikes.length) {
      draftState.vehicle = first;
      draftState.vehicle.id = "mainVehicle";
      draftState.additionalVehicles = rest;
    }
  });
};

export default deleteVehicle;
