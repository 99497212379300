import { PaymentMethod } from "modules/risk/constants";
import { OptionType } from "components/Inputs/Select";
import { Proceed } from "./types";

export const newProceed = (): Proceed => {
  return {
    bankDetails: {
      accountName: "",
      accountNumber: "",
      bankName: "",
      preferredPaymentDay: "",
      sortCode: "",
      termsAgreedPayment: "",
    },
    quoteToken: "",
    update: {
      daytimeTelephone: "",
      detailsOfProductsEmail: "",
      detailsOfProductsPhone: "",
      detailsOfProductsPost: "",
      detailsOfProductsText: "",
      documentDelivery: "",
      email: "",
      paymentMethod: PaymentMethod.MONTHLY,
      termsAgreedPolicy: "",
      vehicleRegistration: "",
    },
    webReference: "",
  };
};

function ordinal(n: number) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function leadingZero(n: number) {
  return String(n > 0 && n < 10 ? `0${n}` : n);
}

export const optionsPaymentDate: OptionType[] = [];
for (let index = 1; index <= 28; index++) {
  const value = index;
  optionsPaymentDate.push({
    label: ordinal(value),
    value: leadingZero(value),
  });
}
