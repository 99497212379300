import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";
import OpeningTimes from "../components/OpeningTimes";
import { ROUTE } from "modules/router/constants";
import CallUs from "../components/CallUs";

type Message404Props = {
  webReference?: string;
};

const Message404: React.FunctionComponent<Message404Props> = ({ webReference }) => {
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Page Not Found</h2>
            <h3>The page you have requested does not exist.</h3>
          </header>

          <CallUs telephone={telephone.sales} text="Need help? Our advisors are here for you." />

          <OpeningTimes openingTimes={openingTimes} />

          <Button
            ariaLabel="Start New Quote"
            href={ROUTE.MOTORCYCLE}
            label="Start New Quote"
            style={ButtonStyle.SUCCESS}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />

          <Button
            ariaLabel="Retrieve Quote"
            href={webReference ? `${ROUTE.RECALL}?webReference=${webReference}` : ROUTE.RECALL}
            label="Retrieve Quote"
            style={ButtonStyle.PRIMARY}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(Message404);
