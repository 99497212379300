import { LOCATION_CHANGE } from "redux-first-history";
import { ThunkMiddleware } from "redux-thunk";
import { matchPath } from "react-router-dom";

import { RouterActionType } from "modules/router/types";
import { articlesActionRequest } from "./actions";
import { ROUTE } from "modules/router/constants";
import { RootState } from "modules/redux/store";
import { ArticlesActionType } from "./types";

const createArticlesMiddleware: () => ThunkMiddleware<RootState> =
  () =>
  ({ dispatch, getState }) =>
  next =>
  async (action: ArticlesActionType | RouterActionType) => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        const { articles }: RootState = getState();
        matchPath(ROUTE.CONFIRMATION, action.payload.location.pathname) &&
          !articles.fixed.length &&
          dispatch(articlesActionRequest());
        break;
      }
    }
    return next(action);
  };

export default createArticlesMiddleware;
