import React from "react";

import { ReactComponent as BikeSVG } from "modules/theme/icons/form/lookup/bike.svg";
import Button, { ButtonStyle } from "components/Button";
import { getFuelType } from "modules/utils";
import { DescriptionProps } from "./types";
import useDescription from "./hooks";

import styles from "./styles.module.scss";

const Description: React.FunctionComponent<DescriptionProps> = ({
  errorYearOfManufacture,
  name,
  open,
  readOnly,
  setFieldTouched,
  setFieldValue,
  setOpen,
  valueAbiCode,
  valueYearOfManufacture,
}) => {
  const [handleClick, loading, result] = useDescription({
    errorYearOfManufacture,
    name,
    open,
    setFieldTouched,
    setFieldValue,
    setOpen,
    valueAbiCode,
    valueYearOfManufacture,
  });
  return valueYearOfManufacture ? (
    <div className={styles["vehicle-description"]}>
      <p>Bike description</p>
      <div>
        <div>
          <BikeSVG />
          {result ? (
            <p>
              {(!!result.manufacturer || !!result.model) && (
                <React.Fragment>
                  {result.manufacturer}
                  {result.model && ` ${result.model}`}
                  <br />
                </React.Fragment>
              )}
              {!!valueYearOfManufacture && (
                <React.Fragment>
                  {valueYearOfManufacture}
                  <br />
                </React.Fragment>
              )}
              {!!result.fuelType && (
                <React.Fragment>
                  {getFuelType(result.fuelType)}
                  <br />
                </React.Fragment>
              )}
              {!!result.engineCC && (
                <React.Fragment>
                  {result.engineCC}cc
                  <br />
                </React.Fragment>
              )}
              {!!result.transmission && (
                <React.Fragment>
                  {result.transmission === "M" ? "Manual" : "Automatic"}
                  <br />
                </React.Fragment>
              )}
            </p>
          ) : loading ? (
            <p>Loading...</p>
          ) : (
            <p>An error has occurred.</p>
          )}
        </div>
        {!open && !loading && (
          <Button
            ariaLabel="Change my bike"
            label="Change my bike"
            onClick={handleClick}
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default React.memo(Description);
