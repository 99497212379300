import { InputAutocomplete, InputType, Sanitisers } from "../../constants";
import { Mask, MaskProps } from "../../types";
import { handleInput } from "../../helpers";

const mask = (props: MaskProps): Mask => ({
  autoComplete: InputAutocomplete.NEW_PASSWORD,
  maxLength: props.maxLength,
  onInput: handleInput(event => event.currentTarget.value.replace(Sanitisers.LEADING_WHITESPACE, "")),
  placeholder: props.placeholder,
  type: InputType.PASSWORD,
});

export default mask;
