import React, { useCallback, useState } from "react";

import { QuestionAddressProps } from "./types";
import Description from "./Description";
import { getProps } from "./helpers";
import Questions from "./Questions";
import Search from "./Search";

const QuestionAddress: React.FunctionComponent<QuestionAddressProps> = props => {
  const { searchProps, questionsProps, descriptionProps } = getProps(props);
  const [showDescription, setShowDescription] = useState<boolean>(
    !!props.values.addressLine1 && !!props.values.addressLine2 && !!props.values.postCode,
  );
  const [showQuestions, setShowQuestions] = useState<boolean>(
    !!props.values.addressLine1 && !props.values.addressLine2 && !!props.values.postCode,
  );
  const handleSetShowDescription = useCallback((state: boolean): void => {
    setShowDescription(state);
    setShowQuestions(!state);
  }, []);
  const handleSetShowQuestions = useCallback((state: boolean): void => {
    setShowDescription(!state);
    setShowQuestions(state);
  }, []);
  return (
    <React.Fragment>
      {!showQuestions && !showDescription && <Search setShowDescription={handleSetShowDescription} {...searchProps} />}

      {showQuestions && <Questions {...questionsProps} />}

      {showDescription && <Description setShowQuestions={handleSetShowQuestions} {...descriptionProps} />}
    </React.Fragment>
  );
};

export default React.memo(QuestionAddress);
