import Messages from "modules/validation/messages";
import yup from "modules/validation";

const schema = yup.object({
  bankDetails: yup.object({
    accountName: yup.string().required(),

    accountNumber: yup.string().required().account("sortCode"),

    bankName: yup.string().required(),

    preferredPaymentDay: yup.string().required(),

    sortCode: yup.string().sortcode().required(),

    termsAgreedPayment: yup.boolean().required(Messages.TERMS).oneOf([true], Messages.TERMS),
  }),
});

export default schema;
