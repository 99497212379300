import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { riskActionSetParams } from "modules/risk/actions";
import { useQuery } from "modules/router/hooks";
import { Dispatch } from "modules/redux/store";
import storage from "modules/storage";

type Affiliates = {
  affinity: string | null;
  policyuser1: string | null;
  policyuser16: string | null;
  subagent: string | null;
};

export const useAffiliates = () => {
  const dispatch: Dispatch = useDispatch();
  const query = useQuery();

  const policyuser16 = query.get("policyuser16");
  const policyuser1 = query.get("policyuser1");
  const affinity = query.get("affinity");
  const subagent = query.get("subagent");

  useEffect(() => {
    if (affinity || policyuser1 || policyuser16 || subagent) {
      dispatch(riskActionSetParams(affinity, policyuser1, policyuser16, subagent));
      storage.setItem("affiliates", true, {
        affinity: affinity || undefined,
        policyuser1: policyuser1 || undefined,
        policyuser16: policyuser16 || undefined,
        subagent: subagent || undefined,
      });
    } else {
      const cached = storage.getItem<Affiliates>("affiliates", true);
      !!cached &&
        dispatch(riskActionSetParams(cached.affinity, cached.policyuser1, cached.policyuser16, cached.subagent));
    }
  }, [affinity, dispatch, policyuser1, policyuser16, subagent]);
};
