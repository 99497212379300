import React from "react";

import { isAdmin } from "modules/admin";

import Navigation from "modules/admin/Navigation";
import LiveChat from "components/LiveChat";
import Header from "components/Header";
import UspBar from "components/UspBar";
import Footer from "components/Footer";

import styles from "./styles.module.scss";

type TemplateProps = {
  children: React.ReactNode;
  showNavigation: boolean;
};

const Template: React.FunctionComponent<TemplateProps> = ({ children, showNavigation }) => {
  return (
    <React.Fragment>
      <Header />
      <UspBar />
      {isAdmin && showNavigation && <Navigation />}
      <main className={styles.main}>
        {children}
        <LiveChat />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Template;
