import React from "react";
import clsx from "clsx";

import { ReactComponent as ChevronHeadSVG } from "modules/theme/icons/progress-bar/chevron-head.svg";
import { ReactComponent as ChevronTailSVG } from "modules/theme/icons/progress-bar/chevron-tail.svg";
import { ROUTE } from "modules/router/constants";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";

import styles from "./styles.module.scss";

type ProgressItemProps = {
  children?: React.ReactNode;
  isActive: boolean;
  isAvailable: boolean;
  label: string;
  path: ROUTE;
  readOnly?: boolean;
};

const ProgressItem: React.FunctionComponent<ProgressItemProps> = ({
  children,
  isActive,
  isAvailable,
  label,
  path,
  readOnly,
}) => {
  const navigate = useNavigate();
  const className = clsx(
    styles["progress-item"],
    isActive && styles["active"],
    isAvailable && styles["available"],
    readOnly && styles["read-only"],
  );
  return !isActive && isAvailable ? (
    <Button ariaLabel={label} className={className} onClick={() => navigate(path)} readOnly={readOnly}>
      <ChevronTailSVG />
      {children}
      <div>{label}</div>
      <ChevronHeadSVG />
    </Button>
  ) : (
    <div className={className}>
      <ChevronTailSVG />
      {children}
      <div>{label}</div>
      <ChevronHeadSVG />
    </div>
  );
};

export default ProgressItem;
