import yup from "modules/validation";

const schemaPart1 = yup.object({
  questionAutocomplete: yup.string().required(),
  questionText: yup.string().required(),
  questionTextHelp: yup.string().required(),
  questionTextArea: yup.string().required(),
  questionDecimal: yup.number().required().positive().max(50),
  questionDecimalNegative: yup.number().required().min(-50).max(50),
  questionEmail: yup.string().required().emailCDL(),
  questionPassword: yup.string().required(),
  questionPostcode: yup.string().required().postcode(true),
  questionVrm: yup.string().required().vrm(true),

  questionSortcode: yup.string().required().sortcode(),
  questionAccount: yup.string().required().account("questionSortcode"),

  questionTel: yup.string().required().telephone(),
  questionName: yup.string().required(),
  questionNumber: yup.number().required().positive().integer().max(50),
  questionNumberNegative: yup.number().required().integer().min(-50).max(50),
  questionCurrency: yup.number().required().max(1000).positive(),
  questionCurrencyNegative: yup.number().required().min(-1000).max(1000),
});

const schemaPart2 = yup.object({
  questionDate: yup.date().required().min("1910-01-01").max("2021-01-01"),
  questionMonth: yup.date().required().min("1910-01").max("2021-01"),
});

const schemaPart3 = yup.object({
  questionSelect: yup.string().required(),
  questionCheckbox: yup.array().of(yup.string().required()).required(),
  questionRadio: yup.string().required(),
  questionSwitch: yup.boolean().required(),
  questionFieldGroup: yup.object().shape(
    {
      questionFieldGroup1: yup.boolean().when(["questionFieldGroup2", "questionFieldGroup3", "questionFieldGroup4"], {
        is: (field1?: boolean, field2?: boolean, field3?: boolean) =>
          field1 === true || field2 === true || field3 === true,
        then: schema => schema.notRequired(),
        otherwise: schema => schema.required(),
      }),
      questionFieldGroup2: yup.boolean().when(["questionFieldGroup1", "questionFieldGroup3", "questionFieldGroup4"], {
        is: (field1?: boolean, field2?: boolean, field3?: boolean) =>
          field1 === true || field2 === true || field3 === true,
        then: schema => schema.notRequired(),
        otherwise: schema => schema.required(),
      }),
      questionFieldGroup3: yup.boolean().when(["questionFieldGroup1", "questionFieldGroup2", "questionFieldGroup4"], {
        is: (field1?: boolean, field2?: boolean, field3?: boolean) =>
          field1 === true || field2 === true || field3 === true,
        then: schema => schema.notRequired(),
        otherwise: schema => schema.required(),
      }),
      questionFieldGroup4: yup.boolean().when(["questionFieldGroup1", "questionFieldGroup2", "questionFieldGroup3"], {
        is: (field1?: boolean, field2?: boolean, field3?: boolean) =>
          field1 === true || field2 === true || field3 === true,
        then: schema => schema.notRequired(),
        otherwise: schema => schema.required(),
      }),
    },
    [
      ["questionFieldGroup1", "questionFieldGroup2"],
      ["questionFieldGroup1", "questionFieldGroup3"],
      ["questionFieldGroup1", "questionFieldGroup4"],
      ["questionFieldGroup2", "questionFieldGroup3"],
      ["questionFieldGroup2", "questionFieldGroup4"],
      ["questionFieldGroup3", "questionFieldGroup4"],
    ],
  ),
});

export const schema = schemaPart1.concat(schemaPart2 as yup.AnyObjectSchema).concat(schemaPart3 as yup.AnyObjectSchema);
