import React, { useCallback, useMemo, useState } from "react";
import { useId } from "react-id-generator";

import { HTMLElementMap, ScrollContext } from "./types";
import { addNode, removeNode } from "./helpers";
import Context from "./context";

type ScrollContextProviderProps = {
  children: React.ReactNode;
};

const ScrollContextProvider: React.FunctionComponent<ScrollContextProviderProps> = ({ children }) => {
  const [nodes, setNodes] = useState<HTMLElementMap>({});
  const register = useCallback<ScrollContext["register"]>((key, node) => {
    setNodes(nodes => addNode(key, nodes, node));
  }, []);
  const unregister = useCallback<ScrollContext["unregister"]>(key => {
    setNodes(nodes => removeNode(key, nodes));
  }, []);
  const [namespace] = useId(1, "scroll-");
  const context = useMemo(() => {
    return {
      namespace: namespace,
      nodes,
      register,
      unregister,
    };
  }, [namespace, nodes, register, unregister]);
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default ScrollContextProvider;
