import React from "react";

import QuestionSwitch, { QuestionSwitchProps } from "../QuestionSwitch";
import { Expand } from "modules/typescript/helpers";

export type QuestionNormallyPayProps = Expand<Omit<QuestionSwitchProps, "label" | "options">>;

const options = [
  { label: "Monthly", value: true },
  { label: "Yearly", value: false },
];

const QuestionNormallyPay: React.FunctionComponent<QuestionNormallyPayProps> = props => {
  return <QuestionSwitch label="How do you usually pay for your bike insurance?" options={options} {...props} />;
};

export default React.memo(QuestionNormallyPay);
