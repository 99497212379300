import { AccountValidateApiRequest, AccountValidateApiResponseResult } from "./types";

const cache: {
  account: AccountValidateApiRequest;
  result: AccountValidateApiResponseResult;
}[] = [];

export const cacheSearch = (account: AccountValidateApiRequest) => {
  return cache.find(
    item => item.account.accountnumber === account.accountnumber && item.account.sortingcode === account.sortingcode,
  );
};

export const cacheResult = (account: AccountValidateApiRequest, result: AccountValidateApiResponseResult) => {
  !cacheSearch(account) &&
    cache.push({
      account: account,
      result: result,
    });
};
