import React from "react";

import { ReactComponent as ArrowSVG } from "modules/theme/icons/product/arrow.svg";
import Button from "components/Button";

import styles from "./styles.module.scss";

type ProductHeaderProps = {
  onBack: () => void;
  webReference: string;
};

const ProductHeader: React.FunctionComponent<ProductHeaderProps> = ({ onBack, webReference }) => {
  return (
    <header className={styles["product-header"]}>
      <Button ariaLabel="Go back" onClick={onBack} label="Go back">
        <ArrowSVG />
      </Button>
      <p>
        Your web reference: <span>{webReference}</span>
      </p>
    </header>
  );
};

export default React.memo(ProductHeader);
