import { cacheResult, cacheSearch } from "./cache";
import { AddressValidateApi } from "./types";
import { api, API } from "../";

const addressValidateApi: AddressValidateApi = address => {
  return new Promise((resolve, reject) => {
    const cached = cacheSearch(address);
    if (cached) {
      resolve(cached.result);
    } else {
      api(API.ADDRESS_VALIDATE_REQUEST(address)).then(
        response => {
          cacheResult(address, response.data.valid);
          resolve(response.data.valid);
        },
        error => reject(error),
      );
    }
  });
};

export default addressValidateApi;
