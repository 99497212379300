import { useCallback, useEffect, useRef } from "react";

type Event = React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;

type UseHandleKeyDownProps = (props: { handleSubmit: () => void; loading: boolean }) => (event: Event) => void;

const useHandleKeyDown: UseHandleKeyDownProps = ({ handleSubmit, loading }) => {
  const handleKeyDownRef = useRef<ReturnType<UseHandleKeyDownProps>>(() => void 0);
  const handleKeyDown = useCallback<(event: Event) => void>(event => handleKeyDownRef.current(event), []);

  useEffect(() => {
    handleKeyDownRef.current = event => {
      if (event.key === "Enter" && !loading) {
        handleSubmit();
        event.preventDefault();
        event.stopPropagation();
      }
    };
  }, [handleSubmit, loading]);

  return handleKeyDown;
};

export default useHandleKeyDown;
