import { AddressValidateApiRequest, AddressValidateApiResponseResult } from "./types";

const cache: {
  address: AddressValidateApiRequest;
  result: AddressValidateApiResponseResult;
}[] = [];

export const cacheSearch = (address: AddressValidateApiRequest) => {
  return cache.find(item => item.address.postcode === address.postcode);
};

export const cacheResult = (address: AddressValidateApiRequest, result: AddressValidateApiResponseResult) => {
  !cacheSearch(address) &&
    cache.push({
      address: address,
      result: result,
    });
};
