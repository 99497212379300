import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import TagManager from "react-gtm-module";
import sentry from "modules/sentry";
import App from "modules/app";

import "modules/theme/sass/_index.scss";

sentry();

if (process.env.REACT_APP_GTM) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM,
  });
  !!process.env.REACT_APP_GTM_ADS && window.gtag("config", process.env.REACT_APP_GTM_ADS);
}

document.addEventListener("touchstart", () => void 0, true);

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
