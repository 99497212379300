import React, { useCallback, useEffect, useState } from "react";
import useAutoComplete from "@mui/material/useAutocomplete";
import { FormikProps } from "formik";
import clsx from "clsx";

import { InputAutocomplete, InputType, Sanitisers } from "components/Inputs/Input/constants";
import { ReactComponent as LoadingSVG } from "modules/theme/icons/form/inputs/loading.svg";
import { ReactComponent as SearchSVG } from "modules/theme/icons/form/inputs/search.svg";
import { ReactComponent as ClearSVG } from "modules/theme/icons/form/inputs/clear.svg";
import { handleInput } from "components/Inputs/Input/helpers";
import { InputProps } from "components/Inputs/Input/types";
import { useScrollRef } from "modules/scroll/hooks";
import { Expand } from "modules/typescript/helpers";

import styles from "components/Inputs/Input/Primitive/styles.module.scss";
import listStyles from "./styles.module.scss";

type OptionType = {
  label: string;
  value: string;
};

export type AutocompleteProps = Expand<
  InputProps & {
    maxLength?: number;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    options?: OptionType[];
    setFieldValue: FormikProps<string>["setFieldValue"];
    value: string;
  }
>;

type GetInputProps = {
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  ref: React.MutableRefObject<HTMLInputElement | null>;
};

const Autocomplete: React.FunctionComponent<AutocompleteProps> = ({
  autoFocus,
  disabled,
  id,
  invalid,
  maxLength,
  name,
  onBlur,
  onFocus,
  onInput,
  onKeyDown,
  options,
  placeholder,
  readOnly,
  setFieldValue,
  tabIndex,
  value,
}) => {
  const [loading, setLoading] = useState(!options?.length);
  const scrollRef = useScrollRef(id);

  useEffect(() => {
    setLoading(!options?.length);
  }, [options]);

  const onChange = useCallback<(event: unknown, value: OptionType | null) => void>(
    (_, value) => {
      setFieldValue(name, value?.value || "");
    },
    [name, setFieldValue],
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutoComplete<OptionType>({
    getOptionLabel: (option: OptionType) => option.label,
    id: id,
    onChange: onChange,
    options: options || [],
    value: options?.find(option => option.value === value) || null,
  });

  const inputProps = getInputProps() as GetInputProps;

  const handleMouseDown = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    onChange && onChange(event, null);
    inputProps.ref.current && inputProps.ref.current.focus();
  };

  const className = clsx(
    styles["input"],
    styles["input-autocomplete"],
    disabled && styles["disabled"],
    !disabled && invalid && styles["invalid"],
    loading && styles["loading"],
  );
  return (
    <React.Fragment>
      <div className={listStyles["autocomplete-container"]}>
        <div {...getRootProps()} className={className} ref={!disabled && invalid ? scrollRef : undefined}>
          <SearchSVG />
          <input
            {...inputProps}
            autoFocus={!disabled && autoFocus}
            autoComplete={InputAutocomplete.NEW_PASSWORD}
            className={className}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              inputProps.onBlur && inputProps.onBlur(event);
              onBlur && onBlur(event);
            }}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
              inputProps.onFocus && inputProps.onFocus(event);
              onFocus && onFocus(event);
            }}
            onInput={(event: React.FormEvent<HTMLInputElement>) => {
              handleInput((event: React.FormEvent<HTMLInputElement>): string =>
                event.currentTarget.value.replace(Sanitisers.LEADING_WHITESPACE, ""),
              )(onInput)(event);
            }}
            onKeyDown={onKeyDown}
            placeholder={loading ? "Loading..." : placeholder ? placeholder : "Type here to search"}
            readOnly={readOnly || loading}
            tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
            type={InputType.TEXT}
          />
          {!!value && !loading && <ClearSVG onMouseDown={!readOnly ? handleMouseDown : undefined} />}
          {loading && <LoadingSVG />}
        </div>
        {groupedOptions.length > 0 ? (
          <div className={listStyles["autocomplete-list"]}>
            <div>
              <ul {...getListboxProps()}>
                {(groupedOptions as OptionType[]).map((option, index) => (
                  <li {...getOptionProps({ option, index })} key={option.value}>
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Autocomplete;
