import { InputAutocomplete, InputType, RegularExpressions } from "../../constants";
import { handleInput, handleKeyDown, setNativeInput } from "../../helpers";
import { maskVrm, MaskVrmInputType } from "../helpers";
import { Mask, MaskProps } from "../../types";

const mask = (props: MaskProps): Mask => ({
  autoComplete: InputAutocomplete.OFF,
  maxLength: 8,
  onInput: handleInput((event): string => maskVrm(event.currentTarget.value, MaskVrmInputType.INPUT)),
  onKeyDown: handleKeyDown(RegularExpressions.ALPHANUMERIC_ALLOW_SPACE),
  onMount: input => {
    const value = input.current?.value;
    const sanitised = value && maskVrm(value, MaskVrmInputType.INPUT);
    sanitised && sanitised !== value && setNativeInput(input, sanitised);
  },
  placeholder: props.placeholder || "ENTER REG",
  type: InputType.TEXT,
});

export default mask;
