import React, { useEffect, useRef } from "react";
import { FieldArray, FormikErrors, FormikTouched } from "formik";
import clsx from "clsx";

import { ReactComponent as ClaimSVG } from "modules/theme/icons/form/claims-convictions/claim.svg";
import { ReactComponent as PlusSVG } from "modules/theme/icons/buttons/plus.svg";
import modalDeleteClaim from "modules/modals/templates/delete/modalDeleteClaim";
import schema from "modules/schema/partials/schemaClaim";
import { ModalIconType } from "components/Modals/Icons";
import Button, { ButtonStyle } from "components/Button";
import { useModalAction } from "modules/modals/hooks";
import { MetadataItem } from "modules/metadata/types";
import { ModalType } from "modules/modals/constants";
import { useScrollRef } from "modules/scroll/hooks";
import { newClaim } from "modules/risk/helpers";
import { Claim } from "modules/risk/types";
import Questions from "./Questions";
import Item from "./Item";

import styles from "./styles.module.scss";

type QuestionClaimsProps = {
  errors?: string | string[] | FormikErrors<Claim>[];
  name: string;
  options: MetadataItem[];
  readOnly?: boolean;
  touched?: FormikTouched<Claim>[];
  values: Claim[];
};

const QuestionClaims: React.FunctionComponent<QuestionClaimsProps> = ({
  errors,
  name,
  options,
  readOnly,
  touched,
  values,
}) => {
  const { modalShow, modalHide } = useModalAction();
  const scrollRef = useScrollRef("question-claims");
  const modal = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      modal.current && modalHide(modal.current);
    };
  }, [modalHide]);

  const handleDeleteClaim = (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    index: number,
    deleteClaim: (index: number) => void,
  ) => {
    if (!modal.current) {
      modal.current = modalShow({
        ...modalDeleteClaim,
        disableRestoreFocus: event.currentTarget !== document.activeElement,
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onConfirm: () => {
          deleteClaim(index);
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        type: ModalType.CONFIRM,
      });
    }
  };

  const handleEditClaim = (
    claim: Claim,
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    index: number,
    updateClaim: (index: number, claim: Claim) => void,
  ) => {
    if (!modal.current) {
      modal.current = modalShow({
        disableRestoreFocus: event.currentTarget !== document.activeElement,
        icon: ModalIconType.CLAIM,
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onSubmit: values => {
          updateClaim(index, values as Claim);
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderContent: ({ values, ...props }) => <Questions {...props} values={values as Claim} />,
        schema: schema,
        state: claim,
        type: ModalType.FORM,
        title: `Claim ${index + 1}`,
      });
    }
  };

  const handlNewClaim = (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    index: number,
    insertClaim: (claim: Claim) => void,
  ) => {
    if (!modal.current) {
      modal.current = modalShow({
        disableRestoreFocus: event.currentTarget !== document.activeElement,
        icon: ModalIconType.CLAIM,
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onSubmit: values => {
          insertClaim(values as Claim);
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderContent: ({ values, ...props }) => <Questions {...props} values={values as Claim} />,
        schema: schema,
        state: newClaim(),
        type: ModalType.FORM,
        title: `Claim ${index + 1}`,
      });
    }
  };

  const className = clsx(styles["question-claims"], !!errors && !!touched && styles["invalid"]);

  return (
    <FieldArray name={name}>
      {({ push, remove, replace }) => {
        return (
          <div className={className}>
            {values.length ? (
              <ul>
                {values.map((claim, index) => (
                  <Item
                    id={`question-claims-claim-${index}`}
                    invalid={!!errors?.[index] && !!touched}
                    key={claim.id}
                    onEdit={event => handleEditClaim(claim, event, index, replace)}
                    onDelete={event => handleDeleteClaim(event, index, remove)}
                    readOnly={readOnly}
                    title={options.find(option => option.value === claim.code)?.label || "Loading..."}
                  />
                ))}
              </ul>
            ) : (
              <div ref={!!errors && !!touched ? scrollRef : undefined}>
                <ClaimSVG />
                <h3>You haven't added any claims</h3>
              </div>
            )}
            <Button
              allowFocus={true}
              ariaLabel="Add a claim"
              disabled={values.length >= 5}
              label="Add a claim"
              onClick={event => values.length < 5 && handlNewClaim(event, values.length, push)}
              readOnly={readOnly}
              style={ButtonStyle.PRIMARY}>
              <PlusSVG />
            </Button>
          </div>
        );
      }}
    </FieldArray>
  );
};

export default React.memo(QuestionClaims);
