import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { ReactComponent as BikeSVG } from "modules/theme/icons/product/addons/bike.svg";
import { ReactComponent as ErrorIcon } from "modules/theme/icons/questions/error.svg";
import { ReactComponent as DeleteSVG } from "modules/theme/icons/buttons/delete.svg";
import { ReactComponent as EditSVG } from "modules/theme/icons/buttons/edit.svg";
import { AbiApiResponseResult } from "modules/api/abi/types";
import Button, { ButtonStyle } from "components/Button";
import { useAbiAction } from "modules/api/abi/hooks";
import { useScrollRef } from "modules/scroll/hooks";
import { Messages } from "modules/validation";
import { Bike } from "modules/risk/types";

import styles from "./styles.module.scss";

type ItemProps = {
  id: string;
  invalid?: boolean;
  isMainVehicle: boolean;
  onDelete?: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    id: string,
  ) => void;
  onEdit: (id: string) => void;
  readOnly?: boolean;
  vehicle: Bike;
};

const Item: React.FunctionComponent<ItemProps> = ({
  id,
  invalid,
  isMainVehicle,
  onDelete,
  onEdit,
  readOnly,
  vehicle,
}) => {
  const [result, setResult] = useState<AbiApiResponseResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { abiRequest } = useAbiAction();
  const scrollRef = useScrollRef(id);
  useEffect(() => {
    if (vehicle.abiCode) {
      let active = true;
      setError(false);
      setLoading(true);
      setResult(undefined);
      (async function () {
        try {
          const response = await abiRequest(vehicle.abiCode);
          active && setResult(response);
        } catch {
          active && setError(true);
        } finally {
          active && setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [vehicle.abiCode, abiRequest]);
  const className = clsx(styles["vehicle"], invalid && styles["invalid"], isMainVehicle && styles["main"]);
  return (
    <li className={className} ref={invalid ? scrollRef : undefined}>
      <BikeSVG />
      <div>
        <div>
          <h2>
            {loading
              ? "Loading..."
              : !error && result && result.manufacturer && result.model
              ? `${result.manufacturer} ${result.model}${vehicle.yearOfManufacture && ` ${vehicle.yearOfManufacture}`}`
              : "An error has occurred."}
          </h2>
          {!!invalid && (
            <div aria-live="polite">
              <ErrorIcon />
              <p>{Messages.SECTION_REQUIRED}</p>
            </div>
          )}
        </div>
        <div>
          <div>
            <Button
              ariaLabel="Edit"
              label="Edit"
              onClick={() => onEdit(vehicle.id)}
              readOnly={readOnly}
              style={ButtonStyle.PRIMARY_CLEAR}>
              <EditSVG />
            </Button>

            {!!onDelete && (
              <Button
                allowFocus={true}
                ariaLabel="Delete"
                label="Delete"
                onClick={event => onDelete(event, vehicle.id)}
                readOnly={readOnly}
                style={ButtonStyle.PRIMARY_CLEAR}>
                <DeleteSVG />
              </Button>
            )}
          </div>
          <div>
            <Button
              ariaLabel="Edit"
              label="Edit"
              onClick={() => onEdit(vehicle.id)}
              readOnly={readOnly}
              style={ButtonStyle.SECONDARY}>
              <EditSVG />
            </Button>

            {!!onDelete && (
              <Button
                allowFocus={true}
                ariaLabel="Delete"
                label="Delete"
                onClick={event => onDelete(event, vehicle.id)}
                readOnly={readOnly}
                style={ButtonStyle.SECONDARY}>
                <DeleteSVG />
              </Button>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default React.memo(Item);
