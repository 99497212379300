import { Debug } from "./types";

export const debug: Debug = {
  questionAutocomplete: "",
  questionText: "",
  questionTextHelp: "",
  questionTextArea: "",
  questionDecimal: "",
  questionDecimalNegative: "",
  questionEmail: "",
  questionPassword: "",
  questionPostcode: "",
  questionVrm: "",
  questionSortcode: "",
  questionAccount: "",
  questionTel: "",
  questionDate: "",
  questionMonth: "",
  questionName: "",
  questionNumber: "",
  questionNumberNegative: "",
  questionCurrency: "",
  questionCurrencyNegative: "",
  questionSelect: "",
  questionCheckbox: [],
  questionRadio: "",
  questionSwitch: "",
  questionFieldGroup: {
    questionFieldGroup1: "",
    questionFieldGroup2: "",
    questionFieldGroup3: "",
    questionFieldGroup4: "",
  },
};
