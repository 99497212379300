import React from "react";
import clsx from "clsx";

import { htmlSafe } from "modules/utils";

import styles from "../styles.module.scss";

type InfoProps = {
  info: string | JSX.Element;
};

const Info: React.FunctionComponent<InfoProps> = ({ info }) => {
  return (
    <div className={clsx(styles["info"], styles["rich-text"])}>{typeof info === "string" ? htmlSafe(info) : info}</div>
  );
};

export default React.memo(Info);
