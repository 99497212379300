import React from "react";

import { ReactComponent as InstagramSVG } from "modules/theme/icons/social/instagram.svg";
import { ReactComponent as FacebookSVG } from "modules/theme/icons/social/facebook.svg";
import { ReactComponent as LinkedinSVG } from "modules/theme/icons/social/linkedin.svg";
import { ReactComponent as TwitterSVG } from "modules/theme/icons/social/twitter.svg";
import { ReactComponent as YoutubeSVG } from "modules/theme/icons/social/youtube.svg";

import styles from "./styles.module.scss";

const ConfirmationSocial: React.FunctionComponent = () => {
  return (
    <div className={styles["confirmation-social"]}>
      <h5>Follow us</h5>
      <ul>
        <li>
          <a
            aria-label="Facebook"
            href="https://www.facebook.com/devittinsurance/"
            onMouseDown={event => event.preventDefault()}
            rel="noopener noreferrer"
            target="_blank">
            <FacebookSVG />
          </a>
        </li>
        <li>
          <a
            aria-label="Instagram"
            href="https://www.instagram.com/devittinsurance/"
            onMouseDown={event => event.preventDefault()}
            rel="noopener noreferrer"
            target="_blank">
            <InstagramSVG />
          </a>
        </li>
        <li>
          <a
            aria-label="LinkedIn"
            href="https://www.linkedin.com/company/devitt-insurance-services-ltd/"
            onMouseDown={event => event.preventDefault()}
            rel="noopener noreferrer"
            target="_blank">
            <LinkedinSVG />
          </a>
        </li>
        <li>
          <a
            aria-label="Twitter"
            href="https://twitter.com/DevittInsurance/"
            onMouseDown={event => event.preventDefault()}
            rel="noopener noreferrer"
            target="_blank">
            <TwitterSVG />
          </a>
        </li>
        <li>
          <a
            aria-label="Youtube"
            href="https://www.youtube.com/channel/UCnmU6micoO-fz3u1rfvd6tg/"
            onMouseDown={event => event.preventDefault()}
            rel="noopener noreferrer"
            target="_blank">
            <YoutubeSVG />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ConfirmationSocial;
