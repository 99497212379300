import React from "react";

import CompareTheMarket from "./CompareTheMarket";

type ProductPartnersProps = {
  webReference: string;
  whereHear: string;
};

const ProductPartners: React.FunctionComponent<ProductPartnersProps> = ({ webReference, whereHear }) => {
  if (webReference && whereHear) {
    switch (whereHear) {
      case "COMKAT":
      case "COMKB1":
      case "COMKL1":
        return <CompareTheMarket webReference={webReference} />;
      default:
        return null;
    }
  } else {
    return null;
  }
};

export default React.memo(ProductPartners);
