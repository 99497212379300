import { produce } from "immer";

import { App, AppActionType } from "./types";
import { isAdmin } from "modules/admin";
import { AppAction } from "./constants";
import { newApp } from "./helpers";

const initialState: App = newApp();

function appReducer(state = initialState, action: AppActionType): App {
  switch (action.type) {
    case AppAction.INIT:
      return produce(state, (draftState: App) => {
        draftState.loading = false;
      });
    case AppAction.UPDATE:
      return produce(state, (draftState: App) => {
        !isAdmin &&
          ((action.error.route && !state.error.route) || action.error.overwrite) &&
          (draftState.error.route = action.error.route);
        !isAdmin &&
          (action.error.webReference || action.error.overwrite) &&
          (draftState.error.webReference = action.error.webReference);
      });
    case AppAction.REQUEST_SUCCESS:
      return produce(state, (draftState: App) => {
        draftState.openingTimes.monday = action.siteInfo.openingTimes.monday;
        draftState.openingTimes.tuesday = action.siteInfo.openingTimes.tuesday;
        draftState.openingTimes.wednesday = action.siteInfo.openingTimes.wednesday;
        draftState.openingTimes.thursday = action.siteInfo.openingTimes.thursday;
        draftState.openingTimes.friday = action.siteInfo.openingTimes.friday;
        draftState.openingTimes.saturday = action.siteInfo.openingTimes.saturday;
        draftState.openingTimes.sunday = action.siteInfo.openingTimes.sunday;
        draftState.contact.telephone.sales = action.siteInfo.contact.telephone.sales;
        draftState.contact.telephone.customerService = action.siteInfo.contact.telephone.customerService;
      });
    default:
      return state;
  }
}

export default appReducer;
