import Messages from "modules/validation/messages";
import yup from "modules/validation";

import schemaVehicle from "./schemaVehicle";

const schema = yup.object({
  vehicle: schemaVehicle,

  hasAdditionalVehicles:
    process.env.REACT_APP_MULTIBIKE === "true" ? yup.boolean().required(Messages.OPTION) : yup.mixed(),

  policy: yup.object({
    previousInsuranceNCBYears: yup.string().required(Messages.OPTION),

    protectedBonus: yup.boolean().when("previousInsuranceNCBYears", {
      is: (value?: string) => !!value && ["4", "5", "6", "7", "8", "9"].includes(value),
      then: schema => schema.required(Messages.OPTION),
    }),

    inceptionDate: yup.date().required().inceptionDate().purchasedDateInceptionDate(),
  }),
});

export default schema;
