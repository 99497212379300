import { AnalyticsItemBreakdown } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemBreakdown: AnalyticsItemBreakdown = (price, type) => {
  switch (type) {
    case "breakdown1":
      return {
        item_id: "RAC_BREAKDOWN_ROADSIDE",
        item_name: "RAC Breakdown Roadside",
        affiliation: "Devitt MC",
        item_brand: "Devitt",
        item_category: "MC Insurance",
        price: getPrice(price),
        quantity: 1,
      };

    case "breakdown2":
      return {
        item_id: "RAC_BREAKDOWN_RECOVERY",
        item_name: "RAC Breakdown Recovery",
        affiliation: "Devitt MC",
        item_brand: "Devitt",
        item_category: "MC Insurance",
        price: getPrice(price),
        quantity: 1,
      };

    case "breakdown3":
      return {
        item_id: "RAC_BREAKDOWN_AT_HOME",
        item_name: "RAC Breakdown At Home",
        affiliation: "Devitt MC",
        item_brand: "Devitt",
        item_category: "MC Insurance",
        price: getPrice(price),
        quantity: 1,
      };

    case "breakdown4":
      return {
        item_id: "RAC_BREAKDOWN_ONWARD_TRAVEL_EU",
        item_name: "RAC Breakdown Onwards Travel + EU",
        affiliation: "Devitt MC",
        item_brand: "Devitt",
        item_category: "MC Insurance",
        price: getPrice(price),
        quantity: 1,
      };
  }
};

export default analyticsItemBreakdown;
