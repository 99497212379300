import React, { createContext } from "react";

import { LiveChatContextType } from "./types";
import { useLiveChat } from "./hooks";

const LiveChatContext = createContext<LiveChatContextType>({
  active: false,
  availability: "offline",
  visibility: "hidden",
});

type LiveChatProviderType = {
  children?: React.ReactNode;
};

export const LiveChatProvider = ({ children }: LiveChatProviderType) => {
  const { active, availability, handleClick, visibility } = useLiveChat();
  const chatContext: LiveChatContextType = {
    active: active,
    availability: availability,
    handleClick: handleClick,
    visibility: visibility,
  };
  return <LiveChatContext.Provider value={chatContext}>{children}</LiveChatContext.Provider>;
};

export default LiveChatContext;
