import { useCallback, useEffect, useRef } from "react";

import { LiveChatAvailability } from "../types";

type Data = { availability: LiveChatAvailability };

type UseHandleAvailabilityProps = (
  setAvailability: React.Dispatch<React.SetStateAction<Data["availability"]>>,
) => (data: Data) => void;

const useHandleAvailability: UseHandleAvailabilityProps = setAvailability => {
  const handleAvailabilityRef = useRef<ReturnType<UseHandleAvailabilityProps>>(() => void 0);
  const handleAvailability = useCallback((data: Data) => handleAvailabilityRef.current(data), []);

  useEffect(() => {
    handleAvailabilityRef.current = (data: Data) => {
      setAvailability(data.availability);
    };
  }, [setAvailability]);

  return handleAvailability;
};

export default useHandleAvailability;
