import React from "react";
import { FormikErrors } from "formik";

import { useModalConfirm, ModalConfirmTemplates } from "modules/modals/hooks";
import { Risk, AdditionalRider } from "modules/risk/types";
import { RiskMaxAdditional } from "modules/risk/constants";
import Button, { ButtonStyle } from "components/Button";
import Item from "./Item";

import styles from "./styles.module.scss";

type QuestionRidersProps = {
  errors?: FormikErrors<Risk>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onMain: (id: string) => void;
  onNew: () => void;
  readOnly?: boolean;
  values: Risk;
};

const QuestionRiders: React.FunctionComponent<QuestionRidersProps> = ({
  errors,
  onDelete,
  onEdit,
  onMain,
  onNew,
  readOnly,
  values,
}) => {
  const handleShowModalConfirm = useModalConfirm();
  const handleDelete = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    id: string,
  ) =>
    handleShowModalConfirm({
      event,
      template: ModalConfirmTemplates.DELETE_RIDER,
      onConfirm: () => onDelete(id),
    });
  return (
    <div className={styles["question-riders"]}>
      <h2>Riders</h2>
      <ul>
        <Item
          id={"question-riders-proposer"}
          invalid={!!errors?.proposer || !!errors?.hasAdditionalRiders || !!errors?.additionalQuestions}
          isProposer={true}
          key={values.proposer.dateOfBirth || values.proposer.id}
          onEdit={onEdit}
          onMain={onMain}
          readOnly={readOnly}
          rider={values.proposer}
        />
        {values.additionalRiders.length > 0 &&
          values.additionalRiders.map((rider: AdditionalRider, index) => (
            <Item
              id={`question-riders-additional-rider-${index}`}
              invalid={Array.isArray(errors?.additionalRiders) && typeof errors?.additionalRiders[index] === "object"}
              isProposer={false}
              key={rider.id}
              onDelete={handleDelete}
              onEdit={onEdit}
              onMain={onMain}
              readOnly={readOnly}
              rider={rider}
            />
          ))}
      </ul>
      <Button
        ariaLabel="Add an additional rider"
        disabled={values.additionalRiders.length >= RiskMaxAdditional.RIDERS}
        label="Add an additional rider"
        onClick={() => values.additionalRiders.length < RiskMaxAdditional.RIDERS && onNew()}
        readOnly={readOnly}
        style={ButtonStyle.PRIMARY}
      />
    </div>
  );
};

export default QuestionRiders;
