import { useCallback } from "react";

import { riskActionUpdateVehicle } from "modules/risk/actions";
import { ROUTE } from "modules/router/constants";
import { Risk } from "modules/risk/types";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, navigate }) =>
  useCallback(
    (values: Risk) => {
      dispatch(riskActionUpdateVehicle(values));
      if (
        process.env.REACT_APP_MULTIBIKE === "true" &&
        values.hasAdditionalVehicles &&
        !values.additionalVehicles.length
      ) {
        navigate(`${ROUTE.MOTORCYCLES}/new`);
      } else if (
        process.env.REACT_APP_MULTIBIKE === "true" &&
        values.hasAdditionalVehicles &&
        values.additionalVehicles.length
      ) {
        navigate(ROUTE.MOTORCYCLES);
      } else {
        navigate(ROUTE.RIDER);
      }
    },
    [dispatch, navigate],
  );

export default useHandleSubmit;
