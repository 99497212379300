import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";
import OpeningTimes from "../components/OpeningTimes";
import { ROUTE } from "modules/router/constants";
import CallUs from "../components/CallUs";

type MessageCardPaymentProps = {
  webReference?: string;
  token?: string;
};

const MessageCardPayment: React.FunctionComponent<MessageCardPaymentProps> = ({ token, webReference }) => {
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Card Payment Failed</h2>
            <h3>
              Sorry, we could not take payment with the card details that were provided. Please check that the card
              details that you entered are correct, and that the card being used is valid.{" "}
            </h3>
          </header>

          {webReference ? (
            <p>
              Please call us on <strong>{telephone.sales}</strong> and provide the following reference:{" "}
              <i>{webReference}</i> and we will be able to complete your policy purchase over the phone.
            </p>
          ) : (
            <p>
              Please call us on <strong>{telephone.sales}</strong> and we will be able to complete your policy purchase
              over the phone.
            </p>
          )}

          <CallUs telephone={telephone.sales} text="Need help? Our advisors are here for you." />

          <OpeningTimes openingTimes={openingTimes} />

          <Button
            ariaLabel="Contact us"
            href="https://www.devittinsurance.com/contact-us/"
            label="Contact us"
            style={ButtonStyle.SUCCESS}
            target={webReference ? ButtonTarget.BLANK : ButtonTarget.SELF}
            type={ButtonType.LINK}
          />

          {token ? (
            <Button
              ariaLabel="Retry"
              href={`${ROUTE.AGGREGATOR}?token=${encodeURIComponent(token)}`}
              label="Retry"
              style={ButtonStyle.PRIMARY}
              target={ButtonTarget.SELF}
              type={ButtonType.LINK}
            />
          ) : (
            <Button
              ariaLabel="Request a callback"
              href="https://www.devittinsurance.com/contact-us/request-callback/"
              label="Request a callback"
              style={ButtonStyle.PRIMARY}
              target={webReference ? ButtonTarget.BLANK : ButtonTarget.SELF}
              type={ButtonType.LINK}
            />
          )}
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(MessageCardPayment);
