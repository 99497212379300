import React, { useMemo } from "react";

import { Article } from "modules/articles/types";
import { truncate } from "modules/utils";

type ItemProps = Article;

const Item: React.FunctionComponent<ItemProps> = ({ excerpt, title, qbExcerpt, uri }) => {
  const text = useMemo(() => (qbExcerpt ? qbExcerpt : truncate(excerpt, 50)), [excerpt, qbExcerpt]);
  return (
    <a href={uri} onMouseDown={event => event.preventDefault()} rel="noopener noreferrer" target="_blank">
      <div>
        <div></div>
      </div>
      <div>
        <h4>{title}</h4>
        {!!text && <p>{text}</p>}
      </div>
    </a>
  );
};

export default React.memo(Item);
