import React from "react";
import { useSelector } from "modules/redux/store";

import { MetadataType } from "modules/metadata/constants";
import { FormProps } from "components/Form/Wrapper";
import { Metadata } from "modules/metadata/types";
import * as Question from "components/Questions";
import { Conviction } from "modules/risk/types";

type QuestionsProps = FormProps<Conviction>;

const Questions: React.FunctionComponent<QuestionsProps> = ({
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  const metadata: Metadata = useSelector(state => state.metadata);
  return (
    <React.Fragment>
      <Question.QuestionDate
        autoFocus={true}
        error={errors.date}
        format={Question.QuestionDateFormat.DATE}
        help="This is the date of conviction as it appears on the rider's driving licence."
        label="Date of conviction"
        name="date"
        readOnly={isSubmitting}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched.date}
        value={values.date}
      />

      <Question.QuestionSelect
        error={errors.code}
        help="This is the conviction code as it appears on the rider's driving licence, e.g. SP30."
        label="Select conviction code"
        name="code"
        onBlur={handleBlur}
        onChange={handleChange}
        options={metadata[MetadataType.CONVICTION]}
        readOnly={isSubmitting}
        touched={touched.code}
        value={values.code}
      />

      <Question.QuestionSecondary
        name="drinkReading"
        open={["DR10", "DR20", "DR40", "DR50"].includes(values.code)}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.drinkReading}>
        <Question.QuestionRadio
          format={Question.QuestionRadioFormat.HORIZONTAL}
          error={errors.drinkReading}
          label="Type of alcohol reading taken"
          name="drinkReading"
          options={metadata[MetadataType.DRINK_READING_TYPE]}
          readOnly={isSubmitting}
          secondary={true}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.drinkReading}
          value={values.drinkReading}
        />
      </Question.QuestionSecondary>

      <Question.QuestionSecondary
        name="drinkReadingLevel"
        open={["DR10", "DR20", "DR40", "DR50"].includes(values.code)}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.drinkReadingLevel}>
        <Question.QuestionNumber
          error={errors.drinkReadingLevel}
          format={Question.QuestionNumberFormat.INTEGER}
          help="<p>You will need to enter the level of alcohol reading, as a quotation cannot be provided unless this field is completed correctly. For information, the LEGAL alcohol limits are as follows:</p>
                <ul>
                <li>35 microgrammes of alcohol per 100 millilitres of BREATH</li>
                <li>80 milligrammes of alcohol per 100 millilitres of BLOOD</li>
                <li>107 milligrammes of alcohol per 100 millilitres of URINE</li>
                </ul>"
          label="Level of reading taken"
          maxLength={3}
          name="drinkReadingLevel"
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={isSubmitting}
          secondary={true}
          touched={touched.drinkReadingLevel}
          value={values.drinkReadingLevel}
        />
      </Question.QuestionSecondary>

      <Question.QuestionNumber
        error={errors.points}
        format={Question.QuestionNumberFormat.INTEGER}
        label="Number of penalty points"
        maxLength={2}
        name="points"
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={isSubmitting}
        touched={touched.points}
        value={values.points}
      />

      <Question.QuestionNumber
        error={errors.fine}
        format={Question.QuestionNumberFormat.CURRENCY}
        label="Amount fined"
        maxLength={4}
        name="fine"
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={isSubmitting}
        touched={touched.fine}
        value={values.fine}
      />

      <Question.QuestionSwitch
        error={errors.disqualified}
        label="Was the rider banned?"
        name="disqualified"
        readOnly={isSubmitting}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched.disqualified}
        value={values.disqualified}
      />

      <Question.QuestionSecondary
        name="disqualification"
        open={values.disqualified === true}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.disqualification}>
        <Question.QuestionNumber
          error={errors.disqualification}
          format={Question.QuestionNumberFormat.INTEGER}
          label="Enter the number of months the ban lasted.<br/><small>If the ban was up to or less than one month, please enter '1'</small>"
          maxLength={2}
          name="disqualification"
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={isSubmitting}
          secondary={true}
          touched={touched.disqualification}
          value={values.disqualification}
        />
      </Question.QuestionSecondary>
    </React.Fragment>
  );
};

export default Questions;
