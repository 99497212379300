/* eslint-disable no-template-curly-in-string */

enum Messages {
  ABI = "Please find your motorcycle before proceeding.",

  ACCESSORIES_VALUE = "Please enter a value between £0 and £999999.",

  ACCOUNT = "Please enter a valid account number.",

  ACCOUNT_INVALID = "Invalid bank account.",

  ACCOUNT_REMOTE = "Please enter a valid account number and sort code.",

  ADDRESS_SEARCH = "Please find your address before proceeding.",

  BANNED = "Please enter a number between 1 and 99.",

  CC = "Please enter a number between 0 and 9999.",

  CLAIM = "Please enter a value between 0 and 999999.",

  CLAIM_DATE = "All claims must be less than 5 years old.",

  CLAIM_REQUIRED = "Please complete this claim.",

  CONVICTION_DATE = "All convictions must be less than 5 years old.",

  CONVICTION_REQUIRED = "Please complete this conviction.",

  DATE = "Please enter a valid date.",

  DATE_FUTURE = "Cannot be a date in the future.",

  DATE_OF_BIRTH = "${you} must be between 16 and 85 years old.",

  EMAIL = "Please enter a valid email address.",

  FINE = "Please enter a value between 0 and 9999.",

  GENDER = "Gender does not match the title given.",

  INCEPTION_DATE_BEFORE = "Sorry, we cannot quote or cover dates in the past.",

  INCEPTION_DATE_AFTER = "Sorry, we cannot quote more than 30 days into the future.",

  LICENCE_DATE = "The date must be between ${date1} and ${date2}.",

  LICENCE_YEARS = "${you1} must have been ${age} or over when ${you2} obtained this licence.",

  LIST = "Please select an item from the list.",

  MAKE = "Please select a make from the list.",

  MAX_LENGTH = "The maximum length the answer can be is ${limit}.",

  MILEAGE = "Please enter a number between 100 and 999999.",

  MODIFICATIONS = "No problem, please call us to discuss your modifications so we can make sure they are properly covered by your policy.",

  NOT_TYPE = "Please enter a valid ${type}.",

  ONE_OF = "Please complete this question.",

  OPTION = "Please select an option.",

  POINTS = "Please enter a number between 0 and 12.",

  POSTCODE = "Please enter a valid postcode.",

  PURCHASED_DATE = "The date that the motorcycle was purchased must not be after the inception date.",

  READING_LEVEL = "Please enter a number between 0 and 999.",

  RELATIONSHIP_RULES_SPOUSE = "If the relationship to the policyholder is Spouse, then both must be Married.",

  RELATIONSHIP_RULES_CIVIL_PARTNER = "If the relationship to the policyholder is Civil Partner, then both must be Civil Partnered.",

  RELATIONSHIP_RULES_MULTIPLE = "Another person has a relationship to the policyholder of Spouse or Civil Partner. Only one person can have this status.",

  REQUIRED = "Please complete this question.",

  RESIDENT_SINCE = "${you} cannot have been a resident before ${your} date of birth.",

  SECTION_REQUIRED = "Please complete this section.",

  SORT_CODE = "Please enter a valid sort code.",

  TELEPHONE = "Please enter a valid telephone number.",

  TERMS = "Please confirm you have read the aforementioned documents.",

  VEHICLE_VALUE = "Sorry, we cannot quote for motorcycles worth less than £50 or more than £999999.",

  VRM = "Please enter a valid registration.",

  VRM_NOT_FOUND = "Sorry, we could not find a motorcycle matching this registration number.",

  VRM_Q_PLATE = "Q Plates are not accepted.",

  VRM_SEARCH = "Please find your motorcycle before proceeding.",

  YEAR_OF_MANUFACTURE = "This is not a recognised year of manufacture.",

  YEAR_OF_MANUFACTURE_PURCHASED = "The motorcycle must be purchased after it was manufactured.",
}

/* eslint-enable no-template-curly-in-string */

export default Messages;
