import React, { useMemo, useState } from "react";
import clsx from "clsx";

import { ReactComponent as ModalArrowSVG } from "modules/theme/icons/header/modal-arrow.svg";
import { ReactComponent as ChevronSVG } from "modules/theme/icons/header/chevron-down.svg";
import { ReactComponent as ClockSVG } from "modules/theme/icons/header/clock.svg";
import { getTimes, getToday } from "modules/app/helpers";
import { App } from "modules/app/types";
import Button from "components/Button";

import styles from "./styles.module.scss";

type HeaderTimesProps = {
  openingTimes: App["openingTimes"];
};

const HeaderTimes: React.FunctionComponent<HeaderTimesProps> = ({ openingTimes }) => {
  const [open, setOpen] = useState(false);
  const times = useMemo(() => getTimes(openingTimes), [openingTimes]);
  const today = useMemo(() => getToday(openingTimes), [openingTimes]);
  const className = clsx(styles["header-times"], open && styles["show-times"]);
  return (
    <div className={className}>
      <div>
        <ClockSVG />
        <span>Lines open today:</span>
        <Button
          ariaLabel="View opening times"
          label={today.opens && today.closes ? `${today.opens} - ${today.closes}` : "Closed"}
          onClick={() => setOpen(!open)}>
          <ChevronSVG />
          <span>
            <ModalArrowSVG />
          </span>
        </Button>
      </div>
      {times.length > 0 && (
        <ul>
          {times.map((time, index) => (
            <li key={index}>
              <span>{time.title}</span>
              {time.opens && time.closes ? (
                <React.Fragment>
                  <span>{time.opens}</span>
                  <span> - </span>
                  <span>{time.closes}</span>
                </React.Fragment>
              ) : (
                <span>Closed</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default React.memo(HeaderTimes);
