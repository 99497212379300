import React from "react";

import { Confirmation } from "modules/confirmation/types";

import styles from "./styles.module.scss";

type ConfirmationMessageProps = {
  confirmation: Confirmation;
};

const ConfirmationMessage: React.FunctionComponent<ConfirmationMessageProps> = ({ confirmation }) => {
  return (
    <section className={styles["confirmation-message"]}>
      <div>
        <h2>We've Got You Covered</h2>
        <p>Thank you for arranging insurance through our website. Your documentation will be sent to you shortly.</p>
      </div>
      {!!confirmation.webReference && (
        <div>
          <p>
            Your policy is in effect from <span>{confirmation.time}</span> on <span>{confirmation.date}</span>
          </p>
          <p>
            Your motorcycle insurance policy is underwritten by <span>{confirmation.insurer}</span>
          </p>
          <p>
            Your web reference is <span>{confirmation.webReference}</span>
          </p>
        </div>
      )}
    </section>
  );
};

export default React.memo(ConfirmationMessage);
