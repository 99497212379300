import React, { useCallback } from "react";
import { FormikProps } from "formik";
import clsx from "clsx";

import { ReactComponent as CheckboxSVG } from "modules/theme/icons/form/inputs/checkbox-tick.svg";
import { useScrollRef } from "modules/scroll/hooks";
import Button from "components/Button";

import styles from "./styles.module.scss";

export type CheckboxProps = {
  disabled?: boolean;
  id: string;
  invalid?: boolean;
  name: string;
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  tabIndex?: number;
  value: "" | boolean;
};

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  disabled,
  id,
  invalid,
  name,
  readOnly,
  setFieldTouched,
  setFieldValue,
  tabIndex,
  value,
}) => {
  const scrollRef = useScrollRef(id);
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      const currentTarget = event.currentTarget;
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement) && document.body.contains(currentTarget)) {
          setFieldTouched(name, true);
        }
      });
    },
    [name, setFieldTouched],
  );
  const handleClick = useCallback(() => {
    setFieldValue(name, value ? false : true);
    setTimeout(() => setFieldTouched(name, true));
  }, [name, setFieldTouched, setFieldValue, value]);
  const className = clsx(
    styles["checkbox"],
    disabled && styles["disabled"],
    !disabled && invalid && styles["invalid"],
    readOnly && styles["read-only"],
    value && styles["checked"],
  );
  return (
    <div className={className} onBlur={handleBlur} ref={!disabled && invalid ? scrollRef : undefined}>
      <Button ariaLabel="Checkbox" onClick={handleClick} tabIndex={tabIndex}>
        <CheckboxSVG />
      </Button>
    </div>
  );
};

export default React.memo(Checkbox);
