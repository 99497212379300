import moment from "moment";

import { getMetadataLabel } from "modules/metadata/helpers";
import { MetadataType } from "modules/metadata/constants";
import { Metadata } from "modules/metadata/types";
import { Risk } from "modules/risk/types";

export const formatRisk = (metadata: Metadata, risk: Risk) => {
  const title =
    risk.proposer.name.title === "Other"
      ? getMetadataLabel(metadata[MetadataType.TITLE_OTHER], risk.proposer.name.titleOther)
      : getMetadataLabel(metadata[MetadataType.TITLE], risk.proposer.name.title);

  const name = `${title ? `${title} ` : ""}${risk.proposer.name.forenames} ${risk.proposer.name.surname}`;

  const dateOfBirth = risk.proposer.dateOfBirth ? moment().diff(risk.proposer.dateOfBirth, "years") : "";

  const fullTimeOccupation = getMetadataLabel(
    metadata[MetadataType.OCCUPATION_TYPES],
    risk.proposer.fullTimeOccupation.type,
  );

  const maritalStatus = getMetadataLabel(metadata[MetadataType.MARITAL_STATUS], risk.proposer.maritalStatus);

  const licenceType = getMetadataLabel(metadata[MetadataType.LICENCE_TYPES], risk.proposer.bikeLicence.type);

  const licenceYears = getMetadataLabel(metadata[MetadataType.LICENCE_HELD_YEARS], risk.proposer.bikeLicence.years);

  const licence = licenceYears ? `${licenceType} licence held for ${licenceYears.toLowerCase()}` : licenceType;

  const immob = getMetadataLabel(metadata[MetadataType.IMMOBILISER], risk.vehicle.immob);

  const tracker = getMetadataLabel(metadata[MetadataType.TRACKER], risk.vehicle.tracker);

  const alarm = getMetadataLabel(metadata[MetadataType.ALARM], risk.vehicle.alarm);

  const cover = getMetadataLabel(metadata[MetadataType.COVER_TYPE], risk.vehicle.cover);

  const inceptionDate = risk.policy.inceptionDate ? moment(risk.policy.inceptionDate).format("Do MMMM YYYY") : "";

  const classOfUse = getMetadataLabel(metadata[MetadataType.CLASS_OF_USE], risk.vehicle.classOfUse);

  const includesPillion =
    risk.vehicle.includesPillion === true ? "Yes" : risk.vehicle.includesPillion === false ? "No" : "";

  const whereKept = getMetadataLabel(metadata[MetadataType.WHERE_KEPT], risk.vehicle.whereKept);

  const previousInsuranceNCBYears = getMetadataLabel(
    metadata[MetadataType.NCD_YEARS],
    risk.policy.previousInsuranceNCBYears,
  );

  const protectedBonus = risk.policy.protectedBonus === true ? "Yes" : risk.policy.protectedBonus === false ? "No" : "";

  return {
    name,
    dateOfBirth,
    fullTimeOccupation,
    maritalStatus,
    licence,
    immob,
    tracker,
    alarm,
    cover,
    inceptionDate,
    classOfUse,
    includesPillion,
    whereKept,
    previousInsuranceNCBYears,
    protectedBonus,
  };
};
