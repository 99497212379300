import { useCallback, useEffect, useRef } from "react";

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

type UseHandleChangeProps = (props: {
  onChange: (event: Event) => void;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => (event: Event) => void;

const useHandleChange: UseHandleChangeProps = ({ onChange, setApiError }) => {
  const handleChangeRef = useRef<ReturnType<UseHandleChangeProps>>(() => void 0);
  const handleChange = useCallback<(event: Event) => void>(event => handleChangeRef.current(event), []);

  useEffect(() => {
    handleChangeRef.current = event => {
      setApiError(undefined);
      onChange(event);
    };
  }, [onChange, setApiError]);

  return handleChange;
};

export default useHandleChange;
