import { quoteActionDestroy } from "modules/quote/actions";
import { riskActionDestroy } from "modules/risk/actions";
import { appActionError } from "modules/app/actions";
import { ROUTE } from "modules/router/constants";
import { Dispatch } from "modules/redux/store";

export const sessionDestroy = () => {
  return (dispatch: Dispatch): void => {
    dispatch(appActionError({ overwrite: true, route: ROUTE.MESSAGE_SESSION_TIMEOUT, webReference: undefined }));
    dispatch(quoteActionDestroy());
    dispatch(riskActionDestroy());
  };
};
