import { useCallback } from "react";

import { quoteActionAddOptionalExtra } from "modules/quote/actions";
import { scrollTop } from "modules/scroll/helpers";
import { UseHandleDevittEnhanced } from "./types";

const useHandleDevittEnhanced: UseHandleDevittEnhanced = ({ dispatch, optionEnhanced }) =>
  useCallback(async () => {
    await scrollTop();
    !!optionEnhanced && !optionEnhanced.selected && dispatch(quoteActionAddOptionalExtra(optionEnhanced.option));
  }, [dispatch, optionEnhanced]);

export default useHandleDevittEnhanced;
