const getFuelType = (fuelType: string) => {
  switch (fuelType) {
    case "D":
      return "Diesel";
    case "E":
      return "Electric";
    case "P":
      return "Petrol";
    default:
      return "Unknown";
  }
};

export default getFuelType;
