import React from "react";

import { Quote } from "modules/quote/types";
import Item from "./Item";

import styles from "./styles.module.scss";

type PolicyDocumentsProps = {
  quote: Quote;
};

const PolicyDocuments: React.FunctionComponent<PolicyDocumentsProps> = ({ quote }) => {
  return (
    <div className={styles["policy-documents"]}>
      <header>
        <h2>Policy Documents</h2>
      </header>
      <section>
        <p>
          Please review the documents below and ensure you're happy with the cover described in the documents before
          purchasing the policy.
        </p>

        <ul>
          {!!quote.precis?.policyWordingLink && (
            <Item
              label="Policy Wording"
              link={quote.precis.policyWordingLink}
              type="link"
              webReference={quote.webReference}
            />
          )}

          {!!quote.productIpid && (
            <Item label="Product Information" link={quote.productIpid} type="link" webReference={quote.webReference} />
          )}

          <Item label="Get Adobe Acrobat Reader" link="https://get.adobe.com/uk/reader/" type="adobe" />
        </ul>
      </section>
    </div>
  );
};

export default React.memo(PolicyDocuments);
