import { useEffect } from "react";

import { aggregatorActionRequest } from "modules/aggregator/actions";
import { UseRequest } from "./types";

const useRequest: UseRequest = ({ dispatch, token }) =>
  useEffect(() => {
    !!token && dispatch(aggregatorActionRequest({ token }));
  }, [dispatch, token]);

export default useRequest;
