import { useCallback } from "react";

import { ROUTE, ROUTE_ACTION } from "modules/router/constants";
import { scrollTop } from "modules/scroll/helpers";
import { UseHandleEdit } from "./types";

const useHandleEdit: UseHandleEdit = navigate =>
  useCallback(
    async (id: string) => {
      await scrollTop();
      navigate(id === "mainVehicle" ? `${ROUTE.MOTORCYCLE}?action=${ROUTE_ACTION.EDIT}` : `${ROUTE.MOTORCYCLES}/${id}`);
    },
    [navigate],
  );

export default useHandleEdit;
