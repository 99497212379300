import React, { useCallback } from "react";
import clsx from "clsx";

import { ReactComponent as CrossSVG } from "modules/theme/icons/product/cross.svg";
import { ReactComponent as InfoSVG } from "modules/theme/icons/product/info.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/product/tick.svg";
import { PaymentMethod } from "modules/risk/constants";
import { OptionalExtra } from "modules/quote/types";
import { usePrice } from "../Option/hooks";
import Button from "components/Button";

import styles from "../Option/styles.module.scss";

type BreakdownPlusProps = {
  label: string;
  onAdd: (option: OptionalExtra) => void;
  onShowModal?: (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  option: OptionalExtra;
  optionPlus: OptionalExtra;
  paymentMethod: PaymentMethod;
  selected: boolean;
};

const BreakdownPlus: React.FunctionComponent<BreakdownPlusProps> = ({
  label,
  onAdd,
  onShowModal,
  option,
  optionPlus,
  paymentMethod,
  selected,
}) => {
  const { price, priceLabel } = usePrice(optionPlus.grossPremium - option.grossPremium, paymentMethod, false);

  const onClick = useCallback(
    () => (selected ? onAdd(option) : onAdd(optionPlus)),
    [onAdd, option, optionPlus, selected],
  );

  const className = clsx(styles["option"], selected && styles["selected"]);
  return (
    <li className={className}>
      <Button ariaLabel={label} label={label} onClick={onShowModal}>
        <InfoSVG />
      </Button>
      <Button ariaLabel={priceLabel} onClick={onClick} tabIndex={0}>
        <span>
          <span>
            <CrossSVG />
            <TickSVG />
          </span>
        </span>
        <span>{price}</span>
      </Button>
    </li>
  );
};

export default React.memo(BreakdownPlus);
