import React, { useMemo } from "react";

import { getTimes } from "modules/app/helpers";
import { App } from "modules/app/types";

type OpeningTimesProps = {
  openingTimes: App["openingTimes"];
};

const OpeningTimes: React.FunctionComponent<OpeningTimesProps> = ({ openingTimes }) => {
  const times = useMemo(() => getTimes(openingTimes), [openingTimes]);
  return (
    <React.Fragment>
      <h4>Our line opening times:</h4>
      {times.length > 0 && (
        <ul className="times">
          {times.map((time, index) => (
            <li key={index}>
              <span>{time.title}</span>
              {time.opens && time.closes ? (
                <React.Fragment>
                  <span>{time.opens}</span>
                  <span> - </span>
                  <span>{time.closes}</span>
                </React.Fragment>
              ) : (
                <span>Closed</span>
              )}
            </li>
          ))}
        </ul>
      )}
      <small>*Calls may be recorded for training purposes</small>
    </React.Fragment>
  );
};

export default React.memo(OpeningTimes);
