import React from "react";

import { ReactComponent as LicenseSVG } from "modules/theme/icons/product/your-riders/license.svg";
import { ReactComponent as RiderSVG } from "modules/theme/icons/product/your-riders/rider.svg";
import { ReactComponent as EditSVG } from "modules/theme/icons/buttons/edit.svg";
import Button, { ButtonStyle } from "components/Button";

export type ItemProps = {
  age: number | string;
  count: number;
  forenames: string;
  licenceType: string;
  licenceYears: string;
  maritalStatus: string;
  occupation: string;
  onClick: () => void;
  surname: string;
  title?: string;
};

const titles = ["Main", "Second", "Third", "Fourth", "Fifth"];

const Item: React.FunctionComponent<ItemProps> = ({
  age,
  count,
  forenames,
  licenceType,
  licenceYears,
  maritalStatus,
  occupation,
  onClick,
  surname,
  title,
}) => {
  return (
    <div>
      <header>
        <div>
          <h3>{titles[count] ? `${titles[count]} ` : ""}Rider</h3>
        </div>
        <Button ariaLabel="Edit" label="Edit" onClick={onClick} style={ButtonStyle.PRIMARY_CLEAR}>
          <EditSVG />
        </Button>
      </header>
      <table>
        <tbody>
          <tr>
            <td>
              <RiderSVG />
            </td>
            <td>
              {`${title ? `${title} ` : ""}${forenames} ${surname}`}
              {age ? `, ${age}` : ""}
              {occupation ? `, ${occupation}` : ""}
              {maritalStatus ? `, ${maritalStatus}` : ""}
            </td>
          </tr>
          {licenceType ? (
            <tr>
              <td>
                <LicenseSVG />
              </td>
              <td>{licenceYears ? `${licenceType} licence held for ${licenceYears.toLowerCase()}` : licenceType}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Item);
