import React from "react";
import { useSelector } from "modules/redux/store";

import { MetadataType } from "modules/metadata/constants";
import { FormProps } from "components/Form/Wrapper";
import { Metadata } from "modules/metadata/types";
import * as Question from "components/Questions";
import { Claim } from "modules/risk/types";

type QuestionsProps = FormProps<Claim>;

const Questions: React.FunctionComponent<QuestionsProps> = ({
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  const metadata: Metadata = useSelector(state => state.metadata);
  return (
    <React.Fragment>
      <Question.QuestionDate
        autoFocus={true}
        error={errors.date}
        format={Question.QuestionDateFormat.DATE}
        label="Date of the incident"
        name="date"
        readOnly={isSubmitting}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched.date}
        value={values.date}
      />

      <Question.QuestionSelect
        error={errors.code}
        label="Select the cause or description"
        name="code"
        onBlur={handleBlur}
        onChange={handleChange}
        options={metadata[MetadataType.MOTOR_CLAIM]}
        readOnly={isSubmitting}
        touched={touched.code}
        value={values.code}
      />

      <Question.QuestionNumber
        error={errors.cost}
        help="Please enter the cost of the repairs to your motorcycle. If you are unsure of this, please provide an estimate. If there were none, please enter 0."
        format={Question.QuestionNumberFormat.CURRENCY}
        label="Cost of repairs to the vehicle being driven<br/><small>(please estimate if you are not sure of the actual cost)</small>"
        maxLength={6}
        name="cost"
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={isSubmitting}
        touched={touched.cost}
        value={values.cost}
      />

      <Question.QuestionNumber
        error={errors.thirdPartyCost}
        format={Question.QuestionNumberFormat.CURRENCY}
        label="Cost of any payment(s) made by the insurer to a third party including any personal injury claim(s)"
        maxLength={6}
        name="thirdPartyCost"
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={isSubmitting}
        touched={touched.thirdPartyCost}
        value={values.thirdPartyCost}
      />

      <Question.QuestionSwitch
        error={errors.personalInjury}
        label="Was there any personal injury claim?"
        name="personalInjury"
        readOnly={isSubmitting}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched.personalInjury}
        value={values.personalInjury}
      />

      <Question.QuestionSwitch
        error={errors.fault}
        help="<p>We need to know how the claim was settled i.e. if the claim affected your No Claim Discount (NCD).</p><p>If the Insurer made a payment, either in respect of damage to the vehicle or in respect of any claim by another person and this was NOT recovered IN FULL from the other party, please answer Yes.</p><p>If the claim is still pending, please also answer Yes. If it was a non-fault accident and claims were recovered IN FULL from the other party, please answer No.</p><p>If the NCD was protected but the NCD would otherwise have been lost or reduced, you should answer Yes.</p>"
        label="Was the No Claim Discount (NCD) affected or would it have been affected had your maximum discount not been protected?"
        name="fault"
        readOnly={isSubmitting}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched.fault}
        value={values.fault}
      />
    </React.Fragment>
  );
};

export default Questions;
