import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "24/7 claim line",
  text: (
    <React.Fragment>
      <p>We're always here when you need us most. Claim service 24/7/365.</p>
      <p>We don't leave you hanging around on the phone waiting for us to pick up.</p>
    </React.Fragment>
  ),
};

export default template;
