import { produce } from "immer";

import { Risk, RiskActionSetParams } from "../../types";

const setParams = (state: Risk, action: RiskActionSetParams): Risk => {
  return produce(state, (draftState: Risk) => {
    /* Affinity */
    !!action.affinity && (draftState.policy.affinity = action.affinity);

    /* Direct */
    if (action.affinity && ["DTES", "DIRI"].includes(action.affinity)) {
      if (action.policyuser1 === "RECOMM" && action.policyuser16) {
        draftState.additionalQuestions.whereHear = "RECOMM";
        draftState.additionalQuestions.campaignCode = action.policyuser16;
      }
      if (action.subagent) {
        draftState.policy.subAgent = action.subagent;
      }
    }
  });
};

export default setParams;
