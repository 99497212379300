import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "RAC Breakdown",
  text: (
    <React.Fragment>
      <h2>Roadside</h2>

      <p>
        Roadside cover provides you with breakdown assistance if you break down more than ¼ of a mile away from your
        home, along with a taxi fare for up to 20 miles if your bike can't be fixed immediately as well as a tow for up
        to 10 miles.
      </p>

      <h2>Recovery</h2>

      <p>
        Recovery cover gives you everything that Roadside cover does - breakdown assistance if you break down ¼ mile or
        more from home, taxi fare for 20 miles, a tow for 10 miles plus recovery for up to 8 people (including you) and
        your bike to any single spot in the UK.
      </p>

      <h2>At Home</h2>

      <p>
        Cover away from the home is all well and good, but if you turn the key first thing in the morning and your bike
        won't start, you're a bit stuck.
      </p>

      <p>
        If you rely on your bike to get to work, it's a good idea to choose At Home cover, which includes assistance if
        you're at home or less than ¼ of a mile away from home, as well as taxi fare for 20 miles and a tow for 10 miles
        (you do get cover if you're more than ¼ of a mile away from home, too).
      </p>

      <h2>Onward Travel plus EU cover</h2>

      <p>
        This option is the most comprehensive cover and is definitely the best choice if you travel long distances on
        your bike regularly.
      </p>

      <p>
        Onward Travel plus EU provides you with breakdown assistance if you're at home or more than ¼ of a mile away
        from home, as well as a replacement bike for up to 2 days whilst your bike is being repaired.
      </p>

      <p>
        If they cannot repair the bike, they can also provide an alternative form of transport, or accommodation if
        you're stuck. If you break down and you're on your way to a lovely warm location somewhere abroad, or while
        you're actually abroad, attempts will be made to fix your bike at the roadside but if that's not possible, it'll
        be taken to a local garage and will be fixed there. Again, if this isn't possible, alternative transport or
        overnight accommodation can be arranged.
      </p>
    </React.Fragment>
  ),
};

export default template;
