import { useCallback, useEffect, useRef } from "react";
import { ApiError } from "modules/api";

type UseHandleErrorProps = (props: {
  isMainVehicle: boolean;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOpen: (state: boolean) => void;
}) => (error: Error) => void;

const useHandleError: UseHandleErrorProps = ({ isMainVehicle, setApiError, setOpen }) => {
  const handleErrorRef = useRef<ReturnType<UseHandleErrorProps>>(() => void 0);
  const handleError = useCallback<(error: Error) => void>(error => handleErrorRef.current(error), []);

  useEffect(() => {
    handleErrorRef.current = (error: Error) => {
      error.message === ApiError.VRM_NOT_FOUND && isMainVehicle && setOpen(true);
      setApiError(error.message);
    };
  }, [isMainVehicle, setApiError, setOpen]);

  return handleError;
};

export default useHandleError;
