import { useCallback, useEffect, useRef, useState } from "react";

export const useHeight = () => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLElement>(null);

  const handleResize = useCallback(() => {
    ref.current && setHeight(ref.current.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { height, ref };
};

export const useSticky = () => {
  const [isSticky, setSticky] = useState(false);
  const [offset, setOffset] = useState(20);
  const ref = useRef<HTMLElement>(null);

  const handleResize = useCallback(() => {
    setOffset(
      window.matchMedia("(max-width: 1024px)").matches ? 8 : window.matchMedia("(max-width: 1240px)").matches ? 16 : 30,
    );
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleScroll = useCallback(() => {
    ref.current && setSticky(ref.current.getBoundingClientRect().top <= offset);
  }, [offset]);

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, ref };
};
