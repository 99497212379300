import { produce } from "immer";

import { Risk, RiskActionUpdateAdditionalRider } from "../../types";

const updateAdditionalRider = (state: Risk, action: RiskActionUpdateAdditionalRider): Risk => {
  return produce(state, (draftState: Risk) => {
    const index = draftState.additionalRiders.findIndex(item => item.id === action.additionalRider.id);

    if (action.additionalRider.isMainRider) {
      draftState.proposer.isMainRider = false;
      draftState.additionalRiders.forEach(item => {
        if (item.id !== action.additionalRider.id) {
          item.isMainRider = false;
        }
      });
    }

    draftState.additionalRiders[index] = action.additionalRider;
  });
};

export default updateAdditionalRider;
