import React from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as BikeSVG } from "modules/theme/icons/progress-bar/bike.svg";
import { matchPaths } from "modules/router/utils";
import { ROUTE } from "modules/router/constants";
import Item from "./Item";

import styles from "./styles.module.scss";

type ProgressProps = {
  hasAdditionalRiders: "" | boolean;
  hasAdditionalVehicles: "" | boolean;
  readOnly?: boolean;
};

const Progress: React.FunctionComponent<ProgressProps> = ({ hasAdditionalRiders, hasAdditionalVehicles, readOnly }) => {
  const location = useLocation();
  const sections = [
    {
      isAvailable: !!matchPaths([ROUTE.RIDER, ROUTE.RIDERS, `${ROUTE.RIDERS}/:id`], location.pathname),
      isActive: !!matchPaths([ROUTE.MOTORCYCLE, ROUTE.MOTORCYCLES, `${ROUTE.MOTORCYCLES}/:id`], location.pathname),
      label: process.env.REACT_APP_MULTIBIKE === "true" && hasAdditionalVehicles ? "Motorcycle(s)" : "Motorcycle",
      path: ROUTE.MOTORCYCLE,
    },
    {
      isAvailable: false,
      isActive: !!matchPaths([ROUTE.RIDER, ROUTE.RIDERS, `${ROUTE.RIDERS}/:id`], location.pathname),
      label: hasAdditionalRiders ? "Rider(s)" : "Rider",
      path: ROUTE.RIDER,
    },
  ];
  return (
    <div className={styles["progress"]}>
      {sections.map(section => (
        <Item
          isActive={section.isActive}
          isAvailable={section.isAvailable}
          key={section.path}
          label={section.label}
          path={section.path}
          readOnly={readOnly}
        />
      ))}
      <Item isActive={false} isAvailable={false} label="Quote" path={ROUTE.QUOTE}>
        <BikeSVG />
      </Item>
    </div>
  );
};

export default React.memo(Progress);
