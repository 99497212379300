import { useEffect, useState } from "react";

import { LiveChatAvailability, LiveChatVisibility } from "../types";
import { API_LICENSE, API_URL } from "../constants";
import useHandleClick from "./handleClick";
import { loadLiveChat } from "../helpers";
import useHandleLoad from "./handleLoad";
import { UseLiveChat } from "../types";

export const useLiveChat: UseLiveChat = () => {
  const [active, setActive] = useState(false);
  const [visibility, setVisibility] = useState<LiveChatVisibility>("minimized");
  const [availability, setAvailability] = useState<LiveChatAvailability>("offline");

  const handleLoad = useHandleLoad({ setActive, setAvailability, setVisibility });

  const handleClick = useHandleClick(visibility);

  useEffect(() => {
    if (API_LICENSE) {
      window.__lc = window.__lc || {};
      window.__lc.license = API_LICENSE;
      loadLiveChat(handleLoad);
      return () => {
        window.LiveChatWidget && window.LiveChatWidget.call("destroy");
        const script = document.querySelector(`script[src="${API_URL}"]`);
        script && script.parentNode && script.parentNode.removeChild(script);
      };
    } else {
      process.env.NODE_ENV === "development" && setActive(true);
    }
  }, [handleLoad]);

  return { active, availability, handleClick, visibility };
};
