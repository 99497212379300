import { v4 as uuidv4 } from "uuid";
import { produce } from "immer";

import { Risk, RiskActionNewAdditionalRider } from "../../types";
import { RiskMaxAdditional } from "../../constants";

const newAdditionalRider = (state: Risk, action: RiskActionNewAdditionalRider): Risk => {
  return produce(state, (draftState: Risk) => {
    if (action.additionalRider.isMainRider) {
      draftState.proposer.isMainRider = false;
      draftState.additionalRiders.forEach(item => (item.isMainRider = false));
    }

    action.additionalRider.id = uuidv4();

    draftState.additionalRiders.length < RiskMaxAdditional.RIDERS &&
      draftState.additionalRiders.push(action.additionalRider);
  });
};

export default newAdditionalRider;
