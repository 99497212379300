import { useMemo } from "react";

import { RiskMaxAdditional } from "modules/risk/constants";
import { UseIndex } from "./types";

const useIndex: UseIndex = ({ additionalRiders, id }) =>
  useMemo(
    () =>
      id === "new"
        ? additionalRiders.length < RiskMaxAdditional.RIDERS
          ? additionalRiders.length
          : -1
        : additionalRiders.findIndex(item => item.id === id),
    [additionalRiders, id],
  );

export default useIndex;
