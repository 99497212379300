import { useCallback } from "react";

import { ROUTE, ROUTE_ACTION } from "modules/router/constants";
import { riskActionUpdateRider } from "modules/risk/actions";
import { scrollTop } from "modules/scroll/helpers";
import { Risk } from "modules/risk/types";
import { UseHandleBack } from "./types";

const useHandleBack: UseHandleBack = ({ dispatch, hasAdditionalVehicles, navigate, query }) => {
  const handleBack = useCallback(
    async (values: Risk) => {
      await scrollTop();
      dispatch(riskActionUpdateRider(values));
      navigate(
        process.env.REACT_APP_MULTIBIKE === "true" && !!hasAdditionalVehicles ? ROUTE.MOTORCYCLES : ROUTE.MOTORCYCLE,
      );
    },
    [dispatch, hasAdditionalVehicles, navigate],
  );
  return query.get("action") !== ROUTE_ACTION.EDIT ? handleBack : undefined;
};

export default useHandleBack;
