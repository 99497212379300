import React, { PropsWithChildren } from "react";
import { TransitionProps } from "@mui/material/transitions";
import MuiDialog from "@mui/material/Dialog";
import useStyles from "./hooks";

type ModalOverlayProps = PropsWithChildren<{
  disableRestoreFocus?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  onExited?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  open: boolean;
  paper?: boolean;
  scroll?: "body" | "paper";
  TransitionComponent?: React.ForwardRefExoticComponent<
    TransitionProps & {
      children: JSX.Element;
    } & React.RefAttributes<unknown>
  >;
}>;

export default function ModalOverlay({ onExited, paper = true, ...props }: ModalOverlayProps) {
  return <MuiDialog disablePortal={true} sx={useStyles(paper)} TransitionProps={{ onExited }} {...props} />;
}
