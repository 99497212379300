import React, { useCallback } from "react";
import { FormikProps } from "formik";
import clsx from "clsx";

import { getDate, setDate, optionsDays, optionsMonthsLong, optionsMonthsShort, optionsYears } from "./helpers";
import { InputDateFormat } from "components/Inputs/Input/constants";
import { useScrollRef } from "modules/scroll/hooks";
import Select from "components/Inputs/Select";

import styles from "./styles.module.scss";

export type DateSelectProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  format?: InputDateFormat;
  id: string;
  invalid?: boolean;
  name: string;
  readOnly?: boolean;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  tabIndex?: number;
  value: string;
};

const DateSelect: React.FunctionComponent<DateSelectProps> = ({
  autoFocus,
  disabled,
  format = InputDateFormat.DATE,
  id,
  invalid,
  name,
  readOnly,
  setFieldTouched,
  setFieldValue,
  tabIndex,
  value,
}) => {
  const { day, month, year } = getDate(value, format);
  const scrollRef = useScrollRef(id);
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      const currentTarget = event.currentTarget;
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement) && document.body.contains(currentTarget)) {
          setFieldTouched(name, true);
        }
      });
    },
    [name, setFieldTouched],
  );
  const handleChange = useCallback<(value: DateSelectProps["value"]) => void>(
    value => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );
  const className = clsx(styles["date-select"]);
  return (
    <div className={className} onBlur={handleBlur} ref={!disabled && invalid ? scrollRef : undefined}>
      <ul>
        {format === InputDateFormat.DATE ? (
          <React.Fragment>
            <li className={styles["day"]}>
              <Select
                ariaLabel="Day"
                autoFocus={!disabled && autoFocus}
                disabled={disabled}
                id={id}
                invalid={invalid}
                name={`${name}Day`}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  handleChange(setDate(event.currentTarget.value, month, year, format))
                }
                options={optionsDays}
                placeholder="DD"
                readOnly={readOnly}
                tabIndex={tabIndex}
                value={day === "DD" ? "" : day}
              />
            </li>
            <li className={styles["month"]}>
              <Select
                ariaLabel="Month"
                disabled={disabled}
                id={`${id}Month`}
                invalid={invalid}
                name={`${name}Month`}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  handleChange(setDate(day, event.currentTarget.value, year, format))
                }
                options={optionsMonthsShort}
                placeholder="MM"
                readOnly={readOnly}
                tabIndex={tabIndex}
                value={month === "MM" ? "" : month}
              />
            </li>
          </React.Fragment>
        ) : (
          <li className={styles["month"]}>
            <Select
              ariaLabel="Month"
              autoFocus={!disabled && autoFocus}
              disabled={disabled}
              id={id}
              invalid={invalid}
              name={`${name}Month`}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleChange(setDate(day, event.currentTarget.value, year, format))
              }
              options={optionsMonthsLong}
              placeholder="Month"
              readOnly={readOnly}
              tabIndex={tabIndex}
              value={month === "MM" ? "" : month}
            />
          </li>
        )}
        <li className={styles["year"]}>
          <Select
            ariaLabel="Year"
            disabled={disabled}
            id={`${id}Year`}
            invalid={invalid}
            name={`${name}Year`}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(setDate(day, month, event.currentTarget.value, format))
            }
            options={optionsYears}
            placeholder={format === InputDateFormat.DATE ? "YYYY" : "Year"}
            readOnly={readOnly}
            tabIndex={tabIndex}
            value={year === "YYYY" ? "" : year}
          />
        </li>
      </ul>
    </div>
  );
};

export default React.memo(DateSelect);
