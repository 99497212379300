import { v4 as uuidv4 } from "uuid";
import { produce } from "immer";

import { Risk, RiskActionNewAdditionalVehicle } from "../../types";
import { RiskMaxAdditional } from "../../constants";

const newAdditionalVehicle = (state: Risk, action: RiskActionNewAdditionalVehicle): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.additionalVehicles.length < RiskMaxAdditional.VEHICLES &&
      draftState.additionalVehicles.push({
        ...action.bike,
        id: uuidv4(),
        regNumber: action.bike.regNumber.replace(/\s+/g, ""),
      });
  });
};

export default newAdditionalVehicle;
