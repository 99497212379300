import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { resetAbiCode } from "../../helpers";

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

type UseHandleChangeProps = (props: {
  isMainVehicle: boolean;
  name: string;
  onChange: (event: Event) => void;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
}) => (event: Event) => void;

const useHandleChange: UseHandleChangeProps = ({
  isMainVehicle,
  onChange,
  name,
  setApiError,
  setFieldTouched,
  setFieldValue,
}) => {
  const handleChangeRef = useRef<ReturnType<UseHandleChangeProps>>(() => void 0);
  const handleChange = useCallback<(event: Event) => void>(event => handleChangeRef.current(event), []);

  useEffect(() => {
    handleChangeRef.current = event => {
      !isMainVehicle && resetAbiCode(name, setFieldTouched, setFieldValue);
      setApiError(undefined);
      onChange(event);
    };
  }, [isMainVehicle, name, onChange, setApiError, setFieldTouched, setFieldValue]);

  return handleChange;
};

export default useHandleChange;
