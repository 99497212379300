import { produce } from "immer";

import { Risk, RiskActionResetAdditionalVehicles } from "../../types";

const resetAdditionalVehicles = (state: Risk, action: RiskActionResetAdditionalVehicles): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.hasAdditionalVehicles = false;
  });
};

export default resetAdditionalVehicles;
