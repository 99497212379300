import { useCallback, useEffect, useRef } from "react";

import { AddressSearchApiResponseResult } from "modules/api/addressSearch/types";
import { useAddressSearchAction } from "modules/api/addressSearch/hooks";
import { PostalAddress } from "modules/risk/types";

type UseHandleRequestProps = (values: PostalAddress) => () => Promise<AddressSearchApiResponseResult>;

const useHandleRequest: UseHandleRequestProps = values => {
  const handleRequestRef = useRef(async (): Promise<AddressSearchApiResponseResult> => {
    return {} as AddressSearchApiResponseResult;
  });
  const handleRequest = useCallback(() => handleRequestRef.current(), []);
  const { addressRequest } = useAddressSearchAction();

  useEffect(() => {
    handleRequestRef.current = async () => {
      const response = await addressRequest({
        houseNumber: values.addressLine1,
        postcode: values.postCode,
      });
      return response;
    };
  }, [addressRequest, values.addressLine1, values.postCode]);

  return handleRequest;
};

export default useHandleRequest;
