import { LOCATION_CHANGE } from "redux-first-history";
import { Middleware } from "redux";

import { RouterActionType } from "modules/router/types";
import { getRoute } from "./utils";

const createRouterMiddleware: () => Middleware = () => () => next => async (action: RouterActionType) => {
  let nextAction;
  switch (action.type) {
    case LOCATION_CHANGE: {
      const route = getRoute(action.payload.location.pathname);
      document.title = `Devitt Motorbike Insurance${route ? ` | ${route.title}` : ""}`;
      window.scrollTo(0, 0);
      break;
    }
  }
  return nextAction ? nextAction : next(action);
};

export default createRouterMiddleware;
