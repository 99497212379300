import { produce } from "immer";

import { Risk, RiskActionDeleteAdditionalRider } from "../../types";

const deleteAdditionalRider = (state: Risk, action: RiskActionDeleteAdditionalRider): Risk => {
  return produce(state, (draftState: Risk) => {
    if (draftState.additionalRiders.find(item => item.id === action.id)?.isMainRider === true) {
      draftState.proposer.isMainRider = true;
    }
    draftState.additionalRiders = draftState.additionalRiders.filter(item => item.id !== action.id);
  });
};

export default deleteAdditionalRider;
