import React from "react";
import clsx from "clsx";

import { ReactComponent as ChevronRightSVG } from "modules/theme/icons/product/chevron-right.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/product/tick-list.svg";
import Button, { ButtonStyle } from "components/Button";
import { PaymentMethod } from "modules/risk/constants";
import { formatPrice } from "modules/utils";
import { Quote } from "modules/quote/types";

import styles from "./styles.module.scss";

type ProductFooterProps = {
  forwardedRef: React.Ref<HTMLElement>;
  onConfirmAndPay: () => void;
  onSetAnnually: () => void;
  onSetMonthly: () => void;
  paymentMethod: PaymentMethod;
  quote: Quote;
};

const ProductFooter: React.FunctionComponent<ProductFooterProps> = ({
  forwardedRef,
  onConfirmAndPay,
  onSetAnnually,
  onSetMonthly,
  paymentMethod,
  quote,
}) => {
  const className = clsx(styles["product-footer"], quote.instalments && styles["instalments"]);
  return (
    <footer className={className} ref={forwardedRef}>
      <div>
        {!!quote.instalments && <p>Select your payment option:</p>}
        <div>
          {!!quote.instalments && (
            <div>
              <div>
                <Button
                  ariaLabel={`${formatPrice(quote.instalments.amount)} per month`}
                  className={paymentMethod === PaymentMethod.MONTHLY ? styles["selected"] : undefined}
                  label={`${formatPrice(quote.instalments.amount)} per month`}
                  onClick={onSetMonthly}
                  tabIndex={paymentMethod === PaymentMethod.MONTHLY ? -1 : 0}>
                  <TickSVG />
                </Button>
                <Button
                  ariaLabel={`${formatPrice(quote.totalAnnualPrice)} per year`}
                  className={paymentMethod === PaymentMethod.MONTHLY ? undefined : styles["selected"]}
                  label={`${formatPrice(quote.totalAnnualPrice)} per year`}
                  onClick={onSetAnnually}
                  tabIndex={paymentMethod !== PaymentMethod.MONTHLY ? -1 : 0}>
                  <TickSVG />
                </Button>
              </div>
              <div>
                <Button
                  ariaLabel={`${formatPrice(quote.instalments.amount)} * p/m`}
                  className={paymentMethod === PaymentMethod.MONTHLY ? styles["selected"] : undefined}
                  label={`${formatPrice(quote.instalments.amount)} * p/m`}
                  onClick={onSetMonthly}
                  tabIndex={paymentMethod === PaymentMethod.MONTHLY ? -1 : 0}>
                  <TickSVG />
                </Button>
                <Button
                  ariaLabel={`${formatPrice(quote.totalAnnualPrice)} p/a`}
                  className={paymentMethod === PaymentMethod.MONTHLY ? undefined : styles["selected"]}
                  label={`${formatPrice(quote.totalAnnualPrice)} p/a`}
                  onClick={onSetAnnually}
                  tabIndex={paymentMethod !== PaymentMethod.MONTHLY ? -1 : 0}>
                  <TickSVG />
                </Button>
              </div>
            </div>
          )}
          <div className={styles["confirm"]}>
            {paymentMethod === PaymentMethod.MONTHLY && quote.instalments ? (
              <div>
                <strong>{formatPrice(quote.instalments.amount)}</strong>
                <span>per month*</span>
              </div>
            ) : (
              <div>
                <strong>{formatPrice(quote.totalAnnualPrice)}</strong>
                <span>per year</span>
              </div>
            )}
            <Button ariaLabel="Continue" label="Continue" onClick={onConfirmAndPay} style={ButtonStyle.SUCCESS}>
              <ChevronRightSVG />
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(ProductFooter);
