import React from "react";
import clsx from "clsx";

import { ReactComponent as ChevronRightSVG } from "modules/theme/icons/product/chevron-right.svg";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { PaymentMethod } from "modules/risk/constants";
import { formatPrice } from "modules/utils";
import { Quote } from "modules/quote/types";

import styles from "./styles.module.scss";

type PolicyFooterProps = {
  forwardedRef: React.Ref<HTMLElement>;
  paymentMethod: PaymentMethod;
  quote: Quote;
  readOnly?: boolean;
};

const PolicyFooter: React.FunctionComponent<PolicyFooterProps> = ({ forwardedRef, paymentMethod, quote, readOnly }) => {
  const className = clsx(
    styles["policy-footer"],
    paymentMethod === PaymentMethod.MONTHLY && quote.instalments && styles["instalments"],
  );
  return (
    <footer className={className} ref={forwardedRef}>
      <div>
        <div>
          <div className={styles["confirm"]}>
            {paymentMethod === PaymentMethod.MONTHLY && quote.instalments ? (
              <div>
                <strong>{formatPrice(quote.instalments.amount)}</strong>
                <span>per month*</span>
              </div>
            ) : (
              <div>
                <strong>{formatPrice(quote.totalAnnualPrice)}</strong>
                <span>per year</span>
              </div>
            )}
            <Button
              ariaLabel="Confirm and pay"
              readOnly={readOnly}
              style={ButtonStyle.SUCCESS}
              type={ButtonType.SUBMIT}>
              <span>Confirm</span>
              <span>and pay</span>
              <ChevronRightSVG />
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(PolicyFooter);
