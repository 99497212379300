import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "Cancel",
  confirmLabel: "Confirm",
  title: "Confirm Delete",
  icon: ModalIconType.CLAIM,
  text: "Are you sure you want to delete this claim?",
};

export default template;
