import React, { useCallback, useContext } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { RemoveScroll } from "react-remove-scroll";
import Zoom from "@mui/material/Zoom";
import clsx from "clsx";

import Button, { ButtonStyle } from "components/Button";
import PlatformContext from "modules/platform/context";
import ModalIcon, { ModalIconType } from "../Icons";
import ModalOverlay from "../ModalOverlay";

import styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalConfirmTemplateProps = {
  cancelLabel?: string;
  confirmLabel?: string;
  icon?: ModalIconType;
  onCancel?: () => void;
  onConfirm?: () => void;
  text?: string | JSX.Element;
  title: string;
};

export const ModalConfirmTemplate = React.memo<ModalConfirmTemplateProps>(
  ({ cancelLabel = "Cancel", confirmLabel = "Continue", icon, onCancel, onConfirm, text, title }) => {
    const className = clsx(styles["modal-confirm"], icon && styles[`${icon}`]);
    return (
      <section className={className} role="dialog">
        <header>
          <h2>{title}</h2>
          {!!icon && <ModalIcon type={icon} />}
        </header>
        <div>{typeof text === "string" ? <p>{text}</p> : text}</div>
        <footer>
          <Button ariaLabel={cancelLabel} label={cancelLabel} onClick={onCancel} style={ButtonStyle.SECONDARY} />
          <Button ariaLabel={confirmLabel} label={confirmLabel} onClick={onConfirm} style={ButtonStyle.SUCCESS} />
        </footer>
      </section>
    );
  },
);

export type ModalConfirmProps = {
  cancelLabel?: string;
  confirmLabel?: string;
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  icon?: ModalIconType;
  id: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  text?: string | JSX.Element;
  title: string;
};

const ModalConfirm: React.FunctionComponent<ModalConfirmProps> = ({
  cancelLabel,
  confirmLabel,
  destroyModal,
  disableRestoreFocus,
  hideModal,
  icon,
  id,
  onCancel,
  onConfirm,
  open,
  text,
  title,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleCancel = useCallback(() => {
    onCancel ? open && onCancel() : open && hideModal();
  }, [hideModal, onCancel, open]);

  const handleConfirm = useCallback(() => {
    onConfirm ? open && onConfirm() : open && hideModal();
  }, [hideModal, onConfirm, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        handleCancel();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key === "Enter") {
        handleConfirm();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleCancel, handleConfirm],
  );

  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}>
        <ModalConfirmTemplate
          cancelLabel={cancelLabel}
          confirmLabel={confirmLabel}
          icon={icon}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          text={text}
          title={title}
        />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default ModalConfirm;
