import React from "react";

import Button from "./Button";

export type ButtonCheckboxProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  icon?: string;
  id: string;
  index: number;
  invalid?: boolean;
  onClick: (value: string[]) => void;
  option: { label: string; value: string };
  readOnly?: boolean;
  tabIndex?: number;
  value: string[];
};

const ButtonCheckbox: React.FunctionComponent<ButtonCheckboxProps> = ({
  autoFocus,
  id,
  index,
  onClick,
  option,
  tabIndex,
  value,
  ...props
}) => {
  !Array.isArray(value) && (value = []);
  return (
    <Button
      {...props}
      autoFocus={autoFocus && (value.includes(option.value) || (value.length === 0 && index === 0))}
      id={index === 0 ? id : undefined}
      label={option.label}
      onClick={() =>
        onClick(
          value.includes(option.value)
            ? value.filter((item: string) => item !== option.value)
            : [...value, option.value],
        )
      }
      selected={value.includes(option.value)}
      tabIndex={tabIndex}
    />
  );
};

export default React.memo(ButtonCheckbox);
