import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import { ROUTE, routes } from "modules/router/constants";
import { useAdminAction } from "modules/admin/hooks";

import styles from "./styles.module.scss";

const Navigation: React.FunctionComponent = () => {
  const { hideNavigation } = useAdminAction();
  const location = useLocation();
  return (
    <nav className={styles.navigation}>
      <ul>
        {routes.map(route => {
          const active = matchPath(route.path, location.pathname);
          return (
            <li key={route.path}>
              <Link
                aria-current={active ? "page" : undefined}
                className={active ? "active" : "inactive"}
                onClick={() => hideNavigation()}
                reloadDocument={route.path === ROUTE.RECALL}
                to={route.path.replace(":id", "new")}>
                {route.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
