import { useCallback, useEffect, useRef } from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalType } from "modules/modals/constants";
import useModalAction from "./useModalAction";

import modalAdditionalVehicles from "modules/modals/templates/confirm/modalAdditionalVehicles";
import modalAdditionalRiders from "modules/modals/templates/confirm/modalAdditionalRiders";
import modalDeleteRider from "modules/modals/templates/delete/modalDeleteRider";
import modalDeleteBike from "modules/modals/templates/delete/modalDeleteBike";

export enum ModalConfirmTemplates {
  ADDITIONAL_RIDERS = "modalAdditionalRiders",
  ADDITIONAL_VEHICLES = "modalAdditionalVehicles",
  DELETE_BIKE = "modalDeleteBike",
  DELETE_RIDER = "modalDeleteRider",
}

function getTemplate(template: ModalConfirmTemplates): ModalConfirmTemplateProps | undefined {
  switch (template) {
    case ModalConfirmTemplates.ADDITIONAL_RIDERS:
      return modalAdditionalRiders;
    case ModalConfirmTemplates.ADDITIONAL_VEHICLES:
      return modalAdditionalVehicles;
    case ModalConfirmTemplates.DELETE_BIKE:
      return modalDeleteBike;
    case ModalConfirmTemplates.DELETE_RIDER:
      return modalDeleteRider;
    default:
      return undefined;
  }
}

type HandleShowModalProps = {
  disableRestoreFocus?: boolean;
  event?:
    | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
    | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>;
  template: ModalConfirmTemplateProps | undefined;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export type HandleShowConfirmModalProps = Omit<HandleShowModalProps, "template"> & {
  template: ModalConfirmTemplates;
};

const useModalConfirm = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      modal.current && modalHide(modal.current);
    };
  }, [modalHide]);

  const handleShowModal = useCallback(
    ({ disableRestoreFocus, event, template, onConfirm, onCancel }: HandleShowModalProps) => {
      if (template && !modal.current) {
        modal.current = modalShow({
          ...template,
          disableRestoreFocus:
            disableRestoreFocus !== undefined
              ? disableRestoreFocus
              : event && event.currentTarget !== document.activeElement,
          onCancel: () => {
            onCancel && onCancel();
            modal.current && modalHide(modal.current);
            modal.current = null;
          },
          onConfirm: () => {
            onConfirm && onConfirm();
            modal.current && modalHide(modal.current);
            modal.current = null;
          },
          type: ModalType.CONFIRM,
        });
      }
    },
    [modalShow, modalHide],
  );

  const handleShowConfirmModal = useCallback(
    ({ disableRestoreFocus, event, template, onConfirm, onCancel }: HandleShowConfirmModalProps) => {
      handleShowModal({
        disableRestoreFocus: disableRestoreFocus,
        event,
        template: getTemplate(template),
        onConfirm: onConfirm,
        onCancel: onCancel,
      });
    },
    [handleShowModal],
  );

  return handleShowConfirmModal;
};

export default useModalConfirm;
