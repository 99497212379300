import { useSelector } from "modules/redux/store";
import { useDispatch } from "react-redux";

import { Confirmation } from "modules/confirmation/types";
import { Articles } from "modules/articles/types";
import { Dispatch } from "modules/redux/store";
import { UseConfirmationRoute } from "./types";
import useQuery from "../useQuery";

import useRedirect from "./useRedirect";
import useRequest from "./useRequest";

const useConfirmationRoute: UseConfirmationRoute = () => {
  const confirmation: Confirmation = useSelector(state => state.confirmation);
  const articles: Articles = useSelector(state => state.articles);
  const token = useQuery().get("token") || undefined;
  const dispatch: Dispatch = useDispatch();

  const redirect = useRedirect(token);

  useRequest({ dispatch, token });

  return { articles, confirmation, redirect };
};

export default useConfirmationRoute;
