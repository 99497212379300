import React from "react";

import { ReactComponent as ClockSVG } from "modules/theme/icons/usp-bar/clock.svg";
import { ReactComponent as PhoneSVG } from "modules/theme/icons/usp-bar/phone.svg";
import { ReactComponent as PrivacyPolicySVG } from "modules/theme/icons/usp-bar/privacy-policy.svg";
import { ReactComponent as SaleSVG } from "modules/theme/icons/usp-bar/sale.svg";

import styles from "./styles.module.scss";

const UspBar: React.FunctionComponent = () => {
  return (
    <div className={styles["usp-bar"]}>
      <ul>
        <li>
          <ClockSVG />
          <span>Be insured within minutes</span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <SaleSVG />
          <span>10% discount at SportsBikeShop</span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <PrivacyPolicySVG />
          <span>90 days full cover in Europe</span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <PhoneSVG />
          <span>24/7 claim line</span>
        </li>
      </ul>
    </div>
  );
};

export default UspBar;
