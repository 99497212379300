import React from "react";
import moment from "moment";

import { AdditionalRider, Rider } from "modules/risk/types";
import { getMetadataLabel } from "modules/metadata/helpers";
import { MetadataType } from "modules/metadata/constants";
import { Metadata } from "modules/metadata/types";
import { ROUTE } from "modules/router/constants";
import Item, { ItemProps } from "./Item";
import ProductAdditional from "..";

type RidersProps = {
  additionalRiders: AdditionalRider[];
  isOpen?: boolean;
  metadata: Metadata;
  onEdit: (route: string) => void;
  proposer: Rider;
};

const getProps = (metadata: Metadata, rider: AdditionalRider | Rider): Omit<ItemProps, "count" | "onClick"> => {
  return {
    age: rider.dateOfBirth ? moment().diff(rider.dateOfBirth, "years") : "",
    forenames: rider.name.forenames,
    maritalStatus: getMetadataLabel(metadata[MetadataType.MARITAL_STATUS], rider.maritalStatus),
    occupation: getMetadataLabel(metadata[MetadataType.OCCUPATION_TYPES], rider.fullTimeOccupation.type),
    surname: rider.name.surname,
    title:
      rider.name.title === "Other"
        ? getMetadataLabel(metadata[MetadataType.TITLE_OTHER], rider.name.titleOther)
        : getMetadataLabel(metadata[MetadataType.TITLE], rider.name.title),
    licenceType: getMetadataLabel(metadata[MetadataType.LICENCE_TYPES], rider.bikeLicence.type),
    licenceYears: getMetadataLabel(metadata[MetadataType.LICENCE_HELD_YEARS], rider.bikeLicence.years),
  };
};

const Riders: React.FunctionComponent<RidersProps> = ({ additionalRiders, isOpen, metadata, onEdit, proposer }) => {
  return (
    <ProductAdditional isOpen={isOpen} title="Your Riders">
      <Item count={0} onClick={() => onEdit(ROUTE.RIDER)} {...getProps(metadata, proposer)} />
      {additionalRiders.map((item: AdditionalRider, index) => (
        <Item
          count={index + 1}
          key={index}
          onClick={() => onEdit(`${ROUTE.RIDERS}/${item.id}`)}
          {...getProps(metadata, item)}
        />
      ))}
    </ProductAdditional>
  );
};

export default Riders;
