import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { AddressSearchApiRequest, AddressSearchApiResponseResult } from "./types";
import { addressSearchActionRequest } from "./actions";
import { Dispatch } from "modules/redux/store";

export const useAddressSearchAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    addressRequest: useCallback(
      (search: AddressSearchApiRequest): Promise<AddressSearchApiResponseResult> => {
        return dispatch(addressSearchActionRequest(search));
      },
      [dispatch],
    ),
  };
};
