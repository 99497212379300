import yup, { CreateError, Messages } from "modules/validation";
import addressValidateApi from "modules/api/addressValidate";
import { ApiError } from "modules/api";
import remote from "../remote";

const Errors = [ApiError.ADDRESS_INVALID, ApiError.ADDRESS_NOT_FOUND];

const [request, setActive] = remote(addressValidateApi, Errors, "postcode");

const regex = new RegExp("^[a-zA-Z]{1,2}[0-9]\\d?[a-zA-Z]?\\s*\\d{1}[abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}$");

const validatePostCode = (value: string): boolean => (regex.test(value) ? true : false);

const validate = (
  createError: CreateError,
  path: string,
  remote: boolean,
  value: { postcode: string },
): boolean | yup.ValidationError | Promise<boolean | yup.ValidationError> =>
  !value.postcode || !validatePostCode(value.postcode) ? false : remote ? request(createError, path, value) : true;

function validator(this: yup.StringSchema, remote?: boolean) {
  return this.test("postcode", Messages.POSTCODE, function (value) {
    const values = { postcode: value || "" };
    setActive(this.path, values);
    return values.postcode ? validate(this.createError, this.path, !!remote, values) : true;
  });
}

export default validator;
