import React from "react";

import { ReactComponent as DirectDebitSVG } from "modules/theme/icons/payment/direct-debit.svg";

import styles from "./styles.module.scss";

const PaymentTitle: React.FunctionComponent = () => {
  return (
    <legend className={styles["payment-title"]}>
      <h2>Secure Direct Debit Payment</h2>
      <span>
        Please complete the form below so that your monthly Direct Debit payments can be taken from your account.
      </span>
      <DirectDebitSVG />
    </legend>
  );
};

export default React.memo(PaymentTitle);
