import React from "react";

import { ModalLoadingRequoteTemplate } from "components/Modals/ModalLoadingRequote";
import { ModalLoadingQuoteTemplate } from "components/Modals/ModalLoadingQuote";
import { ModalConfirmTemplate } from "components/Modals/ModalConfirm";
import { ModalInfoTemplate } from "components/Modals/ModalInfo";
import { useModalAction } from "modules/modals/hooks";
import { ModalType } from "modules/modals/constants";
import PageWrapper from "components/PageWrapper";
import { ModalWrapperProps } from "./types";

import modalUpsellPersonalAccident from "modules/modals/templates/upsell/modalUpsellPersonalAccident";
import modalInfoComprehensiveCover from "modules/modals/templates/info/modalInfoComprehensiveCover";
import modalUpsellDevittEvolution from "modules/modals/templates/upsell/modalUpsellDevittEvolution";
import modalUpsellBreakdownCover from "modules/modals/templates/upsell/modalUpsellBreakdownCover";
import modalUpsellDevittEnhanced from "modules/modals/templates/upsell/modalUpsellDevittEnhanced";
import modalUpsellHelmetLeathers from "modules/modals/templates/upsell/modalUpsellHelmetLeathers";
import modalUpsellLegalExpenses from "modules/modals/templates/upsell/modalUpsellLegalExpenses";
import modalAdditionalVehicles from "modules/modals/templates/confirm/modalAdditionalVehicles";
import modalAdditionalRiders from "modules/modals/templates/confirm/modalAdditionalRiders";
import modalDeleteConviction from "modules/modals/templates/delete/modalDeleteConviction";
import modalInfoLegalExpenses from "modules/modals/templates/info/modalInfoLegalExpenses";
import modalModifications from "modules/modals/templates/info/modalInfoModifications";
import modalDeleteClaim from "modules/modals/templates/delete/modalDeleteClaim";
import modalDeleteRider from "modules/modals/templates/delete/modalDeleteRider";
import modalDeleteBike from "modules/modals/templates/delete/modalDeleteBike";

import styles from "./styles.module.scss";

const ModalWrapper: React.FunctionComponent<ModalWrapperProps> = ({ children, onClick, title }) => {
  return (
    <React.Fragment>
      {!!title && <h2 className={styles["modal-wrapper-title"]}>{title}</h2>}
      <section className={styles["modal-wrapper"]}>
        <div onClick={onClick}>{children}</div>
      </section>
    </React.Fragment>
  );
};

const Template: React.FunctionComponent = () => {
  const { modalShow } = useModalAction();
  return (
    <PageWrapper>
      <ModalWrapper onClick={() => modalShow({ type: ModalType.LOADING_QUOTE })} title="Modal Loading">
        <ModalLoadingQuoteTemplate />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ type: ModalType.LOADING_REQUOTE })}>
        <ModalLoadingRequoteTemplate />
      </ModalWrapper>

      <ModalWrapper
        onClick={() => modalShow({ ...modalDeleteBike, type: ModalType.CONFIRM })}
        title="Modal Confirm - Actions">
        <ModalConfirmTemplate {...modalDeleteBike} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalDeleteRider, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalDeleteRider} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalDeleteClaim, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalDeleteClaim} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalDeleteConviction, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalDeleteConviction} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalAdditionalRiders, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalAdditionalRiders} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalAdditionalVehicles, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalAdditionalVehicles} />
      </ModalWrapper>

      <ModalWrapper
        onClick={() => modalShow({ ...modalUpsellLegalExpenses, type: ModalType.CONFIRM })}
        title="Modal Confirm - Upsell Addons">
        <ModalConfirmTemplate {...modalUpsellLegalExpenses} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalUpsellHelmetLeathers, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalUpsellHelmetLeathers} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalUpsellPersonalAccident, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalUpsellPersonalAccident} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalUpsellBreakdownCover, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalUpsellBreakdownCover} />
      </ModalWrapper>

      <ModalWrapper
        onClick={() => modalShow({ ...modalUpsellDevittEvolution, type: ModalType.CONFIRM })}
        title="Modal Confirm - Upsell Plans">
        <ModalConfirmTemplate {...modalUpsellDevittEvolution} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalUpsellDevittEnhanced, type: ModalType.CONFIRM })}>
        <ModalConfirmTemplate {...modalUpsellDevittEnhanced} />
      </ModalWrapper>

      <ModalWrapper
        onClick={() => modalShow({ ...modalInfoComprehensiveCover, type: ModalType.INFO })}
        title="Modal Info - Plan Features">
        <ModalInfoTemplate {...modalInfoComprehensiveCover} />
      </ModalWrapper>

      <ModalWrapper onClick={() => modalShow({ ...modalInfoLegalExpenses, type: ModalType.INFO })}>
        <ModalInfoTemplate {...modalInfoLegalExpenses} />
      </ModalWrapper>

      <ModalWrapper
        onClick={() => modalShow({ ...modalModifications, type: ModalType.INFO })}
        title="Modal Info - Modifications">
        <ModalInfoTemplate {...modalModifications} />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default Template;
