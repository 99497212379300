import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "RAC Breakdown",
  text: (
    <React.Fragment>
      <h2>Recovery</h2>

      <p>
        Recovery cover gives you everything that Roadside cover does - breakdown assistance if you break down ¼ mile or
        more from home, taxi fare for 20 miles, a tow for 10 miles plus recovery for up to 8 people (including you) and
        your bike to any single spot in the UK.
      </p>
    </React.Fragment>
  ),
};

export default template;
