import React from "react";
import { useId } from "react-id-generator";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import { InputStringProps } from "components/Inputs/Input/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import InputString from "components/Inputs/Input/String";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";
import { QuestionTextFormat } from "./constants";

type QuestionTextProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> &
    Omit<InputStringProps, "format" | "id" | "invalid"> & {
      format?: QuestionTextFormat;
    }
>;

const QuestionText: React.FunctionComponent<QuestionTextProps> = ({
  disabled,
  error,
  format = QuestionTextFormat.TEXT,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      size={size}
      touched={touched}>
      <React.Fragment>
        <InputString disabled={disabled} format={format} id={id} invalid={!!error && touched} {...props} />
        {process.env.NODE_ENV === "development" && <Debug name={props.name} value={props.value} />}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default React.memo(QuestionText);
