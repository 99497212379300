import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";
import OpeningTimes from "../components/OpeningTimes";
import CallUs from "../components/CallUs";

type MessageDocumentProps = {
  webReference?: string;
};

const MessageDocument: React.FunctionComponent<MessageDocumentProps> = ({ webReference }) => {
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Please call us to complete your quote.</h2>
            <h3>There has been a problem generating one of your documents.</h3>
          </header>

          {webReference ? (
            <p>
              Please call us on <strong>{telephone.sales}</strong> and provide the following reference:{" "}
              <i>{webReference}</i> and we will be able to complete your policy purchase over the phone.
            </p>
          ) : (
            <p>
              Please call us on <strong>{telephone.sales}</strong> and we will be able to complete your policy purchase
              over the phone.
            </p>
          )}

          <CallUs telephone={telephone.sales} text="Need help? Our advisors are here for you." />

          <OpeningTimes openingTimes={openingTimes} />

          <Button
            ariaLabel="Contact us"
            href="https://www.devittinsurance.com/contact-us/"
            label="Contact us"
            style={ButtonStyle.SUCCESS}
            target={webReference ? ButtonTarget.BLANK : ButtonTarget.SELF}
            type={ButtonType.LINK}
          />

          <Button
            ariaLabel="Request a callback"
            href="https://www.devittinsurance.com/contact-us/request-callback/"
            label="Request a callback"
            style={ButtonStyle.PRIMARY}
            target={webReference ? ButtonTarget.BLANK : ButtonTarget.SELF}
            type={ButtonType.LINK}
          />
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(MessageDocument);
