import React from "react";
import { Navigate } from "react-router-dom";

import { useAdditionalRidersRoute } from "modules/router/hooks";
import Template from "./Template";

const Route: React.FunctionComponent = () => {
  const { redirect, ...props } = useAdditionalRidersRoute();
  return redirect ? <Navigate replace to={redirect} /> : <Template {...props} />;
};

export default Route;
