import { isEqual } from "lodash";

import { AnalyticsEventAddToCart, AnalyticsPayloadEcommerce } from "./types";
import { pushEvent } from "../utils";
import * as Items from "../items";

let cache = {};

const analyticsEventAddToCart: AnalyticsEventAddToCart = getState => {
  const {
    risk: {
      cart: { optionalExtras: cart },
    },
    quote: { optionalExtras: options, premium },
  } = getState();

  const items = [Items.analyticsItemTierBasic(premium)];

  !!options.enhanced &&
    cart.includes(options.enhanced.type) &&
    items.push(Items.analyticsItemTierEnhanced(options.enhanced.grossPremium));

  !!options.enhancedPlus &&
    cart.includes(options.enhancedPlus.type) &&
    items.push(Items.analyticsItemTierEnhancedPlus(options.enhancedPlus.grossPremium));

  !!options.evolution &&
    cart.includes(options.evolution.type) &&
    items.push(Items.analyticsItemTierEvolution(options.evolution.grossPremium));

  !!options.evolutionPlus &&
    cart.includes(options.evolutionPlus.type) &&
    items.push(Items.analyticsItemTierEvolutionPlus(options.evolutionPlus.grossPremium));

  const totalAnnualPrice = Number(
    items.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0).toFixed(2),
  );

  const payload: AnalyticsPayloadEcommerce = {
    billing: "annually",
    currency: "GBP",
    value: totalAnnualPrice,
    items,
  };

  if (!isEqual(cache, payload)) {
    pushEvent("add_to_cart", payload);
    cache = payload;
  }
};

export default analyticsEventAddToCart;
