import { useCallback, useEffect, useRef } from "react";

type UseHandleErrorProps = (
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>,
) => (error: Error) => void;

const useHandleError: UseHandleErrorProps = setApiError => {
  const handleErrorRef = useRef<ReturnType<UseHandleErrorProps>>(() => void 0);
  const handleError = useCallback<(error: Error) => void>(error => handleErrorRef.current(error), []);

  useEffect(() => {
    handleErrorRef.current = (error: Error) => {
      setApiError(error.message);
    };
  }, [setApiError]);

  return handleError;
};

export default useHandleError;
