import { AnalyticsItemLegalExpenses } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemLegalExpenses: AnalyticsItemLegalExpenses = price => ({
  item_id: "LEGAL_EXPENSES",
  item_name: "Legal Expenses and Loss Recovery",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemLegalExpenses;
