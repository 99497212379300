import { useState } from "react";
import { FormikProps } from "formik";

import handleYearOfManufacture from "./handleYearOfManufacture";
import { AbiApiResponseResult } from "modules/api/abi/types";
import { useAbiAction } from "modules/api/abi/hooks";
import useHandleResponse from "./handleResponse";
import useHandleRequest from "./handleRequest";
import useHandleClick from "./handleClick";
import useHandleError from "./handleError";

type UseDescriptionProps = {
  errorYearOfManufacture?: string;
  name: string;
  open: boolean;
  setFieldTouched: FormikProps<number | string>["setFieldTouched"];
  setFieldValue: FormikProps<number | string>["setFieldValue"];
  setOpen: (state: boolean) => void;
  valueAbiCode: string;
  valueYearOfManufacture: number | "";
};

type UseDescriptionReturnProps = [() => void, boolean, AbiApiResponseResult | undefined];

const useDescription = ({
  errorYearOfManufacture,
  name,
  open,
  setFieldTouched,
  setFieldValue,
  setOpen,
  valueAbiCode,
  valueYearOfManufacture,
}: UseDescriptionProps): UseDescriptionReturnProps => {
  const [result, setResult] = useState<AbiApiResponseResult | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const { abiRequest } = useAbiAction();

  const handleClick = useHandleClick({ name, result, setFieldTouched, setFieldValue, setOpen, valueYearOfManufacture });

  const handleError = useHandleError({ name, setFieldTouched, setFieldValue });

  const handleResponse = useHandleResponse({
    name,
    setFieldTouched,
    setFieldValue,
    setOpen,
    setResult,
    valueYearOfManufacture,
  });

  useHandleRequest({ abiRequest, handleClick, handleError, handleResponse, setLoading, setResult, valueAbiCode });

  handleYearOfManufacture({
    errorYearOfManufacture,
    name,
    open,
    result,
    setFieldTouched,
    setFieldValue,
    setOpen,
    valueYearOfManufacture,
  });

  return [handleClick, loading, result];
};

export default useDescription;
