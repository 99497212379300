import { useCallback } from "react";

import { quoteActionRemoveOptionalExtra } from "modules/quote/actions";
import { scrollTop } from "modules/scroll/helpers";
import { UseHandleDevitt } from "./types";

const useHandleDevitt: UseHandleDevitt = ({ dispatch, optionEnhanced, optionEvolution }) =>
  useCallback(async () => {
    await scrollTop();
    switch (true) {
      case optionEnhanced?.selected:
        !!optionEnhanced && dispatch(quoteActionRemoveOptionalExtra(optionEnhanced.option));
        break;
      case optionEvolution?.selected:
        !!optionEvolution && dispatch(quoteActionRemoveOptionalExtra(optionEvolution.option));
        break;
    }
  }, [dispatch, optionEnhanced, optionEvolution]);

export default useHandleDevitt;
