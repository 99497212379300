import React from "react";
import { shallowEqual, useSelector } from "modules/redux/store";

import { ReactComponent as DirectDebitSVG } from "modules/theme/icons/payment/direct-debit.svg";
import { ReactComponent as PdfSVG } from "modules/theme/icons/form/buttons/pdf.svg";
import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";
import { App } from "modules/app/types";

type WrapperProps = {
  children?: (customerService: string) => React.ReactNode;
};

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }) => {
  const app: App = useSelector(state => state.app);
  const { customerService } = useSelector(state => app.contact.telephone, shallowEqual);
  return <React.Fragment>{children && children(customerService)}</React.Fragment>;
};

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Direct Debit Terms",
  text: (
    <Wrapper>
      {customerService => (
        <React.Fragment>
          <DirectDebitSVG />

          <h2>Pre-Contract Information</h2>

          <h3>Pre-Contract Credit Information</h3>

          <p>
            Please read the{" "}
            <a
              href="https://library.devittinsurance.com/files/pre_contract_sample_document.pdf"
              rel="noopener noreferrer"
              target="_blank">
              sample copy
            </a>{" "}
            of this document and we will include this when we send your policy documents, when you purchase this cover.
          </p>

          <p>
            <Button
              ariaLabel="Sample Document"
              href="https://library.devittinsurance.com/files/pre_contract_sample_document.pdf"
              label="Sample Document"
              style={ButtonStyle.SUCCESS}
              target={ButtonTarget.BLANK}
              type={ButtonType.LINK}>
              <PdfSVG />
            </Button>
          </p>

          <h3>Your right to cancel your Consumer Credit Agreement</h3>

          <p>
            If you have chosen to pay by instalments you may cancel the Consumer Credit Agreement within 14 days of
            receipt. If you would like to cancel the Consumer Credit Agreement, please call us on {customerService} or
            write to Devitt Insurance Services Limited, North House, St Edwards Way, Romford, Essex, RM1 3PP. You may
            cancel your Consumer Credit Agreement within 14 days of receipt and pay the premium in full without
            incurring any penalty charges.
          </p>

          <p>
            Please note that if you cancel your Consumer Credit Agreement or Direct Debit instruction after 14 days of
            receipt you have the option to continue cover under your policy as long as you pay the outstanding balance
            of your account, otherwise cover under your policy may be cancelled.
          </p>

          <p>
            If you do not cancel your Consumer Credit Agreement, you must continue to pay instalments for your policy
            otherwise we may cancel your cover.
          </p>

          <h3>Important information about your Consumer Credit Agreement</h3>

          <p>
            If you fail to pay an instalment when due, we will let you know, however, if the instalment fails due to
            lack of funds we will also attempt to collect from your bank a 2nd time within 7 days. If the default is not
            corrected within the time permitted by the Consumer Credit Act 1974, the whole outstanding balance will
            become due and payable. If this sum is not paid within 14 days you authorise Devitt Insurance Services
            Limited to cancel the insurance cover under the relevant policy(ies). In the event of cancellation any
            refund given will be after the deduction of the Underwriter's premium charge, cancellation fees (if
            applicable) and our instalment charge for the period of cover up to the date of the cancellation.
          </p>

          <p>
            English Law will apply to your Consumer Credit Agreement and it is subject to the jurisdiction of the
            English courts. We have supplied your Consumer Credit Agreement and other information in English and we will
            continue to communicate with you in English.
          </p>

          <p>
            Should you have a query or complaint regarding any of our services or products, including your Consumer
            Credit Agreement, please refer to your insurance policy documentation for the relevant contact details.
          </p>

          <h3>Further terms of your Consumer Credit Agreement</h3>

          <p>
            Devitt Insurance Services Limited agrees to give you credit for premiums and fees due to Underwriters or
            Devitt Insurance Services Limited. Premiums financed will be settled directly to the Underwriters by Devitt
            Insurance Services Limited. We are only able to offer this credit facility to UK residents aged 18 and over.
          </p>

          <p>
            You will pay the instalments through monthly direct debits in the amounts and on the dates as notified to
            you in writing. On renewal the new monthly instalments will be notified to you at least 14 days before
            renewal date. Any deduction for an instalment does not imply that the insurance policy(ies) is/are in force.
          </p>

          <p>
            Interest will be charged on the balance of all amounts outstanding from time to time at the rate set out on
            the Direct Debit agreement. We may change the rate of interest payable on any policy renewal on notice to
            you. Any such variation will take effect 7 days after the date of receipt of such notice by you.
          </p>

          <p>If you change your bank account you must complete a new Direct Debit instruction on request from us.</p>

          <p>
            Please note if you are a corporate body this agreement is not regulated by the Consumer Credit Act 1974 and
            any reference to that Act is deemed not to be incorporated in this agreement. This agreement will
            nevertheless be legally binding between you and us in accordance with its terms.
          </p>
        </React.Fragment>
      )}
    </Wrapper>
  ),
};

export default template;
