import { produce } from "immer";

import { Risk, RiskActionUpdateCart } from "../../types";

const updateCart = (state: Risk, action: RiskActionUpdateCart): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.cart = action.cart;
  });
};

export default updateCart;
