import { useEffect } from "react";

import { confirmationActionRequest } from "modules/confirmation/actions";
import { UseRequest } from "./types";

const useRequest: UseRequest = ({ dispatch, token }) =>
  useEffect(() => {
    !!token && dispatch(confirmationActionRequest(token));
  }, [dispatch, token]);

export default useRequest;
