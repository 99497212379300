import React, { useMemo } from "react";

import { ReactComponent as DevittEvolutionSVG } from "modules/theme/icons/product/plans/evolution.svg";
import { ReactComponent as DevittEnhancedSVG } from "modules/theme/icons/product/plans/enhanced.svg";
import { ReactComponent as TickListSVG } from "modules/theme/icons/product/tick-list.svg";
import { ReactComponent as DevittSVG } from "modules/theme/icons/product/plans/basic.svg";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import { OptionEnhanced, OptionEvolution } from "modules/router/hooks";
import { getMetadataLabel } from "modules/metadata/helpers";
import Button, { ButtonStyle } from "components/Button";
import { MetadataItem } from "modules/metadata/types";
import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import PriceFooter from "./PriceFooter";
import PriceHeader from "./PriceHeader";

import styles from "./styles.module.scss";

type QuoteTiersProps = {
  cover: string;
  handleDevitt: () => void;
  handleDevittEnhanced: () => void;
  handleDevittEvolution: () => void;
  normallyPayByDirectDebit: Risk["additionalQuestions"]["normallyPayByDirectDebit"];
  optionsCover: MetadataItem[];
  optionEnhanced: OptionEnhanced;
  optionEvolution: OptionEvolution;
  quote: Quote;
};

const QuoteTiers: React.FunctionComponent<QuoteTiersProps> = ({
  cover,
  handleDevitt,
  handleDevittEnhanced,
  handleDevittEvolution,
  normallyPayByDirectDebit,
  optionsCover,
  optionEnhanced,
  optionEvolution,
  quote: { instalments, totalAnnualPrice },
}) => {
  const handleShowModalInfo = useModalInfo();
  const selectedBasic = useMemo(
    () => !optionEnhanced?.selected && !optionEvolution?.selected,
    [optionEnhanced?.selected, optionEvolution?.selected],
  );
  return (
    <div className={styles["quote-tiers"]}>
      <header>
        <div>Upgrade your cover</div>
        <div>
          <div>Upgrade your cover</div>
          <div>
            <DevittSVG />
          </div>
          {!!optionEnhanced && (
            <div>
              <DevittEnhancedSVG />
            </div>
          )}
          {!!optionEvolution && (
            <div>
              <DevittEvolutionSVG />
            </div>
          )}
        </div>
        <div className={styles["price"]}>
          <div></div>
          <PriceHeader
            instalments={!!instalments}
            label="pcm"
            monthly={normallyPayByDirectDebit}
            priceAnnually={totalAnnualPrice}
            priceMonthly={instalments?.amount}
          />
          {!!optionEnhanced && (
            <PriceHeader
              instalments={!!instalments}
              label="p/m*"
              monthly={normallyPayByDirectDebit}
              priceAnnually={optionEnhanced.option.grossPremium}
              priceMonthly={optionEnhanced.option.grossPremium / 12}
            />
          )}
          {!!optionEvolution && (
            <PriceHeader
              instalments={!!instalments}
              label="p/m*"
              monthly={normallyPayByDirectDebit}
              priceAnnually={optionEvolution.option.grossPremium}
              priceMonthly={optionEvolution.option.grossPremium / 12}
            />
          )}
        </div>
      </header>
      <div>
        <div>
          <div>
            <Button
              ariaLabel={`${getMetadataLabel(optionsCover, cover)} Cover`}
              label={`${getMetadataLabel(optionsCover, cover)} Cover`}
              onClick={event =>
                handleShowModalInfo({
                  event,
                  template:
                    cover === "TPFT"
                      ? ModalInfoTemplates.THIRD_PARTY_FIRE_THEFT
                      : cover === "TPO"
                      ? ModalInfoTemplates.THIRD_PARTY_ONLY
                      : ModalInfoTemplates.COMPREHENSIVE_COVER,
                })
              }
            />
          </div>
          <div>
            <div>
              <TickListSVG />
            </div>
            {!!optionEnhanced && (
              <div>
                <TickListSVG />
              </div>
            )}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <Button
              ariaLabel="90 days full policy cover in EU"
              label="90 days full policy cover in EU"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.EU_COVER })}
            />
          </div>
          <div>
            <div>
              <TickListSVG />
            </div>
            {!!optionEnhanced && (
              <div>
                <TickListSVG />
              </div>
            )}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <Button
              ariaLabel="10% discount at SportsBikeShop"
              label="10% discount at SportsBikeShop"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.SPORTS_BIKE_SHOP })}
            />
          </div>
          <div>
            <div>
              <TickListSVG />
            </div>
            {!!optionEnhanced && (
              <div>
                <TickListSVG />
              </div>
            )}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <Button
              ariaLabel="24/7 claim line"
              label="24/7 claim line"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.CLAIM_LINE })}
            />
          </div>
          <div>
            <div>
              <TickListSVG />
            </div>
            {!!optionEnhanced && (
              <div>
                <TickListSVG />
              </div>
            )}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <Button
              ariaLabel="Legal Expenses & Loss Recovery"
              label="Legal Expenses & Loss Recovery"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.LEGAL_EXPENSES })}
            />
          </div>
          <div>
            <div></div>
            {!!optionEnhanced && (
              <div>
                <TickListSVG />
              </div>
            )}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            {!!optionEnhanced?.isPlus || !!optionEvolution?.isPlus ? (
              <Button
                ariaLabel="RAC Onward Travel plus EU Breakdown"
                label="RAC Onward Travel plus EU Breakdown"
                onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.BREAKDOWN_ONWARD_TRAVEL })}
              />
            ) : (
              <Button
                ariaLabel="RAC Roadside Breakdown"
                label="RAC Roadside Breakdown"
                onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.BREAKDOWN_ROADSIDE })}
              />
            )}
          </div>
          <div>
            <div></div>
            {!!optionEnhanced && (
              <div>
                <TickListSVG />
              </div>
            )}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <Button
              ariaLabel="Helmet & Leathers Cover"
              label="Helmet & Leathers Cover"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.HELMET_LEATHERS_COVER })}
            />
          </div>
          <div>
            <div></div>
            {!!optionEnhanced && <div></div>}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <Button
              ariaLabel="Personal Accident Cover"
              label="Personal Accident Cover"
              onClick={event => handleShowModalInfo({ event, template: ModalInfoTemplates.PERSONAL_ACCIDENT_COVER })}
            />
          </div>
          <div>
            <div></div>
            {!!optionEnhanced && <div></div>}
            {!!optionEvolution && (
              <div>
                <TickListSVG />
              </div>
            )}
          </div>
        </div>
      </div>
      <footer>
        <div className={styles["price"]}>
          <div>Upgrade cost</div>
          <div></div>
          {!!optionEnhanced && (
            <PriceFooter monthly={normallyPayByDirectDebit} price={optionEnhanced.option.grossPremium} />
          )}
          {!!optionEvolution && (
            <PriceFooter monthly={normallyPayByDirectDebit} price={optionEvolution.option.grossPremium} />
          )}
        </div>
        <div className={styles["buttons"]}>
          <div></div>
          <div>
            <Button
              ariaLabel={selectedBasic ? "Selected" : "Select Plan"}
              label={selectedBasic ? "Selected" : "Select Plan"}
              onClick={handleDevitt}
              readOnly={selectedBasic}
              style={selectedBasic ? ButtonStyle.SUCCESS_INVERTED : ButtonStyle.PRIMARY}>
              {selectedBasic && <TickListSVG />}
            </Button>
          </div>
          {!!optionEnhanced && (
            <div>
              <Button
                ariaLabel={optionEnhanced.selected ? "Selected" : "Select Plan"}
                label={optionEnhanced.selected ? "Selected" : "Select Plan"}
                onClick={handleDevittEnhanced}
                readOnly={optionEnhanced.selected}
                style={optionEnhanced.selected ? ButtonStyle.SUCCESS_INVERTED : ButtonStyle.PRIMARY}>
                {optionEnhanced.selected && <TickListSVG />}
              </Button>
            </div>
          )}
          {!!optionEvolution && (
            <div>
              <Button
                ariaLabel={optionEvolution.selected ? "Selected" : "Select Plan"}
                label={optionEvolution.selected ? "Selected" : "Select Plan"}
                onClick={handleDevittEvolution}
                readOnly={optionEvolution.selected}
                style={optionEvolution.selected ? ButtonStyle.SUCCESS_INVERTED : ButtonStyle.PRIMARY}>
                {optionEvolution.selected && <TickListSVG />}
              </Button>
            </div>
          )}
        </div>
        {(!!optionEnhanced?.option.packageSavingPercent || !!optionEvolution?.option.packageSavingPercent) && (
          <div className={styles["discount"]}>
            <div></div>
            <div></div>
            {!!optionEnhanced?.option.packageSavingPercent && (
              <div>
                <strong>Most popular</strong>
                <span>Save {optionEnhanced.option.packageSavingPercent}**</span>
              </div>
            )}
            {!!optionEvolution?.option.packageSavingPercent && (
              <div>
                <strong>Biggest discount</strong>
                <span>Save {optionEvolution.option.packageSavingPercent}**</span>
              </div>
            )}
          </div>
        )}
      </footer>
    </div>
  );
};

export default QuoteTiers;
