import { useCallback } from "react";

import { riskActionNewAdditionalVehicle, riskActionUpdateAdditionalVehicle } from "modules/risk/actions";
import { CurrentAdditionalVehicle } from "modules/risk/types";
import { ROUTE } from "modules/router/constants";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, id, navigate }) =>
  useCallback(
    (values: CurrentAdditionalVehicle) => {
      dispatch(
        id === "new"
          ? riskActionNewAdditionalVehicle(values.currentAdditionalVehicle)
          : riskActionUpdateAdditionalVehicle(values.currentAdditionalVehicle),
      );
      navigate(ROUTE.MOTORCYCLES);
    },
    [dispatch, id, navigate],
  );

export default useHandleSubmit;
