import React, { useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { useEndorsement } from "./hooks";
import Button from "components/Button";

import styles from "./styles.module.scss";

type PolicyEndorsementProps = {
  endorsement: string;
  isOpen: boolean;
};

const PolicyEndorsement: React.FunctionComponent<PolicyEndorsementProps> = ({ endorsement, isOpen }) => {
  const { compulsory, text, title } = useEndorsement(endorsement);
  const [open, setOpen] = useState(isOpen);
  const className = clsx(styles["policy-endorsement"], compulsory && styles["compulsory"], open && styles["open"]);
  return (
    <li className={className}>
      <header>
        <Button ariaLabel="Your Endorsement" onClick={() => setOpen(!open)}>
          <span>{title}</span>
          {compulsory && <span>Compulsory</span>}
          <ChevronDownSVG />
        </Button>
      </header>
      {open && <div>{text}</div>}
    </li>
  );
};

export default React.memo(PolicyEndorsement);
