import React from "react";
import { shallowEqual, useSelector } from "modules/redux/store";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";
import { App } from "modules/app/types";

type WrapperProps = {
  children?: (sales: string) => React.ReactNode;
};

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }) => {
  const app: App = useSelector(state => state.app);
  const { sales } = useSelector(state => app.contact.telephone, shallowEqual);
  return <React.Fragment>{children && children(sales)}</React.Fragment>;
};

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Can't find your bike?",
  text: (
    <Wrapper>
      {sales => (
        <React.Fragment>
          <p>
            The first thing is to try is entering the bike details manually, which includes Make, Model, Year and Engine
            CC. These should be on your V5C document.
          </p>

          <p>
            Double check the details are completely accurate, for example, if your bike is a Royal Enfield India, be
            careful not to select just Royal Enfield or Enfield. Also try searching for your bike in other ways, for
            example Piaggio is down as Vespa-Piaggio.
          </p>

          <p>
            Sometimes a bike can be so new that it has not been allocated an ABI (Association of British Insurers) code
            yet, this means that no providers have a rating for that particular bike and can't quote yet online.
          </p>

          <p>
            If you still can't find your bike, then please call us on {sales} and our motorcycle insurance specialist
            team will be able to help your further.
          </p>
        </React.Fragment>
      )}
    </Wrapper>
  ),
};

export default template;
