import { InputAutocomplete, InputType } from "../../constants";
import { Mask, MaskProps } from "../../types";
import moment from "moment";

const mask = (props: MaskProps): Mask =>
  props.platform.isMobile
    ? {
        autoComplete: InputAutocomplete.OFF,
        maxLength: 10,
        placeholder: "YYYY-MM-DD",
        type: InputType.DATE,
      }
    : {
        autoComplete: InputAutocomplete.OFF,
        inputMask: {
          alias: "datetime",
          autoUnmask: true,
          groupSeparator: " / ",
          inputFormat: "dd / mm / yyyy",
          onBeforeMask: function (value: string) {
            if (moment(value, "YYYY-MM-DD", true).isValid()) {
              return moment(value, "YYYY-MM-DD", true).format("DD / MM / YYYY");
            }
            return value;
          },
          max: props.max ? moment(props.max).format("DD / MM / YYYY") : undefined,
          min: props.min ? moment(props.min).format("DD / MM / YYYY") : undefined,
          outputFormat: "yyyy-mm-dd",
          placeholder: "DD / MM / YYYY",
          showMaskOnHover: false,
        },
        maxLength: 14,
        placeholder: props.placeholder ? props.placeholder : "DD / MM / YYYY",
        type: InputType.TEXT,
      };

export default mask;
