import React, { useCallback, useRef, useState } from "react";

import { QuestionSwitch } from "components/Questions";
import { QuestionModificationsProps } from "./types";
import ErrorCallUs from "./ErrorCallUs";
import Label from "./Label";

const QuestionModifications: React.FunctionComponent<QuestionModificationsProps> = ({
  autoFocus,
  disabled,
  error,
  setFieldTouched,
  setFieldValue,
  touched,
  value,
  ...props
}) => {
  const [valueSwitch, setValueSwitch] = useState<boolean | "">(value);

  const open = useRef<boolean>(!!valueSwitch);

  const handleSetFieldTouched = useCallback(
    (field: string, isTouched?: boolean | undefined) => {
      !open.current && setFieldTouched(field, isTouched);
    },
    [setFieldTouched],
  );

  const handleSetFieldValue = useCallback(
    (field: string, value: boolean | "") => {
      setFieldValue(field, value === false ? false : "");
      setFieldTouched(field, !value);
      setValueSwitch(value);
      open.current = !!value;
    },
    [setFieldTouched, setFieldValue],
  );

  return (
    <React.Fragment>
      <QuestionSwitch
        autoFocus={autoFocus}
        disabled={disabled}
        error={!open.current ? error : undefined}
        label="Has your bike been modified?"
        setFieldTouched={handleSetFieldTouched}
        setFieldValue={handleSetFieldValue}
        touched={!open.current ? touched : undefined}
        value={valueSwitch}
        {...props}
      />
      {!!open.current && (
        <QuestionSwitch
          disabled={disabled}
          error={error}
          info={error && value === true ? <ErrorCallUs /> : undefined}
          label={<Label disabled={disabled} />}
          secondary={true}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched}
          value={value}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(QuestionModifications);
