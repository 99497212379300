import { produce } from "immer";

import { Risk, RiskActionUpdateAdditionalVehicle } from "../../types";

const updateAdditionalVehicle = (state: Risk, action: RiskActionUpdateAdditionalVehicle): Risk => {
  return produce(state, (draftState: Risk) => {
    const index = draftState.additionalVehicles.findIndex(item => item.id === action.bike.id);

    draftState.additionalVehicles[index] = { ...action.bike, regNumber: action.bike.regNumber.replace(/\s+/g, "") };
  });
};

export default updateAdditionalVehicle;
