import React, { useCallback, useContext, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { RemoveScroll } from "react-remove-scroll";
import Zoom from "@mui/material/Zoom";
import Countdown from "react-countdown";
import Template from "./Template";

import { SessionAction } from "modules/session/constants";
import PlatformContext from "modules/platform/context";
import ModalOverlay from "../ModalOverlay";
import moment from "moment";

const timeoutModal = (Number(process.env.REACT_APP_SESSION_MODAL_TIMEOUT) || 5) * (60 * 1000);
const timeoutSession = (Number(process.env.REACT_APP_SESSION_TIMEOUT) || 25) * (60 * 1000);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

type ModalSessionProps = {
  sessionAction: (action: SessionAction) => void;
};

const ModalSession: React.FunctionComponent<ModalSessionProps> = ({ sessionAction }) => {
  const [open, setOpen] = useState<"open" | SessionAction.DESTROY | SessionAction.RESET>("open");
  const [start] = useState(moment(+new Date() - timeoutSession).valueOf());
  const [end] = useState(+new Date() + timeoutModal);
  const { isMobile } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    open && setOpen(SessionAction.RESET);
  }, [open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Enter") {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose],
  );

  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        onExited={() => sessionAction(open as SessionAction)}
        onKeyDown={handleKeyDown}
        open={open === "open"}
        scroll="paper"
        TransitionComponent={Transition}>
        <Countdown
          date={end}
          onComplete={() => setOpen(SessionAction.DESTROY)}
          renderer={props => (
            <Template
              onContinue={() => !props.completed && open && setOpen(SessionAction.RESET)}
              start={start}
              {...props}
            />
          )}
        />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default ModalSession;
