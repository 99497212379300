import { PlatformType } from "modules/platform/context";
import { Mask, MaskProps } from "./types";
import { AllowedKeys } from "./constants";

import MASK_DATE from "./Date/masks";
import MASK_NUMBER from "./Number/masks";
import MASK_STRING from "./String/masks";

const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  ...MASK_DATE,
  ...MASK_NUMBER,
  ...MASK_STRING,
};

export const getInputPattern = (platform: PlatformType): string | undefined => {
  return platform.isIOS && Math.trunc(Number(platform.osVersion)) <= 12 ? "[0-9]*" : undefined;
};

export function getMask(props: MaskProps): Mask {
  return (MASK[props.format] && MASK[props.format](props)) || {};
}

export const handleInput =
  (sanitiser: (event: React.FormEvent<HTMLInputElement>) => string) =>
  (onInput?: (event: React.FormEvent<HTMLInputElement>) => void) =>
  (event: React.FormEvent<HTMLInputElement>) => {
    onInput && onInput(event);
    const sanitised = sanitiser(event);
    if (event.currentTarget.value !== sanitised) {
      typeof event.currentTarget.selectionEnd === "number"
        ? setSelectionRange(event, sanitised)
        : (event.currentTarget.value = sanitised);
    }
  };

export const handleKeyDown =
  (regex: RegExp) =>
  (onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void) =>
  (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown && onKeyDown(event);
    if (AllowedKeys.indexOf(event.key) > -1) {
      return;
    }
    if (!(event.metaKey || event.ctrlKey) && event.key.length === 1 && !regex.test(event.key)) {
      event.preventDefault();
    }
  };

export const handlePaste = (event: React.FormEvent<HTMLInputElement>) => {
  const newEvent =
    typeof Event === "function"
      ? new Event("change", { bubbles: true, cancelable: true })
      : document.createEvent("Event").initEvent("change", true, true);
  newEvent && event.currentTarget && event.currentTarget.dispatchEvent(newEvent);
};

export function maskCharacter(value: string, character: string, positions: Array<number>): string {
  const oldValue = value
    .replace(new RegExp("[^\\d" + character + "]", "g"), "")
    .replace(new RegExp(character + "+", "g"), character);
  let newValue = value.replace(/[^\d]/g, "");
  if (
    oldValue.slice(-1) === character &&
    positions.some(function (position) {
      return oldValue.length === position;
    })
  ) {
    newValue += character;
  }
  const digits = newValue.split("");
  positions.forEach(position => {
    if (digits.length > position - 1 && digits[position - 1] !== character) {
      digits.splice(position - 1, 0, character);
    }
  });
  return digits.join("");
}

export const setNativeInput = (
  input: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>,
  value: string,
) => {
  const setValue =
    input &&
    Object.getOwnPropertyDescriptor(
      input.current?.tagName.toLowerCase() === "textarea"
        ? window.HTMLTextAreaElement.prototype
        : window.HTMLInputElement.prototype,
      "value",
    )?.set;
  input.current && setValue && setValue.call(input.current, value);
  setTimeout(() => {
    const newEvent =
      typeof Event === "function"
        ? new Event("change", { bubbles: true, cancelable: true })
        : document.createEvent("Event").initEvent("change", true, true);
    newEvent && input.current && input.current.dispatchEvent && input.current.dispatchEvent(newEvent);
  });
};

export const setSelectionRange = (
  event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  value: string,
): void => {
  let selectionEnd = event.currentTarget.selectionEnd || 0;
  selectionEnd =
    value.length < event.currentTarget.value.length
      ? selectionEnd - (event.currentTarget.value.length - value.length)
      : selectionEnd + (value.length - event.currentTarget.value.length);
  event.currentTarget.value = value;
  event.currentTarget.setSelectionRange(selectionEnd, selectionEnd);
};
