import { Dispatch } from "modules/redux/store";
import { matchPath } from "react-router-dom";
import { push } from "redux-first-history";

import { ApiErrorResponse } from "modules/api/types";
import { ROUTE } from "modules/router/constants";
import { errorRoute } from "modules/api/errors";
import { RootState } from "modules/redux/store";
import { isAxiosError } from "modules/api";
import { isAdmin } from "modules/admin";
import { AppAction } from "./constants";
import { isError } from "modules/utils";
import { api, API } from "modules/api";
import {
  AppActionRequestStarted,
  AppActionRequestSuccess,
  AppActionRequestFailure,
  AppApiResponseResult,
  AppActionUpdate,
  AppActionError,
  AppApiResponse,
  AppActionInit,
} from "./types";

export const appActionError = (error: AppActionError) => {
  return (dispatch: Dispatch): void => {
    const webReference = error.response ? error.response.details?.webReference : error.webReference;
    const route = error.response ? errorRoute(error.response) : error.route || ROUTE.MESSAGE;
    switch (true) {
      case !!error.path && !!matchPath(ROUTE.MESSAGE_MAINTENANCE, error.path):
      case !!error.path && route !== ROUTE.MESSAGE_MAINTENANCE && !!matchPath(ROUTE.MESSAGE, error.path):
        break;
      case isAdmin:
        dispatch(push(webReference ? `${route}?webReference=${webReference}` : route));
        break;
      default:
        dispatch(appActionUpdate({ overwrite: error.overwrite, route: route, webReference: webReference }));
    }
  };
};

export const appActionInit = (): AppActionInit => ({
  type: AppAction.INIT,
});

export const appActionUpdate = (error: AppActionUpdate["error"]): AppActionUpdate => ({
  type: AppAction.UPDATE,
  error: error,
});

export const appActionRequest = () => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch(appActionRequestStarted());
    try {
      const response: AppApiResponse = await api(API.APP_REQUEST());
      if (response.data.isMaintenance) {
        throw new Error("Under maintenance.");
      }
      dispatch(appActionRequestSuccess(response.data.siteInfo));
    } catch (error) {
      dispatch(appActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          overwrite: true,
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
          route: ROUTE.MESSAGE_MAINTENANCE,
        }),
      );
    }
  };
};

const appActionRequestStarted = (): AppActionRequestStarted => ({
  type: AppAction.REQUEST_STARTED,
});

const appActionRequestSuccess = (siteInfo: AppApiResponseResult): AppActionRequestSuccess => ({
  type: AppAction.REQUEST_SUCCESS,
  siteInfo: siteInfo,
});

const appActionRequestFailure = (error: string): AppActionRequestFailure => ({
  type: AppAction.REQUEST_FAILURE,
  error: error,
});
