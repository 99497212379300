import { produce } from "immer";

import { Risk, RiskActionSetVoluntaryExcess } from "../../types";

const setVoluntaryExcess = (state: Risk, action: RiskActionSetVoluntaryExcess): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.vehicle.volXS = action.excess;
  });
};

export default setVoluntaryExcess;
