import { useEffect, useMemo, useState } from "react";

import { OptionEnhanced, OptionEvolution } from "./types";
import { UseHandleTiers } from "./types";

const useHandleTiers: UseHandleTiers = ({
  cart: { selectedEnhanced, selectedEnhancedPlus, selectedEvolution, selectedEvolutionPlus },
  enhanced,
  enhancedPlus,
  evolution,
  evolutionPlus,
}) => {
  const [isPlus, setIsPlus] = useState<boolean>(
    (!!enhancedPlus && selectedEnhancedPlus) || (!!evolutionPlus && selectedEvolutionPlus),
  );

  useEffect(() => {
    setIsPlus(prevState => prevState && (!!enhancedPlus || !!evolutionPlus));
  }, [enhancedPlus, evolutionPlus]);

  const optionEnhanced: OptionEnhanced = useMemo(
    () =>
      isPlus
        ? enhancedPlus && {
            isPlus: true,
            option: enhancedPlus,
            selected: selectedEnhancedPlus,
          }
        : enhanced && {
            isPlus: false,
            option: enhanced,
            selected: selectedEnhanced,
          },
    [enhanced, enhancedPlus, isPlus, selectedEnhanced, selectedEnhancedPlus],
  );

  const optionEvolution: OptionEvolution = useMemo(
    () =>
      isPlus
        ? evolutionPlus && {
            isPlus: true,
            option: evolutionPlus,
            selected: selectedEvolutionPlus,
          }
        : evolution && {
            isPlus: false,
            option: evolution,
            selected: selectedEvolution,
          },
    [evolution, evolutionPlus, isPlus, selectedEvolution, selectedEvolutionPlus],
  );

  return {
    optionEnhanced,
    optionEvolution,
  };
};

export default useHandleTiers;
