import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useModalConfirm } from "modules/modals/hooks";
import schema from "modules/schema/routes/riders";
import { Dispatch } from "modules/redux/store";
import { Risk } from "modules/risk/types";
import { UseRidersRoute } from "./types";
import useQuery from "../useQuery";

import useHandleBack from "./useHandleBack";
import useHandleDelete from "./useHandleDelete";
import useHandleEdit from "./useHandleEdit";
import useHandleMain from "./useHandleMain";
import useHandleNew from "./useHandleNew";
import useHandleSubmit from "./useHandleSubmit";
import useValidate from "../useValidate";
import useRedirect from "./useRedirect";

const useRidersRoute: UseRidersRoute = () => {
  const risk: Risk = useSelector(state => state.risk);
  const handleShowModalConfirm = useModalConfirm();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const handleBack = useHandleBack(navigate);

  const handleDelete = useHandleDelete(dispatch);

  const handleEdit = useHandleEdit(navigate);

  const handleMain = useHandleMain(dispatch);

  const handleNew = useHandleNew(navigate);

  const handleSubmit = useHandleSubmit({ dispatch, handleShowModalConfirm, risk });

  const redirect = useRedirect();

  const validate = useValidate(query);

  return {
    handleBack,
    handleDelete,
    handleEdit,
    handleMain,
    handleNew,
    handleSubmit,
    redirect,
    risk,
    schema,
    validate,
  };
};

export default useRidersRoute;
