import React, { useCallback, useContext, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";

import { events, eventsWithScroll, SessionAction } from "./constants";
import PlatformContext from "modules/platform/context";
import { Dispatch } from "modules/redux/store";
import { sessionDestroy } from "./actions";
import { SessionContext } from "./types";
import Context from "./context";

const timeout = (Number(process.env.REACT_APP_SESSION_TIMEOUT) || 25) * (60 * 1000);

type SessionContextProviderProps = {
  children: React.ReactNode;
};

const SessionContextProvider: React.FunctionComponent<SessionContextProviderProps> = ({ children }) => {
  const { isMacOs, isSafari } = useContext(PlatformContext);
  const [sessionActive, setSessionActive] = useState(true);
  const [sessionIdle, setSessionIdle] = useState(false);
  const dispatch: Dispatch = useDispatch();

  const { reset } = useIdleTimer({
    events: isMacOs && isSafari ? events : eventsWithScroll,
    eventsThrottle: 500,
    onIdle: () => sessionActive && setSessionIdle(true),
    stopOnIdle: true,
    throttle: 500,
    timeout: timeout,
  });

  const sessionAction = useCallback(
    (action: SessionAction) => {
      if (action === SessionAction.RESET) {
        setSessionIdle(false);
        reset();
      } else if (action === SessionAction.DESTROY) {
        dispatch(sessionDestroy());
        setSessionActive(false);
      }
    },
    [dispatch, reset],
  );

  const context = useMemo((): SessionContext => {
    return {
      sessionAction,
      sessionActive,
      sessionIdle,
    };
  }, [sessionAction, sessionActive, sessionIdle]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default SessionContextProvider;
