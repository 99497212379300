import yup, { CreateError, Messages } from "modules/validation";
import moment from "moment";

const validateInceptionDate = (
  createError: CreateError,
  inceptionDate: moment.Moment,
): boolean | yup.ValidationError => {
  return inceptionDate.isBefore(moment(), "day")
    ? createError({
        message: Messages.INCEPTION_DATE_BEFORE,
      })
    : inceptionDate.isAfter(moment().add(30, "days"), "day")
    ? createError({
        message: Messages.INCEPTION_DATE_AFTER,
      })
    : true;
};

function validator(this: yup.DateSchema) {
  return this.test("inceptionDate", Messages.DATE, function (value) {
    const inceptionDate = value ? moment(value, "YYYY-MM-DD", true) : false;
    return inceptionDate && inceptionDate.isValid() ? validateInceptionDate(this.createError, inceptionDate) : true;
  });
}

export default validator;
