import { InputAutocomplete, InputMode, InputType, Sanitisers } from "../../constants";
import { Mask, MaskProps } from "../../types";
import { handleInput } from "../../helpers";

const mask = (props: MaskProps): Mask =>
  props.platform.isMobile
    ? {
        autoComplete: InputAutocomplete.NEW_PASSWORD,
        inputMode: InputMode.EMAIL,
        maxLength: props.maxLength,
        onInput: handleInput(event => event.currentTarget.value.replace(Sanitisers.LEADING_WHITESPACE, "")),
        placeholder: props.placeholder,
        type: InputType.EMAIL,
      }
    : {
        autoComplete: InputAutocomplete.NEW_PASSWORD,
        maxLength: props.maxLength,
        onInput: handleInput(event => event.currentTarget.value.replace(Sanitisers.LEADING_WHITESPACE, "")),
        placeholder: props.placeholder,
        type: InputType.TEXT,
      };

export default mask;
