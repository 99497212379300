import { produce } from "immer";

import { Risk, RiskActionSetMonthly } from "../../types";
import { PaymentMethod } from "modules/risk/constants";

const setMonthly = (state: Risk, action: RiskActionSetMonthly): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.cart.paymentMethod = PaymentMethod.MONTHLY;
  });
};

export default setMonthly;
