import React from "react";

import { FormProps, TemplateProps } from "./types";
import * as Question from "components/Questions";
import PageWrapper from "components/PageWrapper";
import * as Form from "components/Form";
import * as Recall from "./Recall";

const Template: React.FunctionComponent<TemplateProps> = ({ handleSubmit, recall, schema }) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={recall}>
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
          <React.Fragment>
            <Form.Fieldset title="Retrieve Your Quote">
              <Question.QuestionText
                autoFocus={true}
                error={errors.webReference}
                label="Your web reference"
                name="webReference"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Web reference"
                readOnly={isSubmitting}
                touched={touched.webReference}
                value={values.webReference}
              />

              <Question.QuestionText
                error={errors.postcode}
                format={Question.QuestionTextFormat.POSTCODE}
                label="Your postcode"
                maxLength={8}
                name="postcode"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Postcode"
                readOnly={isSubmitting}
                touched={touched.postcode}
                value={values.postcode}
              />

              <Question.QuestionDate
                error={errors.dateOfBirth}
                format={Question.QuestionDateFormat.DATE}
                label="Your date of birth"
                name="dateOfBirth"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.dateOfBirth}
                value={values.dateOfBirth}
              />

              <Recall.RecallControl />
            </Form.Fieldset>

            <Recall.RecallBanner />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
