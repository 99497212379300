import { ROUTE } from "modules/router/constants";
import * as redirects from "./redirects";

export type RedirectType = (params: {
  aggregatorToken?: string;
  confirmationToken?: string;
  quoteToken?: string;
  redirect?: ROUTE;
  route: ROUTE;
}) => ROUTE | undefined;

const getRedirect: RedirectType = params => {
  switch (params.route) {
    case ROUTE.MOTORCYCLE:
      return redirects.redirectRouteMotorcycle(params);
    case ROUTE.MOTORCYCLES:
      return redirects.redirectRouteMotorcycles(params);
    case `${ROUTE.MOTORCYCLES}/:id`:
      return redirects.redirectRouteMotorcycles(params);
    case ROUTE.RIDER:
      return redirects.redirectRouteRider(params);
    case ROUTE.RIDERS:
      return redirects.redirectRouteRiders(params);
    case `${ROUTE.RIDERS}/:id`:
      return redirects.redirectRouteRiders(params);
    case ROUTE.QUOTE:
      return redirects.redirectRouteQuote(params);
    case ROUTE.PRODUCT:
      return redirects.redirectRouteProduct(params);
    case ROUTE.POLICY:
      return redirects.redirectRoutePolicy(params);
    case ROUTE.PAYMENT:
      return redirects.redirectRoutePayment(params);
    case ROUTE.CONFIRMATION:
      return redirects.redirectRouteConfirmation(params);
    case ROUTE.AGGREGATOR:
      return redirects.redirectRouteAggregator(params);
    case ROUTE.RECALL:
      return redirects.redirectRouteRecall(params);
    case ROUTE.DEBUG:
      return redirects.redirectRouteDebug(params);
    case ROUTE.MESSAGE:
      return redirects.redirectRouteMessage(params);
    default:
      return undefined;
  }
};

export default getRedirect;
