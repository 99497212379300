import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { resetAbiCode, resetSearch } from "../../helpers";
import { blurDocument } from "modules/utils";
import { validate } from "../helpers";

type UseHandleSubmitProps = (props: {
  errorRegNumber?: string;
  name: string;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: (state: boolean) => void;
  value: string;
}) => () => void;

const useHandleSubmit: UseHandleSubmitProps = ({
  errorRegNumber,
  name,
  setApiError,
  setFieldTouched,
  setFieldValue,
  setLoading,
  setOpen,
  value,
}) => {
  const handleSubmitRef = useRef<ReturnType<UseHandleSubmitProps>>(() => void 0);
  const handleSubmit = useCallback(() => handleSubmitRef.current(), []);

  useEffect(() => {
    handleSubmitRef.current = () => {
      resetAbiCode(name, setFieldTouched, setFieldValue);
      resetSearch(name, setFieldTouched, setFieldValue);
      setApiError(undefined);
      setOpen(false);
      blurDocument();
      validate(name, setApiError, setFieldTouched, setLoading, value, errorRegNumber);
    };
  }, [errorRegNumber, name, setApiError, setFieldTouched, setFieldValue, setLoading, setOpen, value]);

  return handleSubmit;
};

export default useHandleSubmit;
