import { VrmSearchApiResponseResult } from "modules/api/vrmSearch/types";
import Messages from "modules/validation/messages";
import { errorMessage } from "modules/api/errors";
import { ApiError } from "modules/api";
import { FormikProps } from "formik";

export const validate = (
  name: string,
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  regNumber: string,
  error?: string,
) => {
  setFieldTouched(`${name}.regNumber`, true);
  if (!regNumber || error) {
    setApiError(error === Messages.VRM_Q_PLATE ? ApiError.VRM_Q_PLATE : ApiError.VRM_INVALID_VRM);
  } else {
    setLoading(true);
  }
};

export const setFields = (
  result: VrmSearchApiResponseResult,
  name: string,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setFieldValue: FormikProps<number | string>["setFieldValue"],
): boolean => {
  if (result.abiCode && result.manufacturedYear) {
    setFieldValue(`${name}.abiCode`, result.abiCode);
    setTimeout(() => setFieldTouched(`${name}.abiCode`, false));

    setFieldValue(`${name}.yearOfManufacture`, result.manufacturedYear);
    setTimeout(() => setFieldTouched(`${name}.yearOfManufacture`, false));

    return true;
  } else {
    setFieldValue(`${name}.make`, result.make ? result.make : "");
    setTimeout(() => setFieldTouched(`${name}.make`, true));

    setFieldValue(`${name}.model`, result.model ? result.model : "");
    setTimeout(() => setFieldTouched(`${name}.model`, true));

    setFieldValue(`${name}.yearOfManufacture`, result.manufacturedYear ? result.manufacturedYear : "");
    setTimeout(() => setFieldTouched(`${name}.yearOfManufacture`, true));

    setFieldValue(`${name}.cc`, result.cc ? result.cc : "");
    setTimeout(() => setFieldTouched(`${name}.cc`, true));

    return false;
  }
};

export const getError = (
  apiError: string | undefined,
  errorAbiCode: string | undefined,
  errorRegNumber: string | undefined,
  isMainVehicle: boolean,
  open: boolean,
) => {
  if (!!apiError && (!isMainVehicle || (isMainVehicle && apiError !== ApiError.VRM_NOT_FOUND))) {
    return errorMessage(apiError);
  }

  if (errorRegNumber) {
    return errorRegNumber;
  }

  if (errorAbiCode && !open) {
    return errorAbiCode;
  }

  return undefined;
};

export const getTouched = (
  apiError: string | undefined,
  errorAbiCode: string | undefined,
  errorRegNumber: string | undefined,
  isMainVehicle: boolean,
  open: boolean,
  touchedAbiCode: boolean | undefined,
  touchedRegNumber: boolean | undefined,
) => {
  if (!!errorRegNumber && touchedRegNumber) {
    return true;
  }

  if (!!errorAbiCode && !open && touchedAbiCode) {
    return true;
  }

  if (!!apiError && touchedRegNumber && (!isMainVehicle || (isMainVehicle && apiError !== ApiError.VRM_NOT_FOUND))) {
    return true;
  }

  return false;
};
