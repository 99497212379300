import React from "react";
import { useId } from "react-id-generator";

import Autocomplete, { AutocompleteProps } from "components/Inputs/Autocomplete";
import { QuestionTemplateProps } from "../QuestionTemplate/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";

type QuestionAutocompleteProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> & Omit<AutocompleteProps, "id" | "invalid">
>;

const QuestionAutocomplete: React.FunctionComponent<QuestionAutocompleteProps> = ({
  disabled,
  error,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      size={size}
      touched={touched}>
      <React.Fragment>
        <Autocomplete disabled={disabled} id={id} invalid={!!error && touched} {...props} />
        {process.env.NODE_ENV === "development" && <Debug name={props.name} value={props.value} />}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default React.memo(QuestionAutocomplete);
