import { isEqual } from "lodash";

import { AnalyticsEventPageView, AnalyticsPayloadPageView } from "./types";
import { pushEvent } from "../utils";

let cache = {};

const analyticsEventPageView: AnalyticsEventPageView = route => {
  const payload: AnalyticsPayloadPageView = {
    VirtualPageViewLocation: window.location.href,
    VirtualPageViewPath: route?.path.replace("/:id", "/edit"),
    VirtualPageViewTitle: `Devitt Motorbike Insurance${route.title ? ` | ${route.title}` : ""}`,
  };

  if (!isEqual(cache, payload)) {
    pushEvent("VirtualPageView", payload);
    cache = payload;
  }
};

export default analyticsEventPageView;
