import React from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "No thanks",
  confirmLabel: "Upgrade me",
  title: "Upgrade your plan for a better saving",
  icon: ModalIconType.DEVITT_ENHANCED,
  text: (
    <React.Fragment>
      <h3>We suggest you upgrade to Devitt Enhanced to get an even bigger saving on your insurance</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat.
      </p>
      <ul>
        <li>Excepteur sint occaecat</li>
        <li>Cupidatat non proident</li>
      </ul>
    </React.Fragment>
  ),
};

export default template;
