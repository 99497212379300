import { useCallback } from "react";

import { riskActionRequest } from "modules/risk/actions";
import { RiskApiRequest } from "modules/risk/types";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = dispatch =>
  useCallback((values: RiskApiRequest) => dispatch(riskActionRequest(values)), [dispatch]);

export default useHandleSubmit;
