import React from "react";

import { ModalInfoTemplates } from "modules/modals/hooks";
import { PaymentMethod } from "modules/risk/constants";
import { OptionalExtra } from "modules/quote/types";
import useProductOverviewBreakdown from "./hooks";
import Option from "../Option";

type BreakdownProps = {
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  onShowModal: (params: {
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>;
    template: ModalInfoTemplates;
  }) => void;
  option1?: OptionalExtra;
  option2?: OptionalExtra;
  option3?: OptionalExtra;
  option4?: OptionalExtra;
  paymentMethod: PaymentMethod;
  selectedBreakdown: boolean;
  selectedBreakdown1: boolean;
  selectedBreakdown2: boolean;
  selectedBreakdown3: boolean;
  selectedBreakdown4: boolean;
};

const Breakdown: React.FunctionComponent<BreakdownProps> = ({
  onAdd,
  onRemove,
  onShowModal,
  paymentMethod,
  selectedBreakdown,
  ...props
}) => {
  const { label, modal, option } = useProductOverviewBreakdown(props);
  return option ? (
    <Option
      label={label}
      onAdd={onAdd}
      onRemove={onRemove}
      onShowModal={event => onShowModal({ event, template: modal })}
      option={option}
      paymentMethod={paymentMethod}
      selected={selectedBreakdown}
    />
  ) : null;
};

export default Breakdown;
