import { useCallback } from "react";

import { scrollTop } from "modules/scroll/helpers";
import { ROUTE } from "modules/router/constants";
import { UseHandleNew } from "./types";

const useHandleNew: UseHandleNew = navigate =>
  useCallback(async () => {
    await scrollTop();
    navigate(`${ROUTE.RIDERS}/new`);
  }, [navigate]);

export default useHandleNew;
