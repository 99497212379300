import { produce } from "immer";

import { Risk, RiskActionUpdateVehicle } from "../../types";

const updateVehicle = (state: Risk, action: RiskActionUpdateVehicle): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.vehicle = action.risk.vehicle;

    draftState.vehicle.regNumber = action.risk.vehicle.regNumber.replace(/\s+/g, "");

    if (process.env.REACT_APP_MULTIBIKE === "true") {
      draftState.hasAdditionalVehicles = action.risk.hasAdditionalVehicles;
      !action.risk.hasAdditionalVehicles && (draftState.additionalVehicles = []);

      !!action.risk.hasAdditionalVehicles && (draftState.hasAdditionalRiders = false);
      !!action.risk.hasAdditionalVehicles && (draftState.additionalRiders = []);
    }

    draftState.policy.previousInsuranceNCBYears = action.risk.policy.previousInsuranceNCBYears;
    draftState.policy.protectedBonus = action.risk.policy.protectedBonus;
    draftState.policy.inceptionDate = action.risk.policy.inceptionDate;
  });
};

export default updateVehicle;
