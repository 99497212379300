import { Sanitisers } from "components/Inputs/Input/constants";
import { handleInput } from "../helpers";
import { Mask, MaskProps } from "../types";

const mask = (props: MaskProps): Mask => ({
  maxLength: props.maxLength,
  onInput: handleInput(event => event.currentTarget.value.replace(Sanitisers.LEADING_WHITESPACE, "")),
  placeholder: props.placeholder,
});

export default mask;
