import React from "react";

import PageWrapper, { PageWrapperFormat } from "components/PageWrapper";
import { getMetadataLabel } from "modules/metadata/helpers";
import { MetadataType } from "modules/metadata/constants";
import { Risk } from "modules/risk/types";
import * as Form from "components/Form";
import { TemplateProps } from "./types";
import * as Components from "./Policy";

const Template: React.FunctionComponent<TemplateProps> = ({
  cart,
  handleBack,
  handleEdit,
  handleSubmit,
  metadata,
  quote,
  risk,
  schema,
}) => {
  return (
    <PageWrapper format={PageWrapperFormat.FULLWIDTH}>
      <Form.Wrapper<Risk> onSubmit={handleSubmit} schema={schema} state={risk}>
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
          <Components.PolicyWrapper
            onBack={handleBack}
            paymentMethod={risk.cart.paymentMethod}
            quote={quote}
            readOnly={isSubmitting}>
            <Components.PolicyWrapperLeft>
              <Components.PolicyOverview
                cart={cart}
                cover={getMetadataLabel(metadata[MetadataType.COVER_TYPE], risk.vehicle.cover)}
              />
            </Components.PolicyWrapperLeft>
            <Components.PolicyWrapperRight>
              <Components.PolicyReview
                errors={errors}
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.DOCUMENT_DELIVERY]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />

              <Components.PolicyYourDetails metadata={metadata} onEdit={handleEdit} quote={quote} risk={risk} />

              {risk.additionalRiders.length > 0 && (
                <Components.PolicyYourRiders
                  additionalRiders={risk.additionalRiders}
                  metadata={metadata}
                  onEdit={handleEdit}
                  proposer={risk.proposer}
                />
              )}

              {process.env.REACT_APP_MULTIBIKE === "true" && risk.additionalVehicles.length > 0 && (
                <Components.PolicyYourVehicles
                  additionalVehicles={risk.additionalVehicles}
                  onEdit={handleEdit}
                  vehicle={risk.vehicle}
                />
              )}

              {risk.isAggregator && (
                <Components.PolicyContact
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  values={values.additionalQuestions}
                />
              )}

              <Components.PolicyDocuments quote={quote} />

              {Object.values(quote.optionalExtras).filter(item => !!item?.selected).length > 0 && (
                <Components.PolicyAddOnsDocuments cart={cart} options={quote.optionalExtras} />
              )}

              {!!quote.precis?.endorsementWording.length && (
                <Components.PolicyEndorsements endorsements={quote.precis.endorsementWording} />
              )}

              {!!quote.precis?.termsWording.length && (
                <Components.PolicyText text={quote.precis.termsWording} title="Terms & Conditions" />
              )}

              {(!!quote.precis?.coverWording.length || !!quote.precis?.useWording.length) && (
                <Components.PolicyText
                  text={[...quote.precis.coverWording, ...quote.precis.useWording]}
                  title="Cover and Use"
                />
              )}

              <Components.PolicyPremium cart={cart} paymentMethod={risk.cart.paymentMethod} quote={quote} />

              <Components.PolicyAssumptions isOpen={true} />
            </Components.PolicyWrapperRight>
          </Components.PolicyWrapper>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
