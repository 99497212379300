import React, { useContext } from "react";
import clsx from "clsx";

import { ButtonStyle, ButtonTarget, ButtonType } from "./constants";
import PlatformContext from "modules/platform/context";

import styles from "./styles.module.scss";

export { ButtonStyle, ButtonTarget, ButtonType };

type CustomEvent = { pointerType?: "mouse" | "pen" | "touch" };

type ButtonProps = {
  allowFocus?: boolean;
  ariaLabel: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  id?: string;
  label?: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  readOnly?: boolean;
  style?: ButtonStyle;
  tabIndex?: number;
  target?: ButtonTarget;
  type?: ButtonType;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  allowFocus,
  ariaLabel,
  children,
  className,
  disabled,
  href,
  id,
  label,
  onClick,
  readOnly,
  style,
  tabIndex,
  target,
  type,
}) => {
  const { isSafari } = useContext(PlatformContext);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => {
    if (type === ButtonType.SUBMIT && !disabled && !readOnly) {
      event.currentTarget.closest("form")?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
    if (type !== ButtonType.SUBMIT && !disabled && !readOnly) {
      onClick && onClick(event);
    }
    event.preventDefault();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClick(event);
      event.stopPropagation();
    }
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>) => {
    event.preventDefault();
  };
  const handleSubmit = (event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.type === "click" && (event.nativeEvent.detail || (event.nativeEvent as CustomEvent).pointerType === "mouse")
      ? (readOnly || disabled) && event.preventDefault()
      : event.preventDefault();
  };
  const buttonClassName = className
    ? className
    : clsx(
        style && styles["button"],
        styles[`${style}`],
        disabled && styles["button-disabled"],
        readOnly && styles["button-read-only"],
      );
  return type === ButtonType.LINK ? (
    <a
      aria-disabled={disabled ? disabled : undefined}
      aria-label={ariaLabel}
      className={buttonClassName}
      id={id}
      href={href}
      onMouseDown={handleMouseDown}
      rel="noopener"
      role="button"
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
      target={target}>
      {children}
      {!!label && <span>{label}</span>}
    </a>
  ) : isSafari ? (
    <div
      aria-disabled={disabled ? disabled : undefined}
      aria-label={ariaLabel}
      className={buttonClassName}
      id={id}
      onClick={type === ButtonType.SUBMIT ? handleClick : onClick ? handleClick : undefined}
      onKeyDown={type === ButtonType.SUBMIT ? handleKeyDown : onClick ? handleKeyDown : undefined}
      onMouseDown={handleMouseDown}
      role="button"
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}>
      {children}
      {!!label && <span>{label}</span>}
    </div>
  ) : (
    <button
      aria-label={ariaLabel}
      className={buttonClassName}
      disabled={disabled}
      id={id}
      onClick={type === ButtonType.SUBMIT ? handleSubmit : onClick ? handleClick : undefined}
      onKeyDown={type === ButtonType.SUBMIT ? handleKeyDown : undefined}
      onMouseDown={handleMouseDown}
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
      type={type === ButtonType.SUBMIT ? "submit" : "button"}>
      {children}
      {!!label && <span>{label}</span>}
    </button>
  );
};

export default React.memo(Button);
