import React, { useCallback, useContext } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { RemoveScroll } from "react-remove-scroll";
import Zoom from "@mui/material/Zoom";

import { ReactComponent as CloseSVG } from "modules/theme/icons/form/inputs/clear.svg";
import Button, { ButtonStyle } from "components/Button";
import PlatformContext from "modules/platform/context";
import ModalOverlay from "../ModalOverlay";

import styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalInfoTemplateProps = {
  buttonLabel?: string;
  onClose?: () => void;
  text?: string | JSX.Element;
  title: string;
};

export const ModalInfoTemplate = React.memo<ModalInfoTemplateProps>(
  ({ buttonLabel = "Close", onClose, text, title }) => {
    return (
      <section className={styles["modal-info"]} role="dialog">
        <header>
          <h2>{title}</h2>
          <Button onClick={onClose} ariaLabel="Close">
            <CloseSVG />
          </Button>
        </header>
        <div>{typeof text === "string" ? <p>{text}</p> : text}</div>
        <footer>
          <Button ariaLabel={buttonLabel} label={buttonLabel} onClick={onClose} style={ButtonStyle.PRIMARY} />
        </footer>
      </section>
    );
  },
);

export type ModalInfoProps = {
  buttonLabel?: string;
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onClose?: () => void;
  open: boolean;
  text?: string | JSX.Element;
  title: string;
};

const ModalInfo: React.FunctionComponent<ModalInfoProps> = ({
  buttonLabel,
  destroyModal,
  disableRestoreFocus,
  hideModal,
  id,
  onClose,
  open,
  text,
  title,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Enter") {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose],
  );

  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}>
        <ModalInfoTemplate buttonLabel={buttonLabel} onClose={handleClose} text={text} title={title} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default ModalInfo;
