import { useCallback, useEffect, useRef } from "react";

import { VrmSearchApiResponseResult } from "modules/api/vrmSearch/types";
import { useVrmAction } from "modules/api/vrmSearch/hooks";

type UseHandleRequestProps = (value: string) => () => Promise<VrmSearchApiResponseResult>;

const useHandleRequest: UseHandleRequestProps = value => {
  const handleRequestRef = useRef(async (): Promise<VrmSearchApiResponseResult> => {
    return {} as VrmSearchApiResponseResult;
  });
  const handleRequest = useCallback(() => handleRequestRef.current(), []);
  const { vrmSearchRequest } = useVrmAction();

  useEffect(() => {
    handleRequestRef.current = async () => {
      const response = await vrmSearchRequest(value);
      return response;
    };
  }, [value, vrmSearchRequest]);

  return handleRequest;
};

export default useHandleRequest;
