import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Dispatch } from "modules/redux/store";
import { UseQuoteRoute } from "./types";

import useHandleDevittEvolution from "./useHandleDevittEvolution";
import useHandleDevittEnhanced from "./useHandleDevittEnhanced";
import useHandleContinue from "./useHandleContinue";
import useHandleDevitt from "./useHandleDevitt";
import useHandleBack from "./useHandleBack";
import useRedirect from "./useRedirect";
import { useCart } from "modules/cart";
import useProps from "./useProps";

import useHandleTiers from "./useHandleTiers";

const useQuoteRoute: UseQuoteRoute = () => {
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    cover,
    enhanced,
    enhancedPlus,
    evolution,
    evolutionPlus,
    hasAdditionalRiders,
    normallyPayByDirectDebit,
    optionalExtras,
    optionsCover,
    quote,
    quoteToken,
  } = useProps();

  const handleBack = useHandleBack({ hasAdditionalRiders, navigate });

  const handleContinue = useHandleContinue(navigate);

  const cart = useCart(quote.optionalExtras, optionalExtras);

  const { optionEnhanced, optionEvolution } = useHandleTiers({
    cart,
    enhanced,
    enhancedPlus,
    evolution,
    evolutionPlus,
  });

  const handleDevitt = useHandleDevitt({ dispatch, optionEnhanced, optionEvolution });

  const handleDevittEnhanced = useHandleDevittEnhanced({ dispatch, optionEnhanced });

  const handleDevittEvolution = useHandleDevittEvolution({ dispatch, optionEvolution });

  const redirect = useRedirect(quoteToken);

  return {
    cover,
    handleBack,
    handleContinue,
    handleDevitt,
    handleDevittEnhanced,
    handleDevittEvolution,
    normallyPayByDirectDebit,
    optionsCover,
    optionEnhanced,
    optionEvolution,
    quote,
    redirect,
  };
};

export default useQuoteRoute;
