export const AllowedKeys = [
  "Alt",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "Backspace",
  "CapsLock",
  "Control",
  "Delete",
  "Enter",
  "Escape",
  "Meta",
  "Tab",
  "Unidentified",
];

export enum InputMode {
  DECIMAL = "decimal",
  EMAIL = "email",
  NONE = "none",
  NUMERIC = "numeric",
  SEARCH = "search",
  TEL = "tel",
  TEXT = "text",
  URL = "url",
}

export enum InputType {
  DATE = "date",
  EMAIL = "email",
  MONTH = "month",
  NUMBER = "number",
  PASSWORD = "password",
  TEL = "tel",
  TEXT = "text",
}

export enum InputAutocomplete {
  OFF = "off",
  NEW_PASSWORD = "new-password",
}

export enum InputDateFormat {
  DATE = "date",
  MONTH = "month",
}

export enum InputNumberFormat {
  CURRENCY = "currency",
  DECIMAL = "decimal",
  INTEGER = "integer",
}

export enum InputStringFormat {
  ACCOUNT = "account",
  EMAIL = "email",
  NAME = "name",
  PASSWORD = "password",
  POSTCODE = "postcode",
  SORTCODE = "sortcode",
  TEL = "tel",
  TEXT = "text",
  VRM = "vrm",
}

export type InputFormat = InputDateFormat | InputNumberFormat | InputStringFormat;

export const RegularExpressions: { [key: string]: RegExp } = {
  ALPHANUMERIC: /[a-zA-Z0-9]/,
  ALPHANUMERIC_ALLOW_SPACE: /[a-zA-Z0-9\s]/,
  DECIMAL: /^\d|^\./,
  DECIMAL_ALLOW_NEGATIVE: /^\d|^\.|^-/,
  INTEGER: /^\d/,
  INTEGER_ALLOW_SPACE: /^\d|\s/,
  INTEGER_ALLOW_NEGATIVE: /^\d|^-/,
  NAME: /[a-zA-Z-' ]/,
};

export const Sanitisers = {
  ALPHANUMERIC: /[^a-zA-Z0-9]/g,
  ALPHANUMERIC_ALLOW_SPACE: /[^a-zA-Z0-9\s]/g,
  DECIMAL: /[^\d.]/g,
  DECIMAL_ALLOW_NEGATIVE: /(?!^-)[^\d.]/g,
  DECIMAL_PLACES: (value: string | number) => new RegExp("(^-?\\d*.?\\d{0," + value + "})\\d*", "g"),
  INTEGER: /\D+/g,
  INTEGER_ALLOW_SPACE: /[^\d|\s]/g,
  INTEGER_ALLOW_NEGATIVE: /(?!^-)[^\d]/g,
  LEADING_NEGATIVE_PERIOD: /^-\./g,
  LEADING_PERIOD: /^\./g,
  LEADING_WHITESPACE: /^\s+/g,
  LEADING_ZEROS: /^(-?)(0+(?=[1-9])|0+(?=0))/g,
  NAME: /[^a-zA-Z\s\-']/g,
  PERIOD: /\./g,
  TRAILING_ZEROS: /^(\d*[\d.]*?)\.?0*$/g,
};
