import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { AbiApiResponseResult } from "modules/api/abi/types";
import { resetAbiCode, resetSearch } from "../../helpers";
import { setFields } from "../helpers";

type UseHandleResponseProps = (props: {
  name: string;
  setFieldTouched: FormikProps<number | string>["setFieldTouched"];
  setFieldValue: FormikProps<number | string>["setFieldValue"];
  setOpen: (state: boolean) => void;
  setResult: React.Dispatch<React.SetStateAction<AbiApiResponseResult | undefined>>;
  valueYearOfManufacture: number | "";
}) => (response: AbiApiResponseResult) => void;

const useHandleResponse: UseHandleResponseProps = ({
  name,
  setFieldTouched,
  setFieldValue,
  setOpen,
  setResult,
  valueYearOfManufacture,
}) => {
  const handleResponseRef = useRef<ReturnType<UseHandleResponseProps>>(() => void 0);
  const handleResponse = useCallback<(response: AbiApiResponseResult) => void>(
    response => handleResponseRef.current(response),
    [],
  );

  useEffect(() => {
    handleResponseRef.current = response => {
      if (valueYearOfManufacture) {
        setResult(response);
      } else {
        response
          ? setFields(name, response, setFieldTouched, setFieldValue, valueYearOfManufacture)
          : resetSearch(name, setFieldTouched, setFieldValue);
        resetAbiCode(name, setFieldTouched, setFieldValue);
        setOpen(true);
      }
    };
  }, [name, setFieldTouched, setFieldValue, setOpen, setResult, valueYearOfManufacture]);

  return handleResponse;
};

export default useHandleResponse;
