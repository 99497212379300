import React, { useCallback, useEffect, useState } from "react";

import { QuestionDate, QuestionDateFormat } from "components/Questions";
import { QuestionTemplateProps } from "../QuestionTemplate/types";
import { valueToDate, valueToMonth } from "./helpers";
import { Expand } from "modules/typescript/helpers";
import { DateProps } from "components/Inputs/Date";

type QuestionPurchasedProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> & Omit<DateProps, "format" | "id" | "invalid"> & { open: boolean }
>;

const QuestionPurchased: React.FunctionComponent<QuestionPurchasedProps> = ({
  open,
  setFieldValue,
  value,
  ...props
}) => {
  const [date, setDate] = useState(valueToMonth(value));

  const handleSetFieldValue = useCallback(
    (field: string, value: string) => {
      setFieldValue(field, valueToDate(value));
      setDate(value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    !open && setDate("");
  }, [open]);

  return (
    <QuestionDate
      format={QuestionDateFormat.MONTH}
      setFieldValue={handleSetFieldValue}
      showFallback={true}
      value={date}
      {...props}
    />
  );
};

export default React.memo(QuestionPurchased);
