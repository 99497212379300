import { AnalyticsItemPersonalAccident } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemPersonalAccident: AnalyticsItemPersonalAccident = price => ({
  item_id: "PERSONAL_ACCIDENT",
  item_name: "Personal Accident",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemPersonalAccident;
