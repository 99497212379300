import { useEffect } from "react";

import { AbiApiResponseResult } from "modules/api/abi/types";
import { Messages } from "modules/validation";
import { setFields } from "../helpers";
import { FormikProps } from "formik";

type UseHandleYearOfManufacture = (props: {
  errorYearOfManufacture?: string;
  name: string;
  open: boolean;
  result?: AbiApiResponseResult;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setOpen: (state: boolean) => void;
  valueYearOfManufacture: number | "";
}) => void;

const useHandleYearOfManufacture: UseHandleYearOfManufacture = ({
  errorYearOfManufacture,
  name,
  open,
  result,
  setFieldTouched,
  setFieldValue,
  setOpen,
  valueYearOfManufacture,
}) => {
  useEffect(() => {
    if (!open && !!result && errorYearOfManufacture === Messages.YEAR_OF_MANUFACTURE) {
      setFields(name, result, setFieldTouched, setFieldValue, valueYearOfManufacture, false);
      setOpen(true);
    }
  }, [errorYearOfManufacture, name, open, result, setFieldTouched, setFieldValue, setOpen, valueYearOfManufacture]);
};

export default useHandleYearOfManufacture;
