import React from "react";
import { useId } from "react-id-generator";

import Buttons, { ButtonsFormat, ButtonsProps, ButtonsType } from "components/Inputs/Buttons";
import { QuestionTemplateProps } from "../QuestionTemplate/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";

export type QuestionSwitchProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> &
    Omit<ButtonsProps, "format" | "id" | "invalid" | "type" | "value"> & {
      value: "" | boolean;
    }
>;

const defaultOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const QuestionSwitch: React.FunctionComponent<QuestionSwitchProps> = ({
  disabled,
  error,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      touched={touched}>
      <React.Fragment>
        <Buttons
          disabled={disabled}
          format={ButtonsFormat.HORIZONTAL}
          id={id}
          invalid={!!error && touched}
          options={defaultOptions}
          type={ButtonsType.SWITCH}
          {...props}
        />
        {process.env.NODE_ENV === "development" && <Debug name={props.name} value={props.value} />}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default React.memo(QuestionSwitch);
