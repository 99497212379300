import { useCallback, useEffect, useRef } from "react";
import { VehicleApiResponseResult } from "modules/api/vehicle/types";
import { resetAbiCode } from "../../helpers";
import { FormikProps } from "formik";

type Event = React.ChangeEvent<HTMLInputElement | HTMLSelectElement>;

type UseHandleChangeProps = (props: {
  name: string;
  onChange: (event: Event) => void;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  setResults: React.Dispatch<React.SetStateAction<VehicleApiResponseResult | undefined>>;
  valueAbiCode: string;
}) => (event: Event) => void;

const useHandleChange: UseHandleChangeProps = ({
  name,
  onChange,
  setApiError,
  setFieldTouched,
  setFieldValue,
  setResults,
  valueAbiCode,
}) => {
  const handleChangeRef = useRef<ReturnType<UseHandleChangeProps>>(() => void 0);
  const handleChange = useCallback<(event: Event) => void>(event => handleChangeRef.current(event), []);

  useEffect(() => {
    handleChangeRef.current = event => {
      if (event.currentTarget.name !== `${name}.abiCode`) {
        valueAbiCode && resetAbiCode(name, setFieldTouched, setFieldValue);
        setResults(undefined);
      }
      setApiError(undefined);
      onChange(event);
    };
  }, [name, onChange, setApiError, setFieldTouched, setFieldValue, setResults, valueAbiCode]);

  return handleChange;
};

export default useHandleChange;
