import { cacheResult, cacheSearch } from "./cache";
import { AccountValidateApi } from "./types";
import { api, API } from "../";

const accountValidateApi: AccountValidateApi = account => {
  return new Promise((resolve, reject) => {
    const cached = cacheSearch(account);
    if (cached) {
      resolve(cached.result);
    } else {
      api(API.ACCOUNT_VALIDATE_REQUEST(account)).then(
        response => {
          cacheResult(account, response.data.valid);
          resolve(response.data.valid);
        },
        error => reject(error),
      );
    }
  });
};

export default accountValidateApi;
