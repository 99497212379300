import { useContext, useRef } from "react";

import PlatformContext from "modules/platform/context";
import { MaskProps } from "./types";
import { getMask } from "./helpers";

export function useMask(props: Omit<MaskProps, "platform">) {
  const platform = useContext(PlatformContext);
  const mask = useRef(getMask({ platform: platform, ...props }));
  return mask.current;
}
