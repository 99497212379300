export type Storage = {
  setItem: (key: string, session: boolean, value: Record<string, unknown>) => void;
  getItem: <T>(key: string, session: boolean) => T | null;
  removeItem: (key: string, session: boolean) => void;
};

const storage: Storage = {
  setItem: (key, session, value) =>
    session ? sessionStorage.setItem(key, JSON.stringify(value)) : localStorage.setItem(key, JSON.stringify(value)),

  getItem: (key, session) => {
    const value = session ? sessionStorage.getItem(key) : localStorage.getItem(key);
    return value ? JSON.parse(value) : value;
  },

  removeItem: (key, session) => (session ? sessionStorage.removeItem(key) : localStorage.removeItem(key)),
};

export default storage;
