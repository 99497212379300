import moment from "moment";

import { cacheResult, cacheSearch } from "../abi/cache";
import { api, API } from "../";
import {
  YearOfManufactureValidateDate,
  YearOfManufactureValidateApi,
  YearOfManufactureGetYears,
  YearOfManufactureGetYear,
} from "./types";

const yearOfManufactureGetYear: YearOfManufactureGetYear = (years, start, end) => {
  const year = years.slice(start, end);
  return years.replace(/\s+/g, "").split("-").includes(year) && moment(year, "YYYY", true).isValid()
    ? Number(year)
    : null;
};

const yearOfManufactureGetYears: YearOfManufactureGetYears = years => {
  const startYear = yearOfManufactureGetYear(years, 0, 4);
  const endYear = yearOfManufactureGetYear(years, 5, 9);
  return !!startYear && !!endYear
    ? { startYear: startYear > endYear ? endYear : startYear, endYear: startYear > endYear ? startYear : endYear }
    : { startYear, endYear };
};

const yearOfManufactureValidateDate: YearOfManufactureValidateDate = (year, years) => {
  const { startYear, endYear } = yearOfManufactureGetYears(years);
  return !!startYear && !!endYear ? year >= startYear && year <= endYear : true;
};

const yearOfManufactureValidateApi: YearOfManufactureValidateApi = ({ abiCode, yearOfManufacture }) => {
  return new Promise((resolve, reject) => {
    const cached = cacheSearch(abiCode);
    if (cached) {
      resolve(yearOfManufactureValidateDate(yearOfManufacture, cached.result.productionYears));
    } else {
      api(API.ABI_REQUEST(abiCode)).then(
        response => {
          cacheResult(abiCode, response.data.bike);
          resolve(yearOfManufactureValidateDate(yearOfManufacture, response.data.bike.productionYears));
        },
        error => reject(error),
      );
    }
  });
};

export default yearOfManufactureValidateApi;
