import React, { useEffect } from "react";
import { FormikErrors, FormikValues } from "formik";

import logger from "modules/logger";

type ErrorLogProps<T> = {
  errors: FormikErrors<T>;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

function ErrorLog<T extends FormikValues>({ errors, setCount }: ErrorLogProps<T>) {
  useEffect(() => {
    logger.debug(
      `%cyup %c@@event/Validation Error`,
      `color: #757575; font-size: 12px; font-weight: normal`,
      `color: #FF0000; font-size: 13px; font-weight: bold`,
    );
    logger.debug(JSON.stringify(errors, null, 2));
    setCount(count => count + 1);
  }, [errors, setCount]);

  return null;
}

export default React.memo(ErrorLog) as typeof ErrorLog;
