import React from "react";

import { ReactComponent as HeraldLogoSVG } from "modules/theme/partners/herald.svg";

import styles from "./styles.module.scss";

export enum PartnerIconType {
  HERALD = "G027",
}

type PartnerIconProps = {
  subAgent?: PartnerIconType;
};

function PartnerIcon({ subAgent }: PartnerIconProps) {
  switch (subAgent) {
    case PartnerIconType.HERALD:
      return <HeraldLogoSVG className={styles["header-logo-partner-herald"]} />;
    default:
      return null;
  }
}

export default React.memo(PartnerIcon);
