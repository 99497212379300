import React from "react";
import { useOnMount } from "modules/utils";

type ValidateProps = {
  setShouldScroll: (value: React.SetStateAction<boolean>) => void;
  submitForm: () => void;
};

const Validate: React.FunctionComponent<ValidateProps> = ({ setShouldScroll, submitForm }) => {
  useOnMount(() => {
    setTimeout(() => {
      setShouldScroll(true);
      submitForm();
    }, 500);
  });
  return null;
};

export default React.memo(Validate);
