import React from "react";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";

type LabelProps = {
  disabled?: boolean;
};

const Label: React.FunctionComponent<LabelProps> = ({ disabled }) => {
  const handleShowModalInfo = useModalInfo();
  return (
    <React.Fragment>
      Does your motorcycle have any modifications not included in our{" "}
      <button
        aria-label="Accepted as standard"
        disabled={disabled}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          handleShowModalInfo({ disableRestoreFocus: true, event, template: ModalInfoTemplates.MODIFICATIONS });
        }}
        onMouseDown={event => event.preventDefault()}
        type="button">
        Accepted as standard
      </button>{" "}
      list?
    </React.Fragment>
  );
};

export default React.memo(Label);
