import { useCallback, useEffect, useRef } from "react";

import { LiveChatAvailability, LiveChatVisibility } from "../types";
import useHandleAvailability from "./handleAvailability";
import useHandleVisibility from "./handleVisibility";

type Data = {
  state: {
    availability: LiveChatAvailability;
    visibility: LiveChatVisibility;
  };
};

type UseHandleReadyProps = (props: {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setAvailability: React.Dispatch<React.SetStateAction<Data["state"]["availability"]>>;
  setVisibility: React.Dispatch<React.SetStateAction<Data["state"]["visibility"]>>;
}) => (data: Data) => void;

const useHandleReady: UseHandleReadyProps = ({ setActive, setAvailability, setVisibility }) => {
  const handleReadyRef = useRef<ReturnType<UseHandleReadyProps>>(() => void 0);
  const handleReady = useCallback((data: Data) => handleReadyRef.current(data), []);
  const handleAvailability = useHandleAvailability(setAvailability);
  const handleVisibility = useHandleVisibility(setVisibility);

  useEffect(() => {
    handleReadyRef.current = (data: Data) => {
      window.LiveChatWidget && window.LiveChatWidget.on("availability_changed", handleAvailability);
      window.LiveChatWidget && window.LiveChatWidget.on("visibility_changed", handleVisibility);
      window.LiveChatWidget && window.LiveChatWidget.call("hide");

      document.getElementById("chat-widget")?.style.removeProperty("transition");
      document.getElementsByTagName("html")[0].classList.add("platform-chat");

      setAvailability(data.state.availability);
      setVisibility(data.state.visibility);
      setActive(true);
    };
  }, [handleAvailability, handleVisibility, setActive, setAvailability, setVisibility]);

  return handleReady;
};

export default useHandleReady;
