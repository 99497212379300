import React from "react";

import { ButtonIconGroup } from "components/Inputs/Buttons/Button/Icons";
import PageWrapper, { Control } from "components/PageWrapper";
import { MetadataType } from "modules/metadata/constants";
import { FormProps, TemplateProps } from "./types";
import * as Question from "components/Questions";
import Progress from "components/Progress";
import * as Form from "components/Form";

const Template: React.FunctionComponent<TemplateProps> = ({
  index,
  metadata,
  handleCancel,
  handleSubmit,
  schema,
  state,
  validate,
}) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={state} validate={validate}>
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
          <React.Fragment>
            <Progress
              hasAdditionalRiders={state.hasAdditionalRiders}
              hasAdditionalVehicles={state.hasAdditionalVehicles}
              readOnly={isSubmitting}
            />

            <Form.Fieldset title={`Additional Motorcycle ${index + 1}`}>
              <Question.QuestionVehicle
                errorAbiCode={errors?.currentAdditionalVehicle?.abiCode}
                errorCC={errors?.currentAdditionalVehicle?.cc}
                errorMake={errors?.currentAdditionalVehicle?.make}
                errorModel={errors?.currentAdditionalVehicle?.model}
                errorRegNumber={errors?.currentAdditionalVehicle?.regNumber}
                errorYearOfManufacture={errors?.currentAdditionalVehicle?.yearOfManufacture}
                readOnly={isSubmitting}
                name="currentAdditionalVehicle"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.MAKE]}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touchedAbiCode={touched?.currentAdditionalVehicle?.abiCode}
                touchedCC={touched?.currentAdditionalVehicle?.cc}
                touchedMake={touched?.currentAdditionalVehicle?.make}
                touchedModel={touched?.currentAdditionalVehicle?.model}
                touchedRegNumber={touched?.currentAdditionalVehicle?.regNumber}
                touchedYearOfManufacture={touched?.currentAdditionalVehicle?.yearOfManufacture}
                valueAbiCode={values.currentAdditionalVehicle.abiCode}
                valueCC={values.currentAdditionalVehicle.cc}
                valueMake={values.currentAdditionalVehicle.make}
                valueModel={values.currentAdditionalVehicle.model}
                valueRegNumber={values.currentAdditionalVehicle.regNumber}
                valueYearOfManufacture={values.currentAdditionalVehicle.yearOfManufacture}
              />

              <Question.QuestionTracker
                error={errors.currentAdditionalVehicle?.tracker}
                name="currentAdditionalVehicle.tracker"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.TRACKER]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.tracker}
                value={values.currentAdditionalVehicle.tracker}
              />

              <Question.QuestionRadio
                error={errors.currentAdditionalVehicle?.alarm}
                format={Question.QuestionRadioFormat.HORIZONTAL}
                help="<p>Security tags allow the police to easily trace the owner if a motorcycle is recovered after being stolen.</p>
                      <h2>Alphadot</h2>
                      <p>The motorcycle is marked with microdots containing ownership information.</p>
                      <h2>Datatag</h2>
                      <p>Various components of the motorcycle are marked with microdots, radio transponders, and other mechanisms.</p>
                      <h2>SmartWater</h2>
                      <p>The motorcycle is marked with an invisible liquid with a unique chemical signature.</p>"
                icons={ButtonIconGroup.ALARM}
                label="Has the motorcycle been tagged?"
                name="currentAdditionalVehicle.alarm"
                options={metadata[MetadataType.ALARM]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.alarm}
                value={values.currentAdditionalVehicle.alarm}
              />

              <Question.QuestionSecurityDevices
                error={errors.currentAdditionalVehicle?.immob}
                name="currentAdditionalVehicle.immob"
                onBlur={handleBlur}
                options={metadata[MetadataType.IMMOBILISER]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.immob}
                value={values.currentAdditionalVehicle.immob}
              />

              <Question.QuestionSwitch
                error={errors.currentAdditionalVehicle?.imported}
                label="Is your motorcycle an import?<br/><small>(Not usually sold in the UK)</small>"
                name="currentAdditionalVehicle.imported"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.imported}
                value={values.currentAdditionalVehicle.imported}
              />

              <Question.QuestionSwitch
                error={errors.currentAdditionalVehicle?.purchasedYet}
                label="Have you purchased the motorcycle?"
                name="currentAdditionalVehicle.purchasedYet"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.purchasedYet}
                value={values.currentAdditionalVehicle.purchasedYet}
              />

              <Question.QuestionSecondary
                name="currentAdditionalVehicle.purchased"
                open={values.currentAdditionalVehicle.purchasedYet === true}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.currentAdditionalVehicle.purchased}>
                <Question.QuestionPurchased
                  error={errors.currentAdditionalVehicle?.purchased}
                  label="Enter the date of purchase"
                  name="currentAdditionalVehicle.purchased"
                  open={values.currentAdditionalVehicle.purchasedYet === true}
                  readOnly={isSubmitting}
                  secondary={true}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.currentAdditionalVehicle?.purchased}
                  value={values.currentAdditionalVehicle.purchased}
                />
              </Question.QuestionSecondary>

              <Question.QuestionNumber
                error={errors.currentAdditionalVehicle?.value}
                help="Please note that this should be the approximate market value of the motorcycle at present, to the nearest pound. This should not be the original price paid. Please enter only digits - no commas or full stops, e.g. 1000."
                format={Question.QuestionNumberFormat.CURRENCY}
                label="Estimated market value"
                maxLength={6}
                name="currentAdditionalVehicle.value"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.currentAdditionalVehicle?.value}
                value={values.currentAdditionalVehicle.value}
              />

              <Question.QuestionNumber
                error={errors.currentAdditionalVehicle?.accessoriesValue}
                help="Accessories are items fitted to motorcycles, which do not form part of the manufacturer's standard specification, e.g. panniers, luggage rack, windscreen, etc."
                format={Question.QuestionNumberFormat.CURRENCY}
                label="Value of any accessories fitted<br/><small>(enter '0' if none fitted)</small>"
                maxLength={6}
                name="currentAdditionalVehicle.accessoriesValue"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.currentAdditionalVehicle?.accessoriesValue}
                value={values.currentAdditionalVehicle.accessoriesValue}
              />

              {!values.currentAdditionalVehicle.hideModifications && (
                <Question.QuestionModifications
                  error={errors.currentAdditionalVehicle?.modifications}
                  name="currentAdditionalVehicle.modifications"
                  readOnly={isSubmitting}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.currentAdditionalVehicle?.modifications}
                  value={values.currentAdditionalVehicle.modifications}
                />
              )}
            </Form.Fieldset>

            <Form.Fieldset title="About the motorcycle use">
              <Question.QuestionRadio
                error={errors.currentAdditionalVehicle?.whereKept}
                help="A garage refers to a lockable brick built / concrete sectional garage, which is at the home address. It does not include wooden structures e.g. sheds or any garage which is not at the home address."
                icons={ButtonIconGroup.WHERE_KEPT}
                label="Where is your motorcycle kept overnight?"
                name="currentAdditionalVehicle.whereKept"
                options={metadata[MetadataType.WHERE_KEPT]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.whereKept}
                value={values.currentAdditionalVehicle.whereKept}
              />

              <Question.QuestionSwitch
                error={errors.currentAdditionalVehicle?.keptAtHome}
                label="Is the motorcycle normally kept at your home address?"
                name="currentAdditionalVehicle.keptAtHome"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.keptAtHome}
                value={values.currentAdditionalVehicle.keptAtHome}
              />

              <Question.QuestionSecondary
                name="currentAdditionalVehicle.keptPostcode"
                open={values.currentAdditionalVehicle.keptAtHome === false}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.currentAdditionalVehicle.keptPostcode}>
                <Question.QuestionText
                  error={errors.currentAdditionalVehicle?.keptPostcode}
                  format={Question.QuestionTextFormat.POSTCODE}
                  label="Enter the postcode where the motorcycle is normally kept overnight"
                  name="currentAdditionalVehicle.keptPostcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  secondary={true}
                  touched={touched.currentAdditionalVehicle?.keptPostcode}
                  value={values.currentAdditionalVehicle.keptPostcode}
                />
              </Question.QuestionSecondary>

              <Question.QuestionRadio
                error={errors.currentAdditionalVehicle?.classOfUse}
                format={Question.QuestionRadioFormat.VERTICAL}
                help="<p>Social Domestic and Pleasure Only covers personal use only. It does not cover you to ride to and from work.</p>
                      <p>Social Domestic and Pleasure, including Commuting covers personal use and riding to and from a single place of work.</p>
                      <p>Business Use by you, or you and your spouse, or you and any named riders covers personal use, riding to and from work, and use for your business and that of your employer (riding to other sites and meetings elsewhere) but not including travel to make sales or deliver goods.</p>
                      <p>Courier or Despatch Rider covers carrying / delivering packages for others in exchange for payment.</p>"
                label="What will the motorcycle be used for?"
                name="currentAdditionalVehicle.classOfUse"
                options={metadata[MetadataType.CLASS_OF_USE]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.classOfUse}
                value={values.currentAdditionalVehicle.classOfUse}
              />

              <Question.QuestionSwitch
                error={errors.currentAdditionalVehicle?.includesPillion}
                help="Allows you to ride with a passenger on your motorcycle."
                label="Do you want to include pillion (passenger) use?"
                name="currentAdditionalVehicle.includesPillion"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.includesPillion}
                value={values.currentAdditionalVehicle.includesPillion}
              />

              <Question.QuestionNumber
                error={errors.currentAdditionalVehicle?.mileage}
                help="Please estimate your annual mileage."
                format={Question.QuestionNumberFormat.INTEGER}
                info={
                  values.currentAdditionalVehicle.mileage && values.currentAdditionalVehicle.mileage > 100 ? (
                    <Question.AnnualMileage mileage={values.currentAdditionalVehicle.mileage} />
                  ) : undefined
                }
                label="What is the estimated annual mileage on this motorcycle?"
                maxLength={6}
                name="currentAdditionalVehicle.mileage"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.currentAdditionalVehicle?.mileage}
                value={values.currentAdditionalVehicle.mileage}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Your cover">
              <Question.QuestionRadio
                error={errors.currentAdditionalVehicle?.cover}
                help="<h2>Comprehensive</h2>
                      <p>Covers you for accidental damage, theft, damage as a result of a fire, theft or attempted theft. You are also covered for claims made against you by other people for bodily injury or damage to their property.</p>
                      <h2>Third Party, Fire & Theft</h2>
                      <p>Covers you for theft and damage as a result of a fire, theft or attempted theft. You are also covered for claims made against you by other people for bodily injury or damage to their property.</p>
                      <h2>Third Party Only</h2>
                      <p>Cover for claims made against you by other people for bodily injury or damage to their property. It does not cover any damage to your own motorcycle.</p>"
                icons={ButtonIconGroup.COVER}
                label="Level of cover required"
                name="currentAdditionalVehicle.cover"
                options={metadata[MetadataType.COVER_TYPE]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalVehicle?.cover}
                value={values.currentAdditionalVehicle.cover}
              />

              <Question.QuestionSecondary
                name="currentAdditionalVehicle.volXS"
                open={["C", "TPFT"].includes(values.currentAdditionalVehicle.cover)}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.currentAdditionalVehicle.volXS}>
                <Question.QuestionSelect
                  error={errors.currentAdditionalVehicle?.volXS}
                  help="This will be in addition to any excess applied by the insurer and may lead to a reduced premium."
                  label="Voluntary Accidental Damage excess"
                  name="currentAdditionalVehicle.volXS"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={metadata[MetadataType.VOL_XS]}
                  readOnly={isSubmitting}
                  secondary={true}
                  touched={touched.currentAdditionalVehicle?.volXS}
                  value={values.currentAdditionalVehicle.volXS}
                />
              </Question.QuestionSecondary>
            </Form.Fieldset>

            <Control backLabel="Cancel" isSubmitting={isSubmitting} onBack={handleCancel} submit={true} />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
