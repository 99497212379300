import { useEffect } from "react";

import { AbiApiRequest, AbiApiResponseResult } from "modules/api/abi/types";
import { isError } from "modules/utils";

type UseHandleRequestProps = (props: {
  abiRequest: (search: AbiApiRequest) => Promise<AbiApiResponseResult>;
  handleClick: () => void;
  handleError: (error: Error) => void;
  handleResponse: (response: AbiApiResponseResult) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setResult: React.Dispatch<React.SetStateAction<AbiApiResponseResult | undefined>>;
  valueAbiCode: string;
}) => void;

const useHandleRequest: UseHandleRequestProps = ({
  abiRequest,
  handleClick,
  handleError,
  handleResponse,
  setLoading,
  setResult,
  valueAbiCode,
}) => {
  useEffect(() => {
    if (valueAbiCode) {
      let active = true;
      setLoading(true);
      setResult(undefined);
      (async function () {
        try {
          const response = await abiRequest(valueAbiCode);
          active && handleResponse(response);
        } catch (error) {
          active && isError(error) && handleError(error);
        } finally {
          active && setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [abiRequest, handleClick, handleError, handleResponse, setLoading, setResult, valueAbiCode]);
};

export default useHandleRequest;
