import { AggregatorAction } from "modules/aggregator/constants";
import { AggregatorActionType } from "modules/aggregator/types";
import { Risk, RiskActionType } from "../types";
import { RiskAction } from "../constants";
import { newRiskDebug } from "../debug";
import { newRisk } from "../helpers";
import * as helpers from "./helpers";

//const initialState: Risk = newRisk();
const initialState: Risk = process.env.NODE_ENV === "development" ? newRiskDebug() : newRisk();

function riskReducer(state = initialState, action: AggregatorActionType | RiskActionType) {
  switch (action.type) {
    case AggregatorAction.REQUEST_SUCCESS:
      return helpers.requestSuccess(state, action);

    case RiskAction.REQUEST_SUCCESS:
      return helpers.requestSuccess(state, action);

    case RiskAction.DELETE_VEHICLE:
      return helpers.deleteVehicle(state, action);

    case RiskAction.UPDATE_VEHICLE:
      return helpers.updateVehicle(state, action);

    case RiskAction.NEW_ADDITIONAL_VEHICLE:
      return helpers.newAdditionalVehicle(state, action);

    case RiskAction.UPDATE_ADDITIONAL_VEHICLE:
      return helpers.updateAdditionalVehicle(state, action);

    case RiskAction.RESET_ADDITIONAL_VEHICLES:
      return helpers.resetAdditionalVehicles(state, action);

    case RiskAction.SET_MAIN_RIDER:
      return helpers.setMainRider(state, action);

    case RiskAction.UPDATE_RIDER:
      return helpers.updateRider(state, action);

    case RiskAction.DELETE_ADDITIONAL_RIDER:
      return helpers.deleteAdditionalRider(state, action);

    case RiskAction.NEW_ADDITIONAL_RIDER:
      return helpers.newAdditionalRider(state, action);

    case RiskAction.UPDATE_ADDITIONAL_RIDER:
      return helpers.updateAdditionalRider(state, action);

    case RiskAction.RESET_ADDITIONAL_RIDERS:
      return helpers.resetAdditionalRiders(state, action);

    case RiskAction.UPDATE_CART:
      return helpers.updateCart(state, action);

    case RiskAction.ADD_OPTIONAL_EXTRA:
      return helpers.addOptionalExtra(state, action);

    case RiskAction.REMOVE_OPTIONAL_EXTRA:
      return helpers.removeOptionalExtra(state, action);

    case RiskAction.SET_VOLUNTARY_EXCESS:
      return helpers.setVoluntaryExcess(state, action);

    case RiskAction.SET_ANNUAL:
      return helpers.setAnnual(state, action);

    case RiskAction.SET_MONTHLY:
      return helpers.setMonthly(state, action);

    case RiskAction.SET_PARAMS:
      return helpers.setParams(state, action);

    case RiskAction.UPDATE_POLICY:
      return helpers.updatePolicy(state, action);

    case RiskAction.DESTROY:
      return helpers.destroy(state, action);

    default:
      return state;
  }
}

export default riskReducer;
