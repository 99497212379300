import React from "react";

import { FormProps } from "components/Form/Wrapper";
import * as Question from "components/Questions";
import { Debug } from "modules/debug/types";
import * as Form from "components/Form";

const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },
  { label: "Option 4", value: "option4" },
  { label: "Option 5", value: "option5" },
];

const optionsAutocomplete = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const helpComponent = (
  <React.Fragment>
    <h3>Help Component</h3>
    <p>
      Etiam tincidunt eros eu{" "}
      <a href="https://www.google.com" rel="noopener noreferrer" target="_blank">
        arcu volutpat
      </a>{" "}
      tempus.
    </p>
  </React.Fragment>
);

const infoComponent = (
  <React.Fragment>
    <h3>Info Component</h3>
    <ul>
      <li>Aenean euismod semper metus</li>
      <li>Aenean euismod semper metus</li>
      <li>Aenean euismod semper metus</li>
    </ul>
  </React.Fragment>
);

type QuestionsProps = FormProps<Debug> & { disabled?: boolean };

const Questions: React.FunctionComponent<QuestionsProps> = ({
  disabled,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  const optionsFieldGroup = [
    {
      error: errors?.questionFieldGroup?.questionFieldGroup1,
      label: "Option 1",
      name: "questionFieldGroup.questionFieldGroup1",
      selected: !!values.questionFieldGroup?.questionFieldGroup1,
      touched: touched?.questionFieldGroup?.questionFieldGroup1,
    },
    {
      error: errors?.questionFieldGroup?.questionFieldGroup2,
      label: "Option 2",
      name: "questionFieldGroup.questionFieldGroup2",
      selected: !!values.questionFieldGroup?.questionFieldGroup2,
      touched: touched?.questionFieldGroup?.questionFieldGroup2,
    },
    {
      error: errors?.questionFieldGroup?.questionFieldGroup3,
      label: "Option 3",
      name: "questionFieldGroup.questionFieldGroup3",
      selected: !!values.questionFieldGroup?.questionFieldGroup3,
      touched: touched?.questionFieldGroup?.questionFieldGroup3,
    },
    {
      error: errors?.questionFieldGroup?.questionFieldGroup4,
      label: "Option 4",
      name: "questionFieldGroup.questionFieldGroup4",
      selected: !!values.questionFieldGroup?.questionFieldGroup4,
      touched: touched?.questionFieldGroup?.questionFieldGroup4,
    },
  ];
  return (
    <React.Fragment>
      <Form.Fieldset title={`Questions${disabled ? " - Disabled" : ""}`}>
        <Question.QuestionText
          disabled={disabled}
          error={errors.questionText}
          help={"I am an <strong>help text</strong> string"}
          info={"I am an <strong>info text</strong> string"}
          label="TEXT"
          maxLength={6}
          name="questionText"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter text"
          readOnly={isSubmitting}
          touched={touched.questionText}
          value={values.questionText}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionTextHelp}
          help={helpComponent}
          info={infoComponent}
          label="TEXT"
          maxLength={6}
          name="questionTextHelp"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter text"
          readOnly={isSubmitting}
          secondary={true}
          touched={touched.questionTextHelp}
          value={values.questionTextHelp}
        />

        <Question.QuestionTextArea
          disabled={disabled}
          error={errors.questionTextArea}
          label="TEXTAREA"
          maxLength={100}
          name="questionTextArea"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter text"
          readOnly={isSubmitting}
          touched={touched.questionTextArea}
          value={values.questionTextArea}
        />

        <Question.QuestionVrm
          disabled={disabled}
          error={errors.questionVrm}
          help={
            "<p>Please enter your registration number and click <strong>Find my motorcycle</strong>.</p><p>If you do not know your registration number, leave this question blank and click <strong>I don't know the registration</strong>.</p>"
          }
          label="VRM"
          name="questionVrm"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => void 0}
          onSubmit={() => void 0}
          readOnly={isSubmitting}
          touched={touched.questionVrm}
          value={values.questionVrm}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionEmail}
          format={Question.QuestionTextFormat.EMAIL}
          help={
            '<h3>Lorem ipsum dolor sit amet</h3><p>Etiam tincidunt eros eu <a href="https://www.google.com" rel="noopener noreferrer" target="_blank">arcu volutpat</a> tempus.</p><ul><li>Aenean euismod semper metus</li><li>Aenean euismod semper metus</li><li>Aenean euismod semper metus</li></ul>'
          }
          info={
            '<h3>Lorem ipsum dolor sit amet</h3><p>Etiam tincidunt eros eu <a href="https://www.google.com" rel="noopener noreferrer" target="_blank">arcu volutpat</a> tempus.</p><ul><li>Aenean euismod semper metus</li><li>Aenean euismod semper metus</li><li>Aenean euismod semper metus</li></ul>'
          }
          label="EMAIL"
          name="questionEmail"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter your email"
          readOnly={isSubmitting}
          touched={touched.questionEmail}
          value={values.questionEmail}
        />

        <Question.QuestionAutocomplete
          disabled={disabled}
          error={errors.questionAutocomplete}
          label="AUTOCOMPLETE"
          name="questionAutocomplete"
          onBlur={handleBlur}
          options={optionsAutocomplete}
          placeholder="Please enter your address"
          readOnly={isSubmitting}
          setFieldValue={setFieldValue}
          touched={touched.questionAutocomplete}
          value={values.questionAutocomplete}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionName}
          format={Question.QuestionTextFormat.NAME}
          label="NAME"
          maxLength={30}
          name="questionName"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter your name"
          readOnly={isSubmitting}
          touched={touched.questionName}
          value={values.questionName}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionPassword}
          format={Question.QuestionTextFormat.PASSWORD}
          label="PASSWORD"
          name="questionPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter your password"
          readOnly={isSubmitting}
          touched={touched.questionPassword}
          value={values.questionPassword}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionPostcode}
          format={Question.QuestionTextFormat.POSTCODE}
          label="POSTCODE"
          name="questionPostcode"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter your postcode"
          readOnly={isSubmitting}
          touched={touched.questionPostcode}
          value={values.questionPostcode}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionSortcode}
          format={Question.QuestionTextFormat.SORTCODE}
          label="SORTCODE"
          name="questionSortcode"
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={isSubmitting}
          size={Question.QuestionSize.MEDIUM}
          touched={touched.questionSortcode}
          value={values.questionSortcode}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionAccount}
          format={Question.QuestionTextFormat.ACCOUNT}
          label="ACCOUNT"
          maxLength={10}
          name="questionAccount"
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={isSubmitting}
          size={Question.QuestionSize.MEDIUM}
          touched={touched.questionAccount}
          value={values.questionAccount}
        />

        <Question.QuestionText
          disabled={disabled}
          error={errors.questionTel}
          format={Question.QuestionTextFormat.TEL}
          label="TEL"
          name="questionTel"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter your telephone number"
          readOnly={isSubmitting}
          touched={touched.questionTel}
          value={values.questionTel}
        />

        <Question.QuestionNumber
          disabled={disabled}
          error={errors.questionNumber}
          format={Question.QuestionNumberFormat.INTEGER}
          label="NUMBER"
          max={999999}
          maxLength={6}
          min={0}
          name="questionNumber"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter a number"
          readOnly={isSubmitting}
          touched={touched.questionNumber}
          value={values.questionNumber}
        />

        <Question.QuestionNumber
          disabled={disabled}
          error={errors.questionNumberNegative}
          format={Question.QuestionNumberFormat.INTEGER}
          label="NUMBER NEGATIVE"
          max={999999}
          maxLength={6}
          min={-999999}
          name="questionNumberNegative"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter a number"
          readOnly={isSubmitting}
          touched={touched.questionNumberNegative}
          value={values.questionNumberNegative}
        />

        <Question.QuestionNumber
          disabled={disabled}
          error={errors.questionDecimal}
          format={Question.QuestionNumberFormat.DECIMAL}
          label="DECIMAL"
          max={999999}
          maxLength={6}
          min={0}
          name="questionDecimal"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter a number"
          readOnly={isSubmitting}
          touched={touched.questionDecimal}
          value={values.questionDecimal}
        />

        <Question.QuestionNumber
          disabled={disabled}
          error={errors.questionDecimalNegative}
          format={Question.QuestionNumberFormat.DECIMAL}
          label="DECIMAL NEGATIVE"
          max={999999}
          maxLength={6}
          min={-999999}
          name="questionDecimalNegative"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter a number"
          readOnly={isSubmitting}
          touched={touched.questionDecimalNegative}
          value={values.questionDecimalNegative}
        />

        <Question.QuestionNumber
          disabled={disabled}
          error={errors.questionCurrency}
          format={Question.QuestionNumberFormat.CURRENCY}
          label="CURRENCY"
          max={999999}
          maxLength={6}
          min={0}
          name="questionCurrency"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter an amount"
          readOnly={isSubmitting}
          touched={touched.questionCurrency}
          value={values.questionCurrency}
        />

        <Question.QuestionNumber
          disabled={disabled}
          error={errors.questionCurrencyNegative}
          format={Question.QuestionNumberFormat.CURRENCY}
          label="CURRENCY NEGATIVE"
          max={999999}
          maxLength={6}
          min={-999999}
          name="questionCurrencyNegative"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Please enter a number"
          readOnly={isSubmitting}
          touched={touched.questionCurrencyNegative}
          value={values.questionCurrencyNegative}
        />

        <Question.QuestionDate
          disabled={disabled}
          error={errors.questionDate}
          format={Question.QuestionDateFormat.DATE}
          label="DATE"
          max="2021-01-20"
          min="1900-01-20"
          name="questionDate"
          placeholder="Please enter a date"
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionDate}
          value={values.questionDate}
        />

        <Question.QuestionDate
          disabled={disabled}
          error={errors.questionMonth}
          format={Question.QuestionDateFormat.MONTH}
          label="MONTH"
          max="2021-01"
          min="1900-01"
          name="questionMonth"
          placeholder="Please enter a month"
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionMonth}
          value={values.questionMonth}
        />

        <Question.QuestionSelect
          disabled={disabled}
          error={errors.questionSelect}
          label="SELECT"
          name="questionSelect"
          onBlur={handleBlur}
          onChange={handleChange}
          options={options}
          readOnly={isSubmitting}
          touched={touched.questionSelect}
          value={values.questionSelect}
        />

        <Question.QuestionCheckbox
          disabled={disabled}
          error={errors.questionCheckbox}
          format={Question.QuestionCheckboxFormat.HORIZONTAL}
          label="CHECKBOX HORIZONTAL"
          name="questionCheckbox"
          options={options}
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionCheckbox}
          value={values.questionCheckbox}
        />

        <Question.QuestionCheckbox
          disabled={disabled}
          error={errors.questionCheckbox}
          format={Question.QuestionCheckboxFormat.VERTICAL}
          label="CHECKBOX VERTICAL"
          name="questionCheckbox"
          options={options}
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionCheckbox}
          value={values.questionCheckbox}
        />

        <Question.QuestionRadio
          disabled={disabled}
          error={errors.questionRadio}
          format={Question.QuestionRadioFormat.HORIZONTAL}
          label="RADIO HORIZONTAL"
          name="questionRadio"
          options={options}
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionRadio}
          value={values.questionRadio}
        />

        <Question.QuestionRadio
          disabled={disabled}
          error={errors.questionRadio}
          format={Question.QuestionRadioFormat.VERTICAL}
          label="RADIO VERTICAL"
          name="questionRadio"
          options={options}
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionRadio}
          value={values.questionRadio}
        />

        <Question.QuestionFieldGroup
          disabled={disabled}
          error={optionsFieldGroup.filter(option => option.error !== undefined)[0]?.error}
          label="FIELD GROUP"
          options={optionsFieldGroup}
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={optionsFieldGroup.some(option => option.touched === true)}
        />

        <Question.QuestionSwitch
          disabled={disabled}
          error={errors.questionSwitch}
          label="SWITCH"
          name="questionSwitch"
          readOnly={isSubmitting}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched.questionSwitch}
          value={values.questionSwitch}
        />
      </Form.Fieldset>

      {!disabled && (
        <Form.Fieldset title="Questions - Sizes">
          <Question.QuestionText
            disabled={disabled}
            error={errors.questionText}
            help={"I am a <strong>help text</strong> string"}
            label="TEXT"
            maxLength={6}
            name="questionText"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Please enter text"
            readOnly={isSubmitting}
            size={Question.QuestionSize.MEDIUM}
            touched={touched.questionText}
            value={values.questionText}
          />

          <Question.QuestionNumber
            disabled={disabled}
            error={errors.questionNumber}
            format={Question.QuestionNumberFormat.INTEGER}
            help={"I am a <strong>help text</strong> string"}
            label="NUMBER"
            max={100}
            maxLength={3}
            min={0}
            name="questionNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Please enter a number"
            readOnly={isSubmitting}
            size={Question.QuestionSize.SMALL}
            touched={touched.questionNumber}
            value={values.questionNumber}
          />

          <Question.QuestionDate
            disabled={disabled}
            error={errors.questionDate}
            format={Question.QuestionDateFormat.DATE}
            help={"I am a <strong>help text</strong> string"}
            label="DATE"
            max="2021-01-20"
            min="1900-01-20"
            name="questionDate"
            placeholder="Please enter a date"
            readOnly={isSubmitting}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            size={Question.QuestionSize.MEDIUM}
            touched={touched.questionDate}
            value={values.questionDate}
          />

          <Question.QuestionDate
            disabled={disabled}
            error={errors.questionMonth}
            format={Question.QuestionDateFormat.MONTH}
            help={"I am a <strong>help text</strong> string"}
            label="MONTH"
            max="2021-01"
            min="1900-01"
            name="questionMonth"
            placeholder="Please enter a month"
            readOnly={isSubmitting}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            size={Question.QuestionSize.MEDIUM}
            touched={touched.questionMonth}
            value={values.questionMonth}
          />

          <Question.QuestionSelect
            disabled={disabled}
            error={errors.questionSelect}
            help={"I am a <strong>help text</strong> string"}
            label="SELECT"
            name="questionSelect"
            onBlur={handleBlur}
            onChange={handleChange}
            options={options}
            readOnly={isSubmitting}
            size={Question.QuestionSize.MEDIUM}
            touched={touched.questionSelect}
            value={values.questionSelect}
          />
        </Form.Fieldset>
      )}
    </React.Fragment>
  );
};

export default Questions;
