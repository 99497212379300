import { useMemo } from "react";

import { RiskMaxAdditional } from "modules/risk/constants";
import { UseIndex } from "./types";

const useIndex: UseIndex = ({ additionalVehicles, id }) =>
  useMemo(
    () =>
      id === "new"
        ? additionalVehicles.length < RiskMaxAdditional.VEHICLES
          ? additionalVehicles.length
          : -1
        : additionalVehicles.findIndex(item => item.id === id),
    [additionalVehicles, id],
  );

export default useIndex;
