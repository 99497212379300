import { useCallback } from "react";

import { scrollTop } from "modules/scroll/helpers";
import { ROUTE } from "modules/router/constants";
import { UseHandleBack } from "./types";

const useHandleBack: UseHandleBack = navigate =>
  useCallback(async () => {
    await scrollTop();
    navigate(ROUTE.MOTORCYCLE);
  }, [navigate]);

export default useHandleBack;
