import React, { useCallback } from "react";

import { quoteActionVoluntaryExcess } from "modules/quote/actions";
import { UseHandleSetVoluntaryExcess } from "./types";

const useHandleSetVoluntaryExcess: UseHandleSetVoluntaryExcess = ({ dispatch, isMobile }) =>
  useCallback(
    (event: React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLSelectElement>) =>
      dispatch(quoteActionVoluntaryExcess(event, isMobile)),
    [dispatch, isMobile],
  );

export default useHandleSetVoluntaryExcess;
