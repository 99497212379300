import React from "react";

import { useModalInfo, ModalInfoTemplates } from "modules/modals/hooks";
import { getMetadataLabel } from "modules/metadata/helpers";
import { OptionalExtra, Quote } from "modules/quote/types";
import { PaymentMethod } from "modules/risk/constants";
import { MetadataItem } from "modules/metadata/types";
import BreakdownPlus from "../BreakdownPlus";
import { UseCart } from "modules/cart";
import Included from "../Included";
import Option from "../Option";

type ProductOverviewEnhancedProps = {
  cart: ReturnType<UseCart>;
  cover: string;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
  optionsCover: MetadataItem[];
  paymentMethod: PaymentMethod;
};

const ProductOverviewEnhanced: React.FunctionComponent<ProductOverviewEnhancedProps> = ({
  cart: { selectedEnhancedPlus, selectedAccidentHelmetLeathers, selectedHelmetLeathers, selectedPersonalAccident },
  cover,
  onAdd,
  onRemove,
  options,
  optionsCover,
  paymentMethod,
}) => {
  const handleShowModalInfo = useModalInfo();
  return (
    <ul>
      <Included
        label={optionsCover.length ? `${getMetadataLabel(optionsCover, cover)} Cover` : ""}
        onShowModal={event =>
          handleShowModalInfo({
            event,
            template:
              cover === "TPFT"
                ? ModalInfoTemplates.THIRD_PARTY_FIRE_THEFT
                : cover === "TPO"
                ? ModalInfoTemplates.THIRD_PARTY_ONLY
                : ModalInfoTemplates.COMPREHENSIVE_COVER,
          })
        }
      />

      <Included
        label="90 days full policy cover in EU"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.EU_COVER })}
      />

      <Included
        label="10% discount at SportsBikeShop"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.SPORTS_BIKE_SHOP })}
      />

      <Included
        label="24/7 Claim line"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.CLAIM_LINE })}
      />

      <Included
        label="Legal Expenses & Loss Recovery"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.LEGAL_EXPENSES })}
        optional={true}
      />

      <Included
        disabled={selectedEnhancedPlus}
        label="RAC UK Roadside Breakdown Cover"
        onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.BREAKDOWN_ROADSIDE })}
        optional={true}
      />

      {!!options.enhanced && !!options.enhancedPlus && (
        <BreakdownPlus
          label="Upgrade to RAC Roadside, Recovery, At Home, Onward Travel and EU Breakdown"
          onAdd={onAdd}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.BREAKDOWN_ONWARD_TRAVEL })}
          option={options.enhanced}
          optionPlus={options.enhancedPlus}
          paymentMethod={paymentMethod}
          selected={selectedEnhancedPlus}
        />
      )}

      {!!options.helmetLeathers && (
        <Option
          disabled={selectedAccidentHelmetLeathers}
          label="Helmet & Leathers"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.HELMET_LEATHERS_COVER })}
          option={options.helmetLeathers}
          paymentMethod={paymentMethod}
          selected={selectedHelmetLeathers}
        />
      )}

      {!!options.personalAccident && (
        <Option
          disabled={selectedAccidentHelmetLeathers}
          label="Personal Accident"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.PERSONAL_ACCIDENT_COVER })}
          option={options.personalAccident}
          paymentMethod={paymentMethod}
          selected={selectedPersonalAccident}
        />
      )}

      {!!options.accidentHelmetLeathers && (
        <Option
          label="Personal Accident + Helmet & Leathers"
          onAdd={onAdd}
          onRemove={onRemove}
          onShowModal={event => handleShowModalInfo({ event, template: ModalInfoTemplates.ACCIDENT_HELMET_LEATHERS })}
          option={options.accidentHelmetLeathers}
          paymentMethod={paymentMethod}
          selected={selectedAccidentHelmetLeathers}
        />
      )}
    </ul>
  );
};

export default React.memo(ProductOverviewEnhanced);
