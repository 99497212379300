import React from "react";

import { formatPrice } from "modules/utils";
import { Quote } from "modules/quote/types";

import styles from "./styles.module.scss";

type PaymentSummaryProps = {
  instalments: Quote["instalments"];
};

const PaymentSummary: React.FunctionComponent<PaymentSummaryProps> = ({ instalments }) => {
  return instalments ? (
    <div className={styles["payment-summary"]}>
      <ul>
        <li>
          Initial deposit of <span>{formatPrice(instalments.deposit)}</span>
        </li>
        <li>
          11 monthly payments of <span>{formatPrice(instalments.amount)}</span>
        </li>
        <li>
          Total amount to pay <span>{formatPrice(instalments.totalPayable)}</span>
        </li>
      </ul>
      <p>
        Including a charge for credit of {instalments.interestPercentage}% and representative APR of {instalments.apr}%
      </p>
    </div>
  ) : null;
};

export default PaymentSummary;
