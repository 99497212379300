import React, { useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { Quote } from "modules/quote/types";
import Button from "components/Button";
import { UseCart } from "modules/cart";
import Option from "./Option";

import styles from "./styles.module.scss";

type PolicyAddOnsProps = {
  cart: ReturnType<UseCart>;
  isOpen?: boolean;
  options: Quote["optionalExtras"];
};

const PolicyAddOns: React.FunctionComponent<PolicyAddOnsProps> = ({
  cart: {
    selectedAccidentHelmetLeathers,
    selectedBreakdown1OrIncluded,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4OrIncluded,
    selectedHelmetLeathersOrIncluded,
    selectedLegalOrIncluded,
    selectedPersonalAccidentOrIncluded,
  },
  isOpen = false,
  options,
}) => {
  const [open, setOpen] = useState(isOpen);
  const className = clsx(styles["policy-add-ons"], open && styles["open"]);
  return (
    <div className={className}>
      <header>
        <Button ariaLabel="Add-ons Cover Details" onClick={() => setOpen(!open)}>
          <h2>Add-ons Cover Details</h2>
          <ChevronDownSVG />
        </Button>
      </header>
      {open && (
        <section>
          <ul>
            {!!options.legal && selectedLegalOrIncluded && (
              <Option label="Legal Expenses & Loss Recovery" option={options.legal} />
            )}

            {!!options.breakdown1 && selectedBreakdown1OrIncluded && (
              <Option label="RAC Breakdown Cover" option={options.breakdown1} />
            )}

            {!!options.breakdown2 && selectedBreakdown2 && (
              <Option label="RAC Breakdown Cover" option={options.breakdown2} />
            )}

            {!!options.breakdown3 && selectedBreakdown3 && (
              <Option label="RAC Breakdown Cover" option={options.breakdown3} />
            )}

            {!!options.breakdown4 && selectedBreakdown4OrIncluded && (
              <Option label="RAC Breakdown Cover" option={options.breakdown4} />
            )}

            {!!options.helmetLeathers && (selectedHelmetLeathersOrIncluded || selectedAccidentHelmetLeathers) && (
              <Option label="Helmet & Leathers" option={options.helmetLeathers} />
            )}

            {!!options.personalAccident && (selectedPersonalAccidentOrIncluded || selectedAccidentHelmetLeathers) && (
              <Option label="Personal Accident" option={options.personalAccident} />
            )}
          </ul>
        </section>
      )}
    </div>
  );
};

export default React.memo(PolicyAddOns);
