import { produce } from "immer";

import { Risk, RiskActionAddOptionalExtra } from "../../types";

const addOptionalExtra = (state: Risk, action: RiskActionAddOptionalExtra): Risk => {
  return produce(state, (draftState: Risk) => {
    action.option.disallow?.forEach(
      option =>
        draftState.cart.optionalExtras.includes(option) &&
        (draftState.cart.optionalExtras = draftState.cart.optionalExtras.filter(item => item !== option)),
    );
    !draftState.cart.optionalExtras.includes(action.option.type) &&
      draftState.cart.optionalExtras.push(action.option.type);
  });
};

export default addOptionalExtra;
