import { api, API, getApiError } from "modules/api";
import { cacheResult, cacheSearch } from "./cache";
import { Dispatch } from "modules/redux/store";
import { VehicleAction } from "./constants";
import { isError } from "modules/utils";
import {
  VehicleActionRequestStarted,
  VehicleActionRequestSuccess,
  VehicleActionRequestFailure,
  VehicleApiResponseResult,
  VehicleApiResponse,
  VehicleApiRequest,
} from "./types";

export const vehicleActionRequest = (vehicle: VehicleApiRequest) => {
  return async (dispatch: Dispatch): Promise<VehicleApiResponseResult> => {
    const cached = cacheSearch(vehicle);
    if (cached) {
      dispatch(vehicleActionRequestSuccess(cached.result));
      return cached.result;
    } else {
      dispatch(vehicleActionRequestStarted());
      try {
        const response: VehicleApiResponse = await api(API.VEHICLE_REQUEST(vehicle));
        dispatch(vehicleActionRequestSuccess(response.data.vehicles));
        cacheResult(vehicle, response.data.vehicles);
        return response.data.vehicles;
      } catch (error) {
        dispatch(vehicleActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
        throw new Error(getApiError(error));
      }
    }
  };
};

const vehicleActionRequestStarted = (): VehicleActionRequestStarted => ({
  type: VehicleAction.REQUEST_STARTED,
});

const vehicleActionRequestSuccess = (data: VehicleApiResponseResult): VehicleActionRequestSuccess => ({
  type: VehicleAction.REQUEST_SUCCESS,
  data: data,
});

const vehicleActionRequestFailure = (error: string): VehicleActionRequestFailure => ({
  type: VehicleAction.REQUEST_FAILURE,
  error: error,
});
