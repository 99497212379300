import { AddressSearchApiRequest, AddressSearchApiResponseResult } from "./types";

const cache: {
  address: AddressSearchApiRequest;
  result: AddressSearchApiResponseResult;
}[] = [];

export const cacheSearch = (address: AddressSearchApiRequest) => {
  return cache.find(
    item => item.address.houseNumber === address.houseNumber && item.address.postcode === address.postcode,
  );
};

export const cacheResult = (address: AddressSearchApiRequest, result: AddressSearchApiResponseResult) => {
  !cacheSearch(address) &&
    cache.push({
      address: address,
      result: result,
    });
};
