import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import SessionContext from "modules/session/context";
import { ROUTE } from "modules/router/constants";

import styles from "./styles.module.scss";

const HeaderRecall: React.FunctionComponent = () => {
  const { sessionActive } = useContext(SessionContext);
  const navigate = useNavigate();
  return (
    <div className={styles["header-recall"]}>
      <p>Already have a quote with us?</p>
      {sessionActive ? (
        <Button
          ariaLabel="Retrieve your quote"
          label="Retrieve your quote"
          onClick={() => navigate(ROUTE.RECALL)}
          style={ButtonStyle.PRIMARY}
        />
      ) : (
        <Button
          ariaLabel="Retrieve your quote"
          href={ROUTE.RECALL}
          label="Retrieve your quote"
          style={ButtonStyle.PRIMARY}
          target={ButtonTarget.SELF}
          type={ButtonType.LINK}
        />
      )}
    </div>
  );
};

export default React.memo(HeaderRecall);
