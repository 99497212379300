import Messages from "modules/validation/messages";
import yup from "modules/validation";
import moment from "moment";

import schemaConviction from "./schemaConviction";
import schemaClaim from "./schemaClaim";

const schema = yup.object({
  id: yup.string().required(),

  name: yup.object({
    title: yup.string().required(Messages.OPTION),

    titleOther: yup.string().when("title", {
      is: (value?: string) => value === "Other",
      then: schema => schema.required(Messages.LIST),
    }),

    forenames: yup.string().required().maxLength(30),

    surname: yup.string().required().maxLength(20),
  }),

  gender: yup.string().required(Messages.OPTION).gender(),

  dateOfBirth: yup.date().required().max(moment().format("YYYY-MM-DD"), Messages.DATE_FUTURE).dateOfBirth(),

  residentSinceBirth: yup.boolean().required(Messages.OPTION),

  residentSince: yup.date().when("residentSinceBirth", {
    is: false,
    then: schema => schema.required().max(moment().format("YYYY-MM-DD"), Messages.DATE_FUTURE).residentSince(),
  }),

  maritalStatus: yup.string().required(Messages.OPTION),

  bikeLicence: yup.object({
    type: yup.string().required(Messages.OPTION),

    years: yup.string().required(Messages.LIST).bikeLicenceYears(),

    date: yup.date().when("years", {
      is: (value?: string) => !!value && ["0", "1", "2", "3"].includes(value),
      then: schema =>
        schema.required().when("years", {
          is: (value?: string) => value === "0",
          then: schema => schema.max(moment().format("YYYY-MM-DD"), Messages.DATE_FUTURE).bikeLicenceDate("date"),
          otherwise: schema => schema.max(moment().format("YYYY-MM"), Messages.DATE_FUTURE).bikeLicenceDate("month"),
        }),
    }),
  }),

  fullTimeOccupation: yup.object({
    type: yup.string().required(Messages.LIST),

    occCode: yup.string().when("type", {
      is: (value?: string) => !!value && ["C", "D", "E", "FTE", "G", "O", "P", "SE"].includes(value),
      then: schema => schema.required(Messages.LIST),
    }),

    empCode: yup.string().when("type", {
      is: (value?: string) => !!value && ["C", "D", "E", "G", "O", "P", "SE"].includes(value),
      then: schema => schema.required(Messages.LIST),
    }),
  }),

  hasPartTimeOccupation: yup.boolean().required(Messages.OPTION),

  partTimeOccupation: yup.object().when("hasPartTimeOccupation", {
    is: true,
    then: () =>
      yup.object({
        type: yup.string().required(Messages.LIST),

        occCode: yup.string().required(Messages.LIST),

        empCode: yup.string().required(Messages.LIST),
      }),
  }),

  hasClaims: yup.boolean().required(Messages.OPTION),

  claims: yup.array().when("hasClaims", {
    is: true,
    then: schema => schema.of(schemaClaim).required().min(1),
  }),

  hasConvictions: yup.boolean().required(Messages.OPTION),

  convictions: yup.array().when("hasConvictions", {
    is: true,
    then: schema => schema.of(schemaConviction).required().min(1),
  }),

  isMainRider: yup.boolean().required(Messages.OPTION),
});

export default schema;
