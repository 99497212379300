import React, { useEffect } from "react";
import { FormikProps } from "formik";

import Debug from "components/Questions/QuestionTemplate/Debug";
import { Claim, Conviction } from "modules/risk/types";
import useHandleReset from "./hooks";

import styles from "./styles.module.scss";

type QuestionSecondaryDebugProps = Omit<QuestionSecondaryProps, "open" | "setFieldTouched" | "setFieldValue">;

const QuestionSecondaryDebug: React.FunctionComponent<QuestionSecondaryDebugProps> = ({ name, value }) => {
  return (
    <div className={styles["question-secondary"]}>
      <div></div>
      <div>
        <Debug name={name} value={value} />
      </div>
    </div>
  );
};

type QuestionSecondaryProps = {
  children?: React.ReactNode;
  name: string;
  open: boolean;
  setFieldTouched: FormikProps<boolean | string | number | Claim[] | Conviction[]>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string | number | Claim[] | Conviction[]>["setFieldValue"];
  value: boolean | string | number | Claim[] | Conviction[];
};

const QuestionSecondary: React.FunctionComponent<QuestionSecondaryProps> = ({
  children,
  name,
  open,
  setFieldTouched,
  setFieldValue,
  value,
}) => {
  const handleReset = useHandleReset({ name, setFieldTouched, setFieldValue, value });
  useEffect(() => {
    handleReset(open);
  }, [handleReset, open]);
  return open ? (
    <React.Fragment>{open && children}</React.Fragment>
  ) : process.env.NODE_ENV === "development" && process.env.REACT_APP_DEBUG !== "false" ? (
    <QuestionSecondaryDebug name={name} value={value} />
  ) : null;
};

export default React.memo(QuestionSecondary);
