import { useCallback } from "react";

import { riskActionResetAdditionalVehicles } from "modules/risk/actions";
import { ModalConfirmTemplates } from "modules/modals/hooks";
import { ROUTE } from "modules/router/constants";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, handleShowModalConfirm, navigate, risk }) =>
  useCallback(() => {
    if (risk.hasAdditionalVehicles && !risk.additionalVehicles.length) {
      handleShowModalConfirm({
        template: ModalConfirmTemplates.ADDITIONAL_VEHICLES,
        onConfirm: () => {
          dispatch(riskActionResetAdditionalVehicles());
          navigate(ROUTE.RIDER);
        },
      });
    } else {
      navigate(ROUTE.RIDER);
    }
  }, [dispatch, handleShowModalConfirm, navigate, risk.additionalVehicles.length, risk.hasAdditionalVehicles]);

export default useHandleSubmit;
