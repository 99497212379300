import { useMemo } from "react";

import getRedirect from "modules/router/redirect";
import { ROUTE } from "modules/router/constants";
import { UseRedirect } from "./types";

const useRedirect: UseRedirect = ({ location, route }) =>
  useMemo(
    () =>
      getRedirect({
        redirect: route !== location.pathname ? route : undefined,
        route: ROUTE.MESSAGE,
      }),
    [location.pathname, route],
  );

export default useRedirect;
