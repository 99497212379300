import { produce } from "immer";

import { Quote, QuoteActionType } from "./types";
import { QuoteAction } from "./constants";
import { newQuote } from "./helpers";

import { newQuoteDebug } from "./debug";

//const initialState: Quote = newQuote();
const initialState: Quote = process.env.NODE_ENV === "development" ? newQuoteDebug() : newQuote();

function quoteReducer(state = initialState, action: QuoteActionType) {
  switch (action.type) {
    case QuoteAction.REQUEST_SUCCESS:
      return produce(state, () => {
        return action.quote;
      });

    case QuoteAction.UPDATE_PRECIS:
      return produce(state, (draftState: Quote) => {
        draftState.endorsements = action.data.endorsements;
        draftState.productIpid = action.data.productIpid;
        draftState.precis = action.data.precis;
      });

    case QuoteAction.DESTROY:
      return newQuote();

    default:
      return state;
  }
}

export default quoteReducer;
