import React from "react";
import { Navigate } from "react-router-dom";

import { useDebugRoute } from "modules/router/hooks";
import { ROUTE } from "modules/router/constants";

import TemplateModals from "./TemplateModals";
import Template from "./Template";

const Route: React.FunctionComponent = () => {
  const { location, redirect, ...props } = useDebugRoute();
  return redirect ? (
    <Navigate replace to={redirect} />
  ) : location.pathname === ROUTE.DEBUG_MODALS ? (
    <TemplateModals />
  ) : (
    <Template {...props} />
  );
};

export default Route;
