import Messages from "modules/validation/messages";
import yup from "modules/validation";

const schema = yup.object({
  dateOfBirth: yup.date().required(),

  postcode: yup.string().required().postcode().maxLength(8, Messages.POSTCODE),

  webReference: yup.string().required(),
});

export default schema;
