import React, { useCallback } from "react";
import clsx from "clsx";

import { ReactComponent as CrossSVG } from "modules/theme/icons/product/cross.svg";
import { ReactComponent as InfoSVG } from "modules/theme/icons/product/info.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/product/tick.svg";
import { PaymentMethod } from "modules/risk/constants";
import { OptionalExtra } from "modules/quote/types";
import Button from "components/Button";
import { usePrice } from "./hooks";

import styles from "./styles.module.scss";

type OptionProps = {
  disabled?: boolean;
  label: string;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  onShowModal?: (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  option: OptionalExtra;
  paymentMethod: PaymentMethod;
  selected: boolean;
};

const Option: React.FunctionComponent<OptionProps> = ({
  disabled,
  label,
  onAdd,
  onRemove,
  onShowModal,
  option,
  paymentMethod,
  selected,
}) => {
  const { price, priceLabel } = usePrice(option.grossPremium, paymentMethod, disabled);

  const onClick = useCallback(
    () => !disabled && (selected ? onRemove(option) : onAdd(option)),
    [disabled, onAdd, onRemove, option, selected],
  );

  const className = clsx(styles["option"], disabled && styles["disabled"], !disabled && selected && styles["selected"]);
  return (
    <li className={className}>
      <Button ariaLabel={label} label={label} onClick={onShowModal}>
        <InfoSVG />
      </Button>
      <Button ariaLabel={priceLabel} onClick={onClick} tabIndex={disabled ? -1 : 0}>
        <span>
          <span>
            <CrossSVG />
            <TickSVG />
          </span>
        </span>
        <span>{price}</span>
      </Button>
    </li>
  );
};

export default React.memo(Option);
