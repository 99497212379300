import React from "react";

import { LoadingType } from "./constants";
import LoadingDots from "./LoadingDots";
import LoadingWheel from "./LoadingWheel";

type LoadingProps = {
  className?: string;
  type: LoadingType;
};

const Loading: React.FunctionComponent<LoadingProps> = ({ className, type }) => {
  switch (type) {
    case LoadingType.DOTS:
      return <LoadingDots className={className} />;
    case LoadingType.WHEEL:
      return <LoadingWheel className={className} />;
    default:
      return null;
  }
};

export default Loading;
