import { useCallback } from "react";

import { proceedActionRequest, proceedActionUpdate } from "modules/proceed/actions";
import { Proceed } from "modules/proceed/types";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = dispatch =>
  useCallback(
    (values: Proceed) => {
      dispatch(proceedActionUpdate(values));
      dispatch(proceedActionRequest());
    },
    [dispatch],
  );

export default useHandleSubmit;
