import React from "react";

import styles from "./styles.module.scss";

type CompareTheMarketProps = {
  webReference: string;
};

const action = "partnerJourneyStarted";
const brandCode = "DVT3";
const pageName = "Product";
const url = "/partners/ctm.html";

const CompareTheMarket: React.FunctionComponent<CompareTheMarketProps> = ({ webReference }) => {
  let src = url;
  src += `?action=${encodeURIComponent(action)}`;
  src += `&brandCode=${encodeURIComponent(brandCode)}`;
  src += `&pageName=${encodeURIComponent(pageName)}`;
  src += `&partnerQuoteRef=${encodeURIComponent(webReference)}`;
  process.env.NODE_ENV !== "production" && (src += "&env=development");
  return (
    <iframe className={styles["product-partners"]} height={0} src={src} title="Devitt Insurance Partner" width={0} />
  );
};

export default React.memo(CompareTheMarket);
