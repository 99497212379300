import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Legal Expenses & Loss Recovery",
  text: (
    <React.Fragment>
      <p>Protect yourself from legal costs in pursuing a claim for your uninsured losses.</p>
      <ul>
        <li>Up to £100,000 legal cover</li>
        <li>Assistance with handling your claim</li>
        <li>Recover uninsured losses including policy excess and lost income</li>
      </ul>
      <p>Accidents that aren't your fault can happen. Safeguard yourself from paying out on unexpected fees.</p>
    </React.Fragment>
  ),
};

export default template;
