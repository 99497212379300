import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { appActionError } from "modules/app/actions";
import { ROUTE } from "modules/router/constants";
import { Dispatch } from "modules/redux/store";
import { checkLink } from "../helpers";

type Event = React.MouseEvent<HTMLAnchorElement>;

type UseHandleClickProps = (props: {
  documentRequest: (modal: boolean, url: string) => Promise<string | false>;
  href: string | undefined;
  link: string;
  setHref: React.Dispatch<React.SetStateAction<string | undefined>>;
  webReference?: string;
}) => (event: Event) => void;

const useHandleClick: UseHandleClickProps = ({ documentRequest, href, link, setHref, webReference }) => {
  const handleClickRef = useRef<ReturnType<UseHandleClickProps>>(() => void 0);
  const handleClick = useCallback<(event: Event) => void>(event => handleClickRef.current(event), []);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    handleClickRef.current = async (event: Event) => {
      if (!checkLink(link) && !href && !loading) {
        const windowReference = window.open();
        event.preventDefault();
        setLoading(true);
        try {
          const response = await documentRequest(true, link);
          if (response) {
            !!windowReference && (windowReference.location.href = response);
            setHref(response);
            setLoading(false);
          } else {
            throw new Error("Document unavailable.");
          }
        } catch (error) {
          !!windowReference && windowReference.close();
          dispatch(appActionError({ route: ROUTE.MESSAGE_DOCUMENT, webReference: webReference }));
        }
      }
    };
  }, [dispatch, documentRequest, href, link, loading, setHref, webReference]);

  return handleClick;
};

export default useHandleClick;
