import React from "react";
import clsx from "clsx";

import { ReactComponent as LoadingSVG } from "./loading.svg";

import styles from "./styles.module.scss";

type LoadingWheelProps = {
  className?: string;
};

const LoadingWheel: React.FunctionComponent<LoadingWheelProps> = ({ className }) => {
  const classNames = clsx(styles.loadingWheel, className);
  return (
    <div className={classNames}>
      <LoadingSVG />
    </div>
  );
};

export default LoadingWheel;
