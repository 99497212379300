import React from "react";

import PageWrapper, { PageWrapperFormat } from "components/PageWrapper";
import { getMetadataLabel } from "modules/metadata/helpers";
import { MetadataType } from "modules/metadata/constants";
import * as Components from "./Product";
import { TemplateProps } from "./types";

const Template: React.FunctionComponent<TemplateProps> = ({
  cart,
  handleAdd,
  handleBack,
  handleConfirmAndPay,
  handleRemove,
  handleSetAnnually,
  handleSetMonthly,
  handleSetVoluntaryExcess,
  metadata,
  quote,
  risk,
}) => {
  return (
    <PageWrapper format={PageWrapperFormat.FULLWIDTH}>
      <Components.ProductWrapper
        onBack={handleBack}
        onConfirmAndPay={handleConfirmAndPay}
        onSetAnnually={handleSetAnnually}
        onSetMonthly={handleSetMonthly}
        paymentMethod={risk.cart.paymentMethod}
        quote={quote}>
        <Components.ProductWrapperLeft>
          <Components.ProductOverview
            cart={cart}
            cover={risk.vehicle.cover}
            onAdd={handleAdd}
            onRemove={handleRemove}
            options={quote.optionalExtras}
            optionsCover={metadata[MetadataType.COVER_TYPE]}
            paymentMethod={risk.cart.paymentMethod}
            whereHear={risk.additionalQuestions.whereHear}
          />
        </Components.ProductWrapperLeft>

        <Components.ProductWrapperRight>
          <Components.ProductPolicyInfo
            cart={cart}
            classOfUse={getMetadataLabel(metadata[MetadataType.CLASS_OF_USE], risk.vehicle.classOfUse)}
            cover={risk.vehicle.cover}
            coverLabel={getMetadataLabel(metadata[MetadataType.COVER_TYPE], risk.vehicle.cover)}
            paymentMethod={risk.cart.paymentMethod}
            quote={quote}
          />

          <Components.ProductPartners
            webReference={quote.webReference}
            whereHear={risk.additionalQuestions.whereHear}
          />

          {["C", "TPFT"].includes(risk.vehicle.cover) && (
            <Components.ProductExcess
              compulsoryExcess={quote.compulsoryExcess}
              handleSetVoluntaryExcess={handleSetVoluntaryExcess}
              options={metadata[MetadataType.VOL_XS]}
              volXS={risk.vehicle.volXS}
            />
          )}

          <Components.ProductBreakdown
            cart={cart}
            onAdd={handleAdd}
            onRemove={handleRemove}
            options={quote.optionalExtras}
            paymentMethod={risk.cart.paymentMethod}
          />
        </Components.ProductWrapperRight>
      </Components.ProductWrapper>
    </PageWrapper>
  );
};

export default Template;
