import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { AbiApiResponseResult } from "modules/api/abi/types";
import { resetAbiCode, resetSearch } from "../../helpers";
import { setFields } from "../helpers";

type UseHandleClickProps = (props: {
  name: string;
  result?: AbiApiResponseResult;
  setFieldTouched: FormikProps<number | string>["setFieldTouched"];
  setFieldValue: FormikProps<number | string>["setFieldValue"];
  setOpen: (state: boolean) => void;
  valueYearOfManufacture: number | "";
}) => () => void;

const useHandleClick: UseHandleClickProps = ({
  name,
  result,
  setFieldTouched,
  setFieldValue,
  setOpen,
  valueYearOfManufacture,
}) => {
  const handleClickRef = useRef<ReturnType<UseHandleClickProps>>(() => void 0);
  const handleClick = useCallback(() => handleClickRef.current(), []);

  useEffect(() => {
    handleClickRef.current = () => {
      result
        ? setFields(name, result, setFieldTouched, setFieldValue, valueYearOfManufacture)
        : resetSearch(name, setFieldTouched, setFieldValue);
      resetAbiCode(name, setFieldTouched, setFieldValue);
      setOpen(true);
    };
  }, [name, result, setFieldTouched, setFieldValue, setOpen, valueYearOfManufacture]);

  return handleClick;
};

export default useHandleClick;
