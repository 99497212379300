import { useCallback, useMemo } from "react";

import { OptionalExtra, Quote } from "modules/quote/types";
import { UseCart } from "modules/cart";

type UseProductBreakdownBasic = (props: {
  cart: ReturnType<UseCart>;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  options: Quote["optionalExtras"];
}) => {
  breakdown1?: { option: OptionalExtra; selected: boolean };
  breakdown2?: { option: OptionalExtra; selected: boolean };
  breakdown3?: { option: OptionalExtra; selected: boolean };
  breakdown4?: { option: OptionalExtra; selected: boolean };
  onClick: () => void;
  price: number;
  selected: boolean;
};

const useProductBreakdownBasic: UseProductBreakdownBasic = ({
  cart: { selectedBreakdown, selectedBreakdown1, selectedBreakdown2, selectedBreakdown3, selectedBreakdown4 },
  onAdd,
  onRemove,
  options,
}) => {
  const breakdown1 = useMemo(
    () => options.breakdown1 && { option: options.breakdown1, selected: selectedBreakdown1 },
    [options.breakdown1, selectedBreakdown1],
  );

  const breakdown2 = useMemo(
    () => options.breakdown2 && { option: options.breakdown2, selected: selectedBreakdown2 },
    [options.breakdown2, selectedBreakdown2],
  );

  const breakdown3 = useMemo(
    () => options.breakdown3 && { option: options.breakdown3, selected: selectedBreakdown3 },
    [options.breakdown3, selectedBreakdown3],
  );

  const breakdown4 = useMemo(
    () => options.breakdown4 && { option: options.breakdown4, selected: selectedBreakdown4 },
    [options.breakdown4, selectedBreakdown4],
  );

  const onClick = useCallback(
    () =>
      selectedBreakdown
        ? [breakdown1, breakdown2, breakdown3, breakdown4].forEach(item => !!item?.selected && onRemove(item.option))
        : !!breakdown1 && onAdd(breakdown1.option),
    [breakdown1, breakdown2, breakdown3, breakdown4, onAdd, onRemove, selectedBreakdown],
  );

  return {
    breakdown1,
    breakdown2,
    breakdown3,
    breakdown4,
    onClick,
    price: breakdown1?.option.grossPremium || 0,
    selected: selectedBreakdown,
  };
};

export default useProductBreakdownBasic;
