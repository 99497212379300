import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { VrmApiRequest, VrmSearchApiResponseResult } from "./types";
import { vrmSearchActionRequest } from "./actions";
import { Dispatch } from "modules/redux/store";

export const useVrmAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    vrmSearchRequest: useCallback(
      (search: VrmApiRequest): Promise<VrmSearchApiResponseResult> => {
        return dispatch(vrmSearchActionRequest(search));
      },
      [dispatch],
    ),
  };
};
