import { FormikProps } from "formik";

import { DescriptionProps } from "./Description/types";
import { QuestionVehicleProps } from "./types";
import { SearchProps } from "./Search/types";
import { VrmProps } from "./Vrm/types";

type GetProps = {
  vrmProps: Omit<VrmProps, "open" | "setOpen">;
  searchProps: SearchProps;
  descriptionProps: Omit<DescriptionProps, "open" | "setOpen">;
};

export const getProps = (props: QuestionVehicleProps): GetProps => {
  return {
    vrmProps: {
      errorAbiCode: props.errorAbiCode,
      errorRegNumber: props.errorRegNumber,
      name: props.name,
      onBlur: props.onBlur,
      onChange: props.onChange,
      setFieldTouched: props.setFieldTouched,
      setFieldValue: props.setFieldValue,
      touchedAbiCode: props.touchedAbiCode,
      touchedRegNumber: props.touchedRegNumber,
      value: props.valueRegNumber,
    },
    searchProps: {
      errorAbiCode: props.errorAbiCode,
      errorCC: props.errorCC,
      errorMake: props.errorMake,
      errorModel: props.errorModel,
      errorYearOfManufacture: props.errorYearOfManufacture,
      name: props.name,
      onBlur: props.onBlur,
      onChange: props.onChange,
      options: props.options,
      readOnly: props.readOnly,
      setFieldTouched: props.setFieldTouched,
      setFieldValue: props.setFieldValue,
      touchedAbiCode: props.touchedAbiCode,
      touchedCC: props.touchedCC,
      touchedMake: props.touchedMake,
      touchedModel: props.touchedModel,
      touchedYearOfManufacture: props.touchedYearOfManufacture,
      valueAbiCode: props.valueAbiCode,
      valueCC: props.valueCC,
      valueMake: props.valueMake,
      valueModel: props.valueModel,
      valueYearOfManufacture: props.valueYearOfManufacture,
    },
    descriptionProps: {
      errorYearOfManufacture: props.errorYearOfManufacture,
      name: props.name,
      setFieldTouched: props.setFieldTouched,
      setFieldValue: props.setFieldValue,
      valueAbiCode: props.valueAbiCode,
      valueYearOfManufacture: props.valueYearOfManufacture,
    },
  };
};

export const resetAbiCode = (
  name: string,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setFieldValue: FormikProps<string>["setFieldValue"],
): void => {
  setFieldTouched(`${name}.abiCode`, false);
  setFieldValue(`${name}.abiCode`, "");
};

export const resetRegNumber = (
  name: string,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setFieldValue: FormikProps<string>["setFieldValue"],
): void => {
  setFieldTouched(`${name}.regNumber`, false);
  setFieldValue(`${name}.regNumber`, "");
};

export const resetSearch = (
  name: string,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setFieldValue: FormikProps<string>["setFieldValue"],
): void => {
  setFieldTouched(`${name}.yearOfManufacture`, false);
  setFieldValue(`${name}.yearOfManufacture`, "");

  setFieldTouched(`${name}.model`, false);
  setFieldValue(`${name}.model`, "");

  setFieldTouched(`${name}.make`, false);
  setFieldValue(`${name}.make`, "");

  setFieldTouched(`${name}.cc`, false);
  setFieldValue(`${name}.cc`, "");
};

export const resetYearOfManufacture = (
  name: string,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setFieldValue: FormikProps<string>["setFieldValue"],
): void => {
  setFieldTouched(`${name}.yearOfManufacture`, false);
  setFieldValue(`${name}.yearOfManufacture`, "");
};
