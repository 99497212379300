import React from "react";

import styles from "./styles.module.scss";

type WebReferenceProps = {
  webReference?: string;
};

const WebReference: React.FunctionComponent<WebReferenceProps> = ({ webReference }) => {
  return webReference ? (
    <div className={styles["web-reference"]}>
      Your quote is now saved. You can recall it with this web reference: <strong>{webReference}</strong>. We will email
      it to you shortly.
    </div>
  ) : null;
};

export default WebReference;
