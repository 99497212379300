import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useSelector } from "modules/redux/store";

import Trustpilot from "components/Header/Trustpilot";
import { matchPaths } from "modules/router/utils";
import { ROUTE } from "modules/router/constants";
import Contact from "components/Header/Contact";
import Times from "components/Header/Times";
import Logo from "components/Header/Logo";
import { App } from "modules/app/types";
import LiveChat from "./LiveChat";
import Recall from "./Recall";

import styles from "./styles.module.scss";

const Header: React.FunctionComponent = () => {
  const app: App = useSelector(state => state.app);
  const location = useLocation();
  return (
    <header className={styles["header"]}>
      <div>
        <div>
          <Logo loading={app.loading} />
          <Trustpilot />
        </div>
        <div>
          <Contact
            telephone={
              matchPaths(
                [
                  ROUTE.CONFIRMATION,
                  ROUTE.MESSAGE_POLICY_RECALL,
                  ROUTE.MESSAGE_QUOTE_PURCHASED,
                  ROUTE.MESSAGE_QUOTE_RETRIEVE,
                ],
                location.pathname,
              )
                ? app.contact.telephone.customerService
                : app.contact.telephone.sales
            }
          />
          <Times openingTimes={app.openingTimes} />
          {!app.error.route && !!matchPath(ROUTE.MOTORCYCLE, location.pathname) && <Recall />}
          {!!matchPaths([ROUTE.QUOTE, ROUTE.PRODUCT, ROUTE.POLICY], location.pathname) && <LiveChat />}
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
