import { useCallback } from "react";

import { scrollTop } from "modules/scroll/helpers";
import { ROUTE } from "modules/router/constants";
import { UseHandleContinue } from "./types";

const useHandleContinue: UseHandleContinue = navigate =>
  useCallback(async () => {
    await scrollTop();
    navigate(ROUTE.PRODUCT);
  }, [navigate]);

export default useHandleContinue;
