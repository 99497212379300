import { v4 as uuidv4 } from "uuid";

import { AdditionalRider, Bike, Claim, Conviction, Rider, Risk } from "./types";
import { PaymentMethod } from "./constants";

export const newRisk = (): Risk => {
  return {
    additionalQuestions: {
      agreedValue: undefined,
      campaignCode: "",
      detailsOfProductsEmail: "",
      detailsOfProductsPhone: "",
      detailsOfProductsPost: "",
      detailsOfProductsText: "",
      documentDelivery: "D",
      homeowner: "",
      largestCC: "",
      normallyPayByDirectDebit: "",
      termsAgreedPolicy: "",
      whereHear: "",
    },
    hasAdditionalRiders: "",
    additionalRiders: [],
    hasAdditionalVehicles: process.env.REACT_APP_MULTIBIKE !== "true" ? false : "",
    additionalVehicles: [],
    cart: {
      optionalExtras: [],
      paymentMethod: PaymentMethod.MONTHLY,
      quoteToken: "",
    },
    isAggregator: false,
    policy: {
      inceptionDate: "",
      protectedBonus: "",
      previousInsuranceExpiryDate: "",
      previousInsuranceNCBYears: "",
      previousInsuranceType: "",
      affinity: "",
    },
    proposer: newProposer(),
    vehicle: newVehicle("mainVehicle"),
  };
};

const newProposer = (): Rider => {
  return {
    id: "proposer",
    name: {
      title: "",
      titleOther: "",
      forenames: "",
      surname: "",
    },
    gender: "",
    dateOfBirth: "",
    residentSinceBirth: "",
    residentSince: "",
    maritalStatus: "",
    bikeLicence: {
      date: "",
      type: "",
      years: "",
    },
    motoringOrg: "",
    fullTimeOccupation: {
      type: "",
      occCode: "",
      empCode: "",
    },
    hasPartTimeOccupation: "",
    partTimeOccupation: {
      type: "",
      occCode: "",
      empCode: "",
    },
    email: "",
    daytimeTelephone: "",
    daytimeTelephoneUnknown: false,
    address: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      addressLine4: "",
      postCode: "",
      abodeType: "",
    },
    isMainRider: true,
    hasClaims: "",
    claims: [],
    hasConvictions: "",
    convictions: [],
  };
};

export const newAdditionalRider = (): AdditionalRider => {
  return {
    id: "new",
    name: {
      title: "",
      titleOther: "",
      forenames: "",
      surname: "",
    },
    gender: "",
    dateOfBirth: "",
    residentSinceBirth: "",
    residentSince: "",
    maritalStatus: "",
    relationshipToProposer: "",
    bikeLicence: {
      date: "",
      type: "",
      years: "",
    },
    fullTimeOccupation: {
      type: "",
      occCode: "",
      empCode: "",
    },
    hasPartTimeOccupation: "",
    partTimeOccupation: {
      type: "",
      occCode: "",
      empCode: "",
    },
    isMainRider: "",
    hasClaims: "",
    claims: [],
    hasConvictions: "",
    convictions: [],
  };
};

export const newClaim = (): Claim => {
  return {
    id: uuidv4(),
    date: "",
    code: "",
    cost: "",
    thirdPartyCost: "",
    personalInjury: "",
    fault: "",
  };
};

export const newConviction = (): Conviction => {
  return {
    id: uuidv4(),
    date: "",
    code: "",
    points: "",
    fine: "",
    disqualified: "",
    disqualification: "",
    drinkReading: "",
    drinkReadingLevel: "",
  };
};

export const newVehicle = (id?: string): Bike => {
  return {
    id: id || "new",
    regNumber: "",
    abiCode: "",
    make: "",
    model: "",
    yearOfManufacture: "",
    cc: "",
    immob: "",
    tracker: "",
    alarm: "",
    imported: "",
    purchasedYet: "",
    purchased: "",
    value: "",
    accessoriesValue: "",
    modifications: "",
    hideModifications: false,
    whereKept: "",
    keptAtHome: "",
    keptPostcode: "",
    classOfUse: "",
    includesPillion: "",
    mileage: "",
    cover: "",
    volXS: "",
  };
};
