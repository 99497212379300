import React from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "No thanks",
  confirmLabel: "Add to cart",
  title: "Are you sure you don't want to add RAC Breakdown Cover?",
  icon: ModalIconType.BREAKDOWN_COVER,
  text: (
    <React.Fragment>
      <h3>
        Breaking down can be an inconveniance that you could do without. But when it happens, be assured you've got
        someone coming to help you
      </h3>
      <p>
        Choose from a selection of four different RAC Breakdown options to add to your motorbike insurance, depending on
        the level of cover you need
      </p>
    </React.Fragment>
  ),
};

export default template;
