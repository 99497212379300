import yup, { CreateError, Messages } from "modules/validation";
import accountValidateApi from "modules/api/accountValidate";
import { validateSortCode } from "./sortcode";
import { ApiError } from "modules/api";
import remote from "../remote";

const Errors = [
  ApiError.ACCOUNT_INVALID,
  ApiError.ACCOUNT_NUMBER_INVALID,
  ApiError.ACCOUNT_NUMBER_MISSING,
  ApiError.ACCOUNT_NUMBER_SORTING_CODE_MISSING,
  ApiError.SORTING_CODE_MISSING,
  ApiError.SORTING_CODE_INVALID,
];

const [request, setActive] = remote(accountValidateApi, Errors, "account");

const regex = new RegExp("^\\d{6,10}|\\d{2}\\-\\d{8}$");

const validateAccount = (value: string): boolean => regex.test(value);

const validate = (
  createError: CreateError,
  path: string,
  value: {
    accountnumber: string;
    sortingcode: string;
  },
): boolean | yup.ValidationError | Promise<boolean | yup.ValidationError> =>
  !value.accountnumber || !validateAccount(value.accountnumber)
    ? false
    : !value.sortingcode || !validateSortCode(value.sortingcode)
    ? createError({ message: Messages.ACCOUNT_REMOTE })
    : request(createError, path, value);

function validator(this: yup.StringSchema, ref: string) {
  return this.test("account", Messages.ACCOUNT, function (value) {
    const values = {
      accountnumber: value || "",
      sortingcode: this.resolve<string>(yup.ref(ref)) || "",
    };
    setActive(this.path, values);
    return values.accountnumber ? validate(this.createError, this.path, values) : true;
  });
}

export default validator;
