import { useCallback } from "react";

import { precisActionRequest } from "modules/precis/actions";
import { ModalType } from "modules/modals/constants";
import { scrollTop } from "modules/scroll/helpers";
import { UseHandleConfirmAndPay } from "./types";

const useHandleConfirmAndPay: UseHandleConfirmAndPay = dispatch =>
  useCallback(async () => {
    await scrollTop();
    dispatch(precisActionRequest({ type: ModalType.LOADING_REQUOTE }));
  }, [dispatch]);

export default useHandleConfirmAndPay;
