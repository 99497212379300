import { produce } from "immer";

import { Risk, RiskActionUpdatePolicy } from "../../types";

const updatePolicy = (state: Risk, action: RiskActionUpdatePolicy): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.vehicle.regNumber = action.risk.vehicle.regNumber.replace(/\s+/g, "");

    draftState.proposer.email = action.risk.proposer.email;
    draftState.proposer.daytimeTelephone = action.risk.proposer.daytimeTelephone;

    draftState.additionalQuestions.documentDelivery = action.risk.additionalQuestions.documentDelivery;
    draftState.additionalQuestions.termsAgreedPolicy = action.risk.additionalQuestions.termsAgreedPolicy;

    draftState.additionalQuestions.detailsOfProductsEmail = action.risk.additionalQuestions.detailsOfProductsEmail;
    draftState.additionalQuestions.detailsOfProductsPhone = action.risk.additionalQuestions.detailsOfProductsPhone;
    draftState.additionalQuestions.detailsOfProductsPost = action.risk.additionalQuestions.detailsOfProductsPost;
    draftState.additionalQuestions.detailsOfProductsText = action.risk.additionalQuestions.detailsOfProductsText;
  });
};

export default updatePolicy;
