import { useCallback } from "react";

import { scrollTop } from "modules/scroll/helpers";
import { ROUTE } from "modules/router/constants";
import { UseHandleCancel } from "./types";

const useHandleCancel: UseHandleCancel = ({ count, navigate }) =>
  useCallback(async () => {
    await scrollTop();
    navigate(!count ? ROUTE.MOTORCYCLE : ROUTE.MOTORCYCLES);
  }, [count, navigate]);

export default useHandleCancel;
