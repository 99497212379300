import { useEffect, useState } from "react";
import { FormikProps } from "formik";
import sleep from "sleep-promise";

import { getError, getTouched } from "../helpers";
import useHandleResponse from "./handleResponse";
import useHandleKeyDown from "./handleKeyDown";
import useHandleRequest from "./handleRequest";
import useHandleSubmit from "./handleSubmit";
import useHandleChange from "./handleChange";
import useHandleClick from "./handleClick";
import useHandleError from "./handleError";
import { isError } from "modules/utils";

type UseVrmProps = {
  errorAbiCode?: string;
  errorRegNumber?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  open: boolean;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setOpen: (state: boolean) => void;
  touchedAbiCode?: boolean;
  touchedRegNumber?: boolean;
  value: string;
};

type UseVrmReturnProps = [
  string | undefined,
  string | undefined,
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  () => void,
  (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  () => void,
  boolean,
  boolean,
  boolean | undefined,
];

const useVrm = ({
  errorAbiCode,
  errorRegNumber,
  name,
  onChange,
  open,
  setFieldTouched,
  setFieldValue,
  setOpen,
  touchedAbiCode,
  touchedRegNumber,
  value,
}: UseVrmProps): UseVrmReturnProps => {
  const [isMainVehicle] = useState<boolean>(!name.startsWith("current"));
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>();

  const handleChange = useHandleChange({ isMainVehicle, name, onChange, setApiError, setFieldTouched, setFieldValue });

  const handleClick = useHandleClick({ name, open, setApiError, setFieldTouched, setFieldValue, setOpen });

  const handleError = useHandleError({ isMainVehicle, setApiError, setOpen });

  const handleRequest = useHandleRequest(value);

  const handleResponse = useHandleResponse({ name, setFieldTouched, setFieldValue, setOpen });

  const handleSubmit = useHandleSubmit({
    errorRegNumber,
    name,
    setApiError,
    setFieldTouched,
    setFieldValue,
    setLoading,
    setOpen,
    value,
  });

  const handleKeyDown = useHandleKeyDown({ handleSubmit, loading });

  useEffect(() => {
    if (loading) {
      let active = true;
      (async function () {
        try {
          await sleep(500);
          const response = await handleRequest();
          active && handleResponse(response);
        } catch (error) {
          active && isError(error) && handleError(error);
        } finally {
          active && setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [handleError, handleRequest, handleResponse, loading]);

  return [
    apiError,
    getError(apiError, errorAbiCode, errorRegNumber, isMainVehicle, open),
    handleChange,
    handleClick,
    handleKeyDown,
    handleSubmit,
    isMainVehicle,
    loading,
    getTouched(apiError, errorAbiCode, errorRegNumber, isMainVehicle, open, touchedAbiCode, touchedRegNumber),
  ];
};

export default useVrm;
