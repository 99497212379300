import sleep from "sleep-promise";

import { api, API, getApiError } from "modules/api";
import { cacheResult, cacheSearch } from "./cache";
import { Dispatch } from "modules/redux/store";
import { AbiAction } from "./constants";
import { isError } from "modules/utils";
import {
  AbiActionRequestStarted,
  AbiActionRequestSuccess,
  AbiActionRequestFailure,
  AbiApiResponseResult,
  AbiApiResponse,
  AbiApiRequest,
} from "./types";

export const abiActionRequest = (abi: AbiApiRequest) => {
  return async (dispatch: Dispatch): Promise<AbiApiResponseResult> => {
    const cached = cacheSearch(abi);
    if (cached) {
      dispatch(abiActionRequestSuccess(cached.result));
      return cached.result;
    } else {
      dispatch(abiActionRequestStarted());
      try {
        await sleep(500);
        const response: AbiApiResponse = await api(API.ABI_REQUEST(abi));
        dispatch(abiActionRequestSuccess(response.data.bike));
        cacheResult(abi, response.data.bike);
        return response.data.bike;
      } catch (error) {
        dispatch(abiActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
        throw new Error(getApiError(error));
      }
    }
  };
};

const abiActionRequestStarted = (): AbiActionRequestStarted => ({
  type: AbiAction.REQUEST_STARTED,
});

const abiActionRequestSuccess = (data: AbiApiResponseResult): AbiActionRequestSuccess => ({
  type: AbiAction.REQUEST_SUCCESS,
  data: data,
});

const abiActionRequestFailure = (error: string): AbiActionRequestFailure => ({
  type: AbiAction.REQUEST_FAILURE,
  error: error,
});
