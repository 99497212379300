import * as Sentry from "@sentry/react";

import { isAdmin } from "modules/admin";

const beforeBreadcrumb = (breadcrumb: Sentry.Breadcrumb, hint?: Sentry.BreadcrumbHint) =>
  isAdmin && hint?.level === "log" ? null : breadcrumb;

const sentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV !== "development")
    Sentry.init({
      beforeBreadcrumb: beforeBreadcrumb,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment:
        process.env.NODE_ENV === process.env.REACT_APP_ENV
          ? process.env.NODE_ENV
          : `${process.env.NODE_ENV} - ${process.env.REACT_APP_ENV}`,
      integrations: [new Sentry.BrowserTracing()],
      normalizeDepth: 6,
      release: "devitt-bike@" + process.env.REACT_APP_VERSION,
      tracesSampleRate: 0,
    });
};

export default sentry;
