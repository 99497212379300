import React from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "No thanks",
  confirmLabel: "Add to cart",
  title: "Are you sure you don't want to add Legal Expenses & Loss Recovery?",
  icon: ModalIconType.LEGAL_EXPENSES,
  text: (
    <React.Fragment>
      <h3>Accidents that aren't your fault can happen. Safeguard yourself from paying out on unexpected fees</h3>
      <p>Protect yourself from legal costs in pursuing a claim for your uninsured losses</p>
      <ul>
        <li>Up to £100,000 legal cover</li>
        <li>Assistance with handling your claim</li>
        <li>Recover uninsured losses including policy excess and lost income</li>
      </ul>
    </React.Fragment>
  ),
};

export default template;
