import React from "react";

import { ReactComponent as WarningSVG } from "modules/theme/icons/warning/warning.svg";
import Button, { ButtonStyle } from "components/Button";
import moment from "moment";

import styles from "./styles.module.scss";

const formatTime = (hours: number, minutes: number, seconds: number) => {
  return `${hours > 0 ? `${hours} hour${hours !== 1 ? "s " : " "}` : ""}
  ${minutes > 0 ? `${minutes} minute${minutes !== 1 ? "s " : " "}` : ""}${seconds} second${seconds !== 1 ? "s" : ""}`;
};

type ModalSessionTemplateProps = {
  completed: boolean;
  hours: number;
  minutes: number;
  onContinue: () => void;
  seconds: number;
  start: number;
};

const ModalSessionTemplate: React.FunctionComponent<ModalSessionTemplateProps> = ({
  completed,
  hours,
  minutes,
  onContinue,
  seconds,
  start,
}) => {
  const duration = moment.duration(moment().diff(start));
  return (
    <div className={styles["modal-session"]}>
      <div>
        <h2>Session timeout</h2>
        <WarningSVG />
      </div>
      <p>
        You have been idle for{" "}
        <strong>{formatTime(Math.floor(duration.asHours()), duration.minutes(), duration.seconds())}.</strong>
      </p>
      <p>
        Your session will expire in <strong>{formatTime(hours, minutes, seconds)}.</strong>
      </p>
      <Button
        ariaLabel="Continue"
        disabled={completed}
        label="Continue"
        onClick={onContinue}
        style={ButtonStyle.PRIMARY}
      />
    </div>
  );
};

export default ModalSessionTemplate;
