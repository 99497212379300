import { useMemo } from "react";

import getRedirect from "modules/router/redirect";
import { ROUTE } from "modules/router/constants";
import { UseRedirect } from "./types";

const useRedirect: UseRedirect = token =>
  useMemo(() => getRedirect({ confirmationToken: token, route: ROUTE.CONFIRMATION }), [token]);

export default useRedirect;
