import React, { useContext } from "react";
import clsx from "clsx";

import { ReactComponent as ChatSVG } from "modules/theme/icons/header/chat.svg";
import { default as ChatContext } from "modules/livechat";
import Button, { ButtonStyle } from "components/Button";

import styles from "./styles.module.scss";

const LiveChat: React.FunctionComponent = () => {
  const { active, availability, handleClick, visibility } = useContext(ChatContext);
  const className = clsx(
    styles["live-chat"],
    availability === "online" && styles["available"],
    visibility === "maximized" && styles["maximized"],
  );
  return active ? (
    <div className={className}>
      <Button
        ariaLabel={availability === "online" ? "Live chat" : "Leave a message"}
        label={availability === "online" ? "Live chat" : "Leave a message"}
        onClick={handleClick}
        style={ButtonStyle.SUCCESS}>
        <ChatSVG />
      </Button>
    </div>
  ) : null;
};

export default LiveChat;
