import { Route } from "./types";

export enum ROUTE_ACTION {
  EDIT = "edit",
  VALIDATE = "validate",
}

export enum ROUTE {
  MOTORCYCLE = "/",
  MOTORCYCLES = "/motorcycles",
  RIDER = "/rider",
  RIDERS = "/riders",
  QUOTE = "/quote",
  PRODUCT = "/product",
  POLICY = "/policy",
  PAYMENT = "/payment",
  CONFIRMATION = "/confirmation",
  RECALL = "/recall",
  AGGREGATOR = "/aggrecall",
  MESSAGE = "/message",
  MESSAGE_404 = "/message/404",
  MESSAGE_CARD_PAYMENT = "/message/card-payment",
  MESSAGE_DIRECT_DEBIT = "/message/direct-debit",
  MESSAGE_DOCUMENT = "/message/document",
  MESSAGE_MAINTENANCE = "/message/maintenance",
  MESSAGE_PAYMENT = "/message/payment",
  MESSAGE_POLICY_RECALL = "/message/policy-recall",
  MESSAGE_QUOTE = "/message/quote",
  MESSAGE_QUOTE_PURCHASED = "/message/quote-purchased",
  MESSAGE_QUOTE_RETRIEVE = "/message/quote-retrieve",
  MESSAGE_SESSION_TIMEOUT = "/message/session-timeout",
  DEBUG = "/debug",
  DEBUG_MODALS = "/debug-modals",
}

type Routes = Route[];

export const errorRoutes: Routes = [
  {
    path: ROUTE.MESSAGE,
    title: "Message - Technical Problems",
  },
  {
    path: ROUTE.MESSAGE_404,
    title: "Message - Page Not Found",
  },
  {
    path: ROUTE.MESSAGE_CARD_PAYMENT,
    title: "Message - Card Payment",
  },
  {
    path: ROUTE.MESSAGE_DOCUMENT,
    title: "Message - Document",
  },
  {
    path: ROUTE.MESSAGE_DIRECT_DEBIT,
    title: "Message - Direct Debit",
  },
  {
    path: ROUTE.MESSAGE_MAINTENANCE,
    title: "Message - Maintenance",
  },
  {
    path: ROUTE.MESSAGE_PAYMENT,
    title: "Message - Payment",
  },
  {
    path: ROUTE.MESSAGE_POLICY_RECALL,
    title: "Message - Policy Recall",
  },
  {
    path: ROUTE.MESSAGE_QUOTE,
    title: "Message - Quote",
  },
  {
    path: ROUTE.MESSAGE_QUOTE_PURCHASED,
    title: "Message - Quote Purchased",
  },
  {
    path: ROUTE.MESSAGE_QUOTE_RETRIEVE,
    title: "Message - Quote Retrieve",
  },
  {
    path: ROUTE.MESSAGE_SESSION_TIMEOUT,
    title: "Message - Session Timeout",
  },
];

export const routes: Routes = [
  {
    path: ROUTE.MOTORCYCLE,
    title: "Motorcycle",
  },
  ...(process.env.REACT_APP_MULTIBIKE === "true"
    ? [
        {
          path: ROUTE.MOTORCYCLES,
          title: "Motorcycles",
        },
        {
          path: `${ROUTE.MOTORCYCLES}/:id` as ROUTE,
          title: "Additional Motorcycle",
        },
      ]
    : []),
  {
    path: ROUTE.RIDER,
    title: "Rider",
  },
  {
    path: ROUTE.RIDERS,
    title: "Riders",
  },
  {
    path: `${ROUTE.RIDERS}/:id` as ROUTE,
    title: "Additional Rider",
  },
  {
    path: ROUTE.QUOTE,
    title: "Quote",
  },
  {
    path: ROUTE.PRODUCT,
    title: "Product",
  },
  {
    path: ROUTE.POLICY,
    title: "Policy",
  },
  {
    path: ROUTE.PAYMENT,
    title: "Payment",
  },
  {
    path: ROUTE.CONFIRMATION,
    title: "Confirmation",
  },
  {
    path: ROUTE.RECALL,
    title: "Recall",
  },
  {
    path: ROUTE.AGGREGATOR,
    title: "Aggregator Recall",
  },
  ...errorRoutes,
  {
    path: ROUTE.DEBUG,
    title: "Debug",
  },
  {
    path: ROUTE.DEBUG_MODALS,
    title: "Debug - Modals",
  },
];
