import yup, { Messages } from "modules/validation";

export const validateGender = (gender: string, title: string, titleOther: string): boolean => {
  switch (true) {
    case gender === "M" && ["Mrs", "Miss", "Ms", "Mx"].includes(title === "Other" ? titleOther : title):
      return false;

    case gender === "F" && ["Mr", "Mx", "Sir"].includes(title === "Other" ? titleOther : title):
      return false;

    default:
      return true;
  }
};

function validator(this: yup.StringSchema) {
  return this.test("gender", Messages.GENDER, function (value) {
    const title = this.parent.name.title || "";
    const titleOther = this.parent.name.titleOther || "";
    return value ? validateGender(value, title, titleOther) : true;
  });
}

export default validator;
