import { FormikProps } from "formik";

import { VehicleApiResponseResult } from "modules/api/vehicle/types";
import { MetadataItem } from "modules/metadata/types";

export const formatResults = (results?: VehicleApiResponseResult): MetadataItem[] => {
  return results
    ? results.map(item => ({
        value: item.abiCode,
        label: `${item.model} ${item.productionYears} ${item.engineCC}cc`,
      }))
    : [];
};

export const validate = (
  name: string,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  errorCC?: string,
  errorMake?: string,
  errorModel?: string,
  errorYearOfManufacture?: string,
): boolean => {
  setFieldTouched(`${name}.yearOfManufacture`, true);
  setFieldTouched(`${name}.model`, true);
  setFieldTouched(`${name}.make`, true);
  setFieldTouched(`${name}.cc`, true);
  return !(errorCC || errorMake || errorModel || errorYearOfManufacture);
};
