import yup, { CreateError, Messages } from "modules/validation";
import moment from "moment";

const validateResidentSince = (
  createError: CreateError,
  dateOfBirth: moment.Moment,
  proposer: boolean,
  residentSince: moment.Moment,
): boolean | yup.ValidationError => {
  return residentSince.isBefore(dateOfBirth, "day")
    ? createError({
        message: Messages.RESIDENT_SINCE,
        params: proposer ? { you: "You", your: "your" } : { you: "They", your: "their" },
      })
    : true;
};

function validator(this: yup.DateSchema) {
  return this.test("residentSince", Messages.DATE, function (value) {
    const dateOfBirth = this.parent.dateOfBirth ? moment(this.parent.dateOfBirth) : false;
    const residentSince = value ? moment(value) : false;
    const proposer = this.path.startsWith("proposer");
    return dateOfBirth && dateOfBirth.isValid() && residentSince && residentSince.isValid()
      ? validateResidentSince(this.createError, dateOfBirth, proposer, residentSince)
      : true;
  });
}

export default validator;
