import { ReactComponent as DevittEvolutionSVG } from "modules/theme/icons/product/plans/evolution.svg";
import { ReactComponent as DevittEnhancedSVG } from "modules/theme/icons/product/plans/enhanced.svg";
import { ReactComponent as DevittSVG } from "modules/theme/icons/product/plans/basic.svg";

type GetDevittLogo = (props: {
  selectedEnhanced: boolean;
  selectedEnhancedPlus: boolean;
  selectedEvolution: boolean;
  selectedEvolutionPlus: boolean;
}) => JSX.Element;

const getDevittLogo: GetDevittLogo = ({
  selectedEnhanced,
  selectedEnhancedPlus,
  selectedEvolution,
  selectedEvolutionPlus,
}) => {
  switch (true) {
    case selectedEnhanced || selectedEnhancedPlus:
      return <DevittEnhancedSVG />;
    case selectedEvolution || selectedEvolutionPlus:
      return <DevittEvolutionSVG />;
    default:
      return <DevittSVG />;
  }
};

export default getDevittLogo;
