import { useCallback, useEffect, useRef } from "react";

import { LiveChatAvailability, LiveChatVisibility } from "../types";
import useHandleReady from "./handleReady";

type UseHandleLoadProps = (props: {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setAvailability: React.Dispatch<React.SetStateAction<LiveChatAvailability>>;
  setVisibility: React.Dispatch<React.SetStateAction<LiveChatVisibility>>;
}) => () => void;

const useHandleLoad: UseHandleLoadProps = ({ setActive, setAvailability, setVisibility }) => {
  const handleLoadRef = useRef<ReturnType<UseHandleLoadProps>>(() => void 0);
  const handleLoad = useCallback(() => handleLoadRef.current(), []);
  const handleReady = useHandleReady({ setActive, setAvailability, setVisibility });

  useEffect(() => {
    handleLoadRef.current = () => {
      window.LiveChatWidget && window.LiveChatWidget.on("ready", handleReady);
      window.LiveChatWidget && window.LiveChatWidget.call("hide");
    };
  }, [handleReady]);

  return handleLoad;
};

export default useHandleLoad;
