import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import disableScroll from "disable-scroll";
import { HTMLElementMap } from "./types";

export function addNode(key: string, nodes: HTMLElementMap, node: HTMLElement): HTMLElementMap {
  return { ...nodes, [key]: node };
}

export function focusNode(node: HTMLElement) {
  const element = node.querySelector(
    'input[tabindex="0"], select[tabindex="0"], button[tabindex="0"], div[role="button"][tabindex="0"], label',
  ) as HTMLElement;
  if (element?.tagName === "LABEL") {
    document.getElementById((element as HTMLLabelElement)?.htmlFor)?.focus();
  } else {
    element && element.focus();
  }
}

export function sortNodes(nodes: HTMLElement[]) {
  try {
    return nodes.sort(function (a: HTMLElement, b: HTMLElement) {
      const position = a.compareDocumentPosition(b);
      if (position & 4 || position & 16) {
        return -1;
      } else if (position & 2 || position & 8) {
        return 1;
      } else if (position & 1 || position & 32) {
        throw new Error();
      } else {
        return 0;
      }
    });
  } catch {
    return nodes;
  }
}

export function removeNode(key: string, nodes: HTMLElementMap): HTMLElementMap {
  const { [key]: omitted, ...filtered } = nodes;
  return filtered;
}

export function scrollDisable() {
  const html = document.getElementsByTagName("html")[0];
  html.classList.add("scroll-disable");
  disableScroll.on();
}

export function scrollEnable() {
  const html = document.getElementsByTagName("html")[0];
  html.classList.remove("scroll-disable");
  disableScroll.off();
}

export function scrollTop(): Promise<void | undefined> {
  const node = document.getElementsByTagName("body")[0];
  scrollDisable();
  return smoothScrollIntoView(node, {
    scrollMode: "always",
    block: "start",
    inline: "start",
  }).then(() => {
    scrollEnable();
  });
}
