import { useCallback, useEffect, useRef } from "react";

import { VehicleApiRequest, VehicleApiResponseResult } from "modules/api/vehicle/types";
import { useVehicleAction } from "modules/api/vehicle/hooks";

type UseHandleRequestProps = (
  valueCC: "" | number,
  valueMake: string,
  valueModel: string,
  valueYearOfManufacture: "" | number,
) => () => Promise<VehicleApiResponseResult>;

const useHandleRequest: UseHandleRequestProps = (valueCC, valueMake, valueModel, valueYearOfManufacture) => {
  const handleRequestRef = useRef(async (): Promise<VehicleApiResponseResult> => {
    return {} as VehicleApiResponseResult;
  });
  const handleRequest = useCallback(() => handleRequestRef.current(), []);
  const { vehicleRequest } = useVehicleAction();

  useEffect(() => {
    handleRequestRef.current = async () => {
      const response = await vehicleRequest({
        cc: valueCC ? valueCC : 0,
        make: valueMake,
        model: valueModel,
        year: valueYearOfManufacture,
      } as VehicleApiRequest);
      return response;
    };
  }, [valueCC, valueMake, valueModel, valueYearOfManufacture, vehicleRequest]);

  return handleRequest;
};

export default useHandleRequest;
