import { useCallback } from "react";

import { ROUTE, ROUTE_ACTION } from "modules/router/constants";
import { scrollTop } from "modules/scroll/helpers";
import { UseHandleCancel } from "./types";

const useHandleCancel: UseHandleCancel = ({ navigate, query }) => {
  const handleCancel = useCallback(async () => {
    await scrollTop();
    navigate(ROUTE.MOTORCYCLES);
  }, [navigate]);
  return query.get("action") === ROUTE_ACTION.EDIT ? handleCancel : undefined;
};

export default useHandleCancel;
