import React, { useEffect } from "react";
import { FormikErrors, FormikProps, FormikTouched } from "formik";

import { MetadataItem } from "modules/metadata/types";
import * as Question from "components/Questions";
import { Rider } from "modules/risk/types";

type QuestionTitleProps = {
  autoFocus?: boolean;
  errors?: FormikErrors<Rider["name"]>;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLElement>) => void;
  optionsTitle: MetadataItem[];
  optionsTitleOther: MetadataItem[];
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string | string[]>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string | string[]>["setFieldValue"];
  touched?: FormikTouched<Rider["name"]>;
  values: Rider["name"];
  valueGender: Rider["gender"];
};

const QuestionTitle: React.FunctionComponent<QuestionTitleProps> = ({
  autoFocus,
  errors,
  name,
  onBlur,
  onChange,
  optionsTitle,
  optionsTitleOther,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  valueGender,
}) => {
  useEffect(() => {
    switch (true) {
      case ["Mr", "Sir"].includes(values.title === "Other" ? values.titleOther : values.title):
        setFieldValue(`${name}.gender`, "M");
        break;

      case ["Miss", "Mrs", "Ms"].includes(values.title === "Other" ? values.titleOther : values.title):
        setFieldValue(`${name}.gender`, "F");
        break;

      case valueGender === "U" && values.title === "Other" && ["Dr", "Prof"].includes(values.titleOther):
        setFieldValue(`${name}.gender`, "");
        break;

      case values.title === "Other" && values.titleOther === "Mx":
        setFieldValue(`${name}.gender`, "U");
        break;
    }
  }, [name, setFieldValue, valueGender, values.title, values.titleOther]);
  return (
    <React.Fragment>
      <Question.QuestionRadio
        autoFocus={autoFocus}
        error={errors?.title}
        format={Question.QuestionRadioFormat.HORIZONTAL}
        label="Title"
        name={`${name}.name.title`}
        options={optionsTitle}
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched?.title}
        value={values.title}
      />

      <Question.QuestionSecondary
        name={`${name}.name.titleOther`}
        open={values.title === "Other"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.titleOther}>
        <Question.QuestionSelect
          error={errors?.titleOther}
          label="Other title"
          name={`${name}.name.titleOther`}
          onBlur={onBlur}
          onChange={onChange}
          options={optionsTitleOther}
          readOnly={readOnly}
          secondary={true}
          touched={touched?.titleOther}
          value={values.titleOther}
        />
      </Question.QuestionSecondary>
    </React.Fragment>
  );
};

export default QuestionTitle;
