import React, { useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { PaymentMethod } from "modules/risk/constants";
import { formatPrice } from "modules/utils";
import { Quote } from "modules/quote/types";
import Button from "components/Button";
import { UseCart } from "modules/cart";

import styles from "./styles.module.scss";

type PolicyPremiumProps = {
  cart: ReturnType<UseCart>;
  isOpen?: boolean;
  paymentMethod: PaymentMethod;
  quote: Quote;
};

const PolicyPremium: React.FunctionComponent<PolicyPremiumProps> = ({
  cart: {
    selectedBreakdown1,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4,
    selectedEnhanced,
    selectedEnhancedPlus,
    selectedEvolution,
    selectedEvolutionPlus,
    selectedHelmetLeathers,
    selectedLegal,
    selectedPersonalAccident,
    selectedAccidentHelmetLeathers,
  },
  isOpen = false,
  paymentMethod,
  quote: {
    algPremium,
    commissionAmount,
    discountAmount,
    fee,
    instalments,
    ipt,
    iptRate,
    optionalExtras: options,
    premium,
    totalAnnualPrice,
  },
}) => {
  const [open, setOpen] = useState(isOpen);
  const className = clsx(styles["policy-premium"], open && styles["open"]);
  return (
    <div className={className}>
      <header>
        <Button ariaLabel="Premium and Instalment Details" onClick={() => setOpen(!open)}>
          <h2>Premium and Instalment Details</h2>
          <ChevronDownSVG />
        </Button>
      </header>
      {open && (
        <section>
          <div>
            <header>
              <h3>Pay Full Amount</h3>
            </header>
            <table>
              <tbody>
                <tr>
                  <td>Insurer Premium</td>
                  <td>{formatPrice(algPremium)}</td>
                </tr>
                <tr>
                  <td>Insurance Premium Tax at {iptRate}%</td>
                  <td>{formatPrice(ipt)}</td>
                </tr>
                {!!discountAmount && discountAmount > 0 && (
                  <tr>
                    <td>Commission Rebated</td>
                    <td>{formatPrice(discountAmount)}</td>
                  </tr>
                )}
                {!!commissionAmount && commissionAmount > 0 && (
                  <tr>
                    <td>Commission Charged</td>
                    <td>{formatPrice(commissionAmount)}</td>
                  </tr>
                )}
                {!!fee && fee > 0 && (
                  <tr>
                    <td>Administration Fee</td>
                    <td>{formatPrice(fee)}</td>
                  </tr>
                )}
                {Object.values(options).filter(item => !!item?.selected).length === 0 && (
                  <tr>
                    <td>Premium payable</td>
                    <td>{formatPrice(premium)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {Object.values(options).filter(item => !!item?.selected).length > 0 && (
            <div>
              <header>
                <h3>Add-ons</h3>
              </header>
              <table>
                <tbody>
                  {!!options.enhanced && selectedEnhanced && (
                    <tr>
                      <td>
                        Devitt Enhanced
                        <ul>
                          <li>Legal Expenses & Loss Recovery</li>
                          <li>
                            RAC Breakdown Cover
                            <br />
                            <small>UK Roadside</small>
                          </li>
                        </ul>
                      </td>
                      <td>{formatPrice(options.enhanced.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.enhancedPlus && selectedEnhancedPlus && (
                    <tr>
                      <td>
                        Devitt Enhanced Plus
                        <ul>
                          <li>Legal Expenses & Loss Recovery</li>
                          <li>
                            RAC Breakdown Cover
                            <br />
                            <small>UK Roadside, Recovery, At Home + Onward Travel & EU</small>
                          </li>
                        </ul>
                      </td>
                      <td>{formatPrice(options.enhancedPlus.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.evolution && selectedEvolution && (
                    <tr>
                      <td>
                        Devitt Evolution
                        <ul>
                          <li>Legal Expenses & Loss Recovery</li>
                          <li>
                            RAC Breakdown Cover
                            <br />
                            <small>UK Roadside</small>
                          </li>
                          <li>Helmet & Leathers</li>
                          <li>Personal Accident</li>
                        </ul>
                      </td>
                      <td>{formatPrice(options.evolution.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.evolutionPlus && selectedEvolutionPlus && (
                    <tr>
                      <td>
                        Devitt Evolution
                        <ul>
                          <li>Legal Expenses & Loss Recovery</li>
                          <li>
                            RAC Breakdown Cover
                            <br />
                            <small>UK Roadside, Recovery, At Home + Onward Travel & EU</small>
                          </li>
                          <li>Helmet & Leathers</li>
                          <li>Personal Accident</li>
                        </ul>
                      </td>
                      <td>{formatPrice(options.evolutionPlus.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.legal && selectedLegal && (
                    <tr>
                      <td>Legal Expenses & Loss Recovery</td>
                      <td>{formatPrice(options.legal.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.breakdown1 && selectedBreakdown1 && (
                    <tr>
                      <td>
                        RAC Breakdown Cover
                        <br />
                        <small>UK Roadside</small>
                      </td>
                      <td>{formatPrice(options.breakdown1.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.breakdown2 && selectedBreakdown2 && (
                    <tr>
                      <td>
                        RAC Breakdown Cover
                        <br />
                        <small>UK Roadside + Recovery</small>
                      </td>
                      <td>{formatPrice(options.breakdown2.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.breakdown3 && selectedBreakdown3 && (
                    <tr>
                      <td>
                        RAC Breakdown Cover
                        <br />
                        <small>UK Roadside, Recovery + At home</small>
                      </td>
                      <td>{formatPrice(options.breakdown3.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.breakdown4 && selectedBreakdown4 && (
                    <tr>
                      <td>
                        RAC Breakdown Cover
                        <br />
                        <small>UK Roadside, Recovery, At Home + Onward Travel & EU</small>
                      </td>
                      <td>{formatPrice(options.breakdown4.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.helmetLeathers && selectedHelmetLeathers && (
                    <tr>
                      <td>Helmet & Leathers</td>
                      <td>{formatPrice(options.helmetLeathers.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.personalAccident && selectedPersonalAccident && (
                    <tr>
                      <td>Personal Accident</td>
                      <td>{formatPrice(options.personalAccident.grossPremium)}</td>
                    </tr>
                  )}

                  {!!options.accidentHelmetLeathers && selectedAccidentHelmetLeathers && (
                    <tr>
                      <td>Personal Accident + Helmet & Leathers</td>
                      <td>{formatPrice(options.accidentHelmetLeathers.grossPremium)}</td>
                    </tr>
                  )}

                  <tr>
                    <td>Premium payable</td>
                    <td>{formatPrice(totalAnnualPrice)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {paymentMethod === PaymentMethod.MONTHLY && !!instalments && (
            <div>
              <header>
                <h3>Pay By Instalments</h3>
              </header>
              <table>
                <tbody>
                  <tr>
                    <td>Premium payable</td>
                    <td>{formatPrice(totalAnnualPrice)}</td>
                  </tr>
                  <tr>
                    <td>
                      Deposit
                      <br />
                      <small>(payable now by debit or credit card)</small>
                    </td>
                    <td>{formatPrice(instalments.deposit)}</td>
                  </tr>
                  <tr>
                    <td>Remaining loan amount</td>
                    <td>{formatPrice(totalAnnualPrice - instalments.deposit)}</td>
                  </tr>
                  <tr>
                    <td>
                      Charge for credit
                      <br />
                      <small>
                        ({instalments.interestPercentage}% representative APR {instalments.apr}%)
                      </small>
                    </td>
                    <td>{formatPrice(instalments.flatInterestAmount)}</td>
                  </tr>
                  <tr>
                    <td>Total repayment by instalments</td>
                    <td>{formatPrice(instalments.numberOfPayments * instalments.amount)}</td>
                  </tr>
                  <tr>
                    <td>{instalments.numberOfPayments} monthly direct debit payments of</td>
                    <td>{formatPrice(instalments.amount)}</td>
                  </tr>
                  <tr>
                    <td>The total amount you will pay by using direct debit</td>
                    <td>{formatPrice(instalments.totalPayable)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default React.memo(PolicyPremium);
