import React from "react";
import { FormikErrors, FormikTouched } from "formik";

import QuestionFieldGroup, { QuestionFieldGroupProps } from "components/Questions/QuestionFieldGroup";
import { ButtonIconType } from "components/Inputs/Buttons/Button/Icons";
import { OptionType } from "components/Inputs/FieldGroup";
import { Expand } from "modules/typescript/helpers";
import { Risk } from "modules/risk/types";

type QuestionContactProps = Expand<
  Omit<QuestionFieldGroupProps, "options" | "touched"> & {
    errors?: FormikErrors<Risk["additionalQuestions"]>;
    touched?: FormikTouched<Risk["additionalQuestions"]>;
    values: Risk["additionalQuestions"];
  }
>;

const QuestionContact: React.FunctionComponent<QuestionContactProps> = ({ errors, touched, values, ...props }) => {
  const options: OptionType[] = [
    {
      error: errors?.detailsOfProductsEmail,
      icon: ButtonIconType.EMAIL,
      label: "E-mail",
      name: "additionalQuestions.detailsOfProductsEmail",
      selected: !!values.detailsOfProductsEmail,
      touched: touched?.detailsOfProductsEmail,
    },
    {
      error: errors?.detailsOfProductsText,
      icon: ButtonIconType.SMS,
      label: "Text / SMS",
      name: "additionalQuestions.detailsOfProductsText",
      selected: !!values.detailsOfProductsText,
      touched: touched?.detailsOfProductsText,
    },
    {
      error: errors?.detailsOfProductsPhone,
      icon: ButtonIconType.PHONE,
      label: "Phone",
      name: "additionalQuestions.detailsOfProductsPhone",
      selected: !!values.detailsOfProductsPhone,
      touched: touched?.detailsOfProductsPhone,
    },
    {
      error: errors?.detailsOfProductsPost,
      icon: ButtonIconType.POST,
      label: "Post",
      name: "additionalQuestions.detailsOfProductsPost",
      selected: !!values.detailsOfProductsPost,
      touched: touched?.detailsOfProductsPost,
    },
  ];
  return (
    <QuestionFieldGroup
      error={options.filter(option => option.error !== undefined)[0]?.error}
      options={options}
      touched={options.some(option => option.touched === true)}
      {...props}
    />
  );
};

export default React.memo(QuestionContact);
