//import { Bike, Rider, Risk } from "./types";
import { AdditionalRider, Bike, Claim, Conviction, Rider, Risk } from "./types";
import { PaymentMethod } from "./constants";

export const newRiskDebug = (): Risk => {
  return {
    additionalQuestions: {
      campaignCode: "",
      detailsOfProductsPost: true,
      detailsOfProductsPhone: true,
      detailsOfProductsEmail: true,
      detailsOfProductsText: true,
      documentDelivery: "D",
      homeowner: false,
      largestCC: "0",
      normallyPayByDirectDebit: false,
      termsAgreedPolicy: "",
      whereHear: "BIKSHD",
    },
    hasAdditionalRiders: true,
    //additionalRiders: [],
    additionalRiders: [additionalRider1(), additionalRider2()],
    hasAdditionalVehicles: false,
    //additionalVehicles: [],
    additionalVehicles: [additionalVehicle1(), additionalVehicle2()],
    cart: {
      optionalExtras: [],
      paymentMethod: PaymentMethod.MONTHLY,
      quoteToken: "",
    },
    isAggregator: false,
    policy: {
      inceptionDate: "2023-06-21",
      protectedBonus: false,
      previousInsuranceExpiryDate: "",
      previousInsuranceNCBYears: "0",
      previousInsuranceType: "NONE",
      affinity: "DIRI",
      //subAgent: "G027",
    },
    proposer: proposer(),
    vehicle: mainVehicle(),
  };
};

const proposer = (): Rider => {
  return {
    id: "proposer",
    name: {
      title: "Mr",
      titleOther: "",
      forenames: "Matthew",
      surname: "Pringle",
    },
    gender: "M",
    dateOfBirth: "2003-04-06",
    residentSinceBirth: true,
    residentSince: "1978-03-15",
    maritalStatus: "S",
    bikeLicence: {
      date: "2021-07",
      type: "F",
      years: "1",
    },
    motoringOrg: "NONE",
    fullTimeOccupation: {
      type: "R",
      occCode: "R09",
      empCode: "947",
    },
    hasPartTimeOccupation: false,
    partTimeOccupation: {
      type: "NONE",
      occCode: "",
      empCode: "",
    },
    email: "matt@mattpringle.co.uk",
    daytimeTelephone: "07803588801",
    daytimeTelephoneUnknown: false,
    address: {
      addressLine1: "20 Edge Lane",
      addressLine2: "MANCHESTER",
      addressLine3: "",
      addressLine4: "",
      postCode: "M21 9JF",
      abodeType: "H",
    },
    isMainRider: true,
    hasClaims: true,
    //claims: [],
    claims: [
      newClaim("S006", "2019-01-01", "XXXXXXXXXX1"),
      newClaim("M056", "2020-01-01", "XXXXXXXXXX2"),
      newClaim("T004", "2021-11-12", "XXXXXXXXXX3"),
    ],
    hasConvictions: true,
    //convictions: [],
    convictions: [
      newConviction("CD54", "2019-06-03", "XXXXXXXXXX1"),
      newConviction("DR90", "2020-01-01", "XXXXXXXXXX2"),
      newConviction("UT56", "2021-01-01", "XXXXXXXXXX3"),
    ],
  };
};

export const mainVehicle = (): Bike => {
  return {
    id: "mainVehicle",
    regNumber: "DS17 XCT",
    abiCode: "80460599",
    make: "",
    model: "",
    yearOfManufacture: 2017,
    //yearOfManufacture: "",
    cc: "",
    immob: "FACT",
    tracker: "0587",
    alarm: "SMA1",
    imported: false,
    purchasedYet: true,
    purchased: "2021-01-01",
    value: 1000,
    accessoriesValue: 0,
    modifications: false,
    whereKept: "D",
    keptAtHome: true,
    keptPostcode: "",
    classOfUse: "SDPexC",
    includesPillion: false,
    mileage: 1000,
    cover: "C",
    volXS: "100",
  };
};

const additionalRider1 = (): AdditionalRider => {
  return {
    id: "XXXXXXXXXX1",
    name: {
      title: "Mrs",
      titleOther: "",
      forenames: "Sally",
      surname: "Pringle",
    },
    gender: "F",
    dateOfBirth: "1980-01-05",
    residentSinceBirth: true,
    residentSince: "",
    maritalStatus: "M",
    relationshipToProposer: "N",
    bikeLicence: {
      date: "",
      type: "F",
      years: "5",
    },
    fullTimeOccupation: {
      type: "E",
      occCode: "01K",
      empCode: "231",
    },
    hasPartTimeOccupation: true,
    partTimeOccupation: {
      type: "D",
      occCode: "148",
      empCode: "970",
    },
    isMainRider: false,
    hasClaims: true,
    claims: [newClaim("S006", "2021-01-01", "XXXXXXXXXX1")],
    hasConvictions: true,
    convictions: [newConviction("CD54", "2018-08-02", "XXXXXXXXXX1")],
  };
};

const additionalRider2 = (): AdditionalRider => {
  return {
    id: "XXXXXXXXXX2",
    name: {
      title: "Other",
      titleOther: "Prof",
      forenames: "Ben",
      surname: "Pringle",
    },
    gender: "M",
    dateOfBirth: "1982-05-22",
    residentSinceBirth: false,
    residentSince: "1982-05-22",
    maritalStatus: "M",
    relationshipToProposer: "N",
    bikeLicence: {
      date: "",
      type: "F",
      years: "9",
    },
    fullTimeOccupation: {
      type: "E",
      occCode: "01K",
      empCode: "231",
    },
    hasPartTimeOccupation: true,
    partTimeOccupation: {
      type: "D",
      occCode: "148",
      empCode: "970",
    },
    isMainRider: false,
    hasClaims: true,
    claims: [newClaim("S006", "2020-03-05", "XXXXXXXXXX1"), newClaim("S006", "2018-03-05", "XXXXXXXXXX12")],
    hasConvictions: true,
    convictions: [newConviction("CD54", "2018-08-02", "XXXXXXXXXX1")],
  };
};

const newClaim = (code: string, date: string, id: string): Claim => {
  return {
    id: id,
    date: date,
    code: code,
    cost: 1000,
    thirdPartyCost: 1200,
    personalInjury: true,
    fault: true,
  };
};

const newConviction = (code: string, date: string, id: string): Conviction => {
  return {
    id: id,
    date: date,
    code: code,
    points: 5,
    fine: 1000,
    disqualified: true,
    disqualification: 5,
    drinkReading: "",
    drinkReadingLevel: "",
  };
};

const additionalVehicle1 = (): Bike => {
  return {
    id: "XXXXXXXXXX1",
    regNumber: "L412 MNH",
    abiCode: "81040083",
    make: "",
    model: "",
    yearOfManufacture: 1993,
    cc: "",
    immob: "FACT",
    tracker: "0587",
    alarm: "SMA1",
    imported: false,
    purchasedYet: true,
    purchased: "2021-01-01",
    value: 1000,
    accessoriesValue: 0,
    modifications: false,
    whereKept: "D",
    keptAtHome: true,
    keptPostcode: "",
    classOfUse: "SDPexC",
    includesPillion: false,
    mileage: 1000,
    cover: "C",
    volXS: "100",
  };
};

const additionalVehicle2 = (): Bike => {
  return {
    id: "XXXXXXXXXX2",
    regNumber: "MJ20 HKY",
    abiCode: "80120246",
    make: "",
    model: "",
    yearOfManufacture: 2020,
    cc: "",
    immob: "FACT",
    tracker: "0587",
    alarm: "SMA1",
    imported: false,
    purchasedYet: true,
    purchased: "2021-01-01",
    value: 1000,
    accessoriesValue: 0,
    modifications: false,
    whereKept: "D",
    keptAtHome: true,
    keptPostcode: "",
    classOfUse: "SDPexC",
    includesPillion: false,
    mileage: 1000,
    cover: "C",
    volXS: "100",
  };
};
