import React, { useContext, useRef } from "react";
import clsx from "clsx";

import ButtonIcon, { ButtonIconType } from "./Icons";
import PlatformContext from "modules/platform/context";
import { useOnMount } from "modules/utils";

import styles from "./styles.module.scss";

type ButtonProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  icon?: ButtonIconType | string;
  id?: string;
  invalid?: boolean;
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  readOnly?: boolean;
  selected: boolean;
  tabIndex?: number;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  autoFocus,
  disabled,
  icon,
  id,
  invalid,
  label,
  onClick,
  readOnly,
  selected,
  tabIndex,
}) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const { isSafari } = useContext(PlatformContext);

  useOnMount(() => !!autoFocus && isSafari && buttonRef.current && buttonRef.current.focus());

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    !disabled && !readOnly && onClick(event);
    event.preventDefault();
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClick(event);
      event.stopPropagation();
    }
  };
  const className = clsx(
    styles["button"],
    disabled && styles["disabled"],
    !disabled && invalid && styles["invalid"],
    readOnly && styles["read-only"],
    selected && !disabled && styles["selected"],
  );
  return isSafari ? (
    <div
      aria-label={label}
      className={className}
      id={id}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={buttonRef}
      role="button"
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}>
      {!!icon && <ButtonIcon type={icon} />}
      <span>{label}</span>
    </div>
  ) : (
    <button
      aria-label={label}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      id={id}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
      type="button">
      {!!icon && <ButtonIcon type={icon} />}
      <span>{label}</span>
    </button>
  );
};

export default Button;
