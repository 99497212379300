import React, { useRef } from "react";
import clsx from "clsx";

import { useScrollRef } from "modules/scroll/hooks";
import { TextAreaFormat } from "./constants";
import { useOnMount } from "modules/utils";
import { TextAreaProps } from "./types";
import { useMask } from "./hooks";

import styles from "./styles.module.scss";

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  autoFocus,
  disabled,
  format = TextAreaFormat.TEXT,
  id,
  invalid,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  rows,
  tabIndex,
  value,
}) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const scrollRef = useScrollRef(id);

  const inputProps = useMask({
    format: format,
    maxLength: maxLength,
    placeholder: placeholder,
  });

  useOnMount(() => {
    !!inputProps.onMount && inputProps.onMount(inputRef);
  });

  const className = clsx(
    styles["textarea"],
    format && styles[`textarea-${format}`],
    disabled && styles["disabled"],
    !disabled && invalid && styles["invalid"],
  );
  return (
    <div className={className} ref={!disabled && invalid ? scrollRef : undefined}>
      <textarea
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id}
        maxLength={inputProps.maxLength}
        name={name}
        onBlur={inputProps.onBlur ? inputProps.onBlur(onBlur) : onBlur}
        onChange={onChange}
        onFocus={inputProps.onFocus ? inputProps.onFocus(onFocus) : onFocus}
        onInput={inputProps.onInput ? inputProps.onInput(onInput) : onInput}
        onKeyDown={inputProps.onKeyDown ? inputProps.onKeyDown(onKeyDown) : onKeyDown}
        placeholder={inputProps.placeholder}
        readOnly={readOnly}
        ref={inputRef}
        rows={rows}
        tabIndex={disabled ? -1 : tabIndex !== undefined ? tabIndex : 0}
        value={value || ""}
      />
    </div>
  );
};

export default React.memo(TextArea);
