import React from "react";

import { ReactComponent as LegalSVG } from "modules/theme/icons/form/claims-convictions/conviction.svg";
import { ReactComponent as DevittEvolutionSVG } from "modules/theme/icons/product/plans/evolution.svg";
import { ReactComponent as DevittEnhancedSVG } from "modules/theme/icons/product/plans/enhanced.svg";
import { ReactComponent as ClaimSVG } from "modules/theme/icons/form/claims-convictions/claim.svg";
import { ReactComponent as BreakdownSVG } from "modules/theme/icons/product/addons/breakdown.svg";
import { ReactComponent as AccidentSVG } from "modules/theme/icons/product/addons/accident.svg";
import { ReactComponent as HelmetSVG } from "modules/theme/icons/product/addons/helmet.svg";
import { ReactComponent as BikeSVG } from "modules/theme/icons/product/addons/bike.svg";

export enum ModalIconType {
  BIKE = "bike",
  BREAKDOWN_COVER = "breakdown",
  CLAIM = "claim",
  DEVITT_ENHANCED = "enhanced",
  DEVITT_EVOLUTION = "evolution",
  HELMET_LEATHERS = "helmet",
  LEGAL_EXPENSES = "legal",
  PERSONAL_ACCIDENT = "accident",
}

type ModalIconProps = {
  type: ModalIconType | undefined;
};

function ModalIcon({ type }: ModalIconProps) {
  switch (type) {
    case ModalIconType.BIKE:
      return <BikeSVG />;
    case ModalIconType.BREAKDOWN_COVER:
      return <BreakdownSVG />;
    case ModalIconType.CLAIM:
      return <ClaimSVG />;
    case ModalIconType.DEVITT_ENHANCED:
      return <DevittEnhancedSVG />;
    case ModalIconType.DEVITT_EVOLUTION:
      return <DevittEvolutionSVG />;
    case ModalIconType.HELMET_LEATHERS:
      return <HelmetSVG />;
    case ModalIconType.LEGAL_EXPENSES:
      return <LegalSVG />;
    case ModalIconType.PERSONAL_ACCIDENT:
      return <AccidentSVG />;
    default:
      return null;
  }
}

export default ModalIcon;
