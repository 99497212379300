import { InputAutocomplete, InputMode, InputType, RegularExpressions, Sanitisers } from "../../constants";
import { getIsNegative, getMaxLength, handleBlur, handleFocus } from "../helpers";
import { handleInput, handleKeyDown } from "../../helpers";
import { Mask, MaskProps } from "../../types";

const mask = (props: MaskProps): Mask => {
  const isNegative = getIsNegative(props.min, props.max);
  return props.platform.isMobile
    ? {
        autoComplete: InputAutocomplete.OFF,
        inputMode: !isNegative ? InputMode.DECIMAL : undefined,
        maxLength: props.maxLength ? props.maxLength : getMaxLength(0, props.min, props.max),
        onBlur: handleBlur,
        onFocus: handleFocus,
        onInput: handleInput(event =>
          isNegative
            ? event.currentTarget.value
                .replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "")
                .replace(Sanitisers.LEADING_ZEROS, "$1")
                .replace(Sanitisers.LEADING_PERIOD, "0.")
                .replace(Sanitisers.LEADING_NEGATIVE_PERIOD, "-0.")
                .replace(Sanitisers.PERIOD, (char, index, string) => (string.indexOf(char) === index ? char : ""))
                .replace(Sanitisers.DECIMAL_PLACES(4), "$1")
            : event.currentTarget.value
                .replace(Sanitisers.DECIMAL, "")
                .replace(Sanitisers.LEADING_ZEROS, "$1")
                .replace(Sanitisers.LEADING_PERIOD, "0.")
                .replace(Sanitisers.PERIOD, (char, index, string) => (string.indexOf(char) === index ? char : ""))
                .replace(Sanitisers.DECIMAL_PLACES(4), "$1"),
        ),
        onKeyDown: handleKeyDown(isNegative ? RegularExpressions.DECIMAL_ALLOW_NEGATIVE : RegularExpressions.DECIMAL),
        type: InputType.NUMBER,
      }
    : {
        autoComplete: InputAutocomplete.OFF,
        inputMask: {
          alias: "numeric",
          allowMinus: isNegative,
          autoUnmask: true,
          clearIncomplete: true,
          digits: "4",
          max: props.max ? String(props.max) : undefined,
          min: props.min ? String(props.min) : undefined,
          placeholder: "",
          rightAlign: false,
          showMaskOnHover: false,
        },
        maxLength: !props.min && !props.max ? props.maxLength : undefined,
        type: InputType.TEXT,
      };
};

export default mask;
