import React from "react";

type ItemProps = {
  label: string;
  value: number | string;
};

const Item: React.FunctionComponent<ItemProps> = ({ label, value }) => {
  return (
    <tr>
      <td>{label}</td>
      <td>{value}</td>
    </tr>
  );
};

export default React.memo(Item);
