import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Personal Accident Cover",
  text: (
    <React.Fragment>
      <p>Payment if you are hospitalised as a result of an accident.</p>
      <ul>
        <li>Up to £20,000 cover</li>
        <li>£50 per night</li>
      </ul>
      <p>In the event of a serious accident guarantee that your finanical situation is protected.</p>
    </React.Fragment>
  ),
};

export default template;
