import React from "react";
import clsx from "clsx";

import { ReactComponent as TickListSVG } from "modules/theme/icons/product/tick-list.svg";
import Button from "components/Button";

import styles from "./styles.module.scss";

type IncludedProps = {
  disabled?: boolean;
  label: string;
  onShowModal?: (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  optional?: boolean;
};

const Included: React.FunctionComponent<IncludedProps> = ({ disabled, label, onShowModal, optional }) => {
  const className = clsx(styles["included"], disabled && styles["disabled"], optional && styles["optional"]);
  return (
    <li className={className}>
      <Button ariaLabel={label} label={label} onClick={onShowModal}>
        <TickListSVG />
      </Button>
    </li>
  );
};

export default Included;
