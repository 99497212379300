import React, { useMemo, useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { htmlSafe } from "modules/utils";
import Button from "components/Button";

import styles from "./styles.module.scss";

type PolicyTextProps = {
  children?: React.ReactNode;
  isOpen?: boolean;
  text?: string[];
  title: string;
};

const PolicyText: React.FunctionComponent<PolicyTextProps> = ({ children, isOpen = false, text, title }) => {
  const [open, setOpen] = useState(isOpen);
  const parsed = useMemo(() => {
    return text?.map((item: string, index: number) => <React.Fragment key={index}>{htmlSafe(item)}</React.Fragment>);
  }, [text]);
  const className = clsx(styles["policy-terms-conditions"], open && styles["open"]);
  return children || text?.length ? (
    <div className={className}>
      <header>
        <Button ariaLabel={title} onClick={() => setOpen(!open)}>
          <h2>{title}</h2>
          <ChevronDownSVG />
        </Button>
      </header>
      {open && (
        <section>
          {children}
          {!!parsed && parsed}
        </section>
      )}
    </div>
  ) : null;
};

export default React.memo(PolicyText);
