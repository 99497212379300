import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { api, API, getApiError } from "modules/api";
import { ModalType } from "modules/modals/constants";
import { Dispatch } from "modules/redux/store";
import { DocumentAction } from "./constants";
import { isError } from "modules/utils";
import {
  DocumentActionRequestStarted,
  DocumentActionRequestSuccess,
  DocumentActionRequestFailure,
  DocumentApiResponseResult,
  DocumentApiResponse,
  DocumentApiRequest,
} from "./types";

export const documentActionRequest = (modal: boolean, url: DocumentApiRequest) => {
  return async (dispatch: Dispatch): Promise<DocumentApiResponseResult> => {
    const id = modal ? dispatch(modalActionShow({ type: ModalType.LOADING })) : false;
    dispatch(documentActionRequestStarted());
    try {
      const response: DocumentApiResponse = await api(API.DOCUMENT_REQUEST(url));
      dispatch(documentActionRequestSuccess(response.data.url));
      id && dispatch(modalActionHide(id));
      return response.data.url;
    } catch (error) {
      dispatch(documentActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      id && dispatch(modalActionHide(id));
      throw new Error(getApiError(error));
    }
  };
};

const documentActionRequestStarted = (): DocumentActionRequestStarted => ({
  type: DocumentAction.REQUEST_STARTED,
});

const documentActionRequestSuccess = (data: DocumentApiResponseResult): DocumentActionRequestSuccess => ({
  type: DocumentAction.REQUEST_SUCCESS,
  data: data,
});

const documentActionRequestFailure = (error: string): DocumentActionRequestFailure => ({
  type: DocumentAction.REQUEST_FAILURE,
  error: error,
});
