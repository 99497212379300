import React from "react";

import PageWrapper from "components/PageWrapper";
import Message from "./components/Message";
import { TemplateProps } from "./types";

const Template: React.FunctionComponent<TemplateProps> = ({ route, token, webReference }) => {
  return (
    <PageWrapper verticallyAlign={true}>
      <Message route={route} token={token} webReference={webReference} />
    </PageWrapper>
  );
};

export default Template;
