import yup, { CreateError, Messages } from "modules/validation";
import { AdditionalRider, Rider } from "modules/risk/types";
import { getContext } from "./utils";
import moment from "moment";

const validateBikeLicenceDate16 = (
  bikeLicenceDate: moment.Moment,
  dateOfBirth: moment.Moment,
  format: "date" | "month",
): boolean | yup.ValidationError => {
  return dateOfBirth.isSameOrBefore(bikeLicenceDate.subtract(16, "years"), format === "date" ? "day" : "month");
};

const validateBikeLicenceDate17 = (
  bikeLicenceDate: moment.Moment,
  dateOfBirth: moment.Moment,
  format: "date" | "month",
): boolean | yup.ValidationError => {
  return dateOfBirth.isSameOrBefore(bikeLicenceDate.subtract(17, "years"), format === "date" ? "day" : "month");
};

const validateBikeLicenceDateYears = (
  bikeLicenceDate: moment.Moment,
  bikeLicenceYears: string,
  format: "date" | "month",
): boolean | yup.ValidationError => {
  return format === "date"
    ? bikeLicenceDate.isAfter(moment().subtract(1 + Number(bikeLicenceYears), "years"), "day") &&
        bikeLicenceDate.isSameOrBefore(moment().subtract(Number(bikeLicenceYears), "years"), "day")
    : bikeLicenceDate.isSameOrAfter(moment().subtract(1 + Number(bikeLicenceYears), "years"), "month") &&
        bikeLicenceDate.isSameOrBefore(moment().subtract(Number(bikeLicenceYears), "years"), "month");
};

const validateBikeLicenceDate = (
  createError: CreateError,
  bikeLicenceDate: moment.Moment,
  bikeLicenceType: string,
  bikeLicenceYears: string,
  dateOfBirth: moment.Moment,
  format: "date" | "month",
  proposer: boolean,
): boolean | yup.ValidationError => {
  return validateBikeLicenceDateYears(bikeLicenceDate, bikeLicenceYears, format)
    ? ["M", "P"].includes(bikeLicenceType)
      ? validateBikeLicenceDate16(bikeLicenceDate, dateOfBirth, format)
        ? true
        : createError({
            message: Messages.LICENCE_YEARS,
            params: proposer ? { you1: "You", age: "16", you2: "you" } : { you1: "They", age: "16", you2: "they" },
          })
      : validateBikeLicenceDate17(bikeLicenceDate, dateOfBirth, format)
      ? true
      : createError({
          message: Messages.LICENCE_YEARS,
          params: proposer ? { you1: "You", age: "17", you2: "you" } : { you1: "They", age: "17", you2: "they" },
        })
    : createError({
        message: Messages.LICENCE_DATE,
        params: {
          date1:
            format === "date"
              ? moment()
                  .add(1, "day")
                  .subtract(1 + Number(bikeLicenceYears), "years")
                  .format("DD/MM/YYYY")
              : moment()
                  .subtract(1 + Number(bikeLicenceYears), "years")
                  .format("MM/YYYY"),
          date2: moment()
            .subtract(Number(bikeLicenceYears), "years")
            .format(format === "date" ? "DD/MM/YYYY" : "MM/YYYY"),
        },
      });
};

function validator(this: yup.DateSchema, format: "date" | "month") {
  return this.test("bikeLicenceDate", Messages.DATE, function (value) {
    const bikeLicenceDate = value ? moment(value, format === "date" ? "YYYY-MM-DD" : "YYYY-MM", true) : false;
    const bikeLicenceType = getContext<Rider | AdditionalRider>(this)?.bikeLicence.type;
    const bikeLicenceYears = getContext<Rider | AdditionalRider>(this)?.bikeLicence.years;
    const dateOfBirth = getContext<Rider | AdditionalRider>(this)?.dateOfBirth
      ? moment(getContext<Rider | AdditionalRider>(this)?.dateOfBirth, "YYYY-MM-DD", true)
      : false;
    const proposer = this.path.startsWith("proposer");
    return bikeLicenceDate &&
      bikeLicenceDate.isValid() &&
      bikeLicenceYears &&
      bikeLicenceType &&
      dateOfBirth &&
      dateOfBirth.isValid()
      ? validateBikeLicenceDate(
          this.createError,
          bikeLicenceDate,
          bikeLicenceType,
          bikeLicenceYears,
          dateOfBirth,
          format,
          proposer,
        )
      : true;
  });
}

export default validator;
