import React, { useMemo } from "react";
import parse, { domToReact, DOMNode, Element, HTMLReactParserOptions } from "html-react-parser";
import DOMPurify from "dompurify";

const isElement = (domNode: DOMNode): domNode is Element =>
  ["tag", "script"].includes(domNode.type) && (domNode as Element).attribs !== undefined;

const getTitle = (endorsement: string): string | JSX.Element | JSX.Element[] => {
  const options: HTMLReactParserOptions = {
    replace: domNode => {
      if (isElement(domNode) && domNode.name === "div") {
        return !!domNode.children && <React.Fragment>{domToReact(domNode.children, options)}</React.Fragment>;
      }
      if (isElement(domNode) && domNode.name === "h4") {
        return !!domNode.children && <React.Fragment>{domToReact(domNode.children)}</React.Fragment>;
      }
      if (isElement(domNode) && domNode.name !== "h4") {
        return <React.Fragment />;
      }
    },
  };
  return parse(endorsement, options);
};

const getText = (endorsement: string): string | JSX.Element | JSX.Element[] => {
  const options: HTMLReactParserOptions = {
    replace: domNode => {
      if (isElement(domNode) && domNode.name === "div") {
        return !!domNode.children && <React.Fragment>{domToReact(domNode.children, options)}</React.Fragment>;
      }
      if (isElement(domNode) && domNode.name === "h4") {
        return <React.Fragment />;
      }
    },
  };
  return parse(endorsement, options);
};

export const useEndorsement = (endorsement: string) => {
  const sanitized = DOMPurify.sanitize(endorsement, { ADD_ATTR: ["target"] });
  const compulsory = sanitized.search("(compulsory)") > -1;
  const title = useMemo(() => getTitle(sanitized.replace("(compulsory)", "")), [sanitized]);
  const text = useMemo(() => getText(sanitized), [sanitized]);
  return { compulsory, text, title };
};
