import yearOfManufactureValidateApi from "modules/api/yearOfManufacture";
import yup, { CreateError, Messages } from "modules/validation";
import { ApiError } from "modules/api";
import remote from "../remote";
import moment from "moment";

const Errors = [ApiError.ABI_INVALID, ApiError.ABI_NOT_FOUND];

const [request, setActive] = remote(yearOfManufactureValidateApi, Errors, "yearOfManufacture");

type Values = {
  abiCode: string;
  yearOfManufacture: number | "";
};

const validate = (
  createError: CreateError,
  path: string,
  value: Values,
): boolean | yup.ValidationError | Promise<boolean | yup.ValidationError> =>
  !value.yearOfManufacture ? false : value.abiCode ? request(createError, path, value) : true;

function validator(this: yup.NumberSchema) {
  return this.test("yearOfManufacture", Messages.YEAR_OF_MANUFACTURE, function (value) {
    const values: Values = {
      yearOfManufacture: !!value && moment(value, "YYYY", true).isValid() ? value : "",
      abiCode: this.parent.abiCode || "",
    };
    setActive(this.path, values);
    return values.yearOfManufacture ? validate(this.createError, this.path, values) : true;
  });
}

export default validator;
