import { useCallback, useEffect, useRef } from "react";
import { VehicleApiResponseResult } from "modules/api/vehicle/types";
import { resetAbiCode } from "../../helpers";
import { validate } from "../helpers";
import { FormikProps } from "formik";

type UseHandleSubmitProps = (props: {
  errorCC?: string;
  errorMake?: string;
  errorModel?: string;
  errorYearOfManufacture?: string;
  name: string;
  setApiError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setResults: React.Dispatch<React.SetStateAction<VehicleApiResponseResult | undefined>>;
}) => () => void;

const useHandleSubmit: UseHandleSubmitProps = ({
  errorCC,
  errorMake,
  errorModel,
  errorYearOfManufacture,
  name,
  setApiError,
  setFieldTouched,
  setFieldValue,
  setLoading,
  setResults,
}) => {
  const handleSubmitRef = useRef<ReturnType<UseHandleSubmitProps>>(() => void 0);
  const handleSubmit = useCallback(() => handleSubmitRef.current(), []);

  useEffect(() => {
    handleSubmitRef.current = () => {
      resetAbiCode(name, setFieldTouched, setFieldValue);
      setApiError(undefined);
      setResults(undefined);
      validate(name, setFieldTouched, errorCC, errorMake, errorModel, errorYearOfManufacture) && setLoading(true);
    };
  }, [
    errorCC,
    errorMake,
    errorModel,
    errorYearOfManufacture,
    name,
    setApiError,
    setFieldTouched,
    setFieldValue,
    setLoading,
    setResults,
  ]);

  return handleSubmit;
};

export default useHandleSubmit;
