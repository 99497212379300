import { TextAreaFormat } from "../constants";
import { Mask, MaskProps } from "../types";

import text from "./text";
import vrm from "./vrm";

export const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  [TextAreaFormat.TEXT]: text,
  [TextAreaFormat.VRM]: vrm,
};

export default MASK;
