import React, { useContext } from "react";
import { useId } from "react-id-generator";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import Date, { DateProps } from "components/Inputs/Date";
import PlatformContext from "modules/platform/context";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";
import { QuestionDateFormat } from "./constants";

type QuestionDateProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> &
    Omit<DateProps, "format" | "id" | "invalid"> & {
      format?: QuestionDateFormat;
    }
>;

const QuestionDate: React.FunctionComponent<QuestionDateProps> = ({
  disabled,
  error,
  format = QuestionDateFormat.DATE,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const platform = useContext(PlatformContext);
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      size={!platform.isAndroid ? size : undefined}
      touched={touched}>
      <React.Fragment>
        <Date disabled={disabled} format={format} id={id} invalid={!!error && touched} {...props} />
        {process.env.NODE_ENV === "development" && <Debug name={props.name} value={props.value} />}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default React.memo(QuestionDate);
