import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "10% discount at SportsBikeShop",
  text: (
    <React.Fragment>
      <p>We'll send your 10% unique discount code for SportsBikeShop by email two days after your policy starts.</p>
      <p>
        <a href="https://www.sportsbikeshop.co.uk" rel="noopener noreferrer" target="_blank">
          www.sportsbikeshop.co.uk
        </a>
      </p>
    </React.Fragment>
  ),
};

export default template;
