import Messages from "modules/validation/messages";
import yup from "modules/validation";

const schema = yup.object({
  additionalQuestions: yup.object({
    documentDelivery: yup.string().required(),

    termsAgreedPolicy: yup.boolean().required(Messages.TERMS).oneOf([true], Messages.TERMS),
  }),

  proposer: yup.object({
    daytimeTelephone: yup.string().when("daytimeTelephoneUnknown", {
      is: true,
      then: schema => schema.required().telephone().maxLength(16, Messages.TELEPHONE),
    }),

    email: yup.string().required().emailCDL().maxLength(50),
  }),

  vehicle: yup.object({
    regNumber: yup.string().required().vrm(true),
  }),
});

export default schema;
