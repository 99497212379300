import yup from "modules/validation";
import Messages from "modules/validation/messages";

import schemaAddress from "./schemaAddress";
import schemaRider from "./schemaRider";

export const schemaProposerAggregator = yup.object({
  proposer: yup
    .object({
      motoringOrg: yup.string().required(Messages.LIST),

      daytimeTelephone: yup.string().when("daytimeTelephoneUnknown", {
        is: false,
        then: schema => schema.required().telephone().maxLength(16, Messages.TELEPHONE),
      }),

      address: schemaAddress,
    })
    .concat(schemaRider as yup.AnyObjectSchema),

  hasAdditionalRiders: yup.boolean().required(Messages.OPTION),

  additionalQuestions: yup.object({
    homeowner: yup.boolean().required(Messages.OPTION),

    normallyPayByDirectDebit: yup.boolean().required(Messages.OPTION),
  }),
});

export const schemaProposerDirect = yup.object({
  proposer: yup
    .object({
      motoringOrg: yup.string().required(Messages.LIST),

      email: yup.string().required().emailCDL().maxLength(50),

      daytimeTelephone: yup.string().required().telephone().maxLength(16, Messages.TELEPHONE),

      address: schemaAddress,
    })
    .concat(schemaRider as yup.AnyObjectSchema),

  hasAdditionalRiders: yup.boolean().required(Messages.OPTION),

  additionalQuestions: yup.object().when("isAggregator", {
    is: true,
    then: () =>
      yup.object({
        homeowner: yup.boolean().required(Messages.OPTION),

        normallyPayByDirectDebit: yup.boolean().required(Messages.OPTION),
      }),
    otherwise: () =>
      yup.object({
        homeowner: yup.boolean().required(Messages.OPTION),

        normallyPayByDirectDebit: yup.boolean().required(Messages.OPTION),

        whereHear: yup.string().required(Messages.LIST),
      }),
  }),
});
