import React from "react";
import clsx from "clsx";

import { ReactComponent as TickListSVG } from "modules/theme/icons/product/tick-list.svg";

import styles from "./styles.module.scss";

type ItemProps = {
  label: string;
  optional?: boolean;
};

const Item: React.FunctionComponent<ItemProps> = ({ label, optional }) => {
  const className = clsx(styles["included"], optional && styles["optional"]);
  return (
    <li className={className}>
      <div>
        <TickListSVG />
        <span>{label}</span>
      </div>
    </li>
  );
};

export default React.memo(Item);
