import { useLocation } from "react-router-dom";

import { schema } from "modules/debug/schema";
import { debug } from "modules/debug/state";
import { UseDebugRoute } from "./types";

import useHandleSubmit from "./useHandleSubmit";
import useRedirect from "./useRedirect";

const useDebugRoute: UseDebugRoute = () => {
  const handleSubmit = useHandleSubmit();
  const location = useLocation();
  const redirect = useRedirect();
  return { debug, handleSubmit, location, redirect, schema };
};

export default useDebugRoute;
