import React from "react";

import PageWrapper, { Control } from "components/PageWrapper";
import { optionsPaymentDate } from "modules/proceed/helpers";
import * as Question from "components/Questions";
import { FormProps, TemplateProps } from "./types";
import * as Form from "components/Form";
import * as Components from "./Payment";

const Template: React.FunctionComponent<TemplateProps> = ({ handleBack, handleSubmit, proceed, quote, schema }) => {
  return (
    <React.Fragment>
      <PageWrapper>
        <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={proceed}>
          {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
            <React.Fragment>
              <Form.Fieldset>
                <Components.PaymentTitle />

                <Components.PaymentSummary instalments={quote.instalments} />

                <Question.QuestionText
                  autoFocus={true}
                  error={errors.bankDetails?.bankName}
                  label="Name of Bank or Building Society:"
                  name="bankDetails.bankName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  touched={touched.bankDetails?.bankName}
                  value={values.bankDetails.bankName}
                />

                <Question.QuestionText
                  error={errors.bankDetails?.accountName}
                  format={Question.QuestionTextFormat.NAME}
                  label="Name of account holder(s):"
                  name="bankDetails.accountName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  touched={touched.bankDetails?.accountName}
                  value={values.bankDetails.accountName}
                />

                <Question.QuestionText
                  error={errors.bankDetails?.sortCode}
                  format={Question.QuestionTextFormat.SORTCODE}
                  label="Branch sort code:"
                  name="bankDetails.sortCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  size={Question.QuestionSize.MEDIUM}
                  touched={touched.bankDetails?.sortCode}
                  value={values.bankDetails.sortCode}
                />

                <Question.QuestionText
                  error={errors.bankDetails?.accountNumber}
                  format={Question.QuestionTextFormat.ACCOUNT}
                  label="Account number:"
                  name="bankDetails.accountNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={isSubmitting}
                  size={Question.QuestionSize.MEDIUM}
                  touched={touched.bankDetails?.accountNumber}
                  value={values.bankDetails.accountNumber}
                />

                <Question.QuestionSelect
                  error={errors.bankDetails?.preferredPaymentDay}
                  label="Preferred payment date:"
                  name="bankDetails.preferredPaymentDay"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={optionsPaymentDate}
                  placeholder={"DD"}
                  size={Question.QuestionSize.SMALL}
                  touched={touched.bankDetails?.preferredPaymentDay}
                  value={values.bankDetails.preferredPaymentDay}
                />

                <Components.PaymentLegal
                  error={errors.bankDetails?.termsAgreedPayment}
                  name="bankDetails.termsAgreedPayment"
                  readOnly={isSubmitting}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.bankDetails?.termsAgreedPayment}
                  value={values.bankDetails.termsAgreedPayment}
                />
              </Form.Fieldset>

              <Control isSubmitting={isSubmitting} onBack={handleBack} submit={true} />
            </React.Fragment>
          )}
        </Form.Wrapper>
      </PageWrapper>
    </React.Fragment>
  );
};

export default Template;
