import moment from "moment";

import { getDate, setDate } from "components/Inputs/Date";
import { QuestionDateFormat } from "components/Questions";

export const getDay = (month: string, year: string) => {
  const date = moment(`${year}-${month}`, "YYYY-MM", true);
  const today = moment().format("YYYY-MM");
  switch (true) {
    case date.isValid() && date.isSame(today, "month"):
      return moment().format("DD");
    case date.isValid():
      return "01";
    default:
      return "";
  }
};

export const valueToDate = (value: string) => {
  const { month, year } = getDate(value, QuestionDateFormat.MONTH);
  return setDate(getDay(month, year), month, year, QuestionDateFormat.DATE);
};

export const valueToMonth = (value: string) => {
  const { day, month, year } = getDate(value, QuestionDateFormat.DATE);
  return setDate(day, month, year, QuestionDateFormat.MONTH);
};
