import React, { useCallback, useMemo, useRef, useState } from "react";

import { QuestionSelect, QuestionSwitch } from "components/Questions";
import Messages from "modules/validation/messages";
import { QuestionTrackerProps } from "./types";

const QuestionTracker: React.FunctionComponent<QuestionTrackerProps> = ({
  autoFocus,
  error,
  onBlur,
  onChange,
  options,
  setFieldTouched,
  setFieldValue,
  touched,
  value,
  ...props
}) => {
  const [valueSwitch, setValueSwitch] = useState<boolean | "">(value ? (value === "NONE" ? false : true) : "");

  const open = useRef<boolean>(!!valueSwitch);

  const handleSetFieldTouched = useCallback(
    (field: string, isTouched?: boolean | undefined) => {
      !open.current && setFieldTouched(field, isTouched);
    },
    [setFieldTouched],
  );

  const handleSetFieldValue = useCallback(
    (field: string, value: boolean | "") => {
      setFieldValue(field, value === false ? "NONE" : "");
      setFieldTouched(field, !value);
      setValueSwitch(value);
      open.current = !!value;
    },
    [setFieldTouched, setFieldValue],
  );

  const filtered = useMemo(() => options?.filter(option => option.value !== "NONE"), [options]);

  return (
    <React.Fragment>
      <QuestionSwitch
        autoFocus={autoFocus}
        error={!open.current && error ? Messages.OPTION : undefined}
        help="<p>A tracking device is an electronic homing device which is fitted to your motorcycle.</p><p>If your motorcycle is stolen the device can be activated, allowing the police to track the motorcycle to aid recovery.</p>"
        label="Has the motorcycle been fitted with a tracking device?"
        setFieldTouched={handleSetFieldTouched}
        setFieldValue={handleSetFieldValue}
        touched={!open.current ? touched : undefined}
        value={valueSwitch}
        {...props}
      />
      {!!open.current && (
        <QuestionSelect
          error={error}
          label="Please select your tracking device"
          onBlur={onBlur}
          onChange={onChange}
          options={filtered}
          secondary={true}
          touched={touched}
          value={value}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(QuestionTracker);
