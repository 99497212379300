import React from "react";

import { Article } from "modules/articles/types";

import styles from "./styles.module.scss";

type ConfirmationCompetitionProps = {
  competition: Article;
};

const ConfirmationCompetition: React.FunctionComponent<ConfirmationCompetitionProps> = ({ competition }) => {
  return (
    <a
      className={styles["confirmation-competition"]}
      href={competition.uri}
      onMouseDown={event => event.preventDefault()}>
      <h3>{competition.title}</h3>
      <p>{competition.qbExcerpt ? competition.qbExcerpt : competition.excerpt}</p>
    </a>
  );
};

export default React.memo(ConfirmationCompetition);
