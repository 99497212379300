import React from "react";

import PageWrapper, { Control } from "components/PageWrapper";
import { MetadataType } from "modules/metadata/constants";
import { FormProps, TemplateProps } from "./types";
import * as Question from "components/Questions";
import Progress from "components/Progress";
import * as Form from "components/Form";

const Template: React.FunctionComponent<TemplateProps> = ({
  index,
  handleCancel,
  handleSubmit,
  metadata,
  schema,
  state,
  validate,
}) => {
  return (
    <PageWrapper>
      <Form.Wrapper<FormProps> onSubmit={handleSubmit} schema={schema} state={state} validate={validate}>
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldTouched, setFieldValue, touched, values }) => (
          <React.Fragment>
            <Progress
              hasAdditionalRiders={state.hasAdditionalRiders}
              hasAdditionalVehicles={state.hasAdditionalVehicles}
              readOnly={isSubmitting}
            />

            <Form.Fieldset title={`Additional Rider ${index + 1}`} autoFocus={true}>
              <Question.QuestionTitle
                errors={errors.currentAdditionalRider?.name}
                name="currentAdditionalRider"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsTitle={metadata[MetadataType.TITLE]}
                optionsTitleOther={metadata[MetadataType.TITLE_OTHER]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.name}
                values={values.currentAdditionalRider.name}
                valueGender={values.currentAdditionalRider.gender}
              />

              <Question.QuestionText
                error={errors.currentAdditionalRider?.name?.forenames}
                format={Question.QuestionTextFormat.NAME}
                label="First name(s)"
                maxLength={30}
                name="currentAdditionalRider.name.forenames"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.currentAdditionalRider?.name?.forenames}
                value={values.currentAdditionalRider.name.forenames}
              />

              <Question.QuestionText
                error={errors.currentAdditionalRider?.name?.surname}
                format={Question.QuestionTextFormat.NAME}
                label="Surname"
                maxLength={20}
                name="currentAdditionalRider.name.surname"
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={isSubmitting}
                touched={touched.currentAdditionalRider?.name?.surname}
                value={values.currentAdditionalRider.name.surname}
              />

              {values.currentAdditionalRider.name.title === "Other" &&
                ["Dr", "Prof"].includes(values.currentAdditionalRider.name.titleOther) && (
                  <Question.QuestionRadio
                    error={errors.currentAdditionalRider?.gender}
                    label="Gender"
                    name="currentAdditionalRider.gender"
                    options={metadata[MetadataType.GENDER]}
                    readOnly={isSubmitting}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched.currentAdditionalRider?.gender}
                    value={values.currentAdditionalRider.gender}
                  />
                )}

              <Question.QuestionDate
                error={errors.currentAdditionalRider?.dateOfBirth}
                format={Question.QuestionDateFormat.DATE}
                help="Please enter the date in the format DD/MM/YYYY, e.g. 21/01/1975"
                label="Date of birth"
                name="currentAdditionalRider.dateOfBirth"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.dateOfBirth}
                value={values.currentAdditionalRider.dateOfBirth}
              />

              <Question.QuestionSwitch
                error={errors.currentAdditionalRider?.residentSinceBirth}
                label="Has the rider been permanently resident in the UK since birth?"
                name="currentAdditionalRider.residentSinceBirth"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.residentSinceBirth}
                value={values.currentAdditionalRider.residentSinceBirth}
              />

              <Question.QuestionSecondary
                name="currentAdditionalRider.residentSince"
                open={values.currentAdditionalRider.residentSinceBirth === false}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.currentAdditionalRider.residentSince}>
                <Question.QuestionDate
                  error={errors.currentAdditionalRider?.residentSince}
                  format={Question.QuestionDateFormat.DATE}
                  label="Date the rider became a permanent UK resident"
                  name="currentAdditionalRider.residentSince"
                  readOnly={isSubmitting}
                  secondary={true}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched.currentAdditionalRider?.residentSince}
                  value={values.currentAdditionalRider.residentSince}
                />
              </Question.QuestionSecondary>

              <Question.QuestionRadio
                columns={true}
                error={errors.currentAdditionalRider?.maritalStatus}
                format={Question.QuestionRadioFormat.VERTICAL}
                label="Marital status"
                name="currentAdditionalRider.maritalStatus"
                options={metadata[MetadataType.MARITAL_STATUS]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.maritalStatus}
                value={values.currentAdditionalRider.maritalStatus}
              />

              <Question.QuestionSelect
                error={errors.currentAdditionalRider?.relationshipToProposer}
                label="What is this rider's relationship to you?"
                name="currentAdditionalRider.relationshipToProposer"
                onBlur={handleBlur}
                onChange={handleChange}
                options={metadata[MetadataType.RELATIONSHIP_TO_PROPOSER]}
                readOnly={isSubmitting}
                touched={touched.currentAdditionalRider?.relationshipToProposer}
                value={values.currentAdditionalRider.relationshipToProposer}
              />

              <Question.QuestionLicence
                errors={errors.currentAdditionalRider?.bikeLicence}
                name="currentAdditionalRider"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsType={metadata[MetadataType.LICENCE_TYPES]}
                optionsYears={metadata[MetadataType.LICENCE_HELD_YEARS]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.bikeLicence}
                values={values.currentAdditionalRider.bikeLicence}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Employment">
              <Question.QuestionsEmploymentFT
                errors={errors.currentAdditionalRider?.fullTimeOccupation}
                name="currentAdditionalRider.fullTimeOccupation"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsEmployerBusiness={metadata[MetadataType.EMPLOYER_BUSINESS]}
                optionsOccupation={metadata[MetadataType.OCCUPATION]}
                optionsOccupationStudent={metadata[MetadataType.OCCUPATION_STUDENT]}
                optionsOccupationTypes={metadata[MetadataType.OCCUPATION_TYPES]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.fullTimeOccupation}
                values={values.currentAdditionalRider.fullTimeOccupation}
              />

              <Question.QuestionSwitch
                error={errors.currentAdditionalRider?.hasPartTimeOccupation}
                label="Do they have any part-time employment?"
                name="currentAdditionalRider.hasPartTimeOccupation"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.hasPartTimeOccupation}
                value={values.currentAdditionalRider.hasPartTimeOccupation}
              />

              <Question.QuestionsEmploymentPT
                errors={errors.currentAdditionalRider?.partTimeOccupation}
                hasPartTimeOccupation={values.currentAdditionalRider.hasPartTimeOccupation}
                name="currentAdditionalRider.partTimeOccupation"
                onBlur={handleBlur}
                onChange={handleChange}
                optionsEmployerBusiness={metadata[MetadataType.EMPLOYER_BUSINESS]}
                optionsOccupation={metadata[MetadataType.OCCUPATION]}
                optionsOccupationTypesPartTime={metadata[MetadataType.OCCUPATION_TYPES_PART_TIME]}
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.partTimeOccupation}
                values={values.currentAdditionalRider.partTimeOccupation}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Motoring history">
              <Question.QuestionSwitch
                error={errors.currentAdditionalRider?.hasClaims}
                label="<span>Have they had any motor accidents, claims or losses in the past 5 years, no matter who was at fault or if a claim was made?</span>"
                name="currentAdditionalRider.hasClaims"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.hasClaims}
                value={values.currentAdditionalRider.hasClaims}
              />

              <Question.QuestionSecondary
                name="currentAdditionalRider.claims"
                open={values.currentAdditionalRider.hasClaims === true}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.currentAdditionalRider.claims}>
                <Question.QuestionClaims
                  errors={errors.currentAdditionalRider?.claims}
                  name="currentAdditionalRider.claims"
                  options={metadata[MetadataType.MOTOR_CLAIM]}
                  readOnly={isSubmitting}
                  touched={touched.currentAdditionalRider?.claims}
                  values={values.currentAdditionalRider.claims}
                />
              </Question.QuestionSecondary>

              <Question.QuestionSwitch
                error={errors.currentAdditionalRider?.hasConvictions}
                label="<span>Have they had any driving related convictions, endorsements, penalties, disqualifications or bans in the past 5 years?</span>"
                name="currentAdditionalRider.hasConvictions"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.hasConvictions}
                value={values.currentAdditionalRider.hasConvictions}
              />

              <Question.QuestionSecondary
                name="currentAdditionalRider.convictions"
                open={values.currentAdditionalRider.hasConvictions === true}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                value={values.currentAdditionalRider.convictions}>
                <Question.QuestionConvictions
                  errors={errors.currentAdditionalRider?.convictions}
                  name="currentAdditionalRider.convictions"
                  options={metadata[MetadataType.CONVICTION]}
                  readOnly={isSubmitting}
                  touched={touched.currentAdditionalRider?.convictions}
                  values={values.currentAdditionalRider.convictions}
                />
              </Question.QuestionSecondary>
            </Form.Fieldset>

            <Form.Fieldset title="Main rider">
              <Question.QuestionSwitch
                error={errors.currentAdditionalRider?.isMainRider}
                label="Is this rider the main user of the motorcycle/s being insured?"
                name="currentAdditionalRider.isMainRider"
                readOnly={isSubmitting}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.currentAdditionalRider?.isMainRider}
                value={values.currentAdditionalRider.isMainRider}
              />
            </Form.Fieldset>

            <Control backLabel="Cancel" isSubmitting={isSubmitting} onBack={handleCancel} submit={true} />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </PageWrapper>
  );
};

export default Template;
