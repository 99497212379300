import React from "react";

import { useSelector } from "modules/redux/store";
import { App } from "modules/app/types";

import styles from "./styles.module.scss";

type MessageWrapperProps = {
  children?: (props: { openingTimes: App["openingTimes"]; telephone: App["contact"]["telephone"] }) => React.ReactNode;
};

const MessageWrapper: React.FunctionComponent<MessageWrapperProps> = ({ children }) => {
  const app: App = useSelector(state => state.app);
  return (
    <div className={styles["message-wrapper"]}>
      {children && children({ openingTimes: app.openingTimes, telephone: app.contact.telephone })}
    </div>
  );
};

export default MessageWrapper;
