import React from "react";

import * as Question from "components/Questions";
import { SearchProps } from "./types";
import useSearch from "./hooks";
import Footer from "./Footer";

import styles from "./styles.module.scss";

const Search: React.FunctionComponent<SearchProps> = ({
  errorAbiCode,
  errorCC,
  errorMake,
  errorModel,
  errorYearOfManufacture,
  name,
  onBlur,
  onChange,
  options,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touchedAbiCode,
  touchedCC,
  touchedMake,
  touchedModel,
  touchedYearOfManufacture,
  valueAbiCode,
  valueCC,
  valueMake,
  valueModel,
  valueYearOfManufacture,
}) => {
  const [apiError, handleChange, handleSubmit, loading, vehicles] = useSearch({
    errorCC,
    errorMake,
    errorModel,
    errorYearOfManufacture,
    name,
    onChange,
    setFieldTouched,
    setFieldValue,
    valueAbiCode,
    valueCC,
    valueMake,
    valueModel,
    valueYearOfManufacture,
  });
  return (
    <div className={styles["vehicle-search-questions"]}>
      <Question.QuestionSelect
        autoFocus={true}
        disabled={loading}
        error={errorMake}
        grouped={true}
        label="Make"
        name={`${name}.make`}
        onBlur={onBlur}
        onChange={handleChange}
        options={options}
        readOnly={readOnly}
        touched={touchedMake}
        value={valueMake}
      />

      <Question.QuestionText
        disabled={loading}
        error={errorModel}
        grouped={true}
        help="Please enter the model of the motorcycle, e.g. CBR 1000 or YZF 1000 R."
        label="Model"
        maxLength={20}
        name={`${name}.model`}
        onBlur={onBlur}
        onChange={handleChange}
        readOnly={readOnly}
        touched={touchedModel}
        value={valueModel}
      />

      <Question.QuestionNumber
        disabled={loading}
        error={errorYearOfManufacture}
        grouped={true}
        help="Please enter the year of manufacture in full, e.g. 2009."
        label="Year of manufacture"
        maxLength={4}
        name={`${name}.yearOfManufacture`}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder="YYYY"
        readOnly={readOnly}
        size={Question.QuestionSize.SMALL}
        touched={touchedYearOfManufacture}
        value={valueYearOfManufacture}
      />

      <Question.QuestionNumber
        disabled={loading}
        error={errorCC}
        grouped={true}
        label="Engine size in CC<br/><small> e.g. 750cc</small>"
        maxLength={4}
        name={`${name}.cc`}
        onBlur={onBlur}
        onChange={handleChange}
        readOnly={readOnly}
        size={Question.QuestionSize.SMALL}
        touched={touchedCC}
        value={valueCC}
      />

      {vehicles && vehicles.length > 0 ? (
        <Question.QuestionSelect
          disabled={loading}
          error={errorAbiCode}
          grouped={true}
          label="Please select the correct model"
          name={`${name}.abiCode`}
          onBlur={onBlur}
          onChange={handleChange}
          options={vehicles}
          readOnly={readOnly}
          secondary={true}
          touched={touchedAbiCode}
          value={valueAbiCode}
        />
      ) : (
        <Footer
          apiError={apiError}
          disabled={loading || !!errorModel || !!errorMake || !!errorYearOfManufacture}
          error={errorAbiCode}
          handleSubmit={handleSubmit}
          loading={loading}
          readOnly={readOnly}
          touched={touchedAbiCode}
        />
      )}
    </div>
  );
};

export default React.memo(Search);
