import React from "react";
import { FormikErrors } from "formik";

import { useModalConfirm, ModalConfirmTemplates } from "modules/modals/hooks";
import { RiskMaxAdditional } from "modules/risk/constants";
import Button, { ButtonStyle } from "components/Button";
import { Risk, Bike } from "modules/risk/types";
import Item from "./Item";

import styles from "./styles.module.scss";

type QuestionVehiclesProps = {
  errors?: FormikErrors<Risk>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onNew: () => void;
  readOnly?: boolean;
  values: Risk;
};

const QuestionVehicles: React.FunctionComponent<QuestionVehiclesProps> = ({
  errors,
  onDelete,
  onEdit,
  onNew,
  readOnly,
  values,
}) => {
  const handleShowModalConfirm = useModalConfirm();
  const handleDelete = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
    id: string,
  ) =>
    handleShowModalConfirm({
      event,
      template: ModalConfirmTemplates.DELETE_BIKE,
      onConfirm: () => onDelete(id),
    });
  return (
    <div className={styles["question-vehicles"]}>
      <h2>Bikes</h2>
      <ul>
        <Item
          id={"question-vehicles-main-vehicle"}
          invalid={!!errors?.vehicle || !!errors?.hasAdditionalVehicles || !!errors?.policy}
          isMainVehicle={true}
          key={values.vehicle.abiCode || values.vehicle.id}
          onDelete={values.additionalVehicles.length > 0 ? handleDelete : undefined}
          onEdit={onEdit}
          readOnly={readOnly}
          vehicle={values.vehicle}
        />
        {values.additionalVehicles.length > 0 &&
          values.additionalVehicles.map((vehicle: Bike, index) => (
            <Item
              id={`question-vehicles-additional-vehicle-${index}`}
              invalid={
                Array.isArray(errors?.additionalVehicles) && typeof errors?.additionalVehicles[index] === "object"
              }
              isMainVehicle={false}
              key={vehicle.id}
              onDelete={handleDelete}
              onEdit={onEdit}
              readOnly={readOnly}
              vehicle={vehicle}
            />
          ))}
      </ul>
      <Button
        ariaLabel="Add an additional bike"
        disabled={values.additionalVehicles.length >= RiskMaxAdditional.VEHICLES}
        label="Add an additional bike"
        onClick={() => values.additionalVehicles.length < RiskMaxAdditional.VEHICLES && onNew()}
        readOnly={readOnly}
        style={ButtonStyle.PRIMARY}
      />
    </div>
  );
};

export default QuestionVehicles;
