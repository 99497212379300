import { ROUTE } from "modules/router/constants";
import { Quote } from "./types";

export const newQuote = (): Quote => {
  return {
    webReference: "",
    premium: 0,
    ipt: 0,
    iptRate: "",
    insurerCode: "",
    insurerName: "",
    schemeCode: "",
    schemeName: "",
    fee: 0,
    compulsoryExcess: 0,
    grantedVoluntaryExcess: 0,
    totalAnnualPrice: 0,
    algPremium: 0,
    discountAmount: undefined,
    instalments: {
      apr: 0,
      schemeName: "",
      deposit: 0,
      numberOfPayments: 0,
      amount: 0,
      flatInterestAmount: 0,
      interestPercentage: 0,
      totalPayable: 0,
    },
    precis: undefined,
    productIpid: "",
    endorsements: [],
    optionalExtras: {
      legal: undefined,
      personalAccident: undefined,
      helmetLeathers: undefined,
      accidentHelmetLeathers: undefined,
      breakdown1: undefined,
      breakdown2: undefined,
      breakdown3: undefined,
      breakdown4: undefined,
      enhanced: undefined,
      enhancedPlus: undefined,
      evolution: undefined,
      evolutionPlus: undefined,
    },
    includes: {},
  };
};

export const quoteRoute = (callback: (route: string | false) => void, quote: Quote, route?: string) => {
  switch (route) {
    case ROUTE.AGGREGATOR:
      callback(
        Object.values(quote.optionalExtras).filter(item => !!item?.selected).length ? ROUTE.PRODUCT : ROUTE.QUOTE,
      );
      break;

    case ROUTE.RECALL:
      callback(
        Object.values(quote.optionalExtras).filter(item => !!item?.selected).length ? ROUTE.PRODUCT : ROUTE.QUOTE,
      );
      break;

    case ROUTE.QUOTE:
      callback(false);
      break;

    case ROUTE.PRODUCT:
      callback(false);
      break;

    case ROUTE.RIDER:
    case ROUTE.RIDERS:
      callback(
        Object.values(quote.optionalExtras).filter(item => !!item?.selected).length ? ROUTE.PRODUCT : ROUTE.QUOTE,
      );
      break;

    default:
      callback(false);
  }
};
