import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { AbiApiResponseResult } from "modules/api/abi/types";
import { useAbiAction } from "modules/api/abi/hooks";
import { Metadata } from "modules/metadata/types";
import { ROUTE } from "modules/router/constants";
import { Quote } from "modules/quote/types";
import { Risk } from "modules/risk/types";
import Button from "components/Button";
import { formatRisk } from "./helpers";
import { IconType } from "./Icons";
import Section from "./Section";
import Item from "./Item";

import styles from "./styles.module.scss";

type PolicyYourDetailsProps = {
  isOpen?: boolean;
  metadata: Metadata;
  onEdit: (route: string) => void;
  quote: Quote;
  risk: Risk;
};

const PolicyYourDetails: React.FunctionComponent<PolicyYourDetailsProps> = ({
  isOpen,
  metadata,
  onEdit,
  quote,
  risk,
}) => {
  const [result, setResult] = useState<AbiApiResponseResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [open, setOpen] = useState(isOpen);
  const { abiRequest } = useAbiAction();

  const insurerName = quote.insurerName;
  const {
    name,
    dateOfBirth,
    fullTimeOccupation,
    maritalStatus,
    licence,
    immob,
    tracker,
    alarm,
    cover,
    inceptionDate,
    classOfUse,
    includesPillion,
    whereKept,
    previousInsuranceNCBYears,
    protectedBonus,
  } = formatRisk(metadata, risk);

  useEffect(() => {
    if (risk.vehicle.abiCode) {
      let active = true;
      setError(false);
      setLoading(true);
      setResult(undefined);
      (async function () {
        try {
          const response = await abiRequest(risk.vehicle.abiCode);
          active && setResult(response);
        } catch {
          active && setError(true);
        } finally {
          active && setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [abiRequest, risk.vehicle.abiCode]);
  const classNames = clsx(styles["policy-your-details"], open && styles["open"]);
  return (
    <div className={classNames}>
      <header>
        <Button ariaLabel="Your Details" onClick={() => setOpen(!open)}>
          <h2>Your Details</h2>
          <ChevronDownSVG />
        </Button>
      </header>
      {open && (
        <section>
          <Section icon={IconType.RIDER} onClick={() => onEdit(ROUTE.RIDER)} title="Your info">
            <Item label="Name:" value={name} />
            <Item label="Age:" value={dateOfBirth} />
            <Item label="Occupation:" value={fullTimeOccupation} />
            <Item label="Marital Status:" value={maritalStatus} />
            <Item label="License:" value={licence} />
          </Section>

          <Section icon={IconType.MOTORCYCLE} onClick={() => onEdit(ROUTE.MOTORCYCLE)} title="Your motorcycle">
            <Item
              label="Bike Description:"
              value={
                loading
                  ? ""
                  : !error && result && result.manufacturer && result.model
                  ? `${result.manufacturer} ${result.model}${
                      risk.vehicle.yearOfManufacture && ` ${risk.vehicle.yearOfManufacture}`
                    }`
                  : "An error has occurred."
              }
            />
          </Section>

          <Section icon={IconType.SECURITY} onClick={() => onEdit(ROUTE.MOTORCYCLE)} title="Your security">
            <Item label="Alarm/Immobiliser:" value={immob} />
            <Item label="Tracking Device:" value={tracker} />
            <Item label="Data tag:" value={alarm} />
          </Section>

          <Section icon={IconType.COVER} onClick={() => onEdit(ROUTE.MOTORCYCLE)} title="Your cover">
            <Item label="Cover requested:" value={cover} />
            <Item label="Start date:" value={inceptionDate} />
            <Item label="Motorcycle usage:" value={classOfUse} />
            <Item label="Pillion use:" value={includesPillion} />
          </Section>

          <Section icon={IconType.STORAGE} onClick={() => onEdit(ROUTE.MOTORCYCLE)} title="Your storage">
            <Item label="Your motorcycle is kept overnight in:" value={whereKept} />
          </Section>

          <Section icon={IconType.POLICY} onClick={() => onEdit(ROUTE.MOTORCYCLE)} title="Your policy">
            <Item label="Years with NCD:" value={previousInsuranceNCBYears} />
            {["4", "5", "6", "7", "8", "9"].includes(previousInsuranceNCBYears) && (
              <Item label="Procted NCD:" value={protectedBonus} />
            )}
            {!!quote.includes.euCover && <Item label="EU Cover:" value={quote.includes.euCover} />}
            {quote.includes.ridingOtherBikes !== undefined && (
              <Item label="Riding other bikes:" value={quote.includes.ridingOtherBikes ? "Yes" : "No"} />
            )}
            <Item label="Your insurer:" value={insurerName} />
          </Section>
        </section>
      )}
    </div>
  );
};

export default PolicyYourDetails;
