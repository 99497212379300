import { ThunkMiddleware } from "redux-thunk";

import { trustpilotActionRequest } from "./actions";
import { AppAction } from "modules/app/constants";
import { AppActionType } from "modules/app/types";
import { RootState } from "modules/redux/store";
import { TrustpilotActionType } from "./types";

const createTrustpilotMiddleware: () => ThunkMiddleware<RootState> =
  () =>
  ({ dispatch }) =>
  next =>
  async (action: AppActionType | TrustpilotActionType) => {
    switch (action.type) {
      case AppAction.INIT:
        dispatch(trustpilotActionRequest());
        break;
    }
    return next(action);
  };

export default createTrustpilotMiddleware;
