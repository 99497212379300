import { ThunkMiddleware } from "redux-thunk";

import { metadataActionInit, metadataActionRequest } from "./actions";
import { RouterActionType } from "modules/router/types";
import { AppActionType } from "modules/app/types";
import { AppAction } from "modules/app/constants";
import { RootState } from "modules/redux/store";
import { MetadataType } from "./constants";

const createMetadataMiddleware: () => ThunkMiddleware<RootState> =
  () =>
  ({ dispatch, getState }) =>
  next =>
  async (action: AppActionType | RouterActionType) => {
    switch (action.type) {
      case AppAction.INIT: {
        dispatch(metadataActionInit());
        const { metadata }: RootState = getState();
        Object.keys(metadata).forEach(endpoint => {
          !metadata[endpoint].length && dispatch(metadataActionRequest(endpoint as MetadataType));
        });
        break;
      }
    }
    return next(action);
  };

export default createMetadataMiddleware;
