import React from "react";
import { RootState } from "modules/redux/store";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { ReactComponent as TrustpilotLogo } from "./trustpilot-logo.svg";
import { ReactComponent as TrustpilotStars } from "./trustpilot-stars.svg";

import styles from "./styles.module.scss";

const HeaderTrustpilot: React.FunctionComponent = () => {
  const state = useSelector((state: RootState) => state.trustpilot);
  let stars = state.stars;
  stars < 1 && (stars = 1);
  stars > 5 && (stars = 5);
  const className = clsx(styles["header-trustpilot"], state.stars === 0 && styles["loading"]);
  const starsClassName = `header-trustpilot-stars-${String(stars).replace(/\..+/, "-5")}`;
  return (
    <a
      className={className}
      href="https://uk.trustpilot.com/review/devittinsurance.com"
      onMouseDown={event => event.preventDefault()}
      rel="noopener noreferrer"
      target="_blank">
      <div>
        <div>{state.adjective}</div>
        <div>
          <TrustpilotStars aria-label={`${stars} stars`} className={styles[starsClassName]} />
        </div>
      </div>
      <div>
        <div>
          <span>{state.reviews.toLocaleString()}</span> reviews on
        </div>
        <TrustpilotLogo />
      </div>
    </a>
  );
};

export default React.memo(HeaderTrustpilot);
