import React, { useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import { ReactComponent as BreakdownSVG } from "modules/theme/icons/product/addons/breakdown.svg";
import { ReactComponent as TickListSVG } from "modules/theme/icons/product/tick-list.svg";
import { ReactComponent as PlusSVG } from "modules/theme/icons/buttons/plus.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";
import Button, { ButtonStyle } from "components/Button";
import { PaymentMethod } from "modules/risk/constants";
import { formatPrice } from "modules/utils";

import styles from "./styles.module.scss";

type ProductBreakdownTemplateProps = {
  children: React.ReactNode;
  included: boolean;
  onClick?: () => void;
  open: boolean;
  paymentMethod?: PaymentMethod;
  price?: number;
  selected?: boolean;
  subtitle: string;
  title: string;
};

const ProductBreakdownTemplate: React.FunctionComponent<ProductBreakdownTemplateProps> = ({
  children,
  included,
  onClick,
  paymentMethod,
  price,
  selected,
  subtitle,
  title,
  ...props
}) => {
  const [open, setOpen] = useState(props.open);
  const className = clsx(styles["product-breakdown"], included && styles["included"], open && styles["open"]);
  return (
    <div className={className}>
      <header>
        <div>
          <BreakdownSVG />
          <h2>{title}</h2>
          {included ? (
            <div>
              <div>
                <span>Included</span> <TickListSVG />
              </div>
            </div>
          ) : paymentMethod === PaymentMethod.MONTHLY ? (
            <div>
              <span>from</span> {formatPrice(Math.round((price || 0) / 12), true)} p/m
            </div>
          ) : (
            <div>
              <span>from</span> {formatPrice(price || 0, true)}
            </div>
          )}
        </div>
        <div>
          <p>{subtitle}</p>
          <Button ariaLabel="Toggle" onClick={() => setOpen(!open)}>
            <ChevronDownSVG />
          </Button>
        </div>
      </header>
      {!!open && <div>{children}</div>}
      {!included && !!onClick && (
        <footer>
          <Button
            ariaLabel={selected ? "Selected" : "Add to cart"}
            style={selected ? ButtonStyle.SUCCESS : ButtonStyle.PRIMARY}
            label={selected ? "Selected" : "Add to cart"}
            onClick={onClick}>
            {selected ? <TickSVG /> : <PlusSVG />}
          </Button>
        </footer>
      )}
    </div>
  );
};

export default React.memo(ProductBreakdownTemplate);
