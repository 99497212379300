import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "90 days full policy cover in EU",
  text: (
    <React.Fragment>
      <p>With Devitt Insurance you get 90 days EU cover as standard with your policy.</p>
      <p>
        It's a good thing to let your insurer know about your planned trip before you set off to make sure everything is
        in place.
      </p>
    </React.Fragment>
  ),
};

export default template;
