import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { adminActionToggleNavigation } from "./actions";
import { Dispatch } from "modules/redux/store";

export const useAdminAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    hideNavigation: useCallback(() => {
      dispatch(adminActionToggleNavigation(true));
    }, [dispatch]),
    toggleNavigation: useCallback(() => {
      dispatch(adminActionToggleNavigation());
    }, [dispatch]),
  };
};
