import React from "react";
import { useId } from "react-id-generator";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import { TextAreaProps } from "components/Inputs/TextArea/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";
import TextArea from "components/Inputs/TextArea";

type QuestionTextAreaProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> & Omit<TextAreaProps, "format" | "id" | "invalid">
>;

const QuestionTextArea: React.FunctionComponent<QuestionTextAreaProps> = ({
  disabled,
  error,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      size={size}
      touched={touched}>
      <React.Fragment>
        <TextArea disabled={disabled} id={id} invalid={!!error && touched} {...props} />
        {process.env.NODE_ENV === "development" && <Debug name={props.name} value={props.value} />}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default React.memo(QuestionTextArea);
