import { useCallback } from "react";

import { quoteActionRemoveOptionalExtra } from "modules/quote/actions";
import { OptionalExtra } from "modules/quote/types";
import { UseHandleRemove } from "./types";

const useHandleRemove: UseHandleRemove = dispatch =>
  useCallback((option: OptionalExtra) => dispatch(quoteActionRemoveOptionalExtra(option)), [dispatch]);

export default useHandleRemove;
