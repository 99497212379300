import { InputAutocomplete, InputMode, InputType, RegularExpressions, Sanitisers } from "../../constants";
import { getInputPattern, handleInput, handleKeyDown } from "../../helpers";
import { handleBlur, handleFocus } from "../../Number/helpers";
import { Mask, MaskProps } from "../../types";

const mask = (props: MaskProps): Mask =>
  props.platform.isMobile
    ? {
        autoComplete: InputAutocomplete.OFF,
        inputMode: InputMode.NUMERIC,
        maxLength: 10,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onInput: handleInput(event =>
          event.currentTarget.value.replace(Sanitisers.INTEGER, "").replace(Sanitisers.LEADING_WHITESPACE, ""),
        ),
        onKeyDown: handleKeyDown(RegularExpressions.INTEGER),
        pattern: getInputPattern(props.platform),
        placeholder: props.placeholder,
        type: InputType.NUMBER,
      }
    : {
        autoComplete: InputAutocomplete.OFF,
        maxLength: 10,
        onInput: handleInput(event =>
          event.currentTarget.value.replace(Sanitisers.INTEGER, "").replace(Sanitisers.LEADING_WHITESPACE, ""),
        ),
        onKeyDown: handleKeyDown(RegularExpressions.INTEGER),
        placeholder: props.placeholder,
        type: InputType.TEXT,
      };

export default mask;
