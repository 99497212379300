import { InputAutocomplete, InputMode, InputType, RegularExpressions, Sanitisers } from "../../constants";
import { getInputPattern, handleInput, handleKeyDown, maskCharacter, setNativeInput } from "../../helpers";
import { Mask, MaskProps } from "../../types";

const mask = (props: MaskProps): Mask => {
  const handleMount = (input: React.MutableRefObject<HTMLInputElement | null>) => {
    const value = input.current?.value;
    const sanitised =
      value &&
      maskCharacter(value.replace(Sanitisers.INTEGER, "").replace(Sanitisers.LEADING_WHITESPACE, ""), "-", [3, 6]);
    sanitised && sanitised !== value && setNativeInput(input, sanitised);
  };
  return props.platform.isMobile
    ? {
        autoComplete: InputAutocomplete.OFF,
        inputMode: InputMode.NUMERIC,
        maxLength: 8,
        onInput: handleInput(event =>
          maskCharacter(
            event.currentTarget.value.replace(Sanitisers.INTEGER, "").replace(Sanitisers.LEADING_WHITESPACE, ""),
            "-",
            [3, 6],
          ),
        ),
        onKeyDown: handleKeyDown(RegularExpressions.INTEGER),
        onMount: handleMount,
        pattern: getInputPattern(props.platform),
        placeholder: props.placeholder ? props.placeholder : "##-##-##",
        type: InputType.TEXT,
      }
    : {
        autoComplete: InputAutocomplete.OFF,
        inputMask: {
          groupSeparator: "-",
          mask: "99-99-99",
          placeholder: "##-##-##",
          showMaskOnHover: false,
        },
        maxLength: 8,
        onMount: handleMount,
        placeholder: props.placeholder ? props.placeholder : "##-##-##",
        type: InputType.TEXT,
      };
};

export default mask;
