import React from "react";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";
import OpeningTimes from "../components/OpeningTimes";
import CallUs from "../components/CallUs";

const MessageTechnical: React.FunctionComponent = () => {
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Technical Problems</h2>
            <h3>We are sorry but we are currently experiencing technical problems.</h3>
          </header>

          <p>
            Please call us on <strong>{telephone.sales}</strong> and we will be able to complete your quote over the
            phone.
          </p>

          <CallUs telephone={telephone.sales} text="Need help? Our advisors are here for you." />

          <OpeningTimes openingTimes={openingTimes} />

          <Button
            ariaLabel="Contact us"
            href="https://www.devittinsurance.com/contact-us/"
            label="Contact us"
            style={ButtonStyle.SUCCESS}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />

          <Button
            ariaLabel="Request a callback"
            href="https://www.devittinsurance.com/contact-us/request-callback/"
            label="Request a callback"
            style={ButtonStyle.PRIMARY}
            target={ButtonTarget.SELF}
            type={ButtonType.LINK}
          />
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(MessageTechnical);
