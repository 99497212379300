import { useSelector } from "modules/redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import schema from "modules/schema/routes/motorcycle";
import { useAffiliates } from "modules/affiliates";
import { Metadata } from "modules/metadata/types";
import { Dispatch } from "modules/redux/store";
import { UseMotorcycleRoute } from "./types";
import { Risk } from "modules/risk/types";
import useQuery from "../useQuery";

import useHandleCancel from "./useHandleCancel";
import useHandleSubmit from "./useHandleSubmit";
import useValidate from "../useValidate";
import useRedirect from "./useRedirect";

const useMotorcycleRoute: UseMotorcycleRoute = () => {
  const metadata: Metadata = useSelector(state => state.metadata);
  const risk: Risk = useSelector(state => state.risk);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  useAffiliates();

  const handleCancel = useHandleCancel({ navigate, query });

  const handleSubmit = useHandleSubmit({ dispatch, navigate });

  const redirect = useRedirect();

  const validate = useValidate(query);

  return {
    handleCancel,
    handleSubmit,
    metadata,
    redirect,
    risk,
    schema,
    validate,
  };
};

export default useMotorcycleRoute;
