import { StatusCodes } from "http-status-codes";
//import sleep from "sleep-promise";
import retry from "async-retry";
import axios from "axios";

import { ApiConfig, DefaultApiResponse } from "./types";
import { isAdmin } from "modules/admin";
import { isAxiosError } from "./utils";
import logger from "modules/logger";

export { getApiError, isAxiosError } from "./utils";
export { ApiError } from "./errors";
export { API } from "./constants";

const RETRIES = isAdmin ? 2 : 5;

const errorCodes = [
  StatusCodes.BAD_REQUEST,
  StatusCodes.UNAUTHORIZED,
  StatusCodes.UNPROCESSABLE_ENTITY,
  StatusCodes.NOT_FOUND,
];

export const api = async (config: ApiConfig): Promise<DefaultApiResponse> => {
  //process.env.NODE_ENV === "development" && (await sleep(5000));
  const response = await retry(
    async bail => {
      try {
        return await axios({ ...config });
      } catch (error) {
        if (isAxiosError(error) && !!error.response?.status && errorCodes.includes(error.response.status)) {
          logger.error("API BAIL");
          return bail(error);
        }
        logger.warn("API RETRY");
        throw error;
      }
    },
    {
      retries: RETRIES,
    },
  );
  return response as DefaultApiResponse;
};
