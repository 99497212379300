import React from "react";

import { OptionalExtra } from "modules/quote/types";
import Item from "../Item";

type OptionProps = {
  label: string;
  option: OptionalExtra;
};

const Option: React.FunctionComponent<OptionProps> = ({ label, option }) => {
  return !!option.ipid || !!option.booklet ? (
    <React.Fragment>
      {!!option.booklet && <Item label={`${label}<br/><small>Policy Wording</small>`} link={option.booklet} />}
      {!!option.ipid && <Item label={`${label}<br/><small>Policy Information</small>`} link={option.ipid} />}
    </React.Fragment>
  ) : null;
};

export default React.memo(Option);
