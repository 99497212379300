import { AnalyticsItemTierEvolutionPlus } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemTierEvolutionPlus: AnalyticsItemTierEvolutionPlus = price => ({
  item_id: "DEVITT_EVOLUTION_PLUS",
  item_name: "Devitt Evolution Plus Cover",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemTierEvolutionPlus;
