import { isEqual } from "lodash";

import {
  Cache,
  CacheActive,
  CacheFailure,
  CacheIsEqual,
  CacheResponse,
  CacheResult,
  CacheSearch,
  CacheSuccess,
} from "./types";
import { errorMessage } from "modules/api/errors";

export const cache: Cache = {};

const cacheActive: CacheActive = (createError, name, path) => {
  const cached = cacheSearch(name, cache[name].active[path]);
  return cached
    ? typeof cached.valid === "string"
      ? createError({ message: errorMessage(cached.valid) })
      : cached.valid
    : true;
};

export const cacheFailure: CacheFailure = (createError, error, errors, name, path, value) => {
  error && errors.includes(error) && cacheResult(name, error, value);
  return cacheResponse(createError, name, path, error, value);
};

const cacheIsEqual: CacheIsEqual = (valueA, valueB) =>
  typeof valueA === "object" ? isEqual(valueA, valueB) : valueA === valueB;

const cacheResponse: CacheResponse = (createError, name, path, result, value) => {
  return cacheIsEqual(cache[name].active[path], value)
    ? typeof result === "string"
      ? createError({ message: errorMessage(result) })
      : result
    : cacheActive(createError, name, path);
};

export const cacheResult: CacheResult = (name, valid, value) =>
  !cacheSearch(name, value) &&
  cache[name].results.push({
    value: value,
    valid: valid,
  });

export const cacheSearch: CacheSearch = (name, value) =>
  cache[name].results.find(item => cacheIsEqual(item.value, value));

export const cacheSuccess: CacheSuccess = (createError, name, path, valid, value) => {
  cacheResult(name, valid, value);
  return cacheResponse(createError, name, path, valid, value);
};
