import React from "react";
import clsx from "clsx";

import { ReactComponent as ConvictionSVG } from "modules/theme/icons/form/claims-convictions/conviction.svg";
import { ReactComponent as DeleteSVG } from "modules/theme/icons/buttons/delete.svg";
import { ReactComponent as EditSVG } from "modules/theme/icons/buttons/edit.svg";
import { ReactComponent as ErrorIcon } from "modules/theme/icons/questions/error.svg";
import Button, { ButtonStyle } from "components/Button";
import { useScrollRef } from "modules/scroll/hooks";
import { Messages } from "modules/validation";

import styles from "./styles.module.scss";

type ItemProps = {
  id: string;
  invalid?: boolean;
  onDelete: (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  onEdit: (
    event:
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  readOnly?: boolean;
  title: string;
};

const Item: React.FunctionComponent<ItemProps> = ({ id, invalid, onDelete, onEdit, readOnly, title }) => {
  const scrollRef = useScrollRef(id);
  const className = clsx(styles["conviction"], invalid && styles["invalid"]);
  return (
    <li className={className} ref={invalid ? scrollRef : undefined}>
      <div>
        <ConvictionSVG />
        <div>
          <h2>{title}</h2>
          {!!invalid && (
            <div aria-live="polite">
              <ErrorIcon />
              <p>{Messages.CONVICTION_REQUIRED}</p>
            </div>
          )}
        </div>
      </div>
      <div>
        <div>
          <Button
            allowFocus={true}
            ariaLabel="Edit"
            label="Edit"
            onClick={onEdit}
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}>
            <EditSVG />
          </Button>
          <Button
            allowFocus={true}
            ariaLabel="Delete"
            label="Delete"
            onClick={onDelete}
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}>
            <DeleteSVG />
          </Button>
        </div>
        <div>
          <div>
            <Button
              allowFocus={true}
              ariaLabel="Edit"
              label="Edit"
              onClick={onEdit}
              readOnly={readOnly}
              style={ButtonStyle.PRIMARY_CLEAR}>
              <EditSVG />
            </Button>
          </div>
          <span></span>
          <div>
            <Button
              allowFocus={true}
              ariaLabel="Delete"
              label="Delete"
              onClick={onDelete}
              readOnly={readOnly}
              style={ButtonStyle.PRIMARY_CLEAR}>
              <DeleteSVG />
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default React.memo(Item);
