import React from "react";
import clsx from "clsx";
import { shallowEqual, useSelector } from "modules/redux/store";

import { ReactComponent as LogoSVG } from "modules/theme/logo.svg";
import Partners, { PartnerIconType } from "./Partners";
import { useAdminAction } from "modules/admin/hooks";
import { isAdmin } from "modules/admin";
import Button from "components/Button";

import styles from "./styles.module.scss";

type HeaderLogoProps = {
  loading: boolean;
};

const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = ({ loading }) => {
  const { subAgent } = useSelector(state => state.risk.policy, shallowEqual);
  const { toggleNavigation } = useAdminAction();

  const classNames = clsx(
    styles["header-logo"],
    Object.values(PartnerIconType).includes(subAgent as PartnerIconType) && styles["partner-logo"],
  );

  return (
    <div className={classNames}>
      {Object.values(PartnerIconType).includes(subAgent as PartnerIconType) ? (
        <div className={styles["header-logo-partner"]}>
          <div>
            <p>Arranged by</p>
            {isAdmin ? (
              <Button ariaLabel="Devitt" onClick={() => toggleNavigation()}>
                <LogoSVG />
              </Button>
            ) : (
              <LogoSVG />
            )}
          </div>
          <div>
            <p>In association with</p>
            <Partners subAgent={subAgent as PartnerIconType} />
          </div>
        </div>
      ) : (
        <div className={styles["header-logo-default"]}>
          {isAdmin ? (
            <Button ariaLabel="Devitt" onClick={() => toggleNavigation()}>
              <LogoSVG />
            </Button>
          ) : (
            <LogoSVG />
          )}
          {!loading && <p>Trusted since 1936</p>}
        </div>
      )}
    </div>
  );
};

export default React.memo(HeaderLogo);
