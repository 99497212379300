import { useMemo } from "react";

import { ModalInfoTemplates } from "modules/modals/hooks";
import { OptionalExtra } from "modules/quote/types";

type UseProductOverviewBreakdown = (props: {
  option1?: OptionalExtra;
  option2?: OptionalExtra;
  option3?: OptionalExtra;
  option4?: OptionalExtra;
  selectedBreakdown1: boolean;
  selectedBreakdown2: boolean;
  selectedBreakdown3: boolean;
  selectedBreakdown4: boolean;
}) => {
  option?: OptionalExtra;
  label: string;
  modal: ModalInfoTemplates;
};

const useProductOverviewBreakdown: UseProductOverviewBreakdown = ({
  option1,
  option2,
  option3,
  option4,
  selectedBreakdown1,
  selectedBreakdown2,
  selectedBreakdown3,
  selectedBreakdown4,
}) => {
  const option = useMemo(() => {
    switch (true) {
      case !!option4 && selectedBreakdown4:
        return option4;
      case !!option3 && selectedBreakdown3:
        return option3;
      case !!option2 && selectedBreakdown2:
        return option2;
      case !!option1 && selectedBreakdown1:
        return option1;
    }
  }, [
    option1,
    option2,
    option3,
    option4,
    selectedBreakdown1,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4,
  ]);

  const label = useMemo(() => {
    switch (true) {
      case option === option4:
        return "RAC UK & EU Roadside, Recovery, At Home & Onward Travel Breakdown Cover";
      case option === option3:
        return "RAC UK Roadside, Recovery & At Home Breakdown Cover";
      case option === option2:
        return "RAC UK Roadside & Recovery Breakdown Cover";
      case option === option1:
        return "RAC UK Roadside Breakdown Cover";
      default:
        return "RAC Breakdown Cover";
    }
  }, [option, option1, option2, option3, option4]);

  const modal = useMemo(() => {
    switch (true) {
      case option === option4:
        return ModalInfoTemplates.BREAKDOWN_ONWARD_TRAVEL;
      case option === option3:
        return ModalInfoTemplates.BREAKDOWN_AT_HOME;
      case option === option2:
        return ModalInfoTemplates.BREAKDOWN_RECOVERY;
      case option === option1:
        return ModalInfoTemplates.BREAKDOWN_ROADSIDE;
      default:
        return ModalInfoTemplates.BREAKDOWN;
    }
  }, [option, option1, option2, option3, option4]);

  return { label, modal, option: option || option1 };
};

export default useProductOverviewBreakdown;
