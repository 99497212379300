import React, { useState } from "react";
import clsx from "clsx";

import Button, { ButtonStyle } from "components/Button";
import { getDevittLogo } from "modules/utils";
import { UseCart } from "modules/cart";
import Item from "./Item";

import styles from "./styles.module.scss";

type PolicyOverviewProps = {
  cart: ReturnType<UseCart>;
  cover: string;
};

const PolicyOverview: React.FunctionComponent<PolicyOverviewProps> = ({
  cart: {
    selectedAccidentHelmetLeathers,
    selectedBreakdown1OrIncluded,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4OrIncluded,
    selectedEnhanced,
    selectedEnhancedPlus,
    selectedEvolution,
    selectedEvolutionPlus,
    selectedHelmetLeathersOrIncluded,
    selectedLegalOrIncluded,
    selectedPersonalAccidentOrIncluded,
  },
  cover,
}) => {
  const [open, setOpen] = useState(false);

  const className = clsx(styles["policy-overview"], !open && styles["closed"]);
  return (
    <div className={className}>
      <header>
        {getDevittLogo({ selectedEnhanced, selectedEnhancedPlus, selectedEvolution, selectedEvolutionPlus })}
        <Button
          ariaLabel="What's Included"
          label="What's Included"
          style={ButtonStyle.SECONDARY}
          onClick={() => setOpen(!open)}
        />
      </header>
      <div>
        <ul>
          <Item label={cover ? `${cover} Cover` : ""} />

          <Item label="90 days full policy cover in EU" />

          <Item label="10% discount at SportsBikeShop" />

          <Item label="24/7 claim line" />

          {selectedLegalOrIncluded && <Item label="Legal Expenses & Loss Recovery" optional={true} />}

          {selectedBreakdown1OrIncluded && <Item label="RAC UK Roadside Breakdown Cover" optional={true} />}

          {selectedBreakdown2 && <Item label="RAC UK Roadside & Recovery Breakdown Cover" optional={true} />}

          {selectedBreakdown3 && <Item label="RAC UK Roadside, Recovery & At Home Breakdown Cover" optional={true} />}

          {selectedBreakdown4OrIncluded && !selectedEvolution && !selectedEvolutionPlus && (
            <Item label="RAC UK & EU Roadside, Recovery, At Home & Onward Travel Breakdown Cover" optional={true} />
          )}

          {selectedHelmetLeathersOrIncluded && <Item label="Helmet & Leathers" optional={true} />}

          {selectedPersonalAccidentOrIncluded && <Item label="Personal Accident" optional={true} />}

          {selectedAccidentHelmetLeathers && <Item label="Personal Accident + Helmet & Leathers" optional={true} />}

          {selectedBreakdown4OrIncluded && (selectedEvolution || selectedEvolutionPlus) && (
            <Item label="RAC UK & EU Roadside, Recovery, At Home & Onward Travel Breakdown Cover" optional={true} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(PolicyOverview);
