import React from "react";

import { ModalConfirmTemplateProps } from "components/Modals/ModalConfirm";
import { ModalIconType } from "components/Modals/Icons";

const template: ModalConfirmTemplateProps = {
  cancelLabel: "No thanks",
  confirmLabel: "Add to cart",
  title: "Are you sure you don't want to add Personal Accident?",
  icon: ModalIconType.PERSONAL_ACCIDENT,
  text: (
    <React.Fragment>
      <h3>In the event of a serious accident guarantee that your finanical situation is protected</h3>
      <p>Payment if you are hospitalised as a result of an accident</p>
      <ul>
        <li>Up to £20,000 cover</li>
        <li>£50 per night</li>
      </ul>
    </React.Fragment>
  ),
};

export default template;
