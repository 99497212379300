import { useCallback } from "react";

import { riskActionResetAdditionalRiders } from "modules/risk/actions";
import { ModalConfirmTemplates } from "modules/modals/hooks";
import { quoteActionRequest } from "modules/quote/actions";
import { ModalType } from "modules/modals/constants";
import { UseHandleSubmit } from "./types";

const useHandleSubmit: UseHandleSubmit = ({ dispatch, handleShowModalConfirm, risk }) =>
  useCallback(() => {
    if (risk.hasAdditionalRiders && !risk.additionalRiders.length) {
      handleShowModalConfirm({
        template: ModalConfirmTemplates.ADDITIONAL_RIDERS,
        onConfirm: () => {
          dispatch(riskActionResetAdditionalRiders());
          dispatch(quoteActionRequest({ type: ModalType.LOADING_QUOTE }));
        },
      });
    } else {
      dispatch(quoteActionRequest({ type: ModalType.LOADING_QUOTE }));
    }
  }, [dispatch, handleShowModalConfirm, risk.hasAdditionalRiders, risk.additionalRiders.length]);

export default useHandleSubmit;
