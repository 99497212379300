import React from "react";

import { ReactComponent as ArrowSVG } from "modules/theme/icons/product/arrow.svg";
import Button from "components/Button";

import styles from "./styles.module.scss";

type QuoteWrapperProps = {
  children?: React.ReactNode;
  onBack: () => void;
  webReference: string;
};

const QuoteWrapper: React.FunctionComponent<QuoteWrapperProps> = ({ children, onBack, webReference }) => {
  return (
    <div className={styles["quote-wrapper"]}>
      <header>
        <Button ariaLabel="Go back" label="Go back" onClick={onBack}>
          <ArrowSVG />
        </Button>
        <p>
          Your web reference: <span>{webReference}</span>
        </p>
      </header>
      <div>{children}</div>
    </div>
  );
};

export default React.memo(QuoteWrapper);
