import React from "react";
import clsx from "clsx";

import { PaymentMethod } from "modules/risk/constants";
import { formatPrice } from "modules/utils";
import { Quote } from "modules/quote/types";
import { UseCart } from "modules/cart";
import Defaqto from "./Defaqto";

import styles from "./styles.module.scss";

type ProductPolicyInfoProps = {
  cart: ReturnType<UseCart>;
  classOfUse: string;
  cover: string;
  coverLabel: string;
  paymentMethod: PaymentMethod;
  quote: Quote;
};

const ProductPolicyInfo: React.FunctionComponent<ProductPolicyInfoProps> = ({
  cart: {
    selectedBreakdown1,
    selectedBreakdown2,
    selectedBreakdown3,
    selectedBreakdown4,
    selectedEnhanced,
    selectedEnhancedPlus,
    selectedEvolution,
    selectedEvolutionPlus,
    selectedHelmetLeathers,
    selectedLegal,
    selectedPersonalAccident,
    selectedAccidentHelmetLeathers,
  },
  classOfUse,
  cover,
  coverLabel,
  paymentMethod,
  quote: {
    compulsoryExcess,
    grantedVoluntaryExcess,
    includes,
    instalments,
    insurerName,
    optionalExtras: options,
    planSaving,
    premium,
    totalAnnualPrice,
  },
}) => {
  const className = clsx(styles["product-policy-info"], planSaving && styles["plan-saving"]);
  return (
    <div className={className}>
      <header>
        <h2>Your Policy Info</h2>
        <p>
          Here is our most competitive quote based on the details you have provided. All premiums are inclusive of
          Insurance Premium Tax, where applicable. This quote will remain valid for 30 days and is based on 12 months
          cover from the insurance start date.
        </p>
        <Defaqto />
      </header>
      <div>
        <table>
          <tbody>
            {!!includes.euCover && (
              <tr>
                <td>Free EU travel:</td>
                <td>{includes.euCover}</td>
              </tr>
            )}
            {typeof includes.ridingOtherBikes === "boolean" && (
              <tr>
                <td>Riding other bikes:</td>
                <td>{includes.ridingOtherBikes ? "Yes" : "No"}</td>
              </tr>
            )}
            {["C", "TPFT"].includes(cover) ? (
              <React.Fragment>
                <tr>
                  <td>Compulsory Accidental Excess:</td>
                  <td>{formatPrice(compulsoryExcess)}</td>
                </tr>
                <tr>
                  <td>Voluntary Accidental Excess</td>
                  <td>{formatPrice(grantedVoluntaryExcess)}</td>
                </tr>
              </React.Fragment>
            ) : (
              <tr>
                <td>Policy Excess:</td>
                <td>{formatPrice(compulsoryExcess)}</td>
              </tr>
            )}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Insurer:</td>
              <td>{insurerName}</td>
            </tr>
            <tr>
              <td>Level of cover:</td>
              <td>{coverLabel}</td>
            </tr>
            <tr>
              <td>Type of use:</td>
              <td>{classOfUse}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <footer>
        <div>
          <table>
            <tbody>
              <tr>
                <td>Annual Premium</td>
                <td>{formatPrice(premium, true)}</td>
              </tr>

              {!!options.enhanced && selectedEnhanced && (
                <tr>
                  <td>Devitt Enhanced</td>
                  <td>{formatPrice(options.enhanced.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.enhancedPlus && selectedEnhancedPlus && (
                <tr>
                  <td>Devitt Enhanced</td>
                  <td>{formatPrice(options.enhancedPlus.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.evolution && selectedEvolution && (
                <tr>
                  <td>Devitt Evolution</td>
                  <td>{formatPrice(options.evolution.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.evolutionPlus && selectedEvolutionPlus && (
                <tr>
                  <td>Devitt Evolution</td>
                  <td>{formatPrice(options.evolutionPlus.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.legal && selectedLegal && (
                <tr>
                  <td>Legal Expenses & Loss Recovery </td>
                  <td>{formatPrice(options.legal.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.breakdown1 && selectedBreakdown1 && (
                <tr>
                  <td>RAC Breakdown Cover</td>
                  <td>{formatPrice(options.breakdown1.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.breakdown2 && selectedBreakdown2 && (
                <tr>
                  <td>RAC Breakdown Cover</td>
                  <td>{formatPrice(options.breakdown2.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.breakdown3 && selectedBreakdown3 && (
                <tr>
                  <td>RAC Breakdown Cover</td>
                  <td>{formatPrice(options.breakdown3.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.breakdown4 && selectedBreakdown4 && (
                <tr>
                  <td>RAC Breakdown Cover</td>
                  <td>{formatPrice(options.breakdown4.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.helmetLeathers && selectedHelmetLeathers && (
                <tr>
                  <td>Helmet & Leathers</td>
                  <td>{formatPrice(options.helmetLeathers.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.personalAccident && selectedPersonalAccident && (
                <tr>
                  <td>Personal Accident</td>
                  <td>{formatPrice(options.personalAccident.grossPremium, true)}</td>
                </tr>
              )}

              {!!options.accidentHelmetLeathers && selectedAccidentHelmetLeathers && (
                <tr>
                  <td>Personal Accident + Helmet & Leathers</td>
                  <td>{formatPrice(options.accidentHelmetLeathers.grossPremium, true)}</td>
                </tr>
              )}
            </tbody>
          </table>
          {paymentMethod === PaymentMethod.MONTHLY && !!instalments && (
            <table>
              <tbody>
                <tr>
                  <td>Deposit (payable today)</td>
                  <td>{formatPrice(instalments.deposit, true)}</td>
                </tr>
                <tr>
                  <td>Finance charge</td>
                  <td>{formatPrice(instalments.flatInterestAmount, true)}</td>
                </tr>
                <tr>
                  <td>Total amount payable</td>
                  <td>{formatPrice(instalments.totalPayable, true)}</td>
                </tr>
                <tr>
                  <td>APR representative</td>
                  <td>{instalments.apr}%</td>
                </tr>
                <tr>
                  <td>Interest rate</td>
                  <td>{instalments.interestPercentage}%</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div>
          <div>
            {!!planSaving && (
              <React.Fragment>
                <div>Plan saving</div>
                <div>{formatPrice(planSaving, true)}</div>
              </React.Fragment>
            )}
          </div>
          <div>
            <div>Total price</div>
            {paymentMethod === PaymentMethod.MONTHLY && !!instalments ? (
              <div>
                {formatPrice(instalments.amount, true)}
                <span>per month*</span>
              </div>
            ) : (
              <div>
                {formatPrice(totalAnnualPrice, true)}
                <span>per year</span>
              </div>
            )}
          </div>
        </div>
        {!!instalments && (
          <small>
            *Monthly payment: {instalments.numberOfPayments} instalments of {formatPrice(instalments.amount)} with
            initial payment of {formatPrice(instalments.deposit)}. An interest rate of {instalments.interestPercentage}%
            is applicable and the total amount to pay is {formatPrice(instalments.totalPayable)}, which includes APR{" "}
            {instalments.apr}% fixed (more expensive than paying annually).
          </small>
        )}
      </footer>
    </div>
  );
};

export default React.memo(ProductPolicyInfo);
