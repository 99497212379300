import { api, API, getApiError } from "modules/api";
import { cacheResult, cacheSearch } from "./cache";
import { Dispatch } from "modules/redux/store";
import { VrmSearchAction } from "./constants";
import { isError } from "modules/utils";
import {
  VrmSearchActionRequestStarted,
  VrmSearchActionRequestSuccess,
  VrmSearchActionRequestFailure,
  VrmSearchApiResponseResult,
  VrmSearchApiResponse,
  VrmApiRequest,
} from "./types";

export const vrmSearchActionRequest = (vrm: VrmApiRequest) => {
  return async (dispatch: Dispatch): Promise<VrmSearchApiResponseResult> => {
    !!vrm && (vrm = vrm.replace(/\s+/g, ""));
    const cached = cacheSearch(vrm);
    if (cached) {
      dispatch(vrmSearchActionRequestSuccess(cached.result));
      return cached.result;
    } else {
      dispatch(vrmSearchActionRequestStarted());
      try {
        const response: VrmSearchApiResponse = await api(API.VRM_SEARCH_REQUEST(vrm));
        dispatch(vrmSearchActionRequestSuccess(response.data.vehicle));
        cacheResult(vrm, response.data.vehicle);
        return response.data.vehicle;
      } catch (error) {
        dispatch(vrmSearchActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
        throw new Error(getApiError(error));
      }
    }
  };
};

const vrmSearchActionRequestStarted = (): VrmSearchActionRequestStarted => ({
  type: VrmSearchAction.REQUEST_STARTED,
});

const vrmSearchActionRequestSuccess = (data: VrmSearchApiResponseResult): VrmSearchActionRequestSuccess => ({
  type: VrmSearchAction.REQUEST_SUCCESS,
  data: data,
});

const vrmSearchActionRequestFailure = (error: string): VrmSearchActionRequestFailure => ({
  type: VrmSearchAction.REQUEST_FAILURE,
  error: error,
});
