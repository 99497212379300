import { maskVrm, MaskVrmInputType } from "components/Inputs/Input/String/helpers";
import { RegularExpressions } from "components/Inputs/Input/constants";
import { setNativeInput } from "components/Inputs/Input/helpers";
import { handleInput, handleKeyDown } from "../helpers";
import { Mask, MaskProps } from "../types";

type CustomEvent = { inputType?: "deleteContentBackward" };

const mask = (props: MaskProps): Mask => ({
  maxLength: 8,
  onInput: handleInput(event => {
    const value = event.currentTarget.value;
    const sanitised = value && maskVrm(value, MaskVrmInputType.TEXTAREA);
    return (event.nativeEvent as CustomEvent).inputType === "deleteContentBackward" &&
      value === sanitised.replace(/\s+/g, "")
      ? value
      : sanitised;
  }),
  onKeyDown: handleKeyDown(RegularExpressions.ALPHANUMERIC_ALLOW_SPACE),
  onMount: input => {
    const value = input.current?.value;
    const sanitised = value && maskVrm(value, MaskVrmInputType.TEXTAREA);
    sanitised && sanitised !== value && setNativeInput(input, sanitised);
  },
  placeholder: props.placeholder || "ENTER\nREG",
});

export default mask;
