import yup, { Messages } from "modules/validation";

const regex = new RegExp("^(?!(?:0{6}|00-00-00))(?:[0-9]{6}|[0-9][0-9]-[0-9][0-9]-[0-9][0-9])$");

export const validateSortCode = (value: string): boolean => {
  return regex.test(value) ? true : false;
};

function validator(this: yup.StringSchema) {
  return this.test("sortcode", Messages.SORT_CODE, function (value) {
    return value ? validateSortCode(value) : true;
  });
}

export default validator;
