import { InputDateFormat } from "../../constants";
import { Mask, MaskProps } from "../../types";

import date from "./date";
import month from "./month";

export const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  [InputDateFormat.DATE]: date,
  [InputDateFormat.MONTH]: month,
};

export default MASK;
