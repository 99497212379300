import React from "react";
import { useId } from "react-id-generator";

import FieldGroup, { FieldGroupProps } from "components/Inputs/FieldGroup";
import { QuestionTemplateProps } from "../QuestionTemplate/types";
import Debug from "components/Questions/QuestionTemplate/Debug";
import { Expand } from "modules/typescript/helpers";
import QuestionTemplate from "../QuestionTemplate";

export type QuestionFieldGroupProps = Expand<
  Omit<QuestionTemplateProps, "children" | "id"> & Omit<FieldGroupProps, "id" | "invalid">
>;

const QuestionFieldGroup: React.FunctionComponent<QuestionFieldGroupProps> = ({
  disabled,
  error,
  grouped,
  help,
  info,
  label,
  secondary,
  size,
  touched,
  ...props
}) => {
  const [id] = useId(1, "question-");
  return (
    <QuestionTemplate
      disabled={disabled}
      error={error}
      grouped={grouped}
      help={help}
      id={id}
      info={info}
      label={label}
      secondary={secondary}
      size={size}
      touched={touched}>
      <React.Fragment>
        <FieldGroup disabled={disabled} id={id} invalid={!!error && touched} {...props} />
        {process.env.NODE_ENV === "development" &&
          props.options.map((option, index) => (
            <Debug key={index} name={option.name} value={option.selected ? true : ""} />
          ))}
      </React.Fragment>
    </QuestionTemplate>
  );
};

export default QuestionFieldGroup;
