import { api, API, getApiError } from "modules/api";
import { cacheResult, cacheSearch } from "./cache";
import { AddressSearchAction } from "./constants";
import { Dispatch } from "modules/redux/store";
import { isError } from "modules/utils";
import {
  AddressSearchActionRequestStarted,
  AddressSearchActionRequestSuccess,
  AddressSearchActionRequestFailure,
  AddressSearchApiResponseResult,
  AddressSearchApiResponse,
  AddressSearchApiRequest,
} from "./types";

export const addressSearchActionRequest = (address: AddressSearchApiRequest) => {
  return async (dispatch: Dispatch): Promise<AddressSearchApiResponseResult> => {
    const cached = cacheSearch(address);
    if (cached) {
      dispatch(addressSearchActionRequestSuccess(cached.result));
      return cached.result;
    } else {
      dispatch(addressSearchActionRequestStarted());
      try {
        const response: AddressSearchApiResponse = await api(API.ADDRESS_SEARCH_REQUEST(address));
        dispatch(addressSearchActionRequestSuccess(response.data.address));
        cacheResult(address, response.data.address);
        return response.data.address;
      } catch (error) {
        dispatch(addressSearchActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
        throw new Error(getApiError(error));
      }
    }
  };
};

const addressSearchActionRequestStarted = (): AddressSearchActionRequestStarted => ({
  type: AddressSearchAction.REQUEST_STARTED,
});

const addressSearchActionRequestSuccess = (
  data: AddressSearchApiResponseResult,
): AddressSearchActionRequestSuccess => ({
  type: AddressSearchAction.REQUEST_SUCCESS,
  data: data,
});

const addressSearchActionRequestFailure = (error: string): AddressSearchActionRequestFailure => ({
  type: AddressSearchAction.REQUEST_FAILURE,
  error: error,
});
