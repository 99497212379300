export enum MetadataAction {
  INIT = "METADATA_INIT",
  REQUEST_STARTED = "METADATA_REQUEST_STARTED",
  REQUEST_SUCCESS = "METADATA_REQUEST_SUCCESS",
  REQUEST_FAILURE = "METADATA_REQUEST_FAILURE",
}

export enum MetadataType {
  ALARM = "alarm",
  //ALLRISKS = "allrisks",
  CLASS_OF_USE = "class-of-use",
  //CONTENTS = "contents",
  CONVICTION = "conviction",
  //COUNTRY = "country",
  COVER_TYPE = "cover-type",
  DOCUMENT_DELIVERY = "document-delivery",
  DRINK_READING_TYPE = "drink-reading-type",
  EMPLOYER_BUSINESS = "employer-business",
  GENDER = "gender",
  IMMOBILISER = "immobiliser",
  //IMPORTED = "imported",
  INSURANCE_START_DATE = "insurance-start-date",
  LICENCE_HELD_YEARS = "licence-held-years",
  LICENCE_TYPES = "licence-types",
  MAKE = "make",
  MARITAL_STATUS = "marital-status",
  //MEDICAL_CONDITION = "medical-condition",
  MOTOR_CLAIM = "motor-claim",
  MOTOR_ORG = "motor-org",
  NCD_YEARS = "ncd-years",
  OCCUPATION = "occupation",
  OCCUPATION_STUDENT = "occupation-student",
  OCCUPATION_TYPES = "occupation-types",
  OCCUPATION_TYPES_PART_TIME = "occupation-types-part-time",
  PREVIOUS_INSURANCE = "previous-insurance",
  RELATIONSHIP_TO_PROPOSER = "relationship-to-proposer",
  //RESTRICT = "restrict",
  TITLE = "title",
  TITLE_OTHER = "title-other",
  TRACKER = "tracker",
  VOL_XS = "vol-xs",
  WHERE_HEAR = "where-hear",
  WHERE_KEPT = "where-kept",
}
