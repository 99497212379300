import { useCallback } from "react";

import { quoteActionAddOptionalExtra } from "modules/quote/actions";
import { scrollTop } from "modules/scroll/helpers";
import { UseHandleDevittEvolution } from "./types";

const useHandleDevittEvolution: UseHandleDevittEvolution = ({ dispatch, optionEvolution }) =>
  useCallback(async () => {
    await scrollTop();
    !!optionEvolution && !optionEvolution.selected && dispatch(quoteActionAddOptionalExtra(optionEvolution.option));
  }, [dispatch, optionEvolution]);

export default useHandleDevittEvolution;
