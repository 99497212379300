import React from "react";

import { ReactComponent as MotorcycleSVG } from "modules/theme/icons/product/your-details/motorcycle.svg";
import { ReactComponent as SecuritySVG } from "modules/theme/icons/product/your-details/security.svg";
import { ReactComponent as StorageSVG } from "modules/theme/icons/product/your-details/garage.svg";
import { ReactComponent as PolicySVG } from "modules/theme/icons/product/your-details/policy.svg";
import { ReactComponent as CoverSVG } from "modules/theme/icons/product/your-details/cover.svg";
import { ReactComponent as RiderSVG } from "modules/theme/icons/product/your-riders/rider.svg";

export enum IconType {
  COVER = "cover",
  MOTORCYCLE = "motorcycle",
  POLICY = "policy",
  RIDER = "rider",
  SECURITY = "security",
  STORAGE = "storage",
}

type IconProps = {
  icon: IconType;
};

function Icon({ icon }: IconProps) {
  switch (icon) {
    case IconType.COVER:
      return <CoverSVG />;
    case IconType.MOTORCYCLE:
      return <MotorcycleSVG />;
    case IconType.POLICY:
      return <PolicySVG />;
    case IconType.RIDER:
      return <RiderSVG />;
    case IconType.SECURITY:
      return <SecuritySVG />;
    case IconType.STORAGE:
      return <StorageSVG />;
    default:
      return null;
  }
}

export default React.memo(Icon);
