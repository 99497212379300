import React from "react";

import * as Question from "components/Questions";
import { QuestionLicenceProps } from "./types";
import useLicence from "./hooks";

const QuestionLicence: React.FunctionComponent<QuestionLicenceProps> = ({
  errors,
  name,
  onBlur,
  onChange,
  optionsType,
  optionsYears,
  readOnly,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  useLicence({
    date: values.date,
    name: name,
    setFieldValue: setFieldValue,
    years: values.years,
  });
  const isProposer = name === "proposer";
  return (
    <React.Fragment>
      <Question.QuestionRadio
        error={errors?.type}
        columns={true}
        format={Question.QuestionRadioFormat.VERTICAL}
        help={
          isProposer
            ? "<p>If you've passed your UK CBT only please select Provisional UK.</p><p>If you will be riding on your Full UK Car Licence which you got before the 1st February 2001, please choose Provisional UK.</p><p>Only select Full UK if you hold a Full UK Motorcycle Licence including A1, A2 and A Licences.</p>"
            : "<p>If they've passed their UK CBT only please select Provisional UK.</p><p>If they will be riding on their Full UK Car Licence which they got before the 1st February 2001, please choose Provisional UK.</p><p>Only select Full UK if they hold a Full UK Motorcycle Licence including A1, A2 and A Licences.</p>"
        }
        label={
          isProposer
            ? "What type of motorcycle licence do you hold for this motorcycle?"
            : "Type of motorcycle licence held"
        }
        name={`${name}.bikeLicence.type`}
        options={optionsType}
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched?.type}
        value={values.type}
      />

      <Question.QuestionSelect
        error={errors?.years}
        help={
          isProposer
            ? "<p>Please select the number of full / complete years you have on your licence, you cannot round this number up.</p><p>For example, if you have 1 year and 10 months, you have to select 1 year.</p>"
            : "<p>Please select the number of full / complete years they have on their licence, you cannot round this number up.</p><p>For example, if they have 1 year and 10 months, you have to select 1 year.</p>"
        }
        label={
          isProposer
            ? "How many years have you held this motorcycle licence?"
            : "How many years have they held this motorcycle licence?"
        }
        name={`${name}.bikeLicence.years`}
        onBlur={onBlur}
        onChange={onChange}
        options={optionsYears}
        readOnly={readOnly}
        touched={touched?.years}
        value={values.years}
      />

      <Question.QuestionSecondary
        name={`${name}.bikeLicence.date`}
        open={["0", "1", "2", "3"].includes(values.years)}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.date}>
        {values.years === "0" ? (
          <React.Fragment>
            <Question.QuestionDate
              error={errors?.date}
              format={Question.QuestionDateFormat.DATE}
              label="Enter the date the licence was obtained"
              name={`${name}.bikeLicence.date`}
              readOnly={readOnly}
              secondary={true}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched?.date}
              value={values.date}
            />
          </React.Fragment>
        ) : (
          <Question.QuestionDate
            error={errors?.date}
            format={Question.QuestionDateFormat.MONTH}
            label="Enter the date the licence was obtained"
            name={`${name}.bikeLicence.date`}
            readOnly={readOnly}
            secondary={true}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched?.date}
            value={values.date}
          />
        )}
      </Question.QuestionSecondary>
    </React.Fragment>
  );
};

export default QuestionLicence;
