import React from "react";

import { ReactComponent as AdobeReaderSVG } from "modules/theme/icons/product/adobe.svg";
import { ReactComponent as PdfSVG } from "modules/theme/icons/form/buttons/pdf.svg";
import useDocument from "./hooks";

import styles from "./styles.module.scss";

type ItemProps = {
  label: string;
  link: string;
  type: "link" | "adobe";
  webReference?: string;
};

const Item: React.FunctionComponent<ItemProps> = ({ label, link, type, webReference }) => {
  const [handleClick, href] = useDocument(link, webReference);
  return (
    <li className={styles["item"]}>
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
        onMouseDown={event => event.preventDefault()}>
        {type === "adobe" ? (
          <span className={styles["adobe"]}>
            <AdobeReaderSVG />
          </span>
        ) : (
          <PdfSVG />
        )}
        <span>{label}</span>
      </a>
    </li>
  );
};

export default React.memo(Item);
