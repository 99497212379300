import { useCallback } from "react";

import { Debug } from "modules/debug/types";
import { UseHandleSubmit } from "./types";
import logger from "modules/logger";

const useHandleSubmit: UseHandleSubmit = () =>
  useCallback((values: Debug) => {
    logger.debug("Submit");
    logger.debug(values);
  }, []);

export default useHandleSubmit;
