import yup, { Messages } from "modules/validation";

const regex = new RegExp(
  "^([a-zA-Z0-9_\\-\\.!#$'\\*\\/=\\?\\^`{\\|}~\\+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$",
  "i",
);

export const validateEmail = (value: string): boolean => {
  return regex.test(value) ? true : false;
};

function validator(this: yup.StringSchema) {
  return this.test("emailCDL", Messages.EMAIL, function (value) {
    return value ? validateEmail(value) : true;
  });
}

export default validator;
