import React from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Helmet & Leathers Cover",
  text: (
    <React.Fragment>
      <p>Cover for your helmet and protective clothing.</p>
      <ul>
        <li>Up to £1,500 cover</li>
        <li>Protect your helmet and leathers in the event of an accident</li>
      </ul>
      <p>
        Give yourself peace of mind by ensuring your gear is covered and avoid that expensive repurchase in the case of
        an accident.
      </p>
    </React.Fragment>
  ),
};

export default template;
