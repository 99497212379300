import { PaymentMethod } from "modules/risk/constants";
import { Confirmation } from "./types";

export const newConfirmation = (): Confirmation => {
  return {
    date: "",
    insurer: "",
    policyRef: "",
    premiumAmount: "",
    time: "",
    webReference: "",
    whereHear: "",

    paymentMethod: PaymentMethod.ANNUAL,
    premiumBasic: 0,
    totalAnnualPrice: 0,
  };
};
