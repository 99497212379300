import React from "react";

import { ReactComponent as SelectAllSVG } from "modules/theme/icons/form/buttons/select-all.svg";
import { ReactComponent as AdobeReaderSVG } from "modules/theme/icons/product/adobe.svg";
import { ReactComponent as PhoneSVG } from "modules/theme/icons/form/buttons/phone.svg";
import { ReactComponent as EmailSVG } from "modules/theme/icons/form/buttons/email.svg";
import { ReactComponent as PostSVG } from "modules/theme/icons/form/buttons/post.svg";
import { ReactComponent as SmsSVG } from "modules/theme/icons/form/buttons/chat.svg";
import { ReactComponent as PdfSVG } from "modules/theme/icons/form/buttons/pdf.svg";
import { ReactComponent as GarageSVG } from "modules/theme/icons/form/buttons/garage.svg";
import { ReactComponent as DrivewaySVG } from "modules/theme/icons/form/buttons/gate.svg";
import { ReactComponent as RoadSVG } from "modules/theme/icons/form/buttons/road.svg";
import { ReactComponent as CarParkSVG } from "modules/theme/icons/form/buttons/parking-sign.svg";
import { ReactComponent as LockedCompoundSVG } from "modules/theme/icons/form/buttons/lock.svg";
import { ReactComponent as PrivatePropertySVG } from "modules/theme/icons/form/buttons/house.svg";
import { ReactComponent as ComprehensiveSVG } from "modules/theme/icons/form/buttons/comprehensive.svg";
import { ReactComponent as ThirdPartyFireTheftSVG } from "modules/theme/icons/form/buttons/third-party-fire-theft.svg";
import { ReactComponent as ThirdPartyOnlySVG } from "modules/theme/icons/form/buttons/third-party-only.svg";
import { ReactComponent as BikeSVG } from "modules/theme/icons/form/buttons/bike.svg";
import { ReactComponent as AplhadotSVG } from "modules/theme/icons/form/buttons/tagging-device/alpha-dot.svg";
import { ReactComponent as DatatagSVG } from "modules/theme/icons/form/buttons/tagging-device/datatag.svg";
import { ReactComponent as SmartwaterSVG } from "modules/theme/icons/form/buttons/tagging-device/smartwater.svg";

export enum ButtonIconGroup {
  ALARM = "alarm",
  CONTACT = "contact",
  COVER = "cover",
  WHERE_KEPT = "where-kept",
}

export enum ButtonIconType {
  ADOBE_READER = "adobe-reader",
  EMAIL = "email",
  PDF = "pdf",
  PHONE = "phone",
  POST = "post",
  SELECT_ALL = "select-all",
  SMS = "sms",
  GARAGE = "where-kept-g",
  DRIVEWAY = "where-kept-d",
  ROAD = "where-kept-r",
  CAR_PARK = "where-kept-r-cp",
  LOCKED_COMPOUND = "where-kept-r-lc",
  PRIVATE_PROPERTY = "where-kept-r-pp",
  COMPREHENSIVE = "cover-c",
  THIRD_PARTY_FIRE_THEFT = "cover-tpft",
  THIRD_PARTY_ONLY = "cover-tpo",
  NO_TAGGING_DEVICE = "alarm-none",
  APLHADOT = "alarm-alph",
  DATATAG = "alarm-data",
  SMARTWATER = "alarm-sma-1",
}

type ButtonIconProps = {
  type?: ButtonIconType | string;
};

function ButtonIcon({ type }: ButtonIconProps) {
  switch (type) {
    case ButtonIconType.ADOBE_READER:
      return <AdobeReaderSVG />;
    case ButtonIconType.EMAIL:
      return <EmailSVG />;
    case ButtonIconType.PDF:
      return <PdfSVG />;
    case ButtonIconType.PHONE:
      return <PhoneSVG />;
    case ButtonIconType.POST:
      return <PostSVG />;
    case ButtonIconType.SELECT_ALL:
      return <SelectAllSVG />;
    case ButtonIconType.SMS:
      return <SmsSVG />;
    case ButtonIconType.GARAGE:
      return <GarageSVG />;
    case ButtonIconType.DRIVEWAY:
      return <DrivewaySVG />;
    case ButtonIconType.ROAD:
      return <RoadSVG />;
    case ButtonIconType.CAR_PARK:
      return <CarParkSVG />;
    case ButtonIconType.LOCKED_COMPOUND:
      return <LockedCompoundSVG />;
    case ButtonIconType.PRIVATE_PROPERTY:
      return <PrivatePropertySVG />;
    case ButtonIconType.COMPREHENSIVE:
      return <ComprehensiveSVG />;
    case ButtonIconType.THIRD_PARTY_FIRE_THEFT:
      return <ThirdPartyFireTheftSVG />;
    case ButtonIconType.THIRD_PARTY_ONLY:
      return <ThirdPartyOnlySVG />;
    case ButtonIconType.NO_TAGGING_DEVICE:
      return <BikeSVG />;
    case ButtonIconType.APLHADOT:
      return <AplhadotSVG />;
    case ButtonIconType.DATATAG:
      return <DatatagSVG />;
    case ButtonIconType.SMARTWATER:
      return <SmartwaterSVG />;
    default:
      return null;
  }
}

export default React.memo(ButtonIcon);
