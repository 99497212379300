import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { TrustpilotAction } from "./constants";
import { isError } from "modules/utils";
import {
  TrustpilotActionRequestStarted,
  TrustpilotActionRequestSuccess,
  TrustpilotActionRequestFailure,
  TrustpilotApiResponseResult,
  TrustpilotApiResponse,
} from "./types";

export const trustpilotActionRequest = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(trustpilotActionRequestStarted());
    try {
      const response: TrustpilotApiResponse = await api(API.TRUSTPILOT_REQUEST());
      dispatch(trustpilotActionRequestSuccess(response.data.trustpilot));
    } catch (error) {
      dispatch(trustpilotActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
    }
  };
};

const trustpilotActionRequestStarted = (): TrustpilotActionRequestStarted => ({
  type: TrustpilotAction.REQUEST_STARTED,
});

const trustpilotActionRequestSuccess = (trustpilot: TrustpilotApiResponseResult): TrustpilotActionRequestSuccess => ({
  type: TrustpilotAction.REQUEST_SUCCESS,
  trustpilot: trustpilot,
});

const trustpilotActionRequestFailure = (error: string): TrustpilotActionRequestFailure => ({
  type: TrustpilotAction.REQUEST_FAILURE,
  error: error,
});
