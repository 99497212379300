import React from "react";

import { Articles } from "modules/articles/types";
import Item from "./Item";

import styles from "./styles.module.scss";

type ConfirmationPagesProps = {
  pages: Articles["fixed"];
};

const ConfirmationPages: React.FunctionComponent<ConfirmationPagesProps> = ({ pages }) => {
  return (
    <section className={styles["confirmation-pages"]}>
      <h3>
        <span>Need help or have any questions</span>
      </h3>
      <div>
        {pages.map((page, index) => (
          <Item key={index} {...page} />
        ))}
      </div>
    </section>
  );
};

export default React.memo(ConfirmationPages);
