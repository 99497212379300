import React, { useState } from "react";
import clsx from "clsx";

import { ReactComponent as ChevronDownSVG } from "modules/theme/icons/form/select/chevron-down.svg";
import Button from "components/Button";

import styles from "./styles.module.scss";

type PolicyAdditionalProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  title: string;
};

const PolicyAdditional: React.FunctionComponent<PolicyAdditionalProps> = ({ children, isOpen, title }) => {
  const [open, setOpen] = useState(isOpen);
  const classNames = clsx(styles["policy-additional"], open && styles["open"]);
  return (
    <div className={classNames}>
      <header>
        <Button ariaLabel={title} onClick={() => setOpen(!open)}>
          <h2>{title}</h2>
          <ChevronDownSVG />
        </Button>
      </header>
      {open && <section>{children}</section>}
    </div>
  );
};

export default PolicyAdditional;
