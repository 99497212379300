import Messages from "modules/validation/messages";
import yup from "modules/validation";

const schema = yup.object({
  addressLine1: yup.string().required().maxLength(32),

  addressLine2: yup.string().required().maxLength(32),

  addressLine3: yup.string().maxLength(32),

  addressLine4: yup.string().maxLength(32),

  postCode: yup.string().when("addressLine2", {
    is: (value?: string) => !!value,
    then: schema => schema.required().postcode(true).maxLength(8, Messages.POSTCODE),
    otherwise: schema => schema.required().postcode().maxLength(8, Messages.POSTCODE),
  }),
});

export default schema;
