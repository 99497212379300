import { isAdmin } from "modules/admin";

const logger = {
  /* eslint-disable no-console */
  debug: (...messages: unknown[]) => {
    isAdmin && console.log(...messages);
  },
  error: (...messages: unknown[]) => {
    isAdmin && console.error(...messages);
  },
  warn: (...messages: unknown[]) => {
    isAdmin && console.warn(...messages);
  },
  /* eslint-enable no-console */
};

export default logger;
