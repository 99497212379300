import { produce } from "immer";

import { AggregatorActionRequestSuccess } from "modules/aggregator/types";
import { Risk, RiskActionRequestSuccess } from "../../types";

const requestSuccess = (state: Risk, action: AggregatorActionRequestSuccess | RiskActionRequestSuccess): Risk => {
  return produce(action.risk, (draftState: Risk) => {
    draftState.vehicle.regNumber = draftState.vehicle.regNumber.replace(/\s+/g, "");

    process.env.REACT_APP_MULTIBIKE === "true" &&
      draftState.additionalVehicles.forEach(item => {
        item.regNumber = item.regNumber.replace(/\s+/g, "");
      });

    process.env.REACT_APP_MULTIBIKE !== "true" && (draftState.hasAdditionalVehicles = false);
    process.env.REACT_APP_MULTIBIKE !== "true" && (draftState.additionalVehicles = []);
  });
};

export default requestSuccess;
