import { useMemo } from "react";

import getRedirect from "modules/router/redirect";
import { ROUTE } from "modules/router/constants";
import { UseRedirect } from "./types";

const useRedirect: UseRedirect = quoteToken =>
  useMemo(() => getRedirect({ quoteToken, route: ROUTE.PRODUCT }), [quoteToken]);

export default useRedirect;
