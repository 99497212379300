import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { MetadataAction, MetadataType } from "./constants";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import { RootState } from "modules/redux/store";
import { isAxiosError } from "modules/api";
import { isError } from "modules/utils";
import {
  MetadataActionRequestStarted,
  MetadataActionRequestSuccess,
  MetadataActionRequestFailure,
  MetadataApiResponseResult,
  MetadataApiResponse,
  MetadataActionInit,
} from "./types";

export const metadataActionInit = (): MetadataActionInit => ({
  type: MetadataAction.INIT,
});

export const metadataActionRequest = (endpoint: MetadataType) => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch(metadataActionRequestStarted());
    try {
      const response: MetadataApiResponse = await api(API.METADATA_REQUEST(endpoint));
      if (!response.data.metadata.length) {
        throw new Error(`Metadata ${endpoint} returned empty array`);
      }
      dispatch(metadataActionRequestSuccess(endpoint, response.data.metadata));
    } catch (error) {
      dispatch(metadataActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
    }
  };
};

const metadataActionRequestStarted = (): MetadataActionRequestStarted => ({
  type: MetadataAction.REQUEST_STARTED,
});

const metadataActionRequestSuccess = (
  endpoint: MetadataType,
  metadata: MetadataApiResponseResult,
): MetadataActionRequestSuccess => ({
  type: MetadataAction.REQUEST_SUCCESS,
  endpoint: endpoint,
  metadata: metadata,
});

const metadataActionRequestFailure = (error: string): MetadataActionRequestFailure => ({
  type: MetadataAction.REQUEST_FAILURE,
  error: error,
});
