import { useSelector } from "modules/redux/store";

import { Admin } from "modules/admin/types";
import { UseAppRoute } from "./types";

const useAppRoute: UseAppRoute = () => {
  const admin: Admin = useSelector(state => state.admin);
  return {
    showNavigation: admin.showNavigation,
  };
};

export default useAppRoute;
