import getPrice from "./getPrice";

const formatNumber = new Intl.NumberFormat("en-UK", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
});

const formatPrice = (value: number, decimals?: boolean): string =>
  decimals === false ? formatNumber.format(getPrice(value)).replace(".00", "") : formatNumber.format(getPrice(value));

export default formatPrice;
