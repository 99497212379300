import React, { useCallback } from "react";
import { FormikProps } from "formik";
import { kebabCase } from "lodash";
import clsx from "clsx";

import { ButtonIconGroup } from "components/Inputs/Buttons/Button/Icons";
import ButtonCheckbox, { ButtonCheckboxProps } from "./ButtonCheckbox";
import ButtonSwitch, { ButtonSwitchProps } from "./ButtonSwitch";
import ButtonRadio, { ButtonRadioProps } from "./ButtonRadio";
import { ButtonsFormat, ButtonsType } from "./constants";
import { useScrollRef } from "modules/scroll/hooks";

import styles from "./styles.module.scss";

export { ButtonsFormat, ButtonsType };

const iconName = (group: ButtonIconGroup, value: boolean | string): string =>
  `${group}-${value === true ? "yes" : value === false ? "no" : kebabCase(value)}`;

type ButtonProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  icon?: string;
  id: string;
  index: number;
  invalid?: boolean;
  onClick: (value: boolean | string | string[]) => void;
  option: { label: string; value: boolean | string };
  readOnly?: boolean;
  tabIndex?: number;
  type: ButtonsType;
  value: boolean | string | string[];
};

const Button = React.memo<ButtonProps>(({ type, ...props }) => {
  switch (type) {
    case ButtonsType.CHECKBOX:
      return <ButtonCheckbox {...(props as ButtonCheckboxProps)} />;
    case ButtonsType.RADIO:
      return <ButtonRadio {...(props as ButtonRadioProps)} />;
    case ButtonsType.SWITCH:
      return <ButtonSwitch {...(props as ButtonSwitchProps)} />;
    default:
      return null;
  }
});

export type ButtonsProps = {
  autoFocus?: boolean;
  columns?: true;
  disabled?: boolean;
  format?: ButtonsFormat;
  icons?: ButtonIconGroup;
  id: string;
  invalid?: boolean;
  name: string;
  options?: Array<{ label: string; value: boolean | string }>;
  readOnly?: boolean;
  setFieldTouched: FormikProps<boolean | string | string[]>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string | string[]>["setFieldValue"];
  tabIndex?: number;
  type: ButtonsType;
  value: boolean | string | string[];
};

const Buttons: React.FunctionComponent<ButtonsProps> = ({
  columns,
  icons,
  format,
  name,
  options,
  setFieldTouched,
  setFieldValue,
  type,
  ...props
}) => {
  const scrollRef = useScrollRef(props.id);
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLUListElement>) => {
      const currentTarget = event.currentTarget;
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement) && document.body.contains(currentTarget)) {
          setFieldTouched(name, true);
        }
      });
    },
    [name, setFieldTouched],
  );
  const handleClick = useCallback<(value: ButtonsProps["value"]) => void>(
    value => {
      setFieldValue(name, value);
      setTimeout(() => setFieldTouched(name, true));
    },
    [name, setFieldTouched, setFieldValue],
  );
  const className = clsx(
    styles["buttons"],
    styles[`${type}`],
    icons && styles["icons"],
    icons && styles[`icons-${icons}`],
    columns && ButtonsFormat.VERTICAL && styles["columns"],
    format === ButtonsFormat.VERTICAL ? styles["vertical"] : styles["horizontal"],
  );
  return (
    <ul className={className} onBlur={handleBlur} ref={!props.disabled && props.invalid ? scrollRef : undefined}>
      {options?.length
        ? options.map((option, index) => (
            <li key={index}>
              <Button
                icon={icons ? iconName(icons, option.value) : undefined}
                index={index}
                onClick={handleClick}
                option={option}
                type={type}
                {...props}
              />
            </li>
          ))
        : null}
    </ul>
  );
};

export default React.memo(Buttons);
