import React from "react";

import { ReactComponent as PdfSVG } from "modules/theme/icons/form/buttons/pdf.svg";
import { htmlSafe } from "modules/utils";

import styles from "./styles.module.scss";

type ItemProps = {
  label: string;
  link: string;
};

const Item: React.FunctionComponent<ItemProps> = ({ label, link }) => {
  return (
    <li className={styles["item"]}>
      <a href={link} rel="noopener noreferrer" target="_blank" onMouseDown={event => event.preventDefault()}>
        <PdfSVG />
        <span>{htmlSafe(label)}</span>
      </a>
    </li>
  );
};

export default React.memo(Item);
