import { useCallback, useEffect, useRef } from "react";
import { FormikProps } from "formik";

import { AddressSearchApiResponseResult } from "modules/api/addressSearch/types";
import { setFields } from "../helpers";

type UseHandleResponseProps = (props: {
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setShowDescription: (state: boolean) => void;
}) => (response: AddressSearchApiResponseResult) => void;

const useHandleResponse: UseHandleResponseProps = ({ setFieldTouched, setFieldValue, setShowDescription }) => {
  const handleResponseRef = useRef<ReturnType<UseHandleResponseProps>>(() => void 0);
  const handleResponse = useCallback<(response: AddressSearchApiResponseResult) => void>(
    response => handleResponseRef.current(response),
    [],
  );

  useEffect(() => {
    handleResponseRef.current = response => {
      setFields(response, setFieldTouched, setFieldValue);
      setShowDescription(true);
    };
  }, [setFieldTouched, setFieldValue, setShowDescription]);

  return handleResponse;
};

export default useHandleResponse;
