import React from "react";
import clsx from "clsx";

import { htmlSafe } from "modules/utils";

import styles from "./styles.module.scss";

type LabelProps = {
  disabled?: boolean;
  id: string;
  label: string | JSX.Element;
};

const Label: React.FunctionComponent<LabelProps> = ({ disabled, id, label }) => {
  const className = clsx(styles["label"], disabled && styles["disabled"]);
  function handleClick(event: React.MouseEvent<HTMLLabelElement>) {
    const element = document.getElementById(event.currentTarget.htmlFor) as HTMLElement;
    if (disabled) {
      event.preventDefault();
    } else if (element && (element.getAttribute("role") === "button" || element.getAttribute("type") === "button")) {
      event.preventDefault();
      element.focus();
    }
  }
  return (
    <label className={className} htmlFor={id} onClick={handleClick}>
      {typeof label === "string" ? htmlSafe(label) : label}
    </label>
  );
};

export default React.memo(Label);
