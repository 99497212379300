import { MetadataAction, MetadataType } from "./constants";
import { Metadata, MetadataActionType } from "./types";
import { produce } from "immer";

const initialState: Metadata = {
  [MetadataType.ALARM]: [],
  //[MetadataType.ALLRISKS]: [],
  [MetadataType.CLASS_OF_USE]: [],
  //[MetadataType.CONTENTS]: [],
  [MetadataType.CONVICTION]: [],
  //[MetadataType.COUNTRY]: [],
  [MetadataType.COVER_TYPE]: [],
  [MetadataType.DOCUMENT_DELIVERY]: [],
  [MetadataType.DRINK_READING_TYPE]: [],
  [MetadataType.EMPLOYER_BUSINESS]: [],
  [MetadataType.GENDER]: [],
  [MetadataType.IMMOBILISER]: [],
  //[MetadataType.IMPORTED]: [],
  [MetadataType.INSURANCE_START_DATE]: [],
  [MetadataType.LICENCE_HELD_YEARS]: [],
  [MetadataType.LICENCE_TYPES]: [],
  [MetadataType.MAKE]: [],
  [MetadataType.MARITAL_STATUS]: [],
  //[MetadataType.MEDICAL_CONDITION]: [],
  [MetadataType.MOTOR_CLAIM]: [],
  [MetadataType.MOTOR_ORG]: [],
  [MetadataType.NCD_YEARS]: [],
  [MetadataType.OCCUPATION]: [],
  [MetadataType.OCCUPATION_STUDENT]: [],
  [MetadataType.OCCUPATION_TYPES]: [],
  [MetadataType.OCCUPATION_TYPES_PART_TIME]: [],
  [MetadataType.PREVIOUS_INSURANCE]: [],
  [MetadataType.RELATIONSHIP_TO_PROPOSER]: [],
  //[MetadataType.RESTRICT]: [],
  [MetadataType.TITLE]: [],
  [MetadataType.TITLE_OTHER]: [],
  [MetadataType.TRACKER]: [],
  [MetadataType.VOL_XS]: [],
  [MetadataType.WHERE_HEAR]: [],
  [MetadataType.WHERE_KEPT]: [],
};

function metadataReducer(state = initialState, action: MetadataActionType) {
  switch (action.type) {
    case MetadataAction.INIT:
      return state;
    case MetadataAction.REQUEST_SUCCESS:
      return produce(state, (draftState: Metadata) => {
        draftState[action.endpoint] = [...action.metadata];
      });
    default:
      return state;
  }
}

export default metadataReducer;
