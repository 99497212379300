import React from "react";

import { ScrollContext } from "./types";

export default React.createContext<ScrollContext>({
  nodes: {},
  register: () => void 0,
  unregister: () => void 0,
  namespace: "",
});
