import { useEffect, useState } from "react";
import { FormikErrors, FormikProps } from "formik";
import sleep from "sleep-promise";

import { PostalAddress } from "modules/risk/types";
import useHandleResponse from "./handleResponse";
import useHandleKeyDown from "./handleKeyDown";
import useHandleRequest from "./handleRequest";
import useHandleChange from "./handleChange";
import useHandleSubmit from "./handleSubmit";
import useHandleError from "./handleError";
import { isError } from "modules/utils";

type UseSearchProps = {
  errors?: FormikErrors<PostalAddress>;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setFieldTouched: FormikProps<string>["setFieldTouched"];
  setFieldValue: FormikProps<string>["setFieldValue"];
  setShowDescription: (state: boolean) => void;
  values: PostalAddress;
};

type UseSearchReturnProps = [
  string | undefined,
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  () => void,
  boolean,
];

const useSearch = ({
  errors,
  onChange,
  setFieldTouched,
  setFieldValue,
  setShowDescription,
  values,
}: UseSearchProps): UseSearchReturnProps => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>();

  const handleChange = useHandleChange({ onChange, setApiError });

  const handleError = useHandleError(setApiError);

  const handleRequest = useHandleRequest(values);

  const handleResponse = useHandleResponse({ setFieldValue, setFieldTouched, setShowDescription });

  const handleSubmit = useHandleSubmit({
    errors,
    setApiError,
    setFieldTouched,
    setLoading,
  });

  const handleKeyDown = useHandleKeyDown({ handleSubmit, loading });

  useEffect(() => {
    if (loading) {
      let active = true;
      (async function () {
        try {
          await sleep(500);
          const response = await handleRequest();
          active && handleResponse(response);
        } catch (error) {
          active && isError(error) && handleError(error);
        } finally {
          active && setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [handleError, handleRequest, handleResponse, loading]);

  return [apiError, handleChange, handleKeyDown, handleSubmit, loading];
};

export default useSearch;
