import React from "react";
import { shallowEqual, useSelector } from "modules/redux/store";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";
import { App } from "modules/app/types";

type WrapperProps = {
  children?: (sales: string) => React.ReactNode;
};

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }) => {
  const app: App = useSelector(state => state.app);
  const { sales } = useSelector(state => app.contact.telephone, shallowEqual);
  return <React.Fragment>{children && children(sales)}</React.Fragment>;
};

const template: ModalInfoTemplateProps = {
  buttonLabel: "Close",
  title: "Accepted Modifications",
  text: (
    <Wrapper>
      {sales => (
        <React.Fragment>
          <p>You do not need to notify us if you have any of the following modifications:</p>
          <ul>
            <li>Adapted For disability</li>
            <li>Air filter changes</li>
            <li>Bar ends</li>
            <li>Braided hose</li>
            <li>Change of headlight (single or twin)</li>
            <li>Change of indicators</li>
            <li>Change of screen</li>
            <li>Change of tyres (road legal)</li>
            <li>Crash bars / bobbins / bungs</li>
            <li>Decals / stickers (non-racing replica)</li>
            <li>Engine casing guards</li>
            <li>Grippers</li>
            <li>Heated grips</li>
            <li>Hugger</li>
            <li>Mirrors added</li>
            <li>Non-standard seat / replacement seat / adding pillion seat</li>
            <li>Single seat conversion</li>
            <li>R&G crash protectors</li>
            <li>Rear seat cowl</li>
            <li>Scottoiler chain lubrication system</li>
            <li>Sissy bar</li>
            <li>Tail tidy</li>
            <li>Tank pads</li>
          </ul>
          <p>
            If you're unsure, <strong>please call us on {sales}</strong> and one our advisors will be happy to help.
          </p>
        </React.Fragment>
      )}
    </Wrapper>
  ),
};

export default template;
