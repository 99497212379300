import { useCallback } from "react";

import { scrollTop } from "modules/scroll/helpers";
import { ROUTE } from "modules/router/constants";
import { UseHandleBack } from "./types";

const useHandleBack: UseHandleBack = ({ hasAdditionalRiders, navigate }) =>
  useCallback(async () => {
    await scrollTop();
    navigate(hasAdditionalRiders ? ROUTE.RIDERS : ROUTE.RIDER);
  }, [hasAdditionalRiders, navigate]);

export default useHandleBack;
