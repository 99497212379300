import React from "react";
import { useNavigate } from "react-router-dom";

import Button, { ButtonStyle, ButtonTarget, ButtonType } from "components/Button";
import MessageWrapper from "components/MessageWrapper";
import OpeningTimes from "../components/OpeningTimes";
import { ROUTE } from "modules/router/constants";
import CallUs from "../components/CallUs";

type MessageQuoteProps = {
  webReference?: string;
};

const MessageQuote: React.FunctionComponent<MessageQuoteProps> = ({ webReference }) => {
  const navigate = useNavigate();
  return (
    <MessageWrapper>
      {({ openingTimes, telephone }) => (
        <React.Fragment>
          <header>
            <h2>Please call us to complete your quote.</h2>
            <h3>We just need to ask a few more questions in order to finish your quote.</h3>
          </header>

          {webReference ? (
            <p>
              Just let our advisor know you have already started your quote online and provide them with the following
              reference: <i>{webReference}</i> and they will be able to recall the details you have entered so far.
            </p>
          ) : (
            <p>
              Just let our advisor know you have already started your quote online and they will be able to recall the
              details you have entered so far using your registration or name and date of birth.
            </p>
          )}

          <CallUs telephone={telephone.sales} />

          <OpeningTimes openingTimes={openingTimes} />

          <Button
            ariaLabel="Go back"
            label="Go back"
            onClick={() => navigate(ROUTE.MOTORCYCLE)}
            style={ButtonStyle.SUCCESS}
          />

          <Button
            ariaLabel="Contact us"
            href="https://www.devittinsurance.com/contact-us/"
            label="Contact us"
            style={ButtonStyle.PRIMARY}
            target={webReference ? ButtonTarget.BLANK : ButtonTarget.SELF}
            type={ButtonType.LINK}
          />
        </React.Fragment>
      )}
    </MessageWrapper>
  );
};

export default React.memo(MessageQuote);
