import React from "react";

import ProductBreakdownTemplate from "../ProductBreakdownTemplate";

const ProductBreakdownIncludedPlus: React.FunctionComponent = () => {
  return (
    <ProductBreakdownTemplate
      included={true}
      open={false}
      subtitle="UK Roadside, Recovery and At Home cover plus Onward Travel and EU Breakdown. Repair will be attempted at the roadside or local garage, where this isn't possible, alternative transport or accommodation can be arranged."
      title="RAC UK & EU Roadside, Recovery, At Home & Onward Travel Breakdown Cover">
      <ul>
        <li>Help to repair the motorcycle if you're at home or more than ¼ of a mile away from home</li>
        <li>Replacement bike for up to 2 days whilst your bike is being repaired</li>
        <li>
          Recovery for you, your bike and passengers to a destination of your choice, within the UK, if the RAC cannot
          repair the motorcycle
        </li>
        <li>
          Repair will be attempted at the roadside or local garage, where this isn't possible, alternative transport or
          accommodation can be arranged
        </li>
        <li>Lines open 24/7</li>
      </ul>
      <blockquote>
        Breaking down can be an inconvenience that you could do without. But when it happens, be assured you've got
        someone coming to help you.
      </blockquote>
    </ProductBreakdownTemplate>
  );
};

export default React.memo(ProductBreakdownIncludedPlus);
