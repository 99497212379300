import { FormikErrors, FormikProps } from "formik";

import { AddressSearchApiResponseResult } from "modules/api/addressSearch/types";
import { PostalAddress } from "modules/risk/types";

export const validate = (
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  errors?: FormikErrors<PostalAddress>,
) => {
  setFieldTouched("proposer.address.addressLine1", true);
  setFieldTouched("proposer.address.postCode", true);
  return !(errors?.addressLine1 || errors?.postCode);
};

export const setFields = (
  result: AddressSearchApiResponseResult,
  setFieldTouched: FormikProps<string>["setFieldTouched"],
  setFieldValue: FormikProps<string>["setFieldValue"],
) => {
  setFieldTouched("proposer.address.addressLine1", false);
  setFieldValue("proposer.address.addressLine1", result.addressLine1 || "");

  setFieldTouched("proposer.address.addressLine2", false);
  setFieldValue("proposer.address.addressLine2", result.addressLine2 || "");

  setFieldTouched("proposer.address.addressLine3", false);
  setFieldValue("proposer.address.addressLine3", result.addressLine3 || "");

  setFieldTouched("proposer.address.addressLine4", false);
  setFieldValue("proposer.address.addressLine4", result.addressLine4 || "");

  setFieldTouched("proposer.address.postCode", false);
  setFieldValue("proposer.address.postCode", result.postCode || "");
};
