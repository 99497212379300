import React, { useMemo } from "react";
import { useId } from "react-id-generator";
import { FormikProps } from "formik";

import { ButtonIconType } from "components/Inputs/Buttons/Button/Icons";
import FieldGroup, { OptionType } from "components/Inputs/FieldGroup";
import { Risk } from "modules/risk/types";

import styles from "./styles.module.scss";

type PolicyContactProps = {
  setFieldTouched: FormikProps<boolean | string>["setFieldTouched"];
  setFieldValue: FormikProps<boolean | string>["setFieldValue"];
  values: Risk["additionalQuestions"];
};

const PolicyContact: React.FunctionComponent<PolicyContactProps> = ({ setFieldTouched, setFieldValue, values }) => {
  const [id] = useId(1, "question-");
  const options = useMemo((): OptionType[] => {
    return [
      {
        icon: ButtonIconType.EMAIL,
        label: "E-mail",
        name: "additionalQuestions.detailsOfProductsEmail",
        selected: !!values.detailsOfProductsEmail,
      },
      {
        icon: ButtonIconType.SMS,
        label: "Text / SMS",
        name: "additionalQuestions.detailsOfProductsText",
        selected: !!values.detailsOfProductsText,
      },
      {
        icon: ButtonIconType.PHONE,
        label: "Phone",
        name: "additionalQuestions.detailsOfProductsPhone",
        selected: !!values.detailsOfProductsPhone,
      },
      {
        icon: ButtonIconType.POST,
        label: "Post",
        name: "additionalQuestions.detailsOfProductsPost",
        selected: !!values.detailsOfProductsPost,
      },
    ];
  }, [
    values.detailsOfProductsEmail,
    values.detailsOfProductsPhone,
    values.detailsOfProductsPost,
    values.detailsOfProductsText,
  ]);
  return (
    <div className={styles["policy-contact"]}>
      <header>
        <h2>How Should We Contact You?</h2>
      </header>
      <div>
        <p>
          We wouldn't want you to miss out on offers, exclusive deals and the latest information on products and
          services that are available to you.
        </p>
        <p>If you don't wish to hear from us then please leave all options deselected.</p>
        <div>
          <FieldGroup
            id={id}
            options={options}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            styles={styles["buttons"]}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyContact;
