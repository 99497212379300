import { useMemo } from "react";

import { newAdditionalRider } from "modules/risk/helpers";
import { UseState } from "./types";

const useState: UseState = ({ id, index, risk }) =>
  useMemo(
    () => ({
      additionalRiders: risk.additionalRiders.filter(item => item.id !== id),
      currentAdditionalRider: id === "new" ? newAdditionalRider() : risk.additionalRiders[index],
      hasAdditionalRiders: risk.hasAdditionalRiders,
      hasAdditionalVehicles: risk.hasAdditionalVehicles,
      proposer: risk.proposer,
    }),
    [id, index, risk.additionalRiders, risk.hasAdditionalRiders, risk.hasAdditionalVehicles, risk.proposer],
  );

export default useState;
