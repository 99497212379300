import React from "react";

import PageWrapper, { PageWrapperFormat } from "components/PageWrapper";
import { TemplateProps } from "./types";
import * as Components from "./Quote";

const Template: React.FunctionComponent<TemplateProps> = ({
  cover,
  handleBack,
  handleContinue,
  handleDevitt,
  handleDevittEnhanced,
  handleDevittEvolution,
  normallyPayByDirectDebit,
  optionsCover,
  optionEnhanced,
  optionEvolution,
  quote,
}) => {
  return (
    <PageWrapper format={PageWrapperFormat.FULLWIDTH}>
      <Components.QuoteWraper onBack={handleBack} webReference={quote.webReference}>
        <Components.QuoteTiers
          cover={cover}
          handleDevitt={handleDevitt}
          handleDevittEnhanced={handleDevittEnhanced}
          handleDevittEvolution={handleDevittEvolution}
          normallyPayByDirectDebit={normallyPayByDirectDebit}
          optionsCover={optionsCover}
          optionEnhanced={optionEnhanced}
          optionEvolution={optionEvolution}
          quote={quote}
        />
        <Components.QuoteCart
          normallyPayByDirectDebit={normallyPayByDirectDebit}
          onContinue={handleContinue}
          optionEnhanced={optionEnhanced}
          optionEvolution={optionEvolution}
          quote={quote}
        />
      </Components.QuoteWraper>
    </PageWrapper>
  );
};

export default Template;
