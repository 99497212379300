import { isEqual } from "lodash";

import { AnalyticsEventCheckout, AnalyticsPayloadEcommerce } from "./types";
import { PaymentMethod } from "modules/risk/constants";
import { getPrice } from "modules/utils";
import { pushEvent } from "../utils";
import * as Items from "../items";

let cache = {};

const analyticsEventCheckout: AnalyticsEventCheckout = getState => {
  const {
    risk: {
      cart: { optionalExtras: cart, paymentMethod },
    },
    quote: { instalments, optionalExtras: options, premium, totalAnnualPrice },
  } = getState();

  const items = [Items.analyticsItemTierBasic(premium)];

  !!options.enhanced &&
    cart.includes(options.enhanced.type) &&
    items.push(Items.analyticsItemTierEnhanced(options.enhanced.grossPremium));

  !!options.enhancedPlus &&
    cart.includes(options.enhancedPlus.type) &&
    items.push(Items.analyticsItemTierEnhancedPlus(options.enhancedPlus.grossPremium));

  !!options.evolution &&
    cart.includes(options.evolution.type) &&
    items.push(Items.analyticsItemTierEvolution(options.evolution.grossPremium));

  !!options.evolutionPlus &&
    cart.includes(options.evolutionPlus.type) &&
    items.push(Items.analyticsItemTierEvolutionPlus(options.evolutionPlus.grossPremium));

  !!options.legal &&
    cart.includes(options.legal.type) &&
    items.push(Items.analyticsItemLegalExpenses(options.legal.grossPremium));

  if (cart.some(option => ["breakdown1", "breakdown2", "breakdown3", "breakdown4"].includes(option))) {
    switch (true) {
      case !!options.breakdown1 && cart.includes(options.breakdown1.type):
        !!options.breakdown1 && items.push(Items.analyticsItemBreakdown(options.breakdown1.grossPremium, "breakdown1"));
        break;

      case !!options.breakdown2 && cart.includes(options.breakdown2.type):
        !!options.breakdown2 && items.push(Items.analyticsItemBreakdown(options.breakdown2.grossPremium, "breakdown2"));
        break;

      case !!options.breakdown3 && cart.includes(options.breakdown3.type):
        !!options.breakdown3 && items.push(Items.analyticsItemBreakdown(options.breakdown3.grossPremium, "breakdown3"));
        break;

      case !!options.breakdown4 && cart.includes(options.breakdown4.type):
        !!options.breakdown4 && items.push(Items.analyticsItemBreakdown(options.breakdown4.grossPremium, "breakdown4"));
        break;
    }
  }

  !!options.helmetLeathers &&
    cart.includes(options.helmetLeathers.type) &&
    items.push(Items.analyticsItemHelmetLeathers(options.helmetLeathers.grossPremium));

  !!options.personalAccident &&
    cart.includes(options.personalAccident.type) &&
    items.push(Items.analyticsItemPersonalAccident(options.personalAccident.grossPremium));

  !!options.accidentHelmetLeathers &&
    cart.includes(options.accidentHelmetLeathers.type) &&
    items.push(Items.analyticsItemPersonalAccidentHelmetLeathers(options.accidentHelmetLeathers.grossPremium));

  paymentMethod === PaymentMethod.MONTHLY &&
    !!instalments &&
    items.push(Items.analyticsItemFinance(instalments.flatInterestAmount));

  const payload: AnalyticsPayloadEcommerce = {
    billing: paymentMethod === PaymentMethod.MONTHLY ? "monthly" : "annually",
    currency: "GBP",
    value:
      paymentMethod === PaymentMethod.MONTHLY && !!instalments
        ? getPrice(instalments.totalPayable)
        : getPrice(totalAnnualPrice),
    items,
  };

  if (!isEqual(cache, payload)) {
    pushEvent("begin_checkout", payload);
    cache = payload;
  }
};

export default analyticsEventCheckout;
