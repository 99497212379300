export enum ButtonsFormat {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum ButtonsType {
  CHECKBOX = "checkbox",
  RADIO = "radio",
  SWITCH = "switch",
}
