import { produce } from "immer";

import { Confirmation, ConfirmationActionType } from "./types";
import { ConfirmationAction } from "./constants";
import { newConfirmation } from "./helpers";

const initialState: Confirmation = newConfirmation();

function confirmationReducer(state = initialState, action: ConfirmationActionType) {
  switch (action.type) {
    case ConfirmationAction.REQUEST_SUCCESS:
      return produce(state, (draftState: Confirmation) => {
        draftState.date = action.confirmation.date;
        draftState.insurer = action.confirmation.insurer;
        draftState.policyRef = action.confirmation.policyRef;
        draftState.premiumAmount = action.confirmation.premiumAmount;
        draftState.time = action.confirmation.time;
        draftState.webReference = action.confirmation.webReference;
        draftState.whereHear = action.confirmation.whereHear;

        draftState.paymentMethod = action.confirmation.paymentMethod;
        draftState.premiumBasic = action.confirmation.premiumBasic;
        draftState.premiumEnhanced = action.confirmation.premiumEnhanced;
        draftState.premiumEnhancedPlus = action.confirmation.premiumEnhancedPlus;
        draftState.premiumEvolution = action.confirmation.premiumEvolution;
        draftState.premiumEvolutionPlus = action.confirmation.premiumEvolutionPlus;
        draftState.premiumBreakdown = action.confirmation.premiumBreakdown;
        draftState.tierBreakdown = action.confirmation.tierBreakdown;
        draftState.premiumLegal = action.confirmation.premiumLegal;
        draftState.premiumHelmetLeathers = action.confirmation.premiumHelmetLeathers;
        draftState.premiumPersonalAccident = action.confirmation.premiumPersonalAccident;
        draftState.premiumAccidentHelmetLeathers = action.confirmation.premiumAccidentHelmetLeathers;
        draftState.totalAnnualPrice = action.confirmation.totalAnnualPrice;
        draftState.totalMonthlyPrice = action.confirmation.totalMonthlyPrice;
        draftState.totalMonthlyInterest = action.confirmation.totalMonthlyInterest;
      });

    default:
      return state;
  }
}

export default confirmationReducer;
