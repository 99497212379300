import React, { useContext } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { RootState } from "modules/redux/store";
import { TransitionProps } from "@mui/material/transitions";
import { useSelector } from "react-redux";
import Zoom from "@mui/material/Zoom";

import { LoadingType } from "components/Loading/constants";
import { ReactComponent as Defaqto } from "./defaqto.svg";
import { ReactComponent as TrustpilotLogo } from "components/Header/Trustpilot/trustpilot-logo.svg";
import { ReactComponent as TrustpilotStars } from "components/Header/Trustpilot/trustpilot-stars.svg";
import Loading from "components/Loading";
import ModalOverlay from "../ModalOverlay";
import PlatformContext from "modules/platform/context";

import styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: JSX.Element },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export const ModalLoadingRequoteTemplate = React.memo(() => {
  const state = useSelector((state: RootState) => state.trustpilot);
  let stars = state.stars;
  stars < 1 && (stars = 1);
  stars > 5 && (stars = 5);
  const starsClassName = `header-trustpilot-stars-${String(stars).replace(/\..+/, "-5")}`;
  return (
    <section className={styles.modalLoadingRequote} role="dialog">
      <div>
        <Loading className={styles.dots} type={LoadingType.DOTS} />
        <p>Did we mention we're rated as Excellent on Trustpilot and our bike insurance is five star Defaqto rated?</p>
        <a
          href="https://uk.trustpilot.com/review/devittinsurance.com"
          onMouseDown={event => event.preventDefault()}
          rel="noopener noreferrer"
          target="_blank">
          <div>
            <div>{state.adjective}</div>
            <div>
              <TrustpilotStars aria-label={`${stars} stars`} className={styles[starsClassName]} />
            </div>
          </div>
          <div>
            <div>
              <span>{state.reviews.toLocaleString()}</span> reviews on
            </div>
            <TrustpilotLogo />
          </div>
        </a>
        <Defaqto />
      </div>
    </section>
  );
});

export type ModalLoadingRequoteProps = {
  destroyModal: () => void;
  hideModal: () => void;
  id: string;
  open: boolean;
};

const ModalLoadingRequote: React.FunctionComponent<ModalLoadingRequoteProps> = ({ destroyModal, open }) => {
  const { isMobile } = useContext(PlatformContext);
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay onExited={destroyModal} open={open} scroll="paper" TransitionComponent={Transition}>
        <ModalLoadingRequoteTemplate />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default ModalLoadingRequote;
