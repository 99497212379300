import { AnalyticsItemTierEnhanced } from "./types";
import { getPrice } from "modules/utils";

const analyticsItemTierEnhanced: AnalyticsItemTierEnhanced = price => ({
  item_id: "DEVITT_ENHANCED",
  item_name: "Devitt Enhanced Cover",
  affiliation: "Devitt MC",
  item_brand: "Devitt",
  item_category: "MC Insurance",
  price: getPrice(price),
  quantity: 1,
});

export default analyticsItemTierEnhanced;
