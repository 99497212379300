import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "modules/redux/store";

import ModalLoadingRequote, { ModalLoadingRequoteProps } from "components/Modals/ModalLoadingRequote";
import ModalLoadingQuote, { ModalLoadingQuoteProps } from "components/Modals/ModalLoadingQuote";
import ModalConfirm, { ModalConfirmProps } from "components/Modals/ModalConfirm";
import ModalLoading, { ModalLoadingProps } from "components/Modals/ModalLoading";
import ModalForm, { ModalFormProps } from "components/Modals/ModalForm";
import ModalInfo, { ModalInfoProps } from "components/Modals/ModalInfo";
import { modalActionDestroy, modalActionHide } from "./actions";
import ModalSession from "components/Modals/ModalSession";
import SessionContext from "modules/session/context";
import { RootState } from "modules/redux/store";
import { ModalType } from "./constants";

import styles from "./styles.module.scss";

type RenderModalProps = {
  type: ModalType;
} & (
  | ({ type: ModalType.CONFIRM } & ModalConfirmProps)
  | ({ type: ModalType.FORM } & ModalFormProps)
  | ({ type: ModalType.INFO } & ModalInfoProps)
  | ({ type: ModalType.LOADING } & ModalLoadingProps)
  | ({ type: ModalType.LOADING_QUOTE } & ModalLoadingQuoteProps)
  | ({ type: ModalType.LOADING_REQUOTE } & ModalLoadingRequoteProps)
);

const renderModal = ({ type, ...props }: RenderModalProps) => {
  switch (type) {
    case ModalType.CONFIRM:
      return <ModalConfirm key={props.id} {...(props as ModalConfirmProps)} />;
    case ModalType.FORM:
      return <ModalForm key={props.id} {...(props as ModalFormProps)} />;
    case ModalType.INFO:
      return <ModalInfo key={props.id} {...(props as ModalInfoProps)} />;
    case ModalType.LOADING:
      return <ModalLoading key={props.id} {...(props as ModalLoadingProps)} />;
    case ModalType.LOADING_QUOTE:
      return <ModalLoadingQuote key={props.id} {...(props as ModalLoadingQuoteProps)} />;
    case ModalType.LOADING_REQUOTE:
      return <ModalLoadingRequote key={props.id} {...(props as ModalLoadingRequoteProps)} />;
    default:
      return null;
  }
};

const Modals: React.FunctionComponent = () => {
  const { sessionAction, sessionActive, sessionIdle } = useContext(SessionContext);
  const modals = useSelector((state: RootState) => state.modals);
  const dispatch: Dispatch = useDispatch();
  return sessionActive && (modals.length > 0 || sessionIdle) ? (
    <div className={styles["modals"]}>
      {modals.map(modal =>
        renderModal({
          destroyModal: () => dispatch(modalActionDestroy(modal.id)),
          hideModal: () => dispatch(modalActionHide(modal.id)),
          ...modal,
        }),
      )}
      {sessionIdle && <ModalSession sessionAction={sessionAction} />}
    </div>
  ) : null;
};

export default Modals;
