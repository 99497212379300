import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import { ModalType } from "modules/modals/constants";
import { RootState } from "modules/redux/store";
import { ProceedApiResponse } from "./types";
import { ProceedAction } from "./constants";
import { isAxiosError } from "modules/api";
import { Risk } from "modules/risk/types";
import { isError } from "modules/utils";
import {
  ProceedActionRequestConfigure,
  ProceedActionRequestStarted,
  ProceedActionRequestSuccess,
  ProceedActionRequestFailure,
  ProceedActionUpdate,
  Proceed,
} from "./types";

export const proceedActionRequest = () => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const id = dispatch(modalActionShow({ type: ModalType.LOADING }));
    const { risk, quote } = getState();
    dispatch(proceedActionRequestConfigure(risk, quote.webReference));
    dispatch(proceedActionRequestStarted());
    try {
      const { proceed } = getState();
      const response: ProceedApiResponse = await api(API.PROCEED_REQUEST(proceed));
      dispatch(proceedActionRequestSuccess(response.data.redirectUrl));
      window.location.href = response.data.redirectUrl;
    } catch (error) {
      dispatch(proceedActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
      dispatch(modalActionHide(id));
    }
  };
};

export const proceedActionUpdate = (proceed: Proceed): ProceedActionUpdate => ({
  type: ProceedAction.UPDATE,
  proceed: proceed,
});

const proceedActionRequestConfigure = (risk: Risk, webReference: string): ProceedActionRequestConfigure => ({
  type: ProceedAction.REQUEST_CONFIGURE,
  risk: risk,
  webReference: webReference,
});

const proceedActionRequestStarted = (): ProceedActionRequestStarted => ({
  type: ProceedAction.REQUEST_STARTED,
});

const proceedActionRequestSuccess = (url: ProceedApiResponse["data"]["redirectUrl"]): ProceedActionRequestSuccess => ({
  type: ProceedAction.REQUEST_SUCCESS,
  url,
});

const proceedActionRequestFailure = (error: string): ProceedActionRequestFailure => ({
  type: ProceedAction.REQUEST_FAILURE,
  error: error,
});
