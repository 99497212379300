import { AbiApiRequest, AbiApiResponseResult } from "./types";

const cache: {
  abi: AbiApiRequest;
  result: AbiApiResponseResult;
}[] = [];

export const cacheSearch = (abi: AbiApiRequest) => {
  return cache.find(item => item.abi === abi);
};

export const cacheResult = (abi: AbiApiRequest, result: AbiApiResponseResult) => {
  !cacheSearch(abi) &&
    cache.push({
      abi: abi,
      result: result,
    });
};
