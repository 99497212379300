import React, { useCallback } from "react";
import clsx from "clsx";

import { ReactComponent as PlusSVG } from "modules/theme/icons/product/plus.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";
import Button, { ButtonStyle } from "components/Button";
import { PaymentMethod } from "modules/risk/constants";
import { OptionalExtra } from "modules/quote/types";
import { formatPrice } from "modules/utils";

import styles from "./styles.module.scss";

type ProductBreakdownOptionProps = {
  description: string;
  onAdd: (option: OptionalExtra) => void;
  onRemove: (option: OptionalExtra) => void;
  option: OptionalExtra;
  paymentMethod: PaymentMethod;
  selected: boolean;
  title: string;
};

const ProductBreakdownOption: React.FunctionComponent<ProductBreakdownOptionProps> = ({
  description,
  onAdd,
  onRemove,
  option,
  paymentMethod,
  selected,
  title,
}) => {
  const onClick = useCallback(() => (selected ? onRemove(option) : onAdd(option)), [selected, onAdd, onRemove, option]);
  const classNames = clsx(styles["product-breakdown-option"], selected && styles.selected);
  return (
    <article className={classNames}>
      <h3>{title}</h3>
      <p>{description}</p>
      {paymentMethod === PaymentMethod.MONTHLY ? (
        <span>
          <span>{formatPrice(Math.round((option.grossPremium || 0) / 12), true)} p/m</span>
          <small>{formatPrice(option.grossPremium || 0, true)} p/year</small>
        </span>
      ) : (
        <span>
          <span>{formatPrice(option.grossPremium || 0, true)}</span>
          <small>{formatPrice(Math.round((option.grossPremium || 0) / 12), true)} p/m</small>
        </span>
      )}
      <Button
        ariaLabel={selected ? "Selected" : "Select"}
        style={selected ? ButtonStyle.SUCCESS : ButtonStyle.PRIMARY}
        onClick={onClick}>
        {selected ? <TickSVG /> : <PlusSVG />}
      </Button>
    </article>
  );
};

export default React.memo(ProductBreakdownOption);
