export enum ModalAction {
  DESTROY = "MODAL_DESTROY",
  INSERT = "MODEL_INSERT",
  HIDE = "MODAL_HIDE",
}

export enum ModalType {
  CONFIRM = "confirm",
  FORM = "form",
  INFO = "info",
  LOADING = "loading",
  LOADING_QUOTE = "loading-quote",
  LOADING_REQUOTE = "loading-requote",
}
