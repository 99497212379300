import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type LoadingDotsProps = {
  className?: string;
};

const LoadingDots: React.FunctionComponent<LoadingDotsProps> = ({ className }) => {
  const classNames = clsx(styles.loadingDots, className);
  return (
    <div className={classNames}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingDots;
