import React from "react";

import { formatPrice } from "modules/utils";
import { Risk } from "modules/risk/types";

type PriceHeaderProps = {
  instalments: boolean;
  label: string;
  monthly: Risk["additionalQuestions"]["normallyPayByDirectDebit"];
  priceAnnually: number;
  priceMonthly?: number;
};

const PriceHeader: React.FunctionComponent<PriceHeaderProps> = ({
  instalments,
  label,
  monthly,
  priceAnnually,
  priceMonthly,
}) =>
  monthly === false ? (
    <div>
      <strong>
        <span>
          {label !== "pcm" && "+"}
          {formatPrice(priceAnnually)}
        </span>
        {" p/y"}
      </strong>
      {instalments && (
        <span>
          or {formatPrice(priceMonthly || 0)} {label}
        </span>
      )}
    </div>
  ) : (
    <div>
      {instalments && (
        <strong>
          <span>
            {label !== "pcm" && "+"}
            {formatPrice(priceMonthly || 0)}
          </span>{" "}
          {label}
        </strong>
      )}
      <span>or {formatPrice(priceAnnually)} annually</span>
    </div>
  );

export default React.memo(PriceHeader);
