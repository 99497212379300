import { useCallback, useEffect, useRef } from "react";

import { ModalInfoTemplateProps } from "components/Modals/ModalInfo";
import { ModalType } from "modules/modals/constants";
import useModalAction from "./useModalAction";

import modalInfoAccidentHelmetLeathers from "modules/modals/templates/info/modalInfoAccidentHelmetLeathers";
import modalInfoPersonalAccidentCover from "modules/modals/templates/info/modalInfoPersonalAccidentCover";
import modalInfoBreakdownOnwardTravel from "modules/modals/templates/info/modalInfoBreakdownOnwardTravel";
import modalInfoDirectDebitGuarantee from "modules/modals/templates/info/modalInfoDirectDebitGuarantee";
import modalInfoThirdPartyFireTheft from "modules/modals/templates/info/modalInfoThirdPartyFireTheft";
import modalInfoHelmetLeathersCover from "modules/modals/templates/info/modalInfoHelmetLeathersCover";
import modalInfoComprehensiveCover from "modules/modals/templates/info/modalInfoComprehensiveCover";
import modalInfoBreakdownRecovery from "modules/modals/templates/info/modalInfoBreakdownRecovery";
import modalInfoBreakdownRoadside from "modules/modals/templates/info/modalInfoBreakdownRoadside";
import modalInfoDirectDebitTerms from "modules/modals/templates/info/modalInfoDirectDebitTerms";
import modalInfoBreakdownAtHome from "modules/modals/templates/info/modalInfoBreakdownAtHome";
import modalInfoSportsBikeShop from "modules/modals/templates/info/modalInfoSportsBikeShop";
import modalInfoThirdPartyOnly from "modules/modals/templates/info/modalInfoThirdPartyOnly";
import modalInfoVehicleSearch from "modules/modals/templates/info/modalInfoVehicleSearch";
import modalInfoModifications from "modules/modals/templates/info/modalInfoModifications";
import modalInfo90DaysEUCover from "modules/modals/templates/info/modalInfo90DaysEUCover";
import modalInfoLegalExpenses from "modules/modals/templates/info/modalInfoLegalExpenses";
import modalInfo247ClaimLine from "modules/modals/templates/info/modalInfo247ClaimLine";
import modalInfoAssumptions from "modules/modals/templates/info/modalInfoAssumptions";
import modalInfoBreakdown from "modules/modals/templates/info/modalInfoBreakdown";

export enum ModalInfoTemplates {
  ACCIDENT_HELMET_LEATHERS = "modalInfoAccidentHelmetLeathers",
  ASSUMPTIONS = "modalInfoAssumptions",
  BREAKDOWN = "modalInfoBreakdown",
  BREAKDOWN_AT_HOME = "modalInfoBreakdownAtHome",
  BREAKDOWN_ONWARD_TRAVEL = "modalInfoBreakdownOnwardTravel",
  BREAKDOWN_RECOVERY = "modalInfoBreakdownRecovery",
  BREAKDOWN_ROADSIDE = "modalInfoBreakdownRoadside",
  CLAIM_LINE = "modalInfo247ClaimLine",
  COMPREHENSIVE_COVER = "modalInfoComprehensiveCover",
  DIRECT_DEBIT_GUARANTEE = "modalInfoDirectDebitGuarantee",
  DIRECT_DEBIT_TERMS = "modalInfoDirectDebitTerms",
  EU_COVER = "modalInfo90DaysEUCover",
  HELMET_LEATHERS_COVER = "modalInfoHelmetLeathersCover",
  LEGAL_EXPENSES = "modalInfoLegalExpenses",
  MODIFICATIONS = "modalInfoModifications",
  PERSONAL_ACCIDENT_COVER = "modalInfoPersonalAccidentCover",
  SPORTS_BIKE_SHOP = "modalInfoSportsBikeShop",
  THIRD_PARTY_FIRE_THEFT = "modalInfoThirdPartyFireTheft",
  THIRD_PARTY_ONLY = "modalInfoThirdPartyOnly",
  VEHICLE_SEARCH = "modalInfoVehicleSearch",
}

function getTemplate(template: ModalInfoTemplates): ModalInfoTemplateProps | undefined {
  switch (template) {
    case ModalInfoTemplates.ACCIDENT_HELMET_LEATHERS:
      return modalInfoAccidentHelmetLeathers;
    case ModalInfoTemplates.ASSUMPTIONS:
      return modalInfoAssumptions;
    case ModalInfoTemplates.BREAKDOWN:
      return modalInfoBreakdown;
    case ModalInfoTemplates.BREAKDOWN_AT_HOME:
      return modalInfoBreakdownAtHome;
    case ModalInfoTemplates.BREAKDOWN_ONWARD_TRAVEL:
      return modalInfoBreakdownOnwardTravel;
    case ModalInfoTemplates.BREAKDOWN_RECOVERY:
      return modalInfoBreakdownRecovery;
    case ModalInfoTemplates.BREAKDOWN_ROADSIDE:
      return modalInfoBreakdownRoadside;
    case ModalInfoTemplates.CLAIM_LINE:
      return modalInfo247ClaimLine;
    case ModalInfoTemplates.COMPREHENSIVE_COVER:
      return modalInfoComprehensiveCover;
    case ModalInfoTemplates.DIRECT_DEBIT_GUARANTEE:
      return modalInfoDirectDebitGuarantee;
    case ModalInfoTemplates.DIRECT_DEBIT_TERMS:
      return modalInfoDirectDebitTerms;
    case ModalInfoTemplates.EU_COVER:
      return modalInfo90DaysEUCover;
    case ModalInfoTemplates.HELMET_LEATHERS_COVER:
      return modalInfoHelmetLeathersCover;
    case ModalInfoTemplates.LEGAL_EXPENSES:
      return modalInfoLegalExpenses;
    case ModalInfoTemplates.MODIFICATIONS:
      return modalInfoModifications;
    case ModalInfoTemplates.PERSONAL_ACCIDENT_COVER:
      return modalInfoPersonalAccidentCover;
    case ModalInfoTemplates.SPORTS_BIKE_SHOP:
      return modalInfoSportsBikeShop;
    case ModalInfoTemplates.THIRD_PARTY_FIRE_THEFT:
      return modalInfoThirdPartyFireTheft;
    case ModalInfoTemplates.THIRD_PARTY_ONLY:
      return modalInfoThirdPartyOnly;
    case ModalInfoTemplates.VEHICLE_SEARCH:
      return modalInfoVehicleSearch;
    default:
      return undefined;
  }
}

type HandleShowModalProps = {
  disableRestoreFocus?: boolean;
  event?:
    | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
    | React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>;
  template: ModalInfoTemplateProps | undefined;
};

type HandleShowInfoModalProps = Omit<HandleShowModalProps, "template"> & {
  template: ModalInfoTemplates;
};

const useModalInfo = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      modal.current && modalHide(modal.current);
    };
  }, [modalHide]);

  const handleShowModal = useCallback(
    ({ disableRestoreFocus, event, template }: HandleShowModalProps) => {
      if (template && !modal.current) {
        modal.current = modalShow({
          ...template,
          disableRestoreFocus:
            disableRestoreFocus !== undefined
              ? disableRestoreFocus
              : event && event.currentTarget !== document.activeElement,
          onClose: () => {
            modal.current && modalHide(modal.current);
            modal.current = null;
          },
          type: ModalType.INFO,
        });
      }
    },
    [modalHide, modalShow],
  );

  const handleShowInfoModal = useCallback(
    ({ disableRestoreFocus, event, template }: HandleShowInfoModalProps) => {
      handleShowModal({
        disableRestoreFocus: disableRestoreFocus,
        event,
        template: getTemplate(template),
      });
    },
    [handleShowModal],
  );

  return handleShowInfoModal;
};

export default useModalInfo;
