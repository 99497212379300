import { produce } from "immer";

import { Risk, RiskActionResetAdditionalRiders } from "../../types";

const resetAdditionalRiders = (state: Risk, action: RiskActionResetAdditionalRiders): Risk => {
  return produce(state, (draftState: Risk) => {
    draftState.hasAdditionalRiders = false;
  });
};

export default resetAdditionalRiders;
